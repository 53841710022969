import { useNavigate } from 'react-router-dom';

export const LogoImage = () => {
  const navigate = useNavigate();

  return (
    <img
      src="/convelio-grey-crate.svg"
      alt="Convelio - Search no result"
      onClick={() => navigate('/', { replace: true })}
    />
  );
};
