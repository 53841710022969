import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../../locales/translations';
import {
  BookingActionType,
  CrateActionType
} from '../../../../../../../types/BookingType';
import { ActivityData } from '../../ActivityData';
import { BuilderDecisionParams } from '../../BuilderDecisionParams';
import { EventMessageBuilder } from '../../EventMessageBuilder';

export const useEstimationCrateUpdatedMessageBuilder =
  (): EventMessageBuilder => {
    const { t } = useTranslation();

    const supports = ({
      eventResult: { type, data }
    }: BuilderDecisionParams): boolean => {
      return (
        type === BookingActionType.CRATE_UPDATED &&
        data.type === CrateActionType.ESTIMATION
      );
    };

    const build = (_: ActivityData): string => {
      return t(
        translations.pages.booking.features.activity.estimationCrateUpdated
      );
    };

    return {
      supports,
      build
    };
  };
