import { useEffect, useState } from 'react';

import { SearchBookingListResponse } from '../../Core/Application/SearchBookingsResponse';
import { isSearchValueValid } from '../../Core/Domain/Validator/IsSearchValueValid';
import { useSearchBookingsUseCase } from '../../Infrastructure/SearchBarUseCaseContext';
import { useDebouncedSearch } from './useDebouncedSearch';

interface SearchBookingsResultType {
  bookings: SearchBookingListResponse;
  isLoading: boolean;
  error?: Error;
}

export function useSearchBookings(
  searchValue: string
): SearchBookingsResultType {
  const searchBookingsUseCase = useSearchBookingsUseCase();
  const debouncedQuery = useDebouncedSearch(searchValue);
  const [bookings, setBookings] =
    useState<SearchBookingListResponse>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const search = async () => {
    if (!isSearchValueValid(debouncedQuery)) return;
    setIsLoading(true);
    try {
      const bookings = await searchBookingsUseCase.execute(debouncedQuery);
      setBookings(bookings);
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      } else {
        setError(new Error('An unexpected error occurred'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    search();
  }, [debouncedQuery]);

  return {
    bookings,
    isLoading,
    error
  };
}
