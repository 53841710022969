import { Box, styled } from '@mui/material';
import { useResponsiveness } from 'src/app/hooks/useResponsive';

import { BookingPage, BookingPageProps } from '../types/BookingPage';

type ContentWrapperProps = {
  children: JSX.Element;
};
const StyledBox = styled(Box)({
  height: 'calc(100% - 72px)'
});

function PageContentWrapper({ children }: ContentWrapperProps): JSX.Element {
  return <StyledBox>{children}</StyledBox>;
}

type Props = {
  page: BookingPage;
  pageProps: BookingPageProps;
};

function BookingWrapper({ page, pageProps }: Props): JSX.Element {
  const { isTabletView } = useResponsiveness();
  return (
    <PageContentWrapper>
      {isTabletView
        ? page.mobileTabletView(pageProps)
        : page.desktopView(pageProps)}
    </PageContentWrapper>
  );
}

export default BookingWrapper;
