import { Document } from '@react-pdf/renderer';

import { BookingItem } from '../../../../types/Booking';
import { ConvelioLayoutPage } from '../../Page';
import { MerchandiseView } from '../../Page/Section/Merchandise/MerchandiseView';
import { DeliveryView } from './DeliveryView';
import { PickupView } from './PickupView';

interface TransportBookingPDFProps {
  booking: BookingItem;
}

export const TransportBookingDocument = ({
  booking
}: TransportBookingPDFProps): JSX.Element => {
  return (
    <Document>
      <ConvelioLayoutPage booking={booking}>
        <PickupView booking={booking} />
        <DeliveryView booking={booking} />
      </ConvelioLayoutPage>
      <ConvelioLayoutPage booking={booking}>
        <MerchandiseView booking={booking} />
      </ConvelioLayoutPage>
    </Document>
  );
};
