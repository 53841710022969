import { useMemo } from 'react';

import { PackingCrate } from '../../../../../../types/Packing';

export const usePackingCrateDimensions = (
  crate: PackingCrate,
  isConfirmation = false
): string => {
  return useMemo(() => {
    let dimensions = `${crate.length} x ${crate.depth} x ${
      crate.height
    }${crate.lengthMetric?.toLowerCase()}`;

    if (isConfirmation) {
      dimensions = `${dimensions}, ${
        crate.weight
      }${crate.weightMetric?.toLocaleLowerCase()}`;
    }

    return dimensions;
  }, [crate, isConfirmation]);
};
