import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { translations } from '../../../../locales/translations';

function ForgetPasswordLink(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        textAlign: 'right',
        marginTop: 16
      }}
    >
      <Typography
        sx={{
          lineHeight: '1.86rem',
          fontWeight: 700,
          color: 'primary.main'
        }}
      >
        <Link to="/forgot-password">
          {t(translations.forgottenPassword.title)}
        </Link>
      </Typography>
    </Box>
  );
}

export default ForgetPasswordLink;
