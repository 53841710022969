import styles from '@convelio/stylio/styles.module.scss';
import EditNoteIcon from '@mui/icons-material/EditNote';
import {
  Box,
  CircularProgress,
  Skeleton,
  Typography,
  styled
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Button } from 'src/app/components/Button';

import { Address } from '../../../../../../types/Address';
import DatePickerInput from '../../../../../components/DatePickerInput';
import { useDate } from '../../../../../hooks/useDate';
import { useResponsiveness } from '../../../../../hooks/useResponsive';
import { ScheduleDateModal } from './ScheduleDateModal';
import ScheduleDateTitle from './ScheduleDateTitle';

type Props = {
  date?: Date | null;
  getTitle?: (isEditMode: boolean) => string;
  label: string;
  isDone?: boolean;
  maxDate?: Date;
  minDate?: Date;
  isLoading?: boolean;
  isEditMode?: boolean;
  handleSubmit: (date?: Date) => Promise<void>;
  datatestid?: string;
  validateInputDate?: (date: Date) => string[];
  address?: Address;
  modalTitle?: string;
  modalDescription?: string;
};

function MainDatePicker({
  date,
  getTitle,
  address,
  label,
  isDone,
  maxDate,
  minDate,
  isLoading,
  isEditMode,
  handleSubmit,
  datatestid,
  modalTitle = '',
  modalDescription = '',
  validateInputDate
}: Props): JSX.Element {
  const { fromDateToFormat, dateFormat, today } = useDate();
  const [dateInput, setDateInput] = useState<Date | null>(date || today);
  const [editMode, setEditMode] = useState<boolean>(isEditMode || false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const { isMaxWidthTablet } = useResponsiveness();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    setDateInput(date || today);
    setEditMode(isEditMode || false);
  }, [date, isEditMode]);

  const onValidateInputDate = (date: Date): void => {
    if (validateInputDate) {
      const errorMessages = validateInputDate(date);
      setErrorMessages(errorMessages);
    }
  };

  const toggleEditMode = () => {
    isMaxWidthTablet ? setIsModalOpen(true) : setEditMode(!editMode);
  };

  const handleDateSubmit = async (dateValue?: Date): Promise<void> => {
    const effectiveDateValue = dateValue || dateInput;
    if (errorMessages.length > 0 || !effectiveDateValue) return;
    const isSameDate = date && date.getTime() === effectiveDateValue.getTime();

    setIsLoadingSubmit(true);

    if (!isSameDate) {
      await handleSubmit(effectiveDateValue);
    }

    setEditMode(false);
    setIsLoadingSubmit(false);
  };

  return (
    <>
      <ScheduleDateModal
        title={modalTitle}
        description={modalDescription}
        date={date}
        isOpen={isModalOpen}
        setOpen={setIsModalOpen}
        isLoading={isLoading}
        address={address}
        onDateChange={handleDateSubmit}
        minDate={minDate}
        maxDate={maxDate}
      />
      <Box width={'100%'} data-testid="main-date-picker">
        {getTitle && (
          <ScheduleDateTitle
            isLoading={isLoading}
            title={getTitle(editMode)}
            isValid={editMode}
          />
        )}
        <Box
          sx={{
            marginTop: 4
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '85%',
                overflow: 'hidden'
              }}
            >
              <Skeleton variant="text" height="40px" width="60%" />
              <Skeleton
                sx={{ ml: '3%' }}
                variant="text"
                height="40px"
                width="20%"
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                height: '100%'
              }}
            >
              {editMode ? (
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <DatePickerInput
                      datatestid={datatestid}
                      date={dateInput}
                      handleDateChange={setDateInput}
                      minDate={minDate}
                      maxDate={maxDate}
                      validateInputDate={onValidateInputDate}
                    />
                    <Button
                      variant="plain"
                      label={label}
                      dataTestid={
                        datatestid ? `${datatestid}-btn` : 'btn-datepicker'
                      }
                      disabled={errorMessages.length > 0 || isLoadingSubmit}
                      onClick={(_) => handleDateSubmit()}
                      isLoading={isLoadingSubmit}
                      startIcon={<CircularProgress size="1rem" />}
                    />
                  </Box>

                  {errorMessages && (
                    <Box>
                      {errorMessages.map((error, index) => (
                        <Box key={index}>
                          <ErrorTypography>{error}</ErrorTypography>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              ) : (
                <StyledBox>
                  <Typography data-testid={`${datatestid}-value`}>
                    {date ? fromDateToFormat(date) : dateFormat.toUpperCase()}
                  </Typography>
                  {!isDone && (
                    <Box
                      sx={{ ml: 5 }}
                      data-testid={
                        datatestid ? `${datatestid}-edit-btn` : 'edit-btn'
                      }
                    >
                      <Button
                        variant="outlined"
                        color="neutral"
                        startIcon={<EditNoteIcon fontSize="inherit" />}
                        onClick={toggleEditMode}
                        additionalClasses="-icon"
                      />
                    </Box>
                  )}
                </StyledBox>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default MainDatePicker;

const ErrorTypography = styled(Typography)({
  color: 'error.main',
  marginTop: 8,
  fontSize: '0.8rem',
  lineHeight: '14px'
});

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  gap: styles.cvoSpacingXs,
  width: '100%',
  [theme.breakpoints.down('tablet')]: {
    justifyContent: 'space-between'
  }
}));
