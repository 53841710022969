import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../../locales/translations';
import { BookingUserType } from '../../../../../../../types/BookingType';
import { ActivityData } from '../../ActivityData';
import { BuilderDecisionParams } from '../../BuilderDecisionParams';
import { EventMessageBuilder } from '../../EventMessageBuilder';

export const usePackLoadMessageBuilder = (): EventMessageBuilder => {
  const { t } = useTranslation();

  const supports = ({
    eventResult: { data, userType }
  }: BuilderDecisionParams): boolean => {
    return (
      userType === BookingUserType.SF &&
      (data.packLoadValue !== undefined ||
        data.packLoadDescription !== undefined ||
        data.packLoadPackingCondition !== undefined ||
        data.packLoadPackingConditionUsc !== undefined ||
        data.pacLoadDimensionsUsc !== undefined ||
        data.packLoadVolume !== undefined ||
        data.packLoadWeight !== undefined ||
        data.packLoadQuantity !== undefined ||
        data.packLoadDimensions !== undefined ||
        data.packLoadDetails !== undefined)
    );
  };

  const build = (_: ActivityData): string => {
    return t(translations.pages.booking.features.activity.packLoadUpdated);
  };

  return {
    build,
    supports
  };
};
