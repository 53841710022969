import styles from '@convelio/stylio/styles.module.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Typography, styled } from '@mui/material';
import BookingLocation from 'src/app/pages/Dashboard/components/BookingLocation';

import { BookingNumber } from '../../../../../BookingNumber';
import { SearchBookingResponse } from '../../../Core/Application/SearchBookingsResponse';

interface SearchResultProps {
  booking: SearchBookingResponse;
  onSearchResultClicked: (booking: SearchBookingResponse) => void;
  isSelected: boolean;
  updateSelectedBooking: (index: number) => void;
  index: number;
}

export const SearchSuggestion = ({
  onSearchResultClicked,
  booking,
  isSelected,
  updateSelectedBooking,
  index
}: SearchResultProps): JSX.Element => {
  const dataTestId = `search-suggestion-${index}`;

  return (
    <Wrapper
      {...(isSelected ? { 'data-selected': '' } : {})}
      data-testid={dataTestId}
      onMouseEnter={() => updateSelectedBooking(index)}
      onMouseDown={() => onSearchResultClicked(booking)}
      isSelected={isSelected}
      data-id={booking.id}
    >
      <Suggestion>
        <LeftSide>
          <BookingNumber
            bookingNumber={booking.bookingNumber}
            withStyle={false}
            boldCvoId
            testId="bookingNumber"
          />
          <StyledReference>{booking.customerReference}</StyledReference>
        </LeftSide>
        <StyledReference data-testid="account-name">
          {booking.accountName}
        </StyledReference>
      </Suggestion>
      {(booking.startCompanyName || booking.endCompanyName) && (
        <StyledDestination>
          <BookingLocation
            variant="h5"
            location={{
              name: booking.startCompanyName,
              city: booking.startAddressCity,
              country: booking.startAddressCountry
            }}
          />
          <ArrowForwardIcon fontSize="small" style={{ color: styles.cvoG60 }} />
          <BookingLocation
            variant="h5"
            location={{
              name: booking.endCompanyName,
              city: booking.endAddressCity,
              country: booking.endAddressCountry
            }}
          />
        </StyledDestination>
      )}
    </Wrapper>
  );
};

interface isSelectedProps {
  isSelected: boolean;
}

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected'
})<isSelectedProps>(({ theme, isSelected }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: styles.cvoSpacingM,
  borderBottom: `1px solid ${theme.palette.grey['100']}`,
  minHeight: '79px',
  lineHeight: styles.cvoSpacingM,
  backgroundColor: isSelected ? theme.palette.grey['100'] : 'white',
  color: isSelected ? 'black' : 'text.primary',
  '&:hover': {
    cursor: 'pointer'
  }
}));

const Suggestion = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '8px',
  alignItems: 'center'
});

const LeftSide = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: styles.cvoSpacingXs
});

const StyledReference = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey['500'],
  gap: styles.cvoSpacingXxs,
  fontSize: styles.cvoBodySmFontSize
}));

const StyledDestination = styled(Box)({
  display: 'flex',
  gap: styles.cvoSpacingXs,
  alignItems: 'center'
});
