import styles from '@convelio/stylio/styles.module.scss';
import { Box, Typography, styled } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Variant } from '@mui/material/styles/createTypography';
import { useState } from 'react';

import { useResponsiveness } from '../../../../../hooks/useResponsive';

interface FinancialValueProps {
  value: string;
  tooltipTitle: string;
  testId?: string;
  textColor?: string;
  variant?: Variant;
}

export const FinancialValue = ({
  value,
  tooltipTitle,
  testId,
  textColor,
  variant = 'h3'
}: FinancialValueProps) => {
  const [open, setOpen] = useState(false);
  const { isMaxWidthTablet } = useResponsiveness();

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const tooltipProps = isMaxWidthTablet
    ? {
        open,
        onClose: handleTooltipClose,
        onClick: handleTooltipOpen,
        disableHoverListener: true
      }
    : {};

  return (
    <Wrapper variant={variant} textColor={textColor} data-testid={testId}>
      <TooltipStyled
        title={tooltipTitle}
        {...tooltipProps}
        data-testid={`${testId}-tooltip`}
      >
        <StyledValue component="span">{value}</StyledValue>
      </TooltipStyled>
    </Wrapper>
  );
};

const Wrapper = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'textColor'
})<{
  textColor: string | undefined;
}>(
  ({ textColor }) =>
    textColor && {
      color: textColor
    }
);

const TooltipStyled = styled(
  ({ className, children, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }}>
      {children}
    </Tooltip>
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    padding: styles.cvoSpacingS
  }
}));

const StyledValue = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('tablet')]: {
    fontSize: styles.cvoTitleSmFontSize
  }
}));
