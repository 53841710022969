import { UploadedFile } from '../../../../types/booking/File';
import Carousel from '../../components/Carousel';
import { PdfViewer } from '../../components/PdfViewer';

interface MediaDisplayProps {
  type: string;
  files: UploadedFile[] | undefined;
  currentIndex: number | undefined;
  isLoading: boolean;
  selectedFileIndex: number;
  setCurrentIndex: (index: number) => void;
}

export const MediaDisplay = ({
  type,
  files,
  currentIndex,
  isLoading,
  selectedFileIndex,
  setCurrentIndex
}: MediaDisplayProps): JSX.Element | null => {
  if (!files) return null;

  if (type === 'pdf' && currentIndex !== null && currentIndex !== undefined) {
    return <PdfViewer isLoading={isLoading} src={files[currentIndex].url} />;
  }

  return (
    <Carousel
      isLoading={isLoading}
      images={files}
      selectedIndex={selectedFileIndex}
      handleActiveStepChange={setCurrentIndex}
    />
  );
};
