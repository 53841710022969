import { translations } from '../../../../../../../locales/translations';
import { BookingActionType } from '../../../../../../../types/BookingType';
import { ActivityData } from '../../ActivityData';
import { BuilderDecisionParams } from '../../BuilderDecisionParams';
import { EventMessageBuilder } from '../../EventMessageBuilder';
import { useFormatTranslationWithDate } from '../useFormatTranslationWithDate';

export const usePackingDateScheduledMessageBuilder =
  (): EventMessageBuilder => {
    const { formatTranslationWithDate } = useFormatTranslationWithDate();

    const supports = ({ eventResult }: BuilderDecisionParams): boolean => {
      return eventResult.type === BookingActionType.PACKING_DATE_SCHEDULED;
    };

    const build = ({ date }: ActivityData): string => {
      return formatTranslationWithDate(
        translations.pages.booking.features.activity.packingDateScheduled,
        date
      );
    };

    return {
      supports,
      build
    };
  };
