import styles from '@convelio/stylio/styles.module.scss';
import { CircularProgress, styled } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/app/components/Button';
import { ButtonProps } from 'src/app/types/ButtonProps';

import { translations } from '../../../../locales/translations';

type LoginButtonProps = ButtonProps & {
  isLoading?: boolean;
};

function LoginButton({
  isLoading = false,
  ...props
}: LoginButtonProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        textAlign: 'right',
        marginTop: 32
      }}
    >
      <Button
        dataTestid="login-button"
        variant="plain"
        label={t(translations.login.title)}
        isLoading={isLoading}
        startIcon={<CircularProgressStyled size={24} />}
        {...props}
      />
    </Box>
  );
}

export default LoginButton;

const CircularProgressStyled = styled(CircularProgress)(({ theme }) => ({
  marginRight: styles.cvoSpacingXs,
  color: theme.palette.common.white
}));
