import { Typography, styled } from '@mui/material';

export const AdminLabel = () => {
  return <AdminWrapper data-testid="admin-label">Admin</AdminWrapper>;
};

const AdminWrapper = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: 8,
  left: 8,
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  paddingTop: 4,
  paddingBottom: 4,
  paddingRight: 8,
  paddingLeft: 8,
  borderRadius: 4,
  zIndex: 1
}));
