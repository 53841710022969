import { Chip, styled } from '@mui/material';
import { LevelType } from 'src/types/LevelType';

type Props = {
  label: string;
  level?: LevelType;
  icon?: JSX.Element;
};

const ChipStyled = styled(Chip)({
  lineHeight: '1.6rem',
  fontWeight: 700,
  fontStyle: 'normal',
  height: 32,
  paddingY: 4,
  display: 'flex',
  textAlign: 'start',
  width: 'fit-content'
});

function Flag({ level, label, icon }: Props): JSX.Element {
  const color = level ? `${level}.dark` : 'grey.500';
  const backgroundColor = level ? `${level}.light` : 'grey.100';
  return (
    <ChipStyled
      icon={icon}
      label={label}
      sx={{ color: color, backgroundColor: backgroundColor }}
    />
  );
}

export default Flag;
