import styles from '@convelio/stylio/styles.module.scss';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../locales/translations';
import { PackType } from '../../../../../../types/BookingType';

interface MerchandisePackTypeSectionProps {
  packType: PackType;
}

export const MerchandisePackTypeView = ({
  packType
}: MerchandisePackTypeSectionProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View>
      <Text style={style.title}>{t(translations.packingCondition.title)}</Text>
      <Text>{t(translations.packingCondition[packType])}</Text>
    </View>
  );
};

const style = StyleSheet.create({
  title: {
    fontSize: styles.cvoBodySmFontSize,
    fontFamily: 'Helvetica-Bold',
    marginTop: styles.cvoSpacingM,
    marginBottom: styles.cvoSpacingXs
  }
});
