import styles from '@convelio/stylio/styles.module.scss';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/app/components/Button';

import { translations } from '../../../../../locales/translations';

interface DeleteConfirmationDocumentModalProps {
  open: boolean;
  handleClose: () => void;
  handleDeleteFile: () => Promise<void>;
}

export const DeleteConfirmationDocumentModal = ({
  open,
  handleDeleteFile,
  handleClose
}: DeleteConfirmationDocumentModalProps): JSX.Element => {
  const { t } = useTranslation();
  const deleteFileModalTranslations =
    translations.pages.booking.features.deleteFile.modal;

  return (
    <div>
      <Modal
        data-testid="delete-confirmation-document-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-confirmation-document-modal"
        aria-describedby="delete-confirmation-document-modal-description"
      >
        <ModalElementStyled>
          <Title variant="h2">{t(deleteFileModalTranslations.title)}</Title>
          <Typography>{t(deleteFileModalTranslations.description)}</Typography>
          <ActionStyled>
            <Button
              dataTestid="cancel-delete-file-btn"
              variant="plain"
              label={t(deleteFileModalTranslations.cancel)}
              onClick={handleClose}
            />
            <Button
              dataTestid="delete-file-btn"
              variant="plain"
              label={t(deleteFileModalTranslations.delete)}
              onClick={handleDeleteFile}
            />
          </ActionStyled>
        </ModalElementStyled>
      </Modal>
    </div>
  );
};

const ModalElementStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '90%',
  maxWidth: '500px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.paper,
  padding: styles.cvoSpacingG,
  boxShadow: styles.cvoMediumShadow,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '4px',
  [theme.breakpoints.up('mobile')]: {
    width: '80%'
  },
  [theme.breakpoints.up('tablet')]: {
    width: '70%'
  }
}));

const ActionStyled = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: styles.cvoSpacingM,
  gap: styles.cvoSpacingS,
  fontWeight: 'bold'
});

const Title = styled(Typography)({
  marginBottom: styles.cvoSpacingXs,
  fontSize: styles.cvoTitleSmFontSize
});
