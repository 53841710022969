import { Box, BoxProps, styled, useMediaQuery, useTheme } from '@mui/material';
import { BookingListItem } from 'src/types/Booking';

import BookingLocation from '../../../components/BookingLocation';
import DashboardBookingArrow from '../../../components/DashboardBookingArrow';

interface BoxWrapperProps extends BoxProps {
  isMinWidthMobile: boolean;
}

const WrapperBoxStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMinWidthMobile'
})<BoxWrapperProps>(({ isMinWidthMobile }) => ({
  display: 'flex',
  flexDirection: isMinWidthMobile ? 'row' : 'column',
  alignItems: isMinWidthMobile ? 'center' : 'top',
  paddingTop: 16,
  width: '100%'
}));

type Props = {
  booking: BookingListItem;
};

function BookingTransportLogistic(props: Props): JSX.Element {
  const { booking } = props;
  const theme = useTheme();
  const isMinWidthMobile = useMediaQuery(theme.breakpoints.up('mobile'));

  return (
    <WrapperBoxStyled isMinWidthMobile={isMinWidthMobile}>
      <BookingLocation
        location={{
          name: booking.startCompanyName,
          city: booking.startAddressCity,
          country: booking.startAddressCountry
        }}
      />
      {(booking.startCompanyName || booking.endCompanyName) && (
        <DashboardBookingArrow isMinWidthMobile={isMinWidthMobile} />
      )}
      <BookingLocation
        location={{
          name: booking.endCompanyName,
          city: booking.endAddressCity,
          country: booking.endAddressCountry
        }}
      />
    </WrapperBoxStyled>
  );
}

export default BookingTransportLogistic;
