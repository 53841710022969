import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../../locales/translations';
import { BookingUserType } from '../../../../../../../types/BookingType';
import { ActivityData } from '../../ActivityData';
import { BuilderDecisionParams } from '../../BuilderDecisionParams';
import { EventMessageBuilder } from '../../EventMessageBuilder';

export const useNoteMessageBuilder = (): EventMessageBuilder => {
  const { t } = useTranslation();

  const supports = ({
    eventResult: { data, userType }
  }: BuilderDecisionParams): boolean => {
    return userType === BookingUserType.SF && data.note !== undefined;
  };

  const build = (_: ActivityData): string => {
    return t(translations.pages.booking.features.activity.noteUpdated);
  };

  return {
    build,
    supports
  };
};
