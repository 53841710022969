import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { translations } from 'src/locales/translations';
import { BookingListItem } from 'src/types/Booking';
import { BookingStatusType } from 'src/types/BookingType';
import { DEFAULT_BOOKING_DEPOT_COMPLETED } from 'src/utils/helpers/BookingHelper';

import BookingLocationDate from '../../../components/BookingLocationDate';
import BookingLocationPartern from '../../../components/BookingLocationPartner';
import BookingTruckBadge from '../../../components/BookingTruckBadge';
import DashboardBookingArrow from '../../../components/DashboardBookingArrow';

const MainBoxStyled = styled(Box)({
  paddingTop: 8,
  display: 'flex'
});

const BoxRowStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'start'
});

const ArrowBoxStyled = styled(Box)({
  padding: 4
});
const TruckBoxStyled = styled(Box)({
  paddingRight: 4
});

const DateBoxStyled = styled(Box)({
  paddingLeft: 4,
  paddingRight: 14
});

const ParternBoxStyled = styled(Box)({
  paddingLeft: 14,
  paddingRight: 14
});

type Props = {
  booking: BookingListItem;
};
function BookingDepotLogistic({ booking }: Props): JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation();
  const tranlationDepot = translations.pages.dashboard.pages.depot;
  const isMinWidthMobile = useMediaQuery(theme.breakpoints.up('mobile'));

  return (
    <MainBoxStyled
      sx={{
        flexDirection: isMinWidthMobile ? 'row' : 'column'
      }}
    >
      <BoxRowStyled>
        <TruckBoxStyled>
          <BookingTruckBadge bgColor="primary.dark" />
        </TruckBoxStyled>
        <DateBoxStyled>
          <BookingLocationDate
            label={t(
              tranlationDepot.dropOff[
                booking.status === BookingStatusType.PENDING
                  ? 'etaFlag'
                  : 'title'
              ]
            )}
            date={
              booking.status === BookingStatusType.PENDING
                ? booking.startExpectedOn
                : booking.startDate
            }
          />
        </DateBoxStyled>
        <ParternBoxStyled>
          <BookingLocationPartern
            label={t(tranlationDepot.dropOff.partner)}
            partner={booking?.startCompanyName}
          />
        </ParternBoxStyled>
      </BoxRowStyled>

      <ArrowBoxStyled>
        <DashboardBookingArrow isMinWidthMobile={isMinWidthMobile} />
      </ArrowBoxStyled>
      <BoxRowStyled>
        <BookingTruckBadge bgColor="grey.500" />
        <DateBoxStyled>
          <BookingLocationDate
            label={t(
              tranlationDepot.pickup[
                DEFAULT_BOOKING_DEPOT_COMPLETED.includes(booking.status)
                  ? 'title'
                  : 'etaFlag'
              ]
            )}
            date={
              DEFAULT_BOOKING_DEPOT_COMPLETED.includes(booking.status)
                ? booking.endDate
                : booking.endExpectedOn
            }
          />
        </DateBoxStyled>

        <ParternBoxStyled>
          <BookingLocationPartern
            label={t(tranlationDepot.pickup.partner)}
            partner={booking?.endCompanyName}
          />
        </ParternBoxStyled>
      </BoxRowStyled>
    </MainBoxStyled>
  );
}

export default BookingDepotLogistic;
