import { Typography } from '@mui/material';
import { Box } from '@mui/system';

type Props = {
  detail: string;
};

const MerchandisePackDetail = ({ detail }: Props) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column'
    }}
  >
    <Typography
      sx={{
        lineHeight: '1.36rem',
        textAlign: 'left',
        fontSize: '0.875rem'
      }}
    >
      {detail}
    </Typography>
  </Box>
);

export default MerchandisePackDetail;
