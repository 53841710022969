import { Box, styled } from '@mui/material';

import NoteBody from './NoteBody';

const NoteDesktopMainStyledBox = styled(Box)({
  position: 'fixed',
  right: 14,
  bottom: 24,
  width: 360,
  zIndex: 1,
  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
  borderRadius: '4px',
  backgroundColor: 'white'
});

type Props = {
  note: string;
  isEditable: boolean;
  nbrNote: number;
  isLoading?: boolean;
  handleNoteChange: (value?: string) => void;
};

function NotesDesktopView({
  note,
  isEditable,
  nbrNote,
  isLoading,
  handleNoteChange
}: Props): JSX.Element {
  return (
    <NoteDesktopMainStyledBox>
      <NoteBody
        note={note}
        isEditable={isEditable}
        nbrNote={nbrNote}
        isLoading={isLoading}
        handleNoteChange={handleNoteChange}
      />
    </NoteDesktopMainStyledBox>
  );
}

export default NotesDesktopView;
