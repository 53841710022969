import { ActivityData } from '../hooks/MessageBuilder/ActivityData';

export const isContactUpdated = (
  data: ActivityData,
  contactType: ContactType,
  additionalFields: string[] = []
): boolean => {
  const fields = [
    `${contactType}AddressCity`,
    `${contactType}CompanyName`,
    `${contactType}AddressLine1`,
    `${contactType}ContactPhones`,
    `${contactType}AddressCountry`,
    `${contactType}AddressZipcode`,
    `${contactType}ContactLastname`,
    `${contactType}ContactFirstname`,
    ...additionalFields
  ];

  return fields.some(
    (field) => data[field as keyof ActivityData] !== undefined
  );
};

export enum ContactType {
  START = 'start',
  END = 'end'
}
