import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDate } from 'src/app/hooks/useDate';
import MainDatePicker from 'src/app/pages/Booking/Features/ScheduleDate/components/MainDatePicker';
import MainContact from 'src/app/pages/Booking/components/MainContact';
import {
  GridColumnContactStyled,
  GridRowStyled
} from 'src/app/pages/Booking/components/StepBookingStyled';
import { useStep } from 'src/app/pages/Booking/hooks/useStep';
import { BookingItem } from 'src/app/types/Booking';
import { translations } from 'src/locales/translations';

type Props = {
  isDone: boolean;
  isLoading?: boolean;
  booking: BookingItem;
  handleSubmitPickup: (date?: Date) => Promise<void>;
};

function PackingPickupEdit({
  booking,
  isDone,
  isLoading,
  handleSubmitPickup
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { getEndContact } = useStep();
  const { fromIsoToDate } = useDate();

  const packingPickupDate = useMemo((): Date | null => {
    if (booking.endDate) {
      return fromIsoToDate(booking.endDate);
    }
    return null;
  }, [booking.endDate]);

  return (
    <>
      <GridRowStyled>
        <GridColumnContactStyled>
          <MainContact
            disabled={isDone}
            isLoading={isLoading}
            contact={getEndContact(booking)}
          />
        </GridColumnContactStyled>
      </GridRowStyled>
      <MainDatePicker
        isDone={isDone}
        isEditMode={!isDone}
        isLoading={isLoading}
        handleSubmit={handleSubmitPickup}
        datatestid={`packing-confirm-pickup-date`}
        date={packingPickupDate}
        getTitle={() =>
          t(
            translations.pages.booking.pages.packing.features.pickup
              .datePickerLabel
          )
        }
        label={t(
          translations.pages.booking.pages.packing.features.pickup.buttonLabel
        )}
      />
    </>
  );
}

export default PackingPickupEdit;
