import { MeasurementSystem } from 'src/app/pages/Setting/types';
import { FileCategoryType } from 'src/types/FileCategoryType';
import {
  CrateForm,
  PackingCrate,
  PackingCrateOperation,
  PackingCrateStatus,
  SCHEMA_VALIDATION_FORM_CRATE,
  getInitCrate
} from 'src/types/Packing';
import * as yup from 'yup';

type UseCrateHook = {
  getSchemaForm: (formType: PackingCrateOperation) => yup.AnyObjectSchema;
  cratesInitialization: (
    propsCrates: CrateForm[] | null | undefined,
    measureSystem?: MeasurementSystem
  ) => CrateForm[];
  isAllCratesConfirmedWithFiles: (
    crates: PackingCrate[] | null | undefined
  ) => boolean;
  getEndAdornment: (crate: PackingCrate, key: string) => string | undefined;
  cratesUpdateFiles: (
    propsCrates: CrateForm[] | null | undefined,
    stateCrates: CrateForm[],
    currentIndex: number | undefined | null
  ) => CrateForm[];
  isFilesChanged: (
    currentIndex: number | undefined | null,
    propCrates: CrateForm[] | undefined | null,
    stateCrates: CrateForm[]
  ) => boolean | undefined;
};

export function useCrates(): UseCrateHook {
  const cratesInitialization = (
    propsCrates: CrateForm[] | null | undefined,
    measureSystem?: MeasurementSystem
  ): CrateForm[] => {
    if (!propsCrates) return [];
    if (propsCrates?.length === 0) return [getInitCrate(measureSystem)];
    return propsCrates;
  };

  const cratesUpdateFiles = (
    propsCrates: CrateForm[] | null | undefined,
    stateCrates: CrateForm[],
    currentIndex: number | undefined | null
  ): CrateForm[] => {
    const crates: CrateForm[] = [...stateCrates];
    if (
      propsCrates &&
      currentIndex !== null &&
      currentIndex !== undefined &&
      propsCrates[currentIndex]
    ) {
      crates[currentIndex].files = propsCrates[currentIndex].files;
    }
    return crates;
  };

  const getEndAdornment = (
    crate: PackingCrate,
    key: string
  ): string | undefined => {
    if (key === 'quantity') return undefined;
    if (key === 'weight') return crate.weightMetric?.toLocaleLowerCase();
    return crate.lengthMetric?.toLocaleLowerCase();
  };

  const isAllCratesConfirmedWithFiles = (
    crates: CrateForm[] | null | undefined
  ): boolean => {
    if (!crates) return false;
    return crates.every(
      ({ status, files }) =>
        status === PackingCrateStatus.CONFIRMED &&
        files?.some(
          ({ category }) => category === FileCategoryType.PICTURE_CRATE_IN
        ) &&
        files?.some(
          ({ category }) => category === FileCategoryType.PICTURE_CRATE_OUT
        )
    );
  };

  const isFilesChanged = (
    currentIndex: number | undefined | null,
    propCrates: CrateForm[] | undefined | null,
    stateCrates: CrateForm[]
  ): boolean => {
    return (
      currentIndex !== null &&
      currentIndex !== undefined &&
      !!propCrates &&
      !!propCrates[currentIndex] &&
      propCrates[currentIndex].files?.length !==
        stateCrates[currentIndex]?.files?.length
    );
  };

  const getSchemaForm = (
    formType: PackingCrateOperation
  ): yup.AnyObjectSchema => {
    return SCHEMA_VALIDATION_FORM_CRATE[formType];
  };

  return {
    getSchemaForm,
    isFilesChanged,
    getEndAdornment,
    cratesUpdateFiles,
    cratesInitialization,
    isAllCratesConfirmedWithFiles
  };
}
