import { Box, styled } from '@mui/material';
import { BookingItem } from 'src/app/types/Booking';

import { FinancialSeparator } from './FinancialSeparator';
import { OriginalBudget } from './OriginalBudget';
import { TotalCost } from './TotalCost';

interface CostOverviewProps {
  booking: BookingItem;
}

export const CostOverview = ({ booking }: CostOverviewProps): JSX.Element => {
  return (
    <Wrapper data-testid="cost-overview">
      <BookingBudgetStyled>
        {booking.totalCost !== null && booking.totalCost > 0 && (
          <>
            <TotalCost
              totalCost={booking.totalCost}
              budget={booking.budgetEur}
            />
            <FinancialSeparator />
          </>
        )}
        <OriginalBudget
          budgetEur={booking.budgetEur}
          budgetUsd={booking.budgetUsd}
          budgetGbp={booking.budgetGbp}
        />
      </BookingBudgetStyled>
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline'
});

const BookingBudgetStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline'
});
