import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { selectIsLoggedIn } from '../slices/Auth';

type Props = {
  children: JSX.Element;
};

function AuthGuard(props: Props): JSX.Element | null {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  return isLoggedIn ? props.children : <Navigate to="/" replace />;
}

export default AuthGuard;
