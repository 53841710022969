import { SelectChangeEvent } from '@mui/material';
import { FormEvent, useState } from 'react';

import { useAppDispatch } from '../../../../store/hooks';
import { useUpdateSettingsMutation } from '../../../services/SettingsApi';
import { setSettings } from '../../../slices/Auth';
import { SettingItem, UpdatedSettingItem } from '../types';
import { useSettings } from '../useSettings';

export type SettingFormHelper = {
  settingValues: SettingItem[];
  handleChange: (event: SelectChangeEvent, settingId: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  isSuccess: boolean;
  isError: boolean;
  isUpdateInProgress: boolean;
};

export const useSettingFormHelper = (
  settings: SettingItem[]
): SettingFormHelper => {
  const [settingValues, setSettingValues] = useState<SettingItem[]>(settings);
  const [isHttpError, setIsHttpError] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { refreshAppLanguage } = useSettings();

  const [
    updateSettings,
    { isSuccess, isError, isLoading: isUpdateInProgress }
  ] = useUpdateSettingsMutation();

  const handleChange = (event: SelectChangeEvent, settingId: string): void => {
    const newSettingValues = settingValues.map((setting) => {
      if (setting.id === settingId) {
        return { ...setting, value: event.target.value as string };
      }
      return setting;
    });
    setSettingValues(newSettingValues);
  };

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    const updatedSettings: UpdatedSettingItem[] = settingValues.map(
      ({ id, value, name }: SettingItem) => {
        return { id, value, name };
      }
    );

    if (!updatedSettings) return;

    try {
      await updateSettings(updatedSettings).unwrap();

      dispatch(setSettings(updatedSettings));
      await refreshAppLanguage();
    } catch (_) {
      setIsHttpError(true);
    }
  };

  return {
    settingValues,
    handleChange,
    handleSubmit,
    isSuccess,
    isError: isHttpError || isError,
    isUpdateInProgress
  };
};
