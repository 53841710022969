import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../locales/translations';
import { Contact } from '../../../../../types/Contact';
import { Location } from '../../../../../types/Location';
import { useDate } from '../../../../hooks/useDate';
import { useContact } from '../../../../pages/Booking/Pages/TransportBooking/Features/hooks/useContact';
import { useLocation } from '../../../../pages/Booking/Pages/TransportBooking/Features/hooks/useLocation';
import { BookingItem } from '../../../../types/Booking';
import { LogisticsView } from '../../Page/Section/LogisticsView';
import { formatLocation } from '../../helpers/Formater';

interface DropOffViewProps {
  booking: BookingItem;
}

export const DepotDropOffView = ({
  booking
}: DropOffViewProps): JSX.Element => {
  const { t } = useTranslation();
  const { formatIsoToFullDateString } = useDate();
  const dropOffDate = formatIsoToFullDateString(booking.startDate);

  const dropOffLocation: Location = useLocation({
    name: booking.startCompanyName,
    city: booking.startAddressCity,
    country: booking.startAddressCountry
  });

  const dropOffContact: Contact = useContact({
    firstname: booking.startContactFirstname,
    lastname: booking.startContactLastname,
    emails: booking.startContactEmails,
    phones: booking.startContactPhones
  });

  const title = useMemo((): string => {
    return `${t(
      translations.pages.booking.pages.depot.features.dropOff.title
    )}: ${formatLocation(dropOffLocation)}`;
  }, [
    booking.startCompanyName,
    booking.startAddressCity,
    booking.startAddressCountry
  ]);

  return (
    <LogisticsView
      title={title}
      confirmTitle={t(
        translations.pages.booking.pages.depot.features.dropOff.datePickerLabel
      )}
      confirmDate={dropOffDate}
      contact={dropOffContact}
    />
  );
};
