import { BookingListItem } from '../../types/Booking';
import { BookingType, FreightSpeedType } from '../../types/BookingType';
import { useDate } from './useDate';

interface BookingListFilterResultType {
  filterPlannedBookings: (bookings: BookingListItem[]) => BookingListItem[];
  filterConfirmedBookings: (
    bookings: BookingListItem[],
    bookingType?: BookingType
  ) => BookingListItem[];
}

export function useBookingListFilter(): BookingListFilterResultType {
  const { isDateToday, isDateGreaterThanOrEqualToToday, isDateLessThanToday } =
    useDate();
  const filterConfirmedBookings = (
    bookings: BookingListItem[],
    bookingType?: BookingType
  ): BookingListItem[] => {
    if (bookingType === BookingType.CRATING) {
      return bookings.filter((booking) => isDateToday(booking.packExpectedOn));
    }

    return bookings
      .filter(
        (booking) =>
          isDateToday(booking.endExpectedOn) ||
          isDateToday(booking.startExpectedOn)
      )
      .sort((booking) => orderByExpress(booking));
  };

  const filterPlannedBookings = (
    bookings: BookingListItem[]
  ): BookingListItem[] => {
    return bookings
      .filter(
        (data) =>
          isDateGreaterThanOrEqualToToday(data.endExpectedOn) ||
          isDateGreaterThanOrEqualToToday(data.startExpectedOn) ||
          (isDateLessThanToday(data.endExpectedOn) && data.endDate === null) ||
          (isDateLessThanToday(data.startExpectedOn) && data.startDate === null)
      )
      .sort((booking) => orderByExpress(booking));
  };

  const orderByExpress = (booking: BookingListItem): number => {
    return [
      FreightSpeedType.ULTRA_EXPRESS,
      FreightSpeedType.EXPRESS_SPEED
    ].includes(booking.freightSpeed)
      ? -1
      : 1;
  };

  return {
    filterPlannedBookings,
    filterConfirmedBookings
  };
}
