import styles from '@convelio/stylio/styles.module.scss';
import { Box, Paper, PaperProps, styled } from '@mui/material';
import NotificationSnackbar from 'src/app/components/SnackBar';
import Merchandise from 'src/app/pages/Booking/Features/Merchandise';
import { BookingItem } from 'src/app/types/Booking';

import MainPanelWrapper from '../../../../components/MainPanelWrapper';
import PackingConfirmation from '../Confirmation/PackingConfirmation';
import PackingDropOff from '../DropOff/PackingDropOff';
import PackingEstimation from '../Estimation/PackingEstimation';
import PackingPickup from '../Pickup/PackingPickup';
import PackingStatusBanner from '../StatusBanner/PackingStatusBanner';
import { usePackingMainPanelLogic } from './usePackingMainPanelLogic';

interface PackingMainPanelProps {
  version: number;
  isLoading?: boolean;
  booking: BookingItem;
}

export const PackingMainPanel = ({
  version,
  booking,
  isLoading
}: PackingMainPanelProps): JSX.Element => {
  const {
    handlePackingEstimationSubmit,
    handleChangeStatus,
    handleChangeCrate,
    feature,
    isErrorCrate,
    isErrorStatus,
    isSuccessCrate,
    isSuccessStatus,
    crateMethod,
    statusMessage,
    crateMessage,
    bookingAction
  } = usePackingMainPanelLogic(booking);

  return (
    <>
      <MainPanelWrapper
        bannerContent={
          <PackingStatusBanner booking={booking} isLoading={isLoading} />
        }
        mainContent={
          <MainContentBoxStyled>
            <MainContentPaperStyled>
              <PackingDropOff
                booking={booking}
                handleSubmit={handleChangeStatus}
              />
              <PackingEstimation
                booking={booking}
                handleSubmit={handlePackingEstimationSubmit}
                handleChangeCrate={handleChangeCrate}
              />
              <PackingConfirmation
                version={version}
                booking={booking}
                handleSubmit={handleChangeStatus}
                handleChangeCrate={handleChangeCrate}
              />
              <PackingPickup
                booking={booking}
                handleSubmit={handleChangeStatus}
              />
            </MainContentPaperStyled>
          </MainContentBoxStyled>
        }
        lateralContent={<Merchandise booking={booking} isLoading={isLoading} />}
      />
      {feature && bookingAction && (isSuccessStatus || isErrorStatus) && (
        <NotificationSnackbar
          open={true}
          message={statusMessage}
          severity={isErrorStatus ? 'error' : 'success'}
        />
      )}
      {crateMethod && (isSuccessCrate || isErrorCrate) && (
        <NotificationSnackbar
          open={true}
          message={crateMessage}
          severity={isErrorCrate ? 'error' : 'success'}
        />
      )}
    </>
  );
};

const MainContentBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

const MainContentPaperStyled = styled((props: PaperProps) => (
  <Paper elevation={0} {...props} />
))({
  padding: styles.cvoSpacingXxs,
  borderRadius: styles.cvoBorderRadiusMedium,
  backgroundColor: 'white'
});
