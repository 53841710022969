import PersonIcon from '@mui/icons-material/Person';
import { Box, Typography, styled } from '@mui/material';
import { BookingListItem } from 'src/types/Booking';

type Props = {
  booking: BookingListItem;
};

const WrapperBoxStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  paddingLeft: 26,
  paddingTop: 4,
  paddingBottom: 4,
  color: theme.palette.grey['400']
}));

const Icon = styled(PersonIcon)(({ theme }) => ({
  width: 12,
  height: 12,
  marginRight: 7,
  color: theme.palette.grey['500']
}));

function CustomerRef({ booking }: Props): JSX.Element {
  return (
    <>
      {booking?.customerRef && (
        <WrapperBoxStyled>
          <Icon />
          <Typography variant="subtitle1">{`${booking.orderAccountName?.toLocaleUpperCase()} - ${booking.customerRef?.toUpperCase()}`}</Typography>
        </WrapperBoxStyled>
      )}
    </>
  );
}

export default CustomerRef;
