import styles from '@convelio/stylio/styles.module.scss';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../locales/translations';
import { BookingStatusType } from '../../../../../../types/BookingType';

type StatusStepperProps = {
  bookingType: string;
  status: BookingStatusType;
  statusCompleted?: BookingStatusType[];
  steps: { key: string; bookingStatus: BookingStatusType }[];
  hasBookingFileUploaded: boolean;
};

function StatusStepper({
  bookingType,
  status,
  steps,
  statusCompleted,
  hasBookingFileUploaded
}: StatusStepperProps): JSX.Element {
  const { t } = useTranslation();
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  useEffect(() => {
    setIsCompleted(
      statusCompleted
        ? statusCompleted.includes(status) && hasBookingFileUploaded
        : false
    );
  }, [status, statusCompleted]);

  const activeStepIndex = status
    ? steps.findIndex((step) => step.bookingStatus === status)
    : 0;

  const activeStepRank = activeStepIndex + 1;

  const activeStep = steps[activeStepIndex];
  const nextStep = steps[activeStepIndex + 1];

  const labelActiveStep =
    activeStep &&
    translations.pages.booking.pages[bookingType].features.stepper[
      activeStep.key
    ];

  const labelNextStep = nextStep
    ? translations.pages.booking.pages[bookingType].features.stepper[
        nextStep.key
      ]
    : translations.pages.booking.pages.jobComplete;

  return (
    <Wrapper>
      <StepsBox>
        <Flexbox>
          {isCompleted ? (
            <DoneIcon />
          ) : (
            <>
              <ActiveStep>{activeStepRank}</ActiveStep>
              <NumberOfSteps>/{steps.length}</NumberOfSteps>
            </>
          )}
        </Flexbox>
      </StepsBox>
      <Box>
        <Title data-testid="status-stepper-title">
          {isCompleted
            ? t(translations.pages.booking.pages.jobComplete)
            : t(labelActiveStep)}
        </Title>

        {labelNextStep && !isCompleted && (
          <SubTitle data-testid="status-stepper-subtitle">
            {`${t(translations.pages.booking.pages.next)} ${t(labelNextStep)}`}
          </SubTitle>
        )}
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  columnGap: styles.cvoSpacingL,
  [theme.breakpoints.down('tablet')]: {
    justifyContent: 'space-between'
  }
}));

const Flexbox = styled(Box)(() => ({
  display: 'flex'
}));

const StepsBox = styled(Box)(({ theme }) => ({
  width: '56px',
  height: '40px',
  backgroundColor: theme.palette.success.main,
  borderRadius: styles.cvoBorderRadiusFull,
  color: theme.palette.common.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const Title = styled(Typography)(() => ({
  fontWeight: '600'
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  fontSize: styles.cvoBodyMdFontSize,
  fontWeight: '600'
}));

const ActiveStep = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: '600'
}));

const NumberOfSteps = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.light,
  fontWeight: '600'
}));

export default StatusStepper;
