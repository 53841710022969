import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

import { RootState } from '../../store/store';
import { DecodedToken, User } from '../../types/User';
import { transformDateFormat } from '../pages/Setting/SettingHelper';
import { settingName } from '../pages/Setting/constants';
import { UpdatedSettingItem } from '../pages/Setting/types';
import { LoginResponse } from '../types/Login';
import { Role } from '../types/Role';

export type AuthState = {
  user: User | null;
  accessToken: string | null;
  refreshToken: string | null;
  isUnAuthorized: boolean | null;
  settings: UpdatedSettingItem[] | null;
  role: Role | null;
};

const computeInitialRole = (): Role | null => {
  const token = JSON.parse(localStorage['access_token'] || null);
  if (!token) return null;

  const decodedToken = jwt_decode(token) as DecodedToken;
  return decodedToken.role as Role;
};

const slice = createSlice({
  name: 'auth',
  initialState: {
    user: JSON.parse(localStorage['user'] || null),
    accessToken: JSON.parse(localStorage['access_token'] || null),
    refreshToken: JSON.parse(localStorage['refresh_token'] || null),
    settings: JSON.parse(localStorage['settings'] || null),
    role: computeInitialRole()
  } as AuthState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { user, access_token, refresh_token }
      }: PayloadAction<LoginResponse>
    ) => {
      state.user = user;
      state.accessToken = access_token;
      state.refreshToken = refresh_token;
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('access_token', JSON.stringify(access_token));
      localStorage.setItem('refresh_token', JSON.stringify(refresh_token));
      const decodedToken = jwt_decode(access_token) as DecodedToken;
      const settings = decodedToken.settings || [];
      updateSettingsFromStorage(state, settings);
      state.role = decodedToken.role as Role;
      state.isUnAuthorized = null;
    },
    setSettings: (state, { payload }: PayloadAction<UpdatedSettingItem[]>) => {
      updateSettingsFromStorage(state, payload);
    },
    setTokens: (
      state,
      {
        payload: { access_token, refresh_token }
      }: PayloadAction<{ access_token: string; refresh_token: string }>
    ) => {
      state.accessToken = access_token;
      state.refreshToken = refresh_token;
      localStorage.setItem('access_token', JSON.stringify(access_token));
      localStorage.setItem('refresh_token', JSON.stringify(refresh_token));
    },
    resetCredentials: (state) => {
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.settings = null;
      state.role = null;
      localStorage.removeItem('user');
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('settings');
    },
    setUnAuthorized: (
      state,
      { payload: { unAuthorized } }: PayloadAction<{ unAuthorized: boolean }>
    ) => {
      state.isUnAuthorized = unAuthorized;
    },
    setRole: (state, { payload }: PayloadAction<Role>): void => {
      state.role = payload;
    }
  }
});

const updateSettingsFromStorage = (
  state: AuthState,
  settings: UpdatedSettingItem[]
): void => {
  settings.map((setting: UpdatedSettingItem) => {
    if (setting.name === settingName.dateFormat) {
      setting.value = transformDateFormat(setting.value);
    }
  });

  state.settings = settings;
  localStorage.setItem('settings', JSON.stringify(settings));
};

export const {
  setCredentials,
  setTokens,
  resetCredentials,
  setUnAuthorized,
  setSettings,
  setRole
} = slice.actions;

export default slice.reducer;

export const selectAuth = (state: RootState): AuthState => state.auth;

export const selectUser = createSelector(
  [selectAuth],
  (authState) => authState.user
);

export const selectSettings = createSelector(
  [selectAuth],
  (authState) => authState.settings
);

export const selectUserCurrency = createSelector(
  [selectAuth],
  (authState) => authState.user?.account?.billingCurrency
);

export const selectAccessToken = createSelector(
  [selectAuth],
  (authState) => authState.accessToken
);

export const selectRefreshToken = createSelector(
  [selectAuth],
  (authState) => authState.refreshToken
);

export const selectIsUnAuthorized = createSelector(
  [selectAuth],
  (authState) => authState.isUnAuthorized
);

export const selectIsLoggedIn = createSelector([selectAuth], (authState) =>
  isLoggedIn(authState)
);

export const selectRole = createSelector(
  [selectAuth],
  (authState) => authState.role
);

const isLoggedIn = (authState: AuthState) => {
  if (!authState.refreshToken) {
    return false;
  }
  return (
    Date.now() < (jwt_decode(authState.refreshToken) as DecodedToken).exp * 1000
  );
};
