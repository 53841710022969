import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../../locales/translations';
import {
  BookingActionType,
  BookingStatusType,
  BookingUserType
} from '../../../../../../../types/BookingType';
import { ActivityData } from '../../ActivityData';
import { BuilderDecisionParams } from '../../BuilderDecisionParams';
import { EventMessageBuilder } from '../../EventMessageBuilder';

export const usePackingScheduledFromSFMessageBuilder =
  (): EventMessageBuilder => {
    const { t } = useTranslation();

    const supports = ({
      eventResult: { data, userType, type }
    }: BuilderDecisionParams): boolean => {
      return (
        userType === BookingUserType.SF &&
        type === BookingActionType.BOOKING_UPDATED &&
        data.status === BookingStatusType.PACKING_SCHEDULED
      );
    };

    const build = (_: ActivityData): string => {
      return t(
        translations.pages.booking.features.activity.convelioPackingScheduled
      );
    };

    return {
      build,
      supports
    };
  };
