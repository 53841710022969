import { useMediaQuery, useTheme } from '@mui/material';
import TitleLg from 'src/styles/components/Title/TitleLg';
import TitleMd from 'src/styles/components/Title/TitleMd';
import TitleSm from 'src/styles/components/Title/TitleSm';

type Props = {
  title: string;
};

function DashboardPageTitle({ title }: Props): JSX.Element {
  const color = 'black.main';
  const theme = useTheme();
  const isMinDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const isMinWidthTablet = useMediaQuery(theme.breakpoints.up('tablet'));
  const isMinWidthMobile = useMediaQuery(theme.breakpoints.up('mobile'));

  return (
    <>
      {isMinWidthMobile && !isMinWidthTablet && (
        <TitleSm color={color}>
          <>{title}</>
        </TitleSm>
      )}
      {isMinWidthTablet && !isMinDesktop && (
        <TitleMd color={color}>
          <>{title}</>
        </TitleMd>
      )}
      {isMinDesktop && (
        <TitleLg color={color}>
          <>{title}</>
        </TitleLg>
      )}
    </>
  );
}

export default DashboardPageTitle;
