import { Skeleton, SkeletonProps, styled } from '@mui/material';

const StatusBannerLoader = styled((props: SkeletonProps) => (
  <Skeleton variant="rectangular" {...props} />
))({
  marginX: 24,
  height: 24
});

export default StatusBannerLoader;
