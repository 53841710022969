import { useMemo } from 'react';

import { BookingType } from '../../../../../../types/BookingType';
import { FileCategoryType } from '../../../../../../types/FileCategoryType';
import { BOOKING_PACKING_TYPES } from '../../../../../../utils/helpers/BookingHelper';
import {
  ALLOWED_IMAGE_FORMATS,
  SelectFileParam
} from '../../../../../types/booking/File';
import { useFileCategoryTypes } from '../../../hooks/useFileCategoryTypes';

export const useSelectFileParams = (
  bookingType: BookingType | undefined
): SelectFileParam[] => {
  if (!bookingType) return [];
  const fileCategoryTypes: FileCategoryType[] =
    useFileCategoryTypes(bookingType);
  const isPacking: boolean = BOOKING_PACKING_TYPES.includes(bookingType);

  return useMemo(() => {
    if (isPacking) {
      return [
        {
          category: FileCategoryType.OTHER,
          accept: ALLOWED_IMAGE_FORMATS,
          isMandatory: false
        }
      ];
    }

    return fileCategoryTypes.map((category) => {
      return {
        category,
        accept: ALLOWED_IMAGE_FORMATS,
        isMandatory: category !== FileCategoryType.OTHER
      };
    });
  }, [fileCategoryTypes, bookingType, isPacking]);
};
