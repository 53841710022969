import styles from '@convelio/stylio/styles.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  CircularProgress,
  InputAdornment,
  InputBase,
  styled
} from '@mui/material';
import { ChangeEvent, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../locales/translations';

interface SearchInputProps {
  isLoading: boolean;
  onClose: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  handleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  onFocus: () => void;
  onBlur: () => void;
}

export const SearchInput = ({
  isLoading,
  onClose,
  onChange,
  value,
  handleKeyDown,
  onFocus,
  onBlur
}: SearchInputProps) => {
  const { t } = useTranslation();
  const searchBarTranslation = translations.features.searchBar;

  return (
    <StyledInputBase
      data-testid="search-bar"
      onKeyDown={handleKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        value.length > 0 && (
          <>
            {isLoading && <CircularProgress size="1rem" />}
            <StyledCloseIcon onClick={onClose} fontSize="small" />
          </>
        )
      }
      placeholder={t(searchBarTranslation.placeholder.title)}
      onChange={onChange}
      value={value}
    />
  );
};

const StyledInputBase = styled(InputBase)({
  minWidth: '200px',
  maxWidth: '648px',
  ml: 1,
  flex: 1,
  paddingLeft: styles.cvoSpacingXl
});

const StyledCloseIcon = styled(CloseIcon)({
  cursor: 'pointer'
});
