import { UploadedFile } from 'src/app/types/booking/File';

import {
  BookingActionStatusType,
  BookingActionType,
  BookingStatusType,
  BookingType,
  BookingUserType,
  CurrencyType,
  DeliveryMethodType,
  DeliveryOptionType,
  FreightSpeedType,
  OverdueType,
  PackType,
  PackingConditionType
} from '../../types/BookingType';
import { PackingCrate } from '../../types/Packing';
import { ActivityData } from '../pages/Booking/hooks/MessageBuilder/ActivityData';

export const TRANSPORT_STEPS = [
  { key: 'PLANNING_PICKUP', bookingStatus: BookingStatusType.ACCEPTED },
  {
    key: 'PLANNING_DELIVERY',
    bookingStatus: BookingStatusType.TO_BE_SCHEDULED
  },
  { key: 'PICKUP', bookingStatus: BookingStatusType.SCHEDULED },
  { key: 'DELIVERY', bookingStatus: BookingStatusType.IN_PROGRESS },
  { key: 'UPLOAD_FILE', bookingStatus: BookingStatusType.JOB_COMPLETED }
];

export const DEPOT_STEPS = [
  { key: 'DEPOT_DROPOFF', bookingStatus: BookingStatusType.PENDING },
  { key: 'DEPOT_PICKUP', bookingStatus: BookingStatusType.IN_PROGRESS }
];

export const PACKING_STEPS = [
  { key: 'PACKING_DROPOFF', bookingStatus: BookingStatusType.PENDING },
  { key: 'PACKING_ESTIMATION', bookingStatus: BookingStatusType.IN_PROGRESS },
  {
    key: 'PACKING_CONFIRMATION',
    bookingStatus: BookingStatusType.PACKING_SCHEDULED
  },
  { key: 'PACKING_PICKUP', bookingStatus: BookingStatusType.PACKING_COMPLETED }
];

export interface BookingItem {
  id: string;
  externalId: string;
  bookingNumber: string;
  externalOrderId: string;
  orderNumber: string;
  type: BookingType;
  status: BookingStatusType;
  customerRef: string;
  accountId: number;
  accountName: string;
  orderLoadDimensions: string;
  orderLoadDescription: string;
  orderLoadPacking: PackingConditionType;
  orderValue: number;
  orderCurrency: CurrencyType;
  packLoadDimensions: string | null;
  packLoadDimensionsUsc: string | null;
  packLoadDetails: string | null;
  packLoadValue: number | null;
  packLoadVolume: number | null;
  packLoadWeight: number | null;
  eoriNumber: string | null;
  deliveryOptions: DeliveryOptionType;
  deliveryMethod: DeliveryMethodType;
  freightSpeed: FreightSpeedType;
  note: string;
  budgetEur: number;
  budgetUsd: number;
  budgetGbp: number;
  totalCost: number | null;
  startDate: string | null;
  startExpectedOn: string | null;
  startReadyOn: string | null;
  startCompanyName: string;
  startAddressLine1: string;
  startAddressZipcode: string;
  startAddressCity: string;
  startAddressCountry: string;
  startAddressLine2: string;
  startContactFirstname: string;
  startContactLastname: string;
  startContactEmails: string[];
  startContactPhones: string[];
  startNote: string;
  endDate: string | null;
  endExpectedOn: string;
  endDeadline: string;
  endCompanyName: string;
  endAddressLine1: string;
  endAddressZipcode: string;
  endAddressCity: string;
  endAddressCountry: string;
  endAddressLine2: string;
  endContactFirstname: string;
  endContactLastname: string;
  endContactEmails: string[];
  endContactPhones: string[];
  endNote: string;
  overdue: OverdueType[];
  orderAccountName: string;
  packType: PackType | null;
  crates: PackingCrate[] | null;
  packExpectedOn: string | null;
  packDate: string | null;
  files?: UploadedFile[];
}

export interface EventResult {
  streamId: string;
  version: number;
  type: BookingActionType;
  userType: BookingUserType;
  userDetails: UserBooking;
  data: ActivityData;
  meta: unknown;
  created_at: string;
}

export interface LinkedBooking {
  type: BookingType;
  bookingId: string;
}

export interface BookingResponse<T> {
  data: T;
  next?: LinkedBooking;
  previous?: LinkedBooking;
  version: number;
}

export interface BookingBudget {
  budgetEur: number;
  budgetUsd: number;
  budgetGbp: number;
}

export interface BookingMerchandise {
  value: number | null;
  orderLoadDimensions: string[];
  packLoadDimensions: string[] | null;
  packLoadDetails: string[] | null;
  eoriNumber: string | null;
  packLoadVolume: number | null;
  packLoadWeight: number | null;
  orderLoadDescription: string;
  currency: CurrencyType;
  packingCondition: PackingConditionType;
}

export interface ChangeStatusRequest {
  date?: Date | null;
  text?: string;
  version: number;
  accept?: boolean;
  bookingId: string;
  action: BookingActionStatusType;
}

export interface UserBooking {
  name: string;
  email: string;
  id: string | number;
}
