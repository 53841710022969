import { useMemo } from 'react';

import { EventMessageBuilder } from '../EventMessageBuilder';
import { useBudgetMessageBuilder } from './builders/useBudgetMessageBuilder';
import { useCratesCreatedMessageBuilder } from './builders/useCratesCreatedMessageBuilder';
import { useCratesDeletedMessageBuilder } from './builders/useCratesDeletedMessageBuilder';
import { useDeliveryContactMessageBuilder } from './builders/useDeliveryContactMessageBuilder';
import { useDeliveryDateMessageBuilder } from './builders/useDeliveryDateMessageBuilder';
import { useDeliveryDeadlineMessageBuilder } from './builders/useDeliveryDeadlineMessageBuilder';
import { useDeliveryOptionsMessageBuilder } from './builders/useDeliveryOptionsMessageBuilder';
import { useDropOffDateSetMessageBuilder } from './builders/useDropOffDateSetMessageBuilder';
import { useEtaDeliveryDateMessageBuilder } from './builders/useEtaDeliveryDateMessageBuilder';
import { useEtaPickupDateMessageBuilder } from './builders/useEtaPickupDateMessageBuilder';
import { useNoteMessageBuilder } from './builders/useNoteMessageBuilder';
import { usePackLoadMessageBuilder } from './builders/usePackLoadMessageBuilder';
import { usePackingScheduledFromSFMessageBuilder } from './builders/usePackingScheduledFromSFMessageBuilder';
import { usePickupContactMessageBuilder } from './builders/usePickupContactMessageBuilder';
import { usePickupDateMessageBuilder } from './builders/usePickupDateMessageBuilder';
import { useReadyForCollectionDateMessageBuilder } from './builders/useReadyForCollectionDateMessageBuilder';
import { useStatusMessageBuilder } from './builders/useStatusMessageBuilder';

export const useContextMessageBuilders = (): EventMessageBuilder[] => {
  const readyForCollectionDateMessageBuilder: EventMessageBuilder =
    useReadyForCollectionDateMessageBuilder();
  const pickupDateMessageBuilder: EventMessageBuilder =
    usePickupDateMessageBuilder();
  const etaPickupDateMessageBuilder: EventMessageBuilder =
    useEtaPickupDateMessageBuilder();
  const etaDeliveryDateMessageBuilder: EventMessageBuilder =
    useEtaDeliveryDateMessageBuilder();
  const deliveryDateMessageBuilder: EventMessageBuilder =
    useDeliveryDateMessageBuilder();
  const packLoadMessageBuilder: EventMessageBuilder =
    usePackLoadMessageBuilder();
  const noteMessageBuilder: EventMessageBuilder = useNoteMessageBuilder();
  const pickupContactMessageBuilder: EventMessageBuilder =
    usePickupContactMessageBuilder();
  const deliveryContactMessageBuilder: EventMessageBuilder =
    useDeliveryContactMessageBuilder();
  const statusMessageBuilder: EventMessageBuilder = useStatusMessageBuilder();
  const deliveryDeadlineMessageBuilder: EventMessageBuilder =
    useDeliveryDeadlineMessageBuilder();
  const deliveryOptionsMessageBuilder: EventMessageBuilder =
    useDeliveryOptionsMessageBuilder();
  const budgetMessageBuilder: EventMessageBuilder = useBudgetMessageBuilder();
  const cratesDeletedMessageBuilder: EventMessageBuilder =
    useCratesDeletedMessageBuilder();
  const cratesCreatedMessageBuilder: EventMessageBuilder =
    useCratesCreatedMessageBuilder();
  const dropOffDateSetMessageBuilder: EventMessageBuilder =
    useDropOffDateSetMessageBuilder();
  const packingScheduledFromSFMessageBuilder: EventMessageBuilder =
    usePackingScheduledFromSFMessageBuilder();

  return useMemo(
    () => [
      readyForCollectionDateMessageBuilder,
      pickupDateMessageBuilder,
      etaPickupDateMessageBuilder,
      etaDeliveryDateMessageBuilder,
      deliveryDateMessageBuilder,
      packLoadMessageBuilder,
      noteMessageBuilder,
      pickupContactMessageBuilder,
      deliveryContactMessageBuilder,
      statusMessageBuilder,
      deliveryDeadlineMessageBuilder,
      deliveryOptionsMessageBuilder,
      budgetMessageBuilder,
      cratesDeletedMessageBuilder,
      cratesCreatedMessageBuilder,
      dropOffDateSetMessageBuilder,
      packingScheduledFromSFMessageBuilder
    ],
    [
      readyForCollectionDateMessageBuilder,
      pickupDateMessageBuilder,
      etaPickupDateMessageBuilder,
      etaDeliveryDateMessageBuilder,
      deliveryDateMessageBuilder,
      packLoadMessageBuilder,
      noteMessageBuilder,
      pickupContactMessageBuilder,
      deliveryContactMessageBuilder,
      statusMessageBuilder,
      deliveryDeadlineMessageBuilder,
      deliveryOptionsMessageBuilder,
      budgetMessageBuilder,
      cratesDeletedMessageBuilder,
      cratesCreatedMessageBuilder,
      dropOffDateSetMessageBuilder,
      packingScheduledFromSFMessageBuilder
    ]
  );
};
