import styles from '@convelio/stylio/styles.module.scss';
import { Box, Tab, Tabs, styled } from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../locales/translations';
import { a11yProps } from '../../../../utils/helpers/TabHelper';
import TabPanel from '../../../components/TabPanel';

type MobileTabletViewWrapperProps = {
  header?: JSX.Element;
  firstTab: JSX.Element;
  secondTab: JSX.Element;
  notes?: JSX.Element;
};

function MobileTabletViewWrapper({
  header,
  firstTab,
  secondTab,
  notes
}: MobileTabletViewWrapperProps): JSX.Element {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleChange = (event: SyntheticEvent, newTabIndex: number) => {
    setTabIndex(newTabIndex);
  };

  return (
    <MobileBoxStyled>
      {header && <Box>{header}</Box>}
      <MobileTabBoxStyled>
        <MobileTabsStyled
          value={tabIndex}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="basic tabs example"
        >
          <TabStyled
            label={t(translations.jobDetails.title)}
            {...a11yProps('basicTab', 0)}
          />
          <TabStyled
            label={t(translations.filesActivity.title)}
            {...a11yProps('basicTab', 1)}
          />
        </MobileTabsStyled>
      </MobileTabBoxStyled>
      <TabPanel value={tabIndex} index={0}>
        {firstTab}
      </TabPanel>
      <LateralTabPanelStyled value={tabIndex} index={1}>
        <>
          <ColorBoxStyled />
          {secondTab}
        </>
      </LateralTabPanelStyled>
      {notes}
    </MobileBoxStyled>
  );
}

export default MobileTabletViewWrapper;

const MobileBoxStyled = styled(Box)({
  width: '100%',
  height: '100%'
});

const ColorBoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  height: styles.cvoSpacingG
}));

const MobileTabBoxStyled = styled(Box)({
  borderBottom: 1,
  borderColor: 'divider'
});

const MobileTabsStyled = styled(Tabs)(({ theme }) => ({
  backgroundColor: 'white',
  color: theme.palette.common.black,
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.common.black,
    height: styles.cvoSpacingXxs
  }
}));

const LateralTabPanelStyled = styled(TabPanel)({
  backgroundColor: 'white',
  height: '100%'
});

const TabStyled = styled(Tab)(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: '600',
  '&.Mui-selected': {
    color: theme.palette.common.black
  },
  '&.MuiTab-indicator': {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.black
  }
}));
