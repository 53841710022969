import { useMemo } from 'react';

interface ContactProps {
  firstname: string;
  lastname: string;
  emails: string[];
  phones: string[];
}

interface ContactReturnType {
  firstname: string;
  lastname: string;
  emails: string[];
  phones: string[];
}

export const useContact = ({
  firstname,
  lastname,
  emails,
  phones
}: ContactProps): ContactReturnType => {
  return useMemo(
    () => ({ firstname, lastname, emails, phones }),
    [firstname, lastname, emails, phones]
  );
};
