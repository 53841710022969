import { BreakpointsOptions } from '@mui/material';

export default {
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  values: {
    xs: Number(0),
    sm: Number(600),
    md: Number(900),
    lg: Number(1200),
    xl: Number(1536),
    mobile: 450,
    tablet: 768,
    laptop: 1024,
    desktop: 1440
  },
  unit: 'px'
} as BreakpointsOptions;
