import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LevelType } from 'src/types/LevelType';
import { DEFAULT_BOOKING_TRANSPORT_COMPLETED } from 'src/utils/helpers/BookingHelper';

import { translations } from '../../../../locales/translations';
import { BookingStatusType } from '../../../../types/BookingType';

const useMessageMemo = (status: BookingStatusType, type: LevelType): string => {
  const { t } = useTranslation();
  const messageKey = useMemo(() => getMessageKey(status, type), [status]);
  return t(messageKey);
};

const getMessageKey = (status: BookingStatusType, type: LevelType): string => {
  if (type === LevelType.WARNING && status === BookingStatusType.JOB_COMPLETED)
    return translations.pickupDateToUpdateMessage;
  if (status === BookingStatusType.ON_HOLD)
    return translations.onHoldJobMessage.title;
  if (status === BookingStatusType.CANCELLED)
    return translations.cancelledJobMessage.title;
  if (status === BookingStatusType.PENDING)
    return translations.pendingJobMessage.title;
  if (DEFAULT_BOOKING_TRANSPORT_COMPLETED.includes(status))
    return translations.successJobMessage.title;

  return 'unknown_reason';
};

export default useMessageMemo;
