import {
  format,
  isAfter,
  isBefore,
  isSameDay,
  isValid,
  parseISO
} from 'date-fns';
import { useTranslation } from 'react-i18next';

import { formatI18n, getDateFormatI18n } from '../../locales/i18nDate';
import { settingName } from '../pages/Setting/constants';
import { SettingsHook, useSettings } from '../pages/Setting/useSettings';

export type DateReturnType = {
  dateFormat: string;
  fromIsoToDate: (date: string | null) => Date;
  isDateAfterToday: (date: string | null) => boolean;
  isDateGreaterThanOrEqualToToday: (date: string | null) => boolean;
  isDateLessThanToday: (date: string | null) => boolean;
  fromDateToFormat: (date: Date | null) => string;
  isDateToday: (date: string | null) => boolean;
  fromIsoToDateFormat: (date: string | null) => string;
  parseISODateOrNull: (dateString: string | null | undefined) => Date | null;
  today: Date;
  formatDateToFullDateString: (date: Date | null) => string;
  formatIsoToFullDateString: (dateString: string | null) => string;
};

export function useDate(): DateReturnType {
  const { getSettingValue }: SettingsHook = useSettings();
  const { i18n } = useTranslation();

  const parseMidnightISO = (date: string): Date => {
    const dateParsed = parseISO(date);
    dateParsed.setHours(0, 0, 0, 0);
    return dateParsed;
  };

  const fromIsoToDateFormat = (date: string | null): string => {
    if (!date) return '';
    return fromDateToFormat(parseMidnightISO(date));
  };

  const today = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return today;
  };

  const parseISODateOrNull = (
    dateString: string | null | undefined
  ): Date | null => {
    const date = dateString ? parseMidnightISO(dateString) : null;
    return date && isValid(date) ? date : null;
  };

  const fromDateToFormat = (date: Date | null): string => {
    if (!date) return '';
    const dateFormat = getSettingValue(settingName.dateFormat);

    if (!dateFormat) return formatI18n(date);

    return format(date, dateFormat);
  };

  const fromIsoToDate = (date: string | null): Date =>
    date ? parseMidnightISO(date) : new Date();

  const isDateToday = (date: string | null): boolean => {
    if (!date) return false;
    return isSameDay(parseMidnightISO(date), new Date());
  };

  const isDateAfterToday = (date: string | null): boolean => {
    if (!date) return false;
    return isAfter(parseMidnightISO(date), new Date());
  };

  const isDateGreaterThanOrEqualToToday = (date: string | null): boolean => {
    if (!date) return false;
    const today: Date = new Date();
    const dateParsed: Date = parseMidnightISO(date);

    return isSameDay(dateParsed, today) || isAfter(dateParsed, today);
  };

  const isDateLessThanToday = (date: string | null): boolean => {
    if (!date) return false;
    const today: Date = new Date();
    const dateParsed: Date = parseMidnightISO(date);

    return isBefore(dateParsed, today) && !isSameDay(dateParsed, today);
  };

  const getDateFormat = (): string => {
    return (
      getSettingValue(settingName.dateFormat) ||
      getDateFormatI18n(i18n.language)
    );
  };

  const formatIsoToFullDateString = (dateString: string | null): string => {
    const date = parseISODateOrNull(dateString);

    return formatDateToFullDateString(date) || '-';
  };

  const formatDateToFullDateString = (date: Date | null): string => {
    return date
      ? date.toLocaleDateString(getSettingValue(settingName.language), {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })
      : '-';
  };

  return {
    fromIsoToDate,
    fromDateToFormat,
    fromIsoToDateFormat,
    isDateToday,
    isDateGreaterThanOrEqualToToday,
    isDateLessThanToday,
    isDateAfterToday,
    dateFormat: getDateFormat(),
    parseISODateOrNull,
    today: today(),
    formatDateToFullDateString,
    formatIsoToFullDateString
  };
}
