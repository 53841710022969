import styles from '@convelio/stylio/styles.module.scss';
import { Box, Paper, PaperProps, styled } from '@mui/material';
import Merchandise from 'src/app/pages/Booking/Features/Merchandise';
import { BookingItem } from 'src/app/types/Booking';

import MainPanelWrapper from '../../../../components/MainPanelWrapper';
import TransportDelivery from '../Delivery/TransportDelivery';
import TransportPickup from '../Pickup/TransportPickup';
import TransportStatusBanner from '../StatusBanner/TransportStatusBanner';

interface TransportMainPanelProps {
  version: number;
  isLoading?: boolean;
  booking: BookingItem;
}

function TransportMainPanel({
  booking,
  isLoading,
  version
}: TransportMainPanelProps): JSX.Element {
  return (
    <MainPanelWrapper
      bannerContent={
        <TransportStatusBanner
          version={version}
          booking={booking}
          isLoading={isLoading}
        />
      }
      mainContent={
        <MainContentBoxStyled>
          <MainContentPaperStyled>
            <TransportPickup
              booking={booking}
              version={version}
              isLoading={isLoading}
            />
            <TransportDelivery
              booking={booking}
              version={version}
              isLoading={isLoading}
            />
          </MainContentPaperStyled>
        </MainContentBoxStyled>
      }
      lateralContent={<Merchandise booking={booking} isLoading={isLoading} />}
    />
  );
}

export default TransportMainPanel;

const MainContentBoxStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('tablet')]: {
    marginTop: 0
  }
}));

const MainContentPaperStyled = styled((props: PaperProps) => (
  <Paper elevation={0} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: styles.cvoSpacingXs,
  display: 'flex',
  flexDirection: 'column'
}));
