import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../locales/translations';
import { downloadFile as doDownloadFile } from '../../../../services/DownloadFile';
import { UploadedFile } from '../../../../types/booking/File';

interface DownloadFileProps {
  files: UploadedFile[] | undefined;
  currentIndex: number | undefined;
}

interface DownloadFileReturnType {
  downloadFile: () => void;
  downloadFileTranslation: string;
}

export const useDownloadFile = ({
  files,
  currentIndex
}: DownloadFileProps): DownloadFileReturnType => {
  const { t } = useTranslation();

  const downloadFile = (): void => {
    if (
      !files ||
      (!currentIndex && currentIndex !== 0) ||
      !files[currentIndex]
    ) {
      return;
    }
    doDownloadFile(files[currentIndex].url, files[currentIndex].name);
  };

  return {
    downloadFile,
    downloadFileTranslation: t(
      translations.pages.booking.features.downloadFile.title
    )
  };
};
