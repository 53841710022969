import { Box, Drawer, styled } from '@mui/material';
import { useState } from 'react';

import NoteBody from './NoteBody';
import NoteTitle from './NoteTitle';
import NoteTitleLoader from './NoteTitleLoader';

const NoteMobileMainBoxStyled = styled(Box)({
  position: 'fixed',
  left: 0,
  bottom: 0,
  width: '100%',
  height: 64,
  backgroundColor: 'white',
  padding: 16,
  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)'
});

type Props = {
  note: string;
  isEditable: boolean;
  nbrNote: number;
  isLoading?: boolean;
  handleNoteChange: (value?: string) => void;
};

function NotesMobileTabletView({
  note,
  isEditable,
  nbrNote,
  isLoading,
  handleNoteChange
}: Props): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      {!open && (
        <NoteMobileMainBoxStyled onClick={() => setOpen(true)}>
          {isLoading ? (
            <NoteTitleLoader />
          ) : (
            <NoteTitle
              editMode={false}
              nbrNote={nbrNote}
              isEditable={isEditable}
            />
          )}
        </NoteMobileMainBoxStyled>
      )}

      <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)}>
        <NoteBody
          note={note}
          isEditable={isEditable}
          nbrNote={nbrNote}
          isLoading={isLoading}
          handleNoteChange={handleNoteChange}
        />
      </Drawer>
    </>
  );
}

export default NotesMobileTabletView;
