import { Box } from '@mui/system';
import { Link } from 'react-router-dom';

const Logo = (): JSX.Element => {
  return (
    <Link to={`/`}>
      <Box
        sx={{
          position: 'absolute',
          left: '4.17%',
          top: '2.38%'
        }}
      >
        <img
          src="/convelio-blue-crate-only.svg"
          alt="Convelio - Search no result"
        />
      </Box>
    </Link>
  );
};

export default Logo;
