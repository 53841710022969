import { useEffect, useState } from 'react';

import { BookingType } from '../../../../../types/BookingType';
import { useBookingListFilter } from '../../../../hooks/useBookingListFilter';
import { BookingJobType, BookingPage, Tab } from '../../types';
import { usePackingBookingsCounter } from './usePackingBookingsCounter';
import {
  PackingRequestBookings,
  PackingRequests,
  usePackingRequests
} from './usePackingRequests';

export interface PackingPagesBuilder {
  pages: BookingPage | undefined;
}

export const usePackingPagesBuilder = (): PackingPagesBuilder => {
  const [pages, setPages] = useState<BookingPage>();
  const { bookings, isLoading }: PackingRequests = usePackingRequests();
  const { filterConfirmedBookings } = useBookingListFilter();
  const { totalBookingsToUpdate } = usePackingBookingsCounter();

  const buildTab = (
    bookingType: PackingRequestBookings,
    anchor?: string
  ): Tab => {
    return {
      bookings: bookings[bookingType]?.data,
      isLoading,
      total: bookings[bookingType]?.total,
      anchor
    };
  };

  const buildPages = (): BookingPage => {
    const confirmedBookings = filterConfirmedBookings(
      bookings[BookingJobType.CONFIRM_PACKING]?.data || [],
      BookingType.CRATING
    );

    return {
      [BookingJobType.TODO]: {
        tabs: {
          [BookingJobType.OVERALL]: {
            total: totalBookingsToUpdate
          },
          [BookingJobType.OVERDUE]: buildTab(BookingJobType.OVERDUE, 'overdue'),
          [BookingJobType.CONFIRM_PACKING]: {
            bookings: confirmedBookings,
            isLoading,
            total: confirmedBookings.length,
            anchor: 'confirm_packing'
          },
          [BookingJobType.SCHEDULE_PACKING]: buildTab(
            BookingJobType.SCHEDULE_PACKING,
            'schedule_packing'
          ),
          [BookingJobType.CONFIRM_PICKUP]: buildTab(
            BookingJobType.CONFIRM_PICKUP,
            'confirm_pickup'
          ),
          [BookingJobType.CONFIRM_DROP_OFF]: buildTab(
            BookingJobType.CONFIRM_DROP_OFF,
            'confirm_drop_off'
          )
        }
      },
      [BookingJobType.PLANNED]: buildTab(BookingJobType.PLANNED),
      [BookingJobType.HISTORY]: buildTab(BookingJobType.HISTORY)
    };
  };

  useEffect(() => {
    setPages(buildPages());
  }, [
    bookings[BookingJobType.OVERDUE],
    bookings[BookingJobType.SCHEDULE_PACKING],
    bookings[BookingJobType.CONFIRM_PICKUP],
    bookings[BookingJobType.CONFIRM_PACKING],
    bookings[BookingJobType.CONFIRM_DROP_OFF],
    bookings[BookingJobType.HISTORY],
    bookings[BookingJobType.PLANNED]
  ]);

  return { pages };
};
