import { BookingStatusType } from '../../../../../../../types/BookingType';

interface TransportPickupBackgroundColorProps {
  status: BookingStatusType;
  etaPickupDate?: string | null;
}

const ACTIVE_BACKGROUND_COLOR = 'grey.100';
const BACKGROUND_COLOR = 'common.white';
const ACTIVE_COLOR = 'primary.dark';
const COLOR = 'grey.400';

export const useStepBackgroundColor = ({
  status,
  etaPickupDate
}: TransportPickupBackgroundColorProps) => {
  return {
    deliveryBackgroundColor: isDeliveryActive(status, etaPickupDate)
      ? ACTIVE_BACKGROUND_COLOR
      : BACKGROUND_COLOR,
    pickupBackgroundColor: isPickupActive(status, etaPickupDate)
      ? ACTIVE_BACKGROUND_COLOR
      : BACKGROUND_COLOR,
    deliveryColor: isDeliveryActive(status, etaPickupDate)
      ? ACTIVE_COLOR
      : COLOR,
    pickupColor: isPickupActive(status, etaPickupDate) ? ACTIVE_COLOR : COLOR
  };
};

const isPickupActive = (
  status: BookingStatusType,
  etaPickupDate?: string | null
): boolean => {
  return (
    status === BookingStatusType.ACCEPTED ||
    status === BookingStatusType.SCHEDULED ||
    (status === BookingStatusType.TO_BE_SCHEDULED && !etaPickupDate)
  );
};

const isDeliveryActive = (
  status: BookingStatusType,
  etaPickupDate?: string | null
): boolean => {
  return (
    (status === BookingStatusType.TO_BE_SCHEDULED &&
      etaPickupDate !== null &&
      etaPickupDate !== undefined) ||
    status === BookingStatusType.IN_PROGRESS
  );
};
