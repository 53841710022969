import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BookingItem } from 'src/app/types/Booking';
import { translations } from 'src/locales/translations';
import { BookingActionStatusType } from 'src/types/BookingType';

import AccordionStepWrapper from '../../components/AccordionStepWrapper';
import { usePacking } from '../../hooks/usePacking';
import PackingPickupEdit from './PackingPickupEdit';

type Props = {
  booking: BookingItem;
  handleSubmit: (
    feature: string,
    action: BookingActionStatusType,
    date?: Date
  ) => Promise<void>;
};

function PackingPickup({ booking, handleSubmit }: Props): JSX.Element {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { pickupStepDisabled, pickupStepExpanded } = usePacking();
  const translationPacking = translations.pages.booking.pages.packing;

  useEffect(() => {
    setDisabled(pickupStepDisabled(booking));
    setIsExpanded(pickupStepExpanded(booking));
  }, [booking]);

  const handleSubmitPickup = async (date?: Date): Promise<void> => {
    await handleSubmit('pickup', BookingActionStatusType.SET_END_DATE, date);
  };

  return (
    <AccordionStepWrapper
      disabled={disabled}
      isExpanded={isExpanded}
      step={{
        stepBadge: 4,
        stepName: t(translationPacking.features.stepper.PACKING_PICKUP),
        location: {
          name: booking.endCompanyName,
          city: booking.endAddressCity,
          country: booking.endAddressCountry
        }
      }}
      content={
        <PackingPickupEdit
          isDone={disabled}
          booking={booking}
          handleSubmitPickup={handleSubmitPickup}
        />
      }
    />
  );
}

export default PackingPickup;
