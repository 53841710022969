import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryRefresh } from './BaseQueryApi';

export const refreshTokenApi = createApi({
  reducerPath: 'refreshTokenApi',
  baseQuery: baseQueryRefresh,
  endpoints: (builder) => ({
    refreshToken: builder.mutation({
      query: () => ({
        url: `/auth/refresh`,
        method: 'GET'
      })
    })
  })
});

export const { useRefreshTokenMutation } = refreshTokenApi;
