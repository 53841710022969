import { useMemo } from 'react';

import {
  BookingStatusType,
  BookingType
} from '../../../../../../../../types/BookingType';

interface PickupDateInputModeProps {
  bookingStatus: BookingStatusType;
  bookingType: BookingType;
  readyForCollectionDateString: string | null | undefined;
}

interface PickupDateInputModeReturnType {
  isEtaPickupDateInEditMode: boolean;
  isPickupShowConfirmDate: boolean;
  isPickupDateInEditMode: boolean;
  isEtaPickupDone: boolean;
  isPickupDone: boolean;
  isReadyForCollectionDateAvailable: boolean;
}

export const usePickupDateInputMode = ({
  bookingStatus,
  bookingType,
  readyForCollectionDateString
}: PickupDateInputModeProps): PickupDateInputModeReturnType => {
  const isPickupShowConfirmDate: boolean = useMemo(() => {
    return [
      BookingStatusType.SCHEDULED,
      BookingStatusType.IN_PROGRESS,
      BookingStatusType.JOB_COMPLETED,
      BookingStatusType.COMPLETED,
      BookingStatusType.COMPLETED_READ_ONLY
    ].includes(bookingStatus);
  }, [bookingStatus]);

  const isEtaPickupDateInEditMode: boolean = useMemo(() => {
    return BookingStatusType.ACCEPTED === bookingStatus;
  }, [bookingStatus]);

  const isPickupDateInEditMode: boolean = useMemo(() => {
    return BookingStatusType.SCHEDULED === bookingStatus;
  }, [bookingStatus]);

  const isEtaPickupDone = useMemo(() => {
    return [
      BookingStatusType.IN_PROGRESS,
      BookingStatusType.JOB_COMPLETED,
      BookingStatusType.COMPLETED,
      BookingStatusType.COMPLETED_READ_ONLY
    ].includes(bookingStatus);
  }, [bookingStatus]);

  const isPickupDone = useMemo(() => {
    return BookingStatusType.COMPLETED_READ_ONLY === bookingStatus;
  }, [bookingStatus]);

  const isReadyForCollectionDateAvailable: boolean = useMemo(() => {
    return (
      bookingType === BookingType.PICKUP &&
      ([
        BookingStatusType.ACCEPTED,
        BookingStatusType.SCHEDULED,
        BookingStatusType.TO_BE_SCHEDULED
      ].includes(bookingStatus) ||
        (readyForCollectionDateString !== null &&
          readyForCollectionDateString !== undefined))
    );
  }, [bookingStatus, bookingType]);

  return {
    isEtaPickupDateInEditMode,
    isPickupShowConfirmDate,
    isPickupDateInEditMode,
    isEtaPickupDone,
    isPickupDone,
    isReadyForCollectionDateAvailable
  };
};
