import styles from '@convelio/stylio/styles.module.scss';
import { Box, Skeleton, Typography, styled } from '@mui/material';

import { Location } from '../../../../types/Location';
import { getFlagFromName } from '../../../../utils/helpers/CountryFlags';
import AvatarStep from '../../../components/AvatarStep';

type Props = {
  color: string;
  stepName: string;
  location?: Location;
  isLoading?: boolean;
  disabled?: boolean;
  stepBadge: string | number;
};

function StepTitle({
  color,
  isLoading,
  stepBadge,
  stepName,
  disabled,
  location
}: Props): JSX.Element {
  const disabledColor = 'grey.400';
  const flagCountry = getFlagFromName(location?.country);

  if (isLoading) {
    return (
      <Wrapper>
        <StyledBox>
          <AvatarLoader />
          <TitleLoader />
        </StyledBox>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <StyledBox>
        <AvatarStep
          step={stepBadge}
          bgColor={disabled ? disabledColor : color}
        />
        <TypographyTitle color={disabled ? disabledColor : color} mr={8}>
          <>{stepName && `${stepName}`}</>
        </TypographyTitle>
      </StyledBox>
      {location && (
        <TypographyTitle
          noWrap={false}
          color="grey.500"
          mr={styles.cvoSpacingXs}
        >
          <>
            {location.name && `${location.name}`}
            {location.city && `${location.name ? ', ' : ''}${location.city}`}
            {flagCountry && ` ${flagCountry}`}
          </>
        </TypographyTitle>
      )}
    </Wrapper>
  );
}

export default StepTitle;

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'start',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    gap: styles.cvoSpacingXs
  }
}));

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: styles.cvoSpacingXs
});

const TypographyTitle = styled(Typography)({
  fontSize: styles.cvoBodyLgFontSize,
  fontWeight: '600'
});

const AvatarLoader = styled(() => (
  <Skeleton variant="circular" width={24} height={24} />
))({});

const TitleLoader = styled(() => (
  <Skeleton variant="text" width={120} height={24} />
))({});
