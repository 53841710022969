import { Box, Paper, PaperProps, styled } from '@mui/material';
import Merchandise from 'src/app/pages/Booking/Features/Merchandise';
import { BookingItem } from 'src/app/types/Booking';

import MainPanelWrapper from '../../../../components/MainPanelWrapper';
import DepotStatusBanner from '../../../DepotBooking/Features/StatusBanner/DepotStatusBanner';
import DepotDropOff from '../DropOff/DepotDropoff';
import DepotPickup from '../Pickup/DepotPickup';

const MainContentBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});
const MainContentPaperStyled = styled((props: PaperProps) => (
  <Paper elevation={0} {...props} />
))({
  padding: 4,
  borderRadius: '8px',
  backgroundColor: 'white'
});

type Props = {
  version: number;
  isLoading?: boolean;
  booking: BookingItem;
};

function DepotMainPanel({ booking, isLoading, version }: Props): JSX.Element {
  return (
    <MainPanelWrapper
      bannerContent={
        <DepotStatusBanner
          version={version}
          booking={booking}
          isLoading={isLoading}
        />
      }
      mainContent={
        <MainContentBoxStyled>
          <MainContentPaperStyled>
            <DepotDropOff
              booking={booking}
              version={version}
              isLoading={isLoading}
            />
            <DepotPickup
              booking={booking}
              version={version}
              isLoading={isLoading}
            />
          </MainContentPaperStyled>
        </MainContentBoxStyled>
      }
      lateralContent={<Merchandise booking={booking} isLoading={isLoading} />}
    />
  );
}

export default DepotMainPanel;
