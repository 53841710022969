import { useParams } from 'react-router';
import { Navigate, useLocation } from 'react-router-dom';
import { routes } from 'src/types/Routes';

const transportSubMenuAllowed = ['requests', 'planning', 'history', undefined];
const packingSubMenuAllowed = ['planning', 'history', undefined];
const depotSubMenuAllowed = ['planning', 'history', undefined];

type DashboardGuardProps = {
  children: JSX.Element;
};

export const DashboardGuard = ({
  children
}: DashboardGuardProps): JSX.Element => {
  const { subMenu } = useParams();
  const { pathname } = useLocation();

  const isTransportMenu = () => {
    return pathname.includes('transport');
  };

  const isPackingMenu = () => {
    return pathname.includes('packing');
  };

  const isDepotMenu = () => {
    return pathname.includes('depot');
  };

  const isAllowedMenu = (): boolean => {
    if (isTransportMenu()) {
      return transportSubMenuAllowed.includes(subMenu);
    }

    if (isPackingMenu()) {
      return packingSubMenuAllowed.includes(subMenu);
    }

    if (isDepotMenu()) {
      return depotSubMenuAllowed.includes(subMenu);
    }

    return pathname === routes.dashboard;
  };

  return isAllowedMenu() ? children : <Navigate to="/404" replace />;
};
