import { ArrowDropDown } from '@mui/icons-material';
import {
  Box,
  Fade,
  Menu,
  MenuItem,
  MenuProps,
  Typography,
  styled
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { translations } from '../../../locales/translations';
import { User } from '../../../types/User';
import NotificationSnackbar from '../../components/SnackBar';
import { useResponsiveness } from '../../hooks/useResponsive';
import { UserActionType } from './types';
import { useLogout } from './useLogout';

type Props = {
  user: User | null;
};

function UserAccount(props: Props): JSX.Element | null {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveness();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { handleLogout, error, resetError } = useLogout();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = async (action: string): Promise<void> => {
    if (action === UserActionType.LOGOUT) await handleLogout();
    if (action === UserActionType.SETTINGS) navigate('/settings');
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Typography component={'span'} variant="body1">
        <Wrapper onClick={handleClick} data-testid="user-menu">
          {!isSmallScreen && (
            <UserBoxStyled
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              {props.user?.name}
            </UserBoxStyled>
          )}
          <ArrowDropDown />
        </Wrapper>
      </Typography>
      <StyledMenu
        color="secondary"
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {Object.values(UserActionType).map((item) => (
          <MenuItem
            key={item}
            onClick={() => handleChange(item)}
            data-testid={`user-menu-${item.toLowerCase()}`}
          >
            {t(translations[item].title)}
          </MenuItem>
        ))}
      </StyledMenu>
      {error && (
        <NotificationSnackbar
          message={error}
          severity="error"
          open
          onClose={resetError}
        />
      )}
    </>
  );
}

export default UserAccount;

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={1}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.shape.borderRadius,
    marginTop: 55,
    right: 0,
    minWidth: 118,
    color: theme.palette.primary,
    outerWidth: '100%',
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.grey['600'],
        marginRight: 8
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
      }
    }
  }
}));

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  minWidth: 72,
  width: 'fit-content',
  padding: 22,
  cursor: 'pointer'
});

const UserBoxStyled = styled(Box)({
  marginRight: 15
});
