import { Box, Grid } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../locales/translations';
import ErrorPageBackToHomeButton from '../../components/ErrorPageBackToHomeButton/ErrorPageBackToHomeButton';
import ErrorPageImage from '../../components/ErrorPageImage/ErrorPageImage';
import ErroPageMessage from '../../components/ErrorPageMessage/ErrorPageMessage';
import Logo from '../../components/Logo';

const mainBoxSx: SxProps = {
  display: 'flex',
  flexDirection: 'column'
};

const mainGridSx: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  height: '100%',
  paddingX: 72
};

const itemGridSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start'
};

function PageNotFound(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box sx={mainBoxSx} data-testid="page-not-found">
      <Logo />
      <Grid container spacing={0} sx={mainGridSx}>
        <Grid item xs={7} sx={itemGridSx}>
          <ErroPageMessage
            title={t(translations.pages.pageNotFound.title)}
            subTitle={t(translations.pages.pageNotFound.subtilte)}
          />
          <ErrorPageBackToHomeButton />
        </Grid>
        <Grid item xs={5} sx={itemGridSx}>
          <ErrorPageImage
            src={'/convelio-404.png'}
            alt={'Convelio - 404 no result'}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PageNotFound;
