import { translations } from '../../../../../../../locales/translations';
import { BookingActionType } from '../../../../../../../types/BookingType';
import { BOOKING_TRANSPORT_TYPES } from '../../../../../../../utils/helpers/BookingHelper';
import { ActivityData } from '../../ActivityData';
import { BuilderDecisionParams } from '../../BuilderDecisionParams';
import { EventMessageBuilder } from '../../EventMessageBuilder';
import { useFormatTranslationWithDate } from '../useFormatTranslationWithDate';

export const useTransportEndDateSetMessageBuilder = (): EventMessageBuilder => {
  const { formatTranslationWithDate } = useFormatTranslationWithDate();

  const supports = ({
    bookingType,
    eventResult
  }: BuilderDecisionParams): boolean => {
    return (
      BOOKING_TRANSPORT_TYPES.includes(bookingType) &&
      eventResult.type === BookingActionType.BOOKING_END_DATE_SET
    );
  };

  const build = ({ date }: ActivityData): string => {
    return formatTranslationWithDate(
      translations.pages.booking.features.activity.transportEndDateSet,
      date
    );
  };

  return {
    supports,
    build
  };
};
