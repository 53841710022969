import { Box, Typography, styled } from '@mui/material';

const WrapperBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start'
});
type Props = {
  isLoading?: boolean;
  label: string;
  partner: string;
};

function BookingLocationPartern({ label, partner }: Props): JSX.Element {
  return (
    <WrapperBoxStyled>
      <Typography variant="subtitle1" color="grey.400" mb={2}>
        {label}
      </Typography>
      <Typography variant="h6" color={'grey.600'}>
        {partner}
      </Typography>
    </WrapperBoxStyled>
  );
}

export default BookingLocationPartern;
