import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationSnackbar from 'src/app/components/SnackBar';
import { useResponsiveness } from 'src/app/hooks/useResponsive';
import { useChangeStatusMutation } from 'src/app/services/BookingApi';
import { BookingItem } from 'src/app/types/Booking';
import { translations } from 'src/locales/translations';
import {
  BookingActionStatusType,
  BookingStatusType
} from 'src/types/BookingType';

import NotesDesktopView from './components/NotesDesktopView';
import NotesMobileTabletView from './components/NotesMobileTabletView';

type Props = {
  isLoading?: boolean;
  version: number;
  booking: BookingItem;
};

function Notes({ isLoading, booking, version }: Props): JSX.Element {
  const { t } = useTranslation();
  const { isTabletView } = useResponsiveness();

  const [note, setNote] = useState<string>('');
  const [nbrNote, setNbrNote] = useState<number>(0);
  const [isEditable, setIsEditable] = useState<boolean>(false);

  useEffect(() => {
    if (!booking) return;
    setNote(booking.note);
    setNbrNote(booking.note ? 1 : 0);
    setIsEditable(
      booking.status !== BookingStatusType.NEW &&
        booking.status !== BookingStatusType.COMPLETED_READ_ONLY
    );
  }, [booking]);

  const [changeStatus, { isError, isSuccess }] = useChangeStatusMutation();
  const handleNoteChange = async (value?: string) => {
    if (!value || !booking || version === undefined) return;
    try {
      await changeStatus({
        bookingId: booking.id,
        action: BookingActionStatusType.EDIT_NOTE,
        version: version,
        text: value
      }).unwrap();
    } catch (e) {
      console.debug(e);
    }
  };

  return (
    <Box>
      {isTabletView ? (
        <NotesMobileTabletView
          note={note}
          isEditable={isEditable}
          nbrNote={nbrNote}
          isLoading={isLoading}
          handleNoteChange={handleNoteChange}
        />
      ) : (
        <NotesDesktopView
          note={note}
          isEditable={isEditable}
          nbrNote={nbrNote}
          isLoading={isLoading}
          handleNoteChange={handleNoteChange}
        />
      )}
      {(isError || isSuccess) && (
        <NotificationSnackbar
          open={true}
          severity={isError ? 'error' : 'success'}
          message={t(
            translations.writeNote[isError ? 'failedMessage' : 'successMessage']
          )}
        />
      )}
    </Box>
  );
}

export default Notes;
