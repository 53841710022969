import styles from '@convelio/stylio/styles.module.scss';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { MerchandiseDimensionType } from '../../../../../../types/MerchandiseDimensionType';
import useTitle from '../../../../../pages/Booking/Features/Merchandise/components/MerchandiseTitle/useTitle';
import {
  SettingsHook,
  useSettings
} from '../../../../../pages/Setting/useSettings';
import { BookingItem } from '../../../../../types/Booking';
import { MerchandiseDimensionsView } from './MerchandiseDimensionsView';
import { MerchandiseEoriNumberView } from './MerchandiseEoriNumberView';
import { MerchandisePackDetailsView } from './MerchandisePackDetailsView';
import { MerchandisePackTypeView } from './MerchandisePackTypeView';
import { MerchandiseValueView } from './MerchandiseValueView';
import { useMerchandise } from './useMerchandise';

interface MerchandiseSectionProps {
  booking: BookingItem;
}

export const MerchandiseView = ({ booking }: MerchandiseSectionProps) => {
  const { measurementSystem }: SettingsHook = useSettings();
  const { merchandise, merchandiseDescriptionTitle } = useMerchandise({
    booking,
    measurementSystem
  });
  const merchandiseTitle = useTitle({
    packingCondition: merchandise.packingCondition,
    bookingType: booking.type
  });

  return (
    <View style={style.view} data-testid="merchandise-view">
      <Text style={style.merchandiseTitle}>{merchandiseTitle}</Text>
      <View>
        <Text style={style.merchandiseDescriptionTitle}>
          {merchandiseDescriptionTitle}
        </Text>
        {merchandise.orderLoadDescription && (
          <Text>{merchandise.orderLoadDescription}</Text>
        )}
        {merchandise.packLoadDimensions?.length && (
          <MerchandiseDimensionsView
            dimensions={merchandise.packLoadDimensions}
            type={MerchandiseDimensionType.PACK_LOAD}
          />
        )}
        {merchandise.packLoadDetails &&
          merchandise.packLoadDetails.length !== 0 && (
            <MerchandisePackDetailsView details={merchandise.packLoadDetails} />
          )}
        {merchandise.orderLoadDimensions &&
          merchandise.orderLoadDimensions.length !== 0 && (
            <MerchandiseDimensionsView
              dimensions={merchandise.orderLoadDimensions}
              type={MerchandiseDimensionType.ORDER_LOAD}
            />
          )}
        {merchandise.eoriNumber && (
          <MerchandiseEoriNumberView eoriNumber={merchandise.eoriNumber} />
        )}
        {(merchandise.packLoadVolume || merchandise.packLoadVolume === 0) &&
          (merchandise.packLoadWeight || merchandise.packLoadWeight === 0) && (
            <Text>
              {merchandise.packLoadVolume}cmb, {merchandise.packLoadWeight}kg
            </Text>
          )}
        {merchandise.value && (
          <MerchandiseValueView
            value={merchandise.value}
            currencyType={merchandise.currency}
          />
        )}
        {booking.packType && (
          <MerchandisePackTypeView packType={booking.packType} />
        )}
      </View>
    </View>
  );
};

const style = StyleSheet.create({
  view: {
    flexDirection: 'column',
    marginBottom: 10,
    padding: 10
  },
  merchandiseTitle: {
    fontSize: styles.cvoBodyLgFontSize,
    fontFamily: 'Helvetica-Bold',
    marginTop: styles.cvoSpacingS
  },
  merchandiseDescriptionTitle: {
    fontSize: styles.cvoBodyMdFontSize,
    fontFamily: 'Helvetica-Bold',
    marginTop: styles.cvoSpacingM,
    marginBottom: styles.cvoSpacingXs
  }
});
