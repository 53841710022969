import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../../locales/translations';
import { BookingActionType } from '../../../../../../../types/BookingType';
import { ActivityData } from '../../ActivityData';
import { BuilderDecisionParams } from '../../BuilderDecisionParams';
import { EventMessageBuilder } from '../../EventMessageBuilder';

export const useBookingFileDeletedMessageBuilder = (): EventMessageBuilder => {
  const { t } = useTranslation();

  const supports = (params: BuilderDecisionParams): boolean => {
    return params.eventResult.type === BookingActionType.FILE_DELETED;
  };

  const build = (data: ActivityData): string => {
    const fileName = data.file !== undefined ? `"${data.file.name}"` : '';
    return t(translations.pages.booking.features.activity.fileDeleted, {
      fileName
    });
  };

  return {
    supports,
    build
  };
};
