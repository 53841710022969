import { Container } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { translations } from '../../../locales/translations';
import LangDropdown from '../../Features/LangDropdown';
import Logo from '../../components/Logo';
import NotificationSnackbar from '../../components/SnackBar';
import { useRecoveryPasswordMutation } from '../../services/RecoveryPasswordAPI';
import { RecoveryPasswordRequest } from '../../types/RecoveryPassword';
import { RecoverPasswordForm } from './Features/RecoverPassword';
import { useEmailTokenFromQueryString } from './Features/RecoverPassword/hooks/useEmailTokenFromQueryString';
import RecoverPasswordMessage from './components/RecoverPassworMessage';

function RecoveryPassword(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { email, token }: { email: string | null; token: string | null } =
    useEmailTokenFromQueryString();
  const [recoveryPassword, { isError, isSuccess }] =
    useRecoveryPasswordMutation();

  const handleFormSubmit = async (password: string): Promise<void> => {
    if (!email || !token) return;
    const request: RecoveryPasswordRequest = { password, email, token };
    try {
      await recoveryPassword(request).unwrap();
      setTimeout(() => navigate('/'), 2000);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Logo />
      <LangDropdown />
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <RecoverPasswordMessage />
        {(isError || isSuccess) && (
          <NotificationSnackbar
            open={true}
            message={t(
              translations.recoveryPassword[
                isError ? 'errorNotifcation' : 'successNotifcation'
              ]
            )}
            severity={isError ? 'error' : 'success'}
          />
        )}
        {email && token && (
          <RecoverPasswordForm
            email={email}
            token={token}
            onFormSubmit={handleFormSubmit}
          />
        )}
      </Container>
    </Box>
  );
}

export default RecoveryPassword;
