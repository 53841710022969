import axios from 'axios';

export function downloadFile(url: string | undefined, name: string): void {
  if (!url) return;
  axios
    .get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*'
      },
      responseType: 'blob'
    })
    .then((response) => {
      const temp = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = temp;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
    });
}
