import { createApi } from '@reduxjs/toolkit/query/react';

import { RecoveryPasswordRequest } from '../types/RecoveryPassword';
import { baseQueryWithReauth } from './BaseQueryApi';

export const recoveryPasswordApi = createApi({
  reducerPath: 'recoveryPasswordApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    recoveryPassword: builder.mutation<void, RecoveryPasswordRequest>({
      query: (credentials) => ({
        url: '/auth/reset-password',
        method: 'POST',
        body: credentials
      })
    }),
    protected: builder.mutation<{ message: string }, void>({
      query: () => 'protected'
    })
  })
});

export const { useRecoveryPasswordMutation, useProtectedMutation } =
  recoveryPasswordApi;
