import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../../locales/translations';
import { useCurrency } from '../../../../../../hooks/useCurrency';

interface TotalCostDataProps {
  totalCost: number | null;
  budget: number;
}

export const useTotalCostData = ({ totalCost, budget }: TotalCostDataProps) => {
  const { getFormattedTotalCost } = useCurrency();
  const { t } = useTranslation();
  const theme = useTheme();

  const totalCostFormatted = useMemo(
    () => getFormattedTotalCost(totalCost),
    [totalCost]
  );

  const isGreaterThanBudget = totalCost !== null ? totalCost > budget : false;

  return {
    totalCostFormatted,
    textColor: isGreaterThanBudget
      ? theme.palette.error.main
      : theme.palette.success.main,
    tooltipTitle: `${t(translations.pages.booking.header.totalCost)} (${t(
      translations.pages.booking.header.includeTax
    )})`
  };
};
