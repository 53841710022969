import { Box, Skeleton, Typography, styled } from '@mui/material';

import { BookingType } from '../../../../../../types/BookingType';
import { EventResult } from '../../../../../types/Booking';
import { useBookingActivityEvent } from '../../../hooks/useBookingActivityEvent';

type Props = {
  isLoading?: boolean;
  event: EventResult;
  bookingType: BookingType;
};

function BookingActivity({
  isLoading,
  bookingType,
  event
}: Props): JSX.Element | null {
  const {
    eventDate,
    eventUser,
    activityMessage: { mainMessage, contextMessages }
  } = useBookingActivityEvent(event, bookingType);

  return mainMessage.length > 0 ? (
    <Wrapper>
      <EventMessage>
        {isLoading ? (
          <Skeleton variant="text" width="80%" />
        ) : (
          event && (
            <>
              <span>
                <strong>{eventUser}</strong> {mainMessage}
              </span>
              {contextMessages && contextMessages.length > 0 && (
                <Ul>
                  {contextMessages.map((message, index) => (
                    <li key={index}>
                      <Typography variant="body2">{message}</Typography>
                    </li>
                  ))}
                </Ul>
              )}
            </>
          )
        )}
      </EventMessage>
      <EventDate>
        {isLoading ? (
          <Skeleton variant="text" width="20%" />
        ) : (
          event?.created_at && <EventDateText>{eventDate}</EventDateText>
        )}
      </EventDate>
    </Wrapper>
  ) : null;
}

export default BookingActivity;

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: 16
});

const EventMessage = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

const EventDate = styled(Box)({});

const EventDateText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '0.93rem',
  lineHeight: '1.27rem',
  color: theme.palette.grey['500']
}));

const Ul = styled('ul')({
  paddingLeft: 30,
  marginTop: 0,
  marginBottom: 0
});
