import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../locales/translations';
import {
  BookingActionStatusType,
  BookingStatusType,
  BookingType,
  bookingStatusTypeBlocked
} from '../../../../types/BookingType';
import {
  BOOKING_PACKING_TYPES,
  BOOKING_TRANSPORT_TYPES
} from '../../../../utils/helpers/BookingHelper';
import { BookingJobType } from '../types';

const requestJobTypes = [BookingJobType.REQUEST, BookingJobType.PLANNED];
const confirmationJobTypes = [
  BookingJobType.TO_CONFIRM,
  BookingJobType.OVERDUE
];

interface CallToActionMessage {
  callToActionLabel: string;
  id: string;
}

export const useCallToActionMessageBuilder = (
  jobType: BookingJobType,
  bookingType: BookingType,
  bookingStatus: BookingStatusType,
  pickupDate?: string | null
): CallToActionMessage => {
  const { t } = useTranslation();
  const actionTranslations =
    translations.pages.dashboard.pages.todo.bookingCardAction;

  const isPickup = useMemo(
    () =>
      [BookingStatusType.ACCEPTED, BookingStatusType.SCHEDULED].includes(
        bookingStatus
      ) ||
      (bookingStatus === BookingStatusType.JOB_COMPLETED &&
        pickupDate === null),
    [bookingStatus, pickupDate]
  );

  const createCTAMessageForPacking = (): CallToActionMessage => {
    switch (jobType) {
      case BookingJobType.OVERDUE:
      case BookingJobType.CONFIRM_PACKING:
      case BookingJobType.SCHEDULE_PACKING:
      case BookingJobType.CONFIRM_PICKUP:
      case BookingJobType.CONFIRM_DROP_OFF:
        return {
          callToActionLabel: t(actionTranslations[jobType]),
          id: jobType
        };
      default:
        return {
          callToActionLabel: t(actionTranslations.seeJobAction),
          id: `${BookingActionStatusType.SEE_JOB}-button`
        };
    }
  };

  const createCTAMessageForTransport = (): CallToActionMessage => {
    let messageKey = actionTranslations.seeJobAction;
    let id = 'see-job-button';

    if (bookingStatusTypeBlocked.includes(bookingStatus)) {
      return { callToActionLabel: t(messageKey), id };
    }

    const actionHandlers = [
      {
        condition: () => jobType === BookingJobType.TO_SCHEDULE,
        execute: () => {
          messageKey = actionTranslations[`schedule${action}`];
          id = 'btn-schedule-action';
        }
      },
      {
        condition: () =>
          (bookingStatus === BookingStatusType.JOB_COMPLETED &&
            pickupDate === null) ||
          confirmationJobTypes.includes(jobType),
        execute: () => {
          messageKey = actionTranslations[`confirm${action}`];
          id = 'btn-confirm-action';
        }
      },
      {
        condition: () =>
          jobType === BookingJobType.FILE_TO_UPLOAD &&
          (bookingStatus !== BookingStatusType.JOB_COMPLETED ||
            pickupDate !== null),
        execute: () => {
          messageKey = actionTranslations['fileToUpload'];
          id = 'btn-file-to-upload-action';
        }
      },
      {
        condition: () => requestJobTypes.includes(jobType),
        execute: () => {
          id = 'btn-request-action';
        }
      }
    ];

    const action = isPickup ? 'Pickup' : 'Delivery';

    for (const handler of actionHandlers) {
      if (handler.condition()) {
        handler.execute();
        break;
      }
    }

    return { callToActionLabel: t(messageKey), id };
  };

  return useMemo(() => {
    if (BOOKING_PACKING_TYPES.includes(bookingType)) {
      return createCTAMessageForPacking();
    }

    if (BOOKING_TRANSPORT_TYPES.includes(bookingType)) {
      return createCTAMessageForTransport();
    }

    return {
      callToActionLabel: t(actionTranslations.seeJobAction),
      id: `${BookingActionStatusType.SEE_JOB}-button`
    };
  }, [jobType, bookingType]);
};
