import { Palette, Theme, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DashboardColor } from 'src/app/types/menu';

type Color = { light: string; main: string; dark: string };
export type menuColor = {
  color: string;
  backgroundColor: string;
};

export interface DashboardColors {
  transport: string;
  packing: string;
  depot: string;
  dashboard: string;
}

const COLORS = {
  transport: 'black',
  packing: 'black',
  depot: 'black',
  dashboard: 'black'
};

export const useDashboardColor = (): DashboardColor => {
  const theme = useTheme();
  const location = useLocation();
  useEffect(() => {
    setDashboardColor(getDashboardColor(computeActiveRoute(), theme));
  }, [location.pathname]);

  const getDashboardColor = (route: string, theme: Theme): DashboardColor => {
    if (!(route in COLORS)) return dashboardColor;
    const key = COLORS[route as keyof typeof COLORS];
    const color = theme.palette[key as keyof Palette] as Color;
    return {
      color: color?.main,
      backgroundColor: color?.light
    };
  };

  const computeActiveRoute = (): string => {
    for (const key in COLORS) {
      if (location.pathname.includes(key)) {
        return key;
      }
    }
    return 'dashboard';
  };

  const [dashboardColor, setDashboardColor] = useState<DashboardColor>({
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main
  });

  return dashboardColor;
};
