import LockIcon from '@mui/icons-material/Lock';
import { Box, Grid, Paper, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../locales/translations';

const MainPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey['100'],
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'row'
}));

const IconBox = styled(Box)({
  paddingTop: 36,
  paddingBottom: 36,
  paddingLeft: 16
});

const MessageBox = styled(Box)({
  padding: 12
});

function ContactLock(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Grid container direction="row" justifyContent="start">
      <Grid item xs={11} xl={7}>
        <MainPaper elevation={0}>
          <IconBox>
            <LockIcon />
          </IconBox>
          <MessageBox>
            <Typography
              variant="body1"
              whiteSpace="pre-line"
              color={`grey.600`}
            >
              {t(translations.contactHideMessage.title)}
            </Typography>
          </MessageBox>
        </MainPaper>
      </Grid>
    </Grid>
  );
}

export default ContactLock;
