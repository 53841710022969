import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem,
  styled,
  useTheme
} from '@mui/material';
import Menu from '@mui/material/Menu';
import { Box } from '@mui/system';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'src/locales/translations';

import { DashboardMenuItem } from '../types';

interface SubMenuSmallScreenProps {
  activeIndex: number;
  menuItems: DashboardMenuItem[];
  onMenuItemClicked: (index: number) => void;
}

export const SubMenuSmallScreen = ({
  menuItems,
  activeIndex,
  onMenuItemClicked
}: SubMenuSmallScreenProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (
    event: MouseEvent<HTMLElement>,
    index: number
  ) => {
    setAnchorEl(null);
    onMenuItemClicked(index);
  };

  return (
    <Wrapper>
      <StyledButton
        fullWidth
        id="sub-menu-button"
        aria-controls={open ? 'sub-menu-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        startIcon={menuItems[activeIndex]?.icon}
      >
        <Text>{t(translations[menuItems[activeIndex]?.type]?.title)}</Text>
      </StyledButton>
      <Menu
        PaperProps={{
          style: {
            width: '90%'
          }
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuItems.map((item, index) => (
          <Item
            key={index}
            selected={index === activeIndex}
            onClick={(event: MouseEvent<HTMLElement>) =>
              handleMenuItemClick(event, index)
            }
          >
            <ItemIcon>
              <ListItemIcon>
                <item.icon.type
                  {...item.icon.props}
                  style={
                    index === activeIndex
                      ? { color: theme.palette.grey['100'] }
                      : {}
                  }
                />
              </ListItemIcon>
            </ItemIcon>
            <ItemText>
              <ListItemText primary={t(translations[item.type].title)} />
            </ItemText>
          </Item>
        ))}
      </Menu>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '40px',
  display: 'flex',
  margin: '20px 12px 0 12px',
  backgroundColor: theme.palette.grey[600],
  borderRadius: '4px',
  alignItems: 'center',
  padding: '0 12px'
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey['100'],
  width: '100%',
  textAlign: 'left',
  display: 'flex',
  alignItems: 'center'
}));

const Item = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-start',
  '&.Mui-selected': {
    borderRadius: '4px',
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.grey['100']
  },
  '&.Mui-selected:hover ': {
    backgroundColor: theme.palette.grey[500]
  }
}));

const ItemIcon = styled(Box)({});

const ItemText = styled(Box)({});

const Text = styled(Box)({
  width: '100%'
});
