import CalendarTodayOutlined from '@mui/icons-material/CalendarTodayOutlined';
import { Box, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCurrency } from 'src/app/hooks/useCurrency';
import { translations } from 'src/locales/translations';
import { BookingListItem } from 'src/types/Booking';
import { DEFAULT_BOOKING_TRANSPORT_COMPLETED } from 'src/utils/helpers/BookingHelper';

import { BookingJobType } from '../../../types';
import { useTransportScheduleDate } from '../hooks/useTransportScheduleDate';
import BookingTransportBudget from './BookingTransportBudget';

const TypographyStyled = styled(Typography)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});

const Icon = styled(CalendarTodayOutlined)({
  marginRight: 6
});

type Props = {
  jobType: BookingJobType;
  booking: BookingListItem;
};

function BookingTransportSubComment({ booking, jobType }: Props): JSX.Element {
  const { t } = useTranslation();
  const { getFormattedBudget } = useCurrency();
  const { plannedDate, plannedDateLabel } = useTransportScheduleDate(booking);

  return (
    <>
      {!DEFAULT_BOOKING_TRANSPORT_COMPLETED.includes(booking.status) && (
        <Box>
          {jobType === BookingJobType.REQUEST ? (
            <BookingTransportBudget
              label={t(translations.budget.title)}
              budget={getFormattedBudget({
                budgetEur: booking.budgetEur,
                budgetUsd: booking.budgetUsd,
                budgetGbp: booking.budgetGbp
              })}
            />
          ) : (
            plannedDate && (
              <TypographyStyled variant="h6" color="common.black">
                <Icon fontSize="small" />
                {`${
                  plannedDate ? t(translations[plannedDateLabel].title) : ''
                } : ${plannedDate}`}
              </TypographyStyled>
            )
          )}
        </Box>
      )}
    </>
  );
}

export default BookingTransportSubComment;
