import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CircularProgress, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { translations } from '../../../locales/translations';
import { routes } from '../../../types/Routes';
import { Header, LogoHeader } from '../../components/Header';
import { useGetSettingsQuery } from '../../services/SettingsApi';
import { useDashboardColor } from '../Dashboard/hooks/useDashboardColor';
import { SettingForm } from './Form/SettingForm';
import { SettingItem } from './types';

export const Settings = (): JSX.Element => {
  const dashboardColor = useDashboardColor();
  const { t } = useTranslation();
  const { data: settings, isLoading } = useGetSettingsQuery<{
    data: SettingItem[];
    isLoading: boolean;
  }>({});

  return (
    <Wrapper>
      <Header logo={<LogoHeader activeMenuColor={dashboardColor} />} />
      <Body>
        <NavStyled>
          <ReturnLinkStyled to={routes.dashboard}>
            <ArrowBackIcon fontSize="small" />{' '}
            {t(translations.pages.setting.return)}
          </ReturnLinkStyled>
          <Typography variant="h2">
            {t(translations.pages.setting.title)}
          </Typography>
        </NavStyled>
        <Main>
          <TypographySubTitle variant="h3">
            {t(translations.pages.setting.general.title)}
          </TypographySubTitle>
          <CardStyled>
            {isLoading ? (
              <CircularProgressStyled size={50} />
            ) : (
              <SettingForm settings={settings} isLoading={isLoading} />
            )}
          </CardStyled>
        </Main>
      </Body>
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
});

const Main = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  height: 'calc(100% - 72px)',
  paddingLeft: 70,
  paddingRight: 70,
  paddingTop: 20
});

const NavStyled = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  paddingLeft: 70,
  paddingRight: 70,
  paddingTop: 15,
  paddingBottom: 15,
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: theme.palette.primary.light
}));

const Body = styled(Box)({
  height: 'calc(100% - 72px)'
});

const ReturnLinkStyled = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey['500'],
  '&:hover': {
    color: theme.palette.primary.main
  },
  cursor: 'pointer',
  '& svg': {
    marginRight: 5
  },
  marginBottom: 10
}));

const TypographySubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey['500'],
  marginBottom: 30,
  marginTop: 10
}));

const CardStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 6,
  marginBottom: 20,
  width: '100%',
  backgroundColor: 'white',
  border: `1px solid ${theme.palette.primary.light}`
}));

const CircularProgressStyled = styled(CircularProgress)({
  margin: 50,
  alignSelf: 'center',
  justifySelf: 'center'
});
