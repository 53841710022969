import { BookingItem } from '../../app/types/Booking';
import { BookingListItem } from '../../types/Booking';

const convertSimulatedUTCDateToLocalDate = (
  dateUTC: string | undefined | null
): string | null => {
  if (!dateUTC) return null;
  if (dateUTC.endsWith('Z')) {
    return dateUTC.slice(0, -1);
  }

  return dateUTC;
};

const bookingDateFields: string[] = [
  'startDate',
  'startExpectedOn',
  'startReadyOn',
  'endDate',
  'endExpectedOn',
  'endDeadline',
  'packExpectedOn',
  'packDate'
];

export const convertBookingItemSimulatedUTCDatesToLocale = (
  booking: BookingItem
): void => {
  for (const field of bookingDateFields) {
    const value = booking[field as keyof BookingItem];
    if (typeof value === 'string') {
      Object.assign(booking, {
        [field]: convertSimulatedUTCDateToLocalDate(value)
      });
    }
  }
};

export const convertBookingItemListSimulatedUTCDatesToLocale = (
  bookings: BookingListItem[]
): void => {
  for (const booking of bookings) {
    for (const field of bookingDateFields) {
      const value = booking[field as keyof BookingListItem];
      if (typeof value === 'string') {
        Object.assign(booking, {
          [field]: convertSimulatedUTCDateToLocalDate(value)
        });
      }
    }
  }
};

export const convertLocalDateToSimulatedMidnightUTCDate = (
  date: Date | undefined | null
): Date | null => {
  if (!date) return null;
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return new Date(`${year}-${month}-${day}T00:00:00.000Z`);
};
