import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Box,
  BoxProps,
  Skeleton,
  Stack,
  Typography,
  styled
} from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'src/locales/translations';

interface DashboardCardProps {
  disabled?: boolean;
  title: string;
  icon: ReactElement;
  jobsTotal: number;
  titleColor: string;
  totalItemColor: string;
  metric1: {
    label: string;
    value: number;
  };
  metric2: {
    label: string;
    value: number;
  };
  link?: string;
  isLoading?: boolean;
}

export const DashboardCard = ({
  title,
  icon,
  jobsTotal,
  titleColor,
  totalItemColor,
  metric1,
  metric2,
  isLoading,
  disabled
}: DashboardCardProps): JSX.Element => {
  const { t } = useTranslation();
  const translationMainDashboard =
    translations.pages.dashboard.pages.mainDashboard;

  const CardLoader = (): JSX.Element => (
    <Wrapper disabled={disabled}>
      <CardHeader>
        <LeftHeaderSkeleton />
        <SubTitleSkeleton />
      </CardHeader>
      <CardContent disabled={disabled}>
        <ContentMain>
          <ContentMainSkeleton />
          <ContentMainSkeleton />
        </ContentMain>
        <ContentSub>
          <SubChildSkeleton />
        </ContentSub>
      </CardContent>
    </Wrapper>
  );

  return isLoading ? (
    <CardLoader />
  ) : (
    <Wrapper disabled={disabled}>
      <CardHeader>
        <LeftHeader>
          <Icon>{icon}</Icon>
          <Title color={disabled ? 'grey.200' : titleColor}>{title}</Title>
        </LeftHeader>
        <SubTitle>{`${jobsTotal} jobs`}</SubTitle>
      </CardHeader>
      <CardContent disabled={disabled}>
        <ContentMain>
          <ContentMainChild>
            <TotalItem color={disabled ? 'grey.200' : totalItemColor}>
              {metric1.value}
            </TotalItem>
            <>{metric1.label}</>
          </ContentMainChild>
          <ContentMainChild>
            <TotalItem color={disabled ? 'grey.200' : totalItemColor}>
              {metric2.value}
            </TotalItem>
            <>{metric2.label}</>
          </ContentMainChild>
        </ContentMain>
        <ContentSub>
          <ContentSubChild>
            <Icon>
              <ArrowForwardIcon />
            </Icon>
            {t(translationMainDashboard.displayBooking)}
          </ContentSubChild>
        </ContentSub>
      </CardContent>
    </Wrapper>
  );
};

interface CardBoxProps extends BoxProps {
  disabled?: boolean;
}

const Wrapper = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'disabled'
})<CardBoxProps>(({ disabled, theme }) => ({
  width: 400,
  height: 224,
  display: 'flex',
  flexDirection: 'column',
  '&:hover': disabled
    ? {}
    : {
        backgroundColor: theme.palette.grey[100],
        borderRadius: '4px',
        color: theme.palette.grey['500']
      }
}));

const CardHeader = styled(Box)({
  height: 44,
  width: '100%',
  padding: '0 16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
});

const LeftHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 8
});

const Icon = styled('div')({
  fontSize: '0.8rem',
  display: 'flex',
  alignItems: 'center',
  '& > *': {
    fontSize: '1.1rem'
  }
});

interface ColorProps {
  color: string;
}

const Title = styled(Typography)<ColorProps>(({ color }) => ({
  color: color,
  fontSize: '1.2rem',
  fontWeight: 'bold'
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  fontSize: '0.8rem'
}));

const CardContent = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'disabled'
})<CardBoxProps>(({ disabled }) => ({
  backgroundColor: disabled ? 'rgba(0, 0, 0, 0.12)' : 'white',
  height: '180px',
  width: '100%',
  borderRadius: '8px',
  border: '1px solid #E2EAFF',
  display: 'flex',
  flexDirection: 'column'
}));

const ContentMain = styled(Box)({
  flex: 2,
  display: 'flex',
  width: '100%'
});

const ContentMainChild = styled(Box)({
  flex: 1,
  padding: 32
});

const ContentSub = styled(Box)({
  flex: 1,
  display: 'flex',
  borderTop: '1px solid #E2EAFF',
  justifyContent: 'flex-end',
  alignItems: 'center'
});

const TotalItem = styled(Typography)<ColorProps>(({ color }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  paddingBottom: 5,
  color: color
}));

const ContentSubChild = styled(Box)({
  display: 'flex',
  gap: 8,
  paddingRight: 28
});
const StackRowStartSkeleton = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'start',
  width: '80%'
});
const StackRowEndSkeleton = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'end',
  width: '100%'
});

const LeftHeaderSkeleton = (): JSX.Element => (
  <StackRowStartSkeleton>
    <Skeleton height={24} width={'10%'} />
    <Skeleton height={24} width={'35%'} sx={{ marginLeft: 8 }} />
  </StackRowStartSkeleton>
);
const SubTitleSkeleton = (): JSX.Element => (
  <StackRowEndSkeleton>
    <Skeleton height={24} width={'8%'} />
    <Skeleton height={24} width={'10%'} sx={{ marginLeft: 8 }} />
  </StackRowEndSkeleton>
);

const ContentMainSkeleton = (): JSX.Element => (
  <Stack
    sx={{
      flex: 1,
      padding: 32,
      width: '100%'
    }}
  >
    <Skeleton height={30} width={'30%'} />
    <Skeleton height={24} width={'70%'} />
  </Stack>
);
const SubChildSkeleton = (): JSX.Element => (
  <StackRowEndSkeleton sx={{ paddingRight: 28 }}>
    <Skeleton height={24} width={'5%'} />
    <Skeleton height={24} width={'30%'} sx={{ marginLeft: 16 }} />
  </StackRowEndSkeleton>
);
