import { Typography, TypographyProps } from '@mui/material';

interface Props extends TypographyProps {
  children: JSX.Element;
}

function TitleLg({ children, ...others }: Props): JSX.Element {
  return (
    <Typography variant="h2" {...others}>
      {children}
    </Typography>
  );
}

export default TitleLg;
