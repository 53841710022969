import { createApi } from '@reduxjs/toolkit/query/react';

import { UpdatedSettingItem } from '../pages/Setting/types';
import { baseQueryWithReauth } from './BaseQueryApi';

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Settings'],
  endpoints: (builder) => ({
    getSettings: builder.query({
      query: () => '/settings',
      providesTags: ['Settings']
    }),
    updateSettings: builder.mutation({
      query: (body: UpdatedSettingItem[]) => ({
        url: '/settings',
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['Settings']
    })
  })
});

export const { useGetSettingsQuery, useUpdateSettingsMutation } = settingsApi;
