import { Box, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Header, LogoHeader } from '../../../../components/Header';
import { useDashboardColor } from '../../hooks/useDashboardColor';

export const AdminDashboard = (): JSX.Element => {
  const dashboardColor = useDashboardColor();
  const navigate = useNavigate();

  const currentPath = window.location.pathname;
  if (currentPath !== '/dashboard') {
    navigate('/dashboard');
  }

  return (
    <HeaderContainer>
      <Header logo={<LogoHeader activeMenuColor={dashboardColor} />} />
    </HeaderContainer>
  );
};

const HeaderContainer = styled(Box)({
  position: 'relative'
});
