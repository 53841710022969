import { usePackingBookingsCounter } from './Packing/usePackingBookingsCounter';
import { useDepotBookings } from './useDepotBookings';
import { useTransportBookings } from './useTransportBookings';

type BookingFamilyType = 'transport' | 'packing' | 'depot';

interface BookingListState {
  metric1: number;
  metric2: number;
  isLoading: boolean;
  bookingsTotal: number;
}

export const useComputeTotalBookings = (): Record<
  BookingFamilyType,
  BookingListState
> => {
  const {
    transportJobToUpdateTotal,
    transportJobRequestTotal,
    isTransportRequestLoading,
    isTransportJobsToUpdateLoading
  } = useTransportBookings();

  const {
    depotJobsInComingTotal,
    depotJobsInHouseTotal,
    isDepotInHouseLoading,
    isDepotIncomingLoading
  } = useDepotBookings();

  const {
    totalBookingsToUpdate: totalPackingBookingsToUpdate,
    totalBookingsInComing: totalPackingBookingsInComing,
    isLoading: isPackingLoading
  } = usePackingBookingsCounter();

  return {
    transport: {
      bookingsTotal: transportJobToUpdateTotal + transportJobRequestTotal,
      metric1: transportJobToUpdateTotal,
      metric2: transportJobRequestTotal,
      isLoading: isTransportJobsToUpdateLoading || isTransportRequestLoading
    },
    depot: {
      bookingsTotal: depotJobsInHouseTotal + depotJobsInComingTotal,
      metric1: depotJobsInHouseTotal,
      metric2: depotJobsInComingTotal,
      isLoading: isDepotIncomingLoading || isDepotInHouseLoading
    },
    packing: {
      bookingsTotal:
        totalPackingBookingsToUpdate + totalPackingBookingsInComing,
      metric1: totalPackingBookingsToUpdate,
      metric2: totalPackingBookingsInComing,
      isLoading: isPackingLoading
    }
  };
};
