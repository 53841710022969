import { Text } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../locales/translations';
import { TableCell, TableRow } from '../../../Table';

export const PackingTableHeader = () => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell>
        <Text>{t(translations.components.pdf.table.reference)}</Text>
      </TableCell>
      <TableCell>
        <Text>{t(translations.components.pdf.table.quantity)}</Text>
      </TableCell>
      <TableCell>
        <Text>{t(translations.components.pdf.table.dimensions)}</Text>
      </TableCell>
    </TableRow>
  );
};
