import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { Box, styled } from '@mui/material';

const WrapperBoxStyled = styled(Box)({
  height: 24,
  width: 24,
  borderRadius: '100%',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const Icon = styled(LocalShippingOutlinedIcon)({
  height: 12,
  width: 12
});

type Props = {
  bgColor: string;
};
function BookingTruckBadge({ bgColor }: Props): JSX.Element {
  return (
    <WrapperBoxStyled bgcolor={bgColor}>
      <Icon />
    </WrapperBoxStyled>
  );
}

export default BookingTruckBadge;
