import { SearchBooking } from '../Domain/Model/SearchBooking';
import { SearchBookingsRepository } from '../Domain/SearchBookingsRepository';
import { sortByFreightSpeed } from '../Domain/Utils/SortByFreightSpeed';
import { SearchValue } from '../Domain/ValueObject/SearchValue';
import { searchBookingModelToResponse } from './SearchBookingModelToResponse';
import { SearchBookingListResponse } from './SearchBookingsResponse';

export class SearchBookingsUseCase {
  constructor(private repository: SearchBookingsRepository) {}

  async execute(searchValue: string): Promise<SearchBookingListResponse> {
    const bookings: SearchBooking[] = await this.repository.search(
      SearchValue.fromString(searchValue)
    );

    return searchBookingModelToResponse(sortByFreightSpeed(bookings));
  }
}
