import { Text } from '@react-pdf/renderer';

interface MerchandiseDimensionSectionProps {
  dimension: string;
}

export const MerchandiseDimensionText = ({
  dimension
}: MerchandiseDimensionSectionProps) => {
  return <Text>{dimension}</Text>;
};
