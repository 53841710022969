import HistoryIcon from '@mui/icons-material/History';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { BookingMacroType } from 'src/types/BookingType';
import { routes } from 'src/types/Routes';

import { Dashboard } from '../../components/Dashboard';
import { useDepotBookings } from '../../hooks/useDepotBookings';
import { BookingJobType, DashboardMenu } from '../../types';

const DEPOT_MENU_ITEMS: DashboardMenu = {
  all: {
    type: BookingJobType.TODO,
    path: routes.depot,
    icon: <PlaylistAddCheckIcon />
  },
  history: {
    type: BookingJobType.HISTORY,
    path: routes.depotHistory,
    icon: <HistoryIcon />
  }
};

export function Depot(): JSX.Element {
  const { subMenu } = useParams();
  const { pages } = useDepotBookings();
  const [activeIndex, setActiveIndex] = useState(0);
  const menuItems = Object.values(DEPOT_MENU_ITEMS);
  const [jobType, setBookingJobType] = useState<BookingJobType>(
    BookingJobType.TODO
  );

  useEffect(() => {
    const type =
      DEPOT_MENU_ITEMS[subMenu as keyof DashboardMenu]?.type ||
      BookingJobType.TODO;
    setBookingJobType(type);
    setActiveIndex(menuItems.findIndex((menu) => menu.type === type));
  }, [subMenu]);

  return (
    <>
      {pages && (
        <Dashboard
          key={jobType}
          tabs={pages[jobType].tabs}
          jobType={jobType}
          activeIndex={activeIndex}
          macroType={BookingMacroType.DEPOT}
          bookings={pages[jobType].bookings}
          isLoading={pages[jobType].isLoading}
          menuItems={Object.values(DEPOT_MENU_ITEMS)}
          onMenuItemClicked={setActiveIndex}
        />
      )}
    </>
  );
}
