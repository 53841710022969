import { useTranslation } from 'react-i18next';
import { BookingStatusType } from 'src/types/BookingType';
import { LevelType } from 'src/types/LevelType';

import { translations } from '../../../../../../locales/translations';
import Banner from '../../../../../components/Banner';
import useMessageMemo from '../../../hooks/useMessageMemo';

type Props = {
  status: BookingStatusType | null;
  type?: LevelType;
};

function MessageBanner({
  status,
  type = LevelType.ERROR
}: Props): JSX.Element | null {
  const { t } = useTranslation();
  const buttonLink = (): string | undefined =>
    type !== LevelType.WARNING ? '/' : undefined;
  const buttonText = (): string | undefined =>
    type !== LevelType.WARNING
      ? t(translations.features.statusBanner.button.backToDashboard)
      : undefined;

  if (null === status) {
    return null;
  }

  const message: string | null = useMessageMemo(status, type);

  return (
    <Banner
      type={type}
      message={message}
      buttonLink={buttonLink()}
      buttonText={buttonText()}
    />
  );
}

export default MessageBanner;
