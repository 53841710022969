import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography, styled } from '@mui/material';
import { subDays } from 'date-fns';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import BasicDatePickerInput from 'src/app/components/BasicDatePickerInput';
import { Button } from 'src/app/components/Button';
import { useDate } from 'src/app/hooks/useDate';
import * as yup from 'yup';

const MainEditWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 16
});

const InputWrapper = styled(Box)({
  width: 300,
  display: 'flex',
  flexDirection: 'column'
});

const SubmitStyledBox = styled(Box)({
  width: '100%',
  paddingTop: 16,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end'
});

const schema = yup.object().shape({
  date: yup.date().min(subDays(new Date(), 1)).nullable(true)
});

type Props = {
  label?: string;
  isDone: boolean;
  disabled?: boolean;
  buttonLabel: string;
  date?: string | null;
  isBeingEdited: boolean;
  handleDateChange: (value: { date: Date }) => void;
  dataTestId?: string;
};

function PackingDateForm({
  date,
  label,
  isDone,
  disabled,
  buttonLabel,
  isBeingEdited,
  handleDateChange,
  dataTestId = 'packing-date-form'
}: Props): JSX.Element {
  const { fromIsoToDate, fromDateToFormat, dateFormat, today } = useDate();

  const packingExpectedDate = useMemo((): Date | null => {
    if (date) {
      return fromIsoToDate(date);
    }
    return null;
  }, [date]);

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm<{ date: Date }>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: packingExpectedDate
      ? { date: packingExpectedDate }
      : { date: today }
  });

  return (
    <MainEditWrapper>
      <Typography variant="body2" color={'grey.400'} sx={{ mb: 4 }}>
        {label}
      </Typography>
      {isDone && !isBeingEdited && (
        <Typography
          variant="h5"
          color={'grey.500'}
          data-testid="packing-expected-date"
        >
          {packingExpectedDate
            ? fromDateToFormat(packingExpectedDate)
            : dateFormat.toUpperCase()}
        </Typography>
      )}
      {isBeingEdited && (
        <form onSubmit={handleSubmit(handleDateChange)}>
          <Controller
            key={`date`}
            control={control}
            name={`date`}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error }
            }) => (
              <InputWrapper>
                <BasicDatePickerInput
                  disabled={disabled}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  onBlur={onBlur}
                />
              </InputWrapper>
            )}
          />

          {(!disabled || !isDone) && (
            <SubmitStyledBox>
              <Button
                variant="plain"
                dataTestid={`${dataTestId}-btn`}
                disabled={!isValid || disabled}
                label={buttonLabel}
                type="submit"
              />
            </SubmitStyledBox>
          )}
        </form>
      )}
    </MainEditWrapper>
  );
}

export default PackingDateForm;
