import { useMediaQuery, useTheme } from '@mui/material';
import { BookingItem } from 'src/app/types/Booking';
import TitleLg from 'src/styles/components/Title/TitleLg';
import TitleMd from 'src/styles/components/Title/TitleMd';

import { BookingNumber } from '../../../../components/BookingNumber';

type HeaderBookingNumberProps = {
  booking: BookingItem;
};

function HeaderBookingNumber({
  booking
}: HeaderBookingNumberProps): JSX.Element {
  const theme = useTheme();
  const isMinDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const Title = isMinDesktop ? TitleLg : TitleMd;

  return (
    <>
      {booking && (
        <Title data-testid="bookingNumber">
          <BookingNumber
            bookingNumber={booking?.bookingNumber}
            withStyle={false}
          />
        </Title>
      )}
    </>
  );
}

export default HeaderBookingNumber;
