import { List, ListItem, ListItemText } from '@mui/material';

import { getFileNameWithoutUuid } from '../../../../../../utils/helpers/FileHelper';
import { UploadedFile } from '../../../../../types/booking/File';

type Props = {
  files: UploadedFile[];
  handleFileClick: (file: UploadedFile) => void;
};
function AccordionFileList({ files, handleFileClick }: Props): JSX.Element {
  return (
    <List dense={true}>
      {files.map((file) => (
        <ListItem
          key={file.id}
          onClick={() => handleFileClick(file)}
          sx={{
            cursor: 'pointer'
          }}
        >
          <ListItemText
            primary={getFileNameWithoutUuid(file.name)}
            sx={{
              color: 'grey.500'
            }}
          />
        </ListItem>
      ))}
    </List>
  );
}

export default AccordionFileList;
