import { SearchValueTooShortError } from '../Error/SearchValueTooShortError';
import { isSearchValueValid } from '../Validator/IsSearchValueValid';

export class SearchValue {
  private constructor(public readonly value: string) {}

  static fromString(value: string): SearchValue {
    SearchValue.validate(value);
    return new SearchValue(value);
  }

  toString() {
    return this.value;
  }

  private static validate(value: string) {
    if (!isSearchValueValid(value)) {
      throw new SearchValueTooShortError();
    }
  }
}
