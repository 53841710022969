import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../locales/translations';
import { useDate } from '../../../../hooks/useDate';

interface EtaPickupReturnType {
  etaTitle: string;
  etaDate: string;
}

export const useEtaPickup = (
  etaPickupDate: Date | null,
  readyForCollectionDate: Date | null
): EtaPickupReturnType => {
  const { t } = useTranslation();
  const { formatDateToFullDateString } = useDate();

  const etaTitle: string = useMemo(() => {
    if (!etaPickupDate) {
      return t(
        translations.pages.booking.features.readyForCollectionDate
          .collectReadyFrom.title
      );
    }
    return t(translations.planPickupDate.title);
  }, [etaPickupDate]);

  const etaDate: string = useMemo(() => {
    if (!etaPickupDate) {
      return formatDateToFullDateString(readyForCollectionDate);
    }
    return formatDateToFullDateString(etaPickupDate);
  }, [etaPickupDate, readyForCollectionDate]);

  return {
    etaTitle,
    etaDate
  };
};
