import { yupResolver } from '@hookform/resolvers/yup';
import { Container } from '@mui/material';
import { Box } from '@mui/system';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { translations } from '../../../locales/translations';
import LangDropdown from '../../Features/LangDropdown';
import EmailInput from '../../components/EmailInput';
import Logo from '../../components/Logo';
import NotificationSnackbar from '../../components/SnackBar';
import { useForgottenPasswordMutation } from '../../services/ForgottenPasswordAPI';
import { ForgottenPasswordRequest } from '../../types/ForgetPassword';
import ForgetPasswordMessage from './components/ForgetPasswordMessage';
import ResetPasswordButton from './components/ResetPasswordButton';

type DataError = {
  error: string;
  message: string;
  statusCode: number;
};

function ForgottenPassword(): JSX.Element {
  const { t } = useTranslation();
  const schema = yup
    .object({
      email: yup.string().email().required()
    })
    .required();
  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid }
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });
  const navigate = useNavigate();
  const [forgottenPassword, { isError, isSuccess, error }] =
    useForgottenPasswordMutation();

  const handleRecoveryPassword = async (request: ForgottenPasswordRequest) => {
    try {
      const result = await forgottenPassword(request).unwrap();
      setTimeout(() => {
        if (!result) navigate('/');
      }, 2000);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Logo />
      <LangDropdown />

      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <form
          onSubmit={handleSubmit(
            async (data) =>
              await handleRecoveryPassword(data as ForgottenPasswordRequest)
          )}
        >
          {(isError || isSuccess) && (
            <NotificationSnackbar
              open={true}
              message={
                error
                  ? ((error as FetchBaseQueryError)?.data as DataError).message
                  : t(
                      translations.forgottenPassword[
                        isError ? 'errorNotification' : 'successNotifcation'
                      ]
                    )
              }
              severity={isError ? 'error' : 'success'}
            />
          )}
          <ForgetPasswordMessage />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 16,
              px: {
                xs: '4%',
                sm: 2
              }
            }}
          >
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error }
              }) => (
                <EmailInput
                  sx={{
                    marginTop: 16
                  }}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  onBlur={onBlur}
                />
              )}
            />
            <ResetPasswordButton
              type="submit"
              disabled={!isDirty || !isValid}
            />
          </Box>
        </form>
      </Container>
    </Box>
  );
}

export default ForgottenPassword;
