import {
  Grid,
  GridProps,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { BookingListItem } from 'src/types/Booking';

import BookingLocation from '../../../components/BookingLocation';
import BookingLocationDate from '../../../components/BookingLocationDate';
import DashboardBookingArrow from '../../../components/DashboardBookingArrow';

const GridRowStyled = styled((props: GridProps) => (
  <Grid item xs={6} {...props} />
))({
  minHeight: '50%'
});
type Props = {
  booking: BookingListItem;
  startDate?: {
    label: string;
    date: string | null;
  };
  endDate?: {
    label: string;
    date: string | null;
  };
};

function BookingTransportLogisticPlanned({
  booking,
  startDate,
  endDate
}: Props): JSX.Element {
  const theme = useTheme();
  const isMinWidthMobile = useMediaQuery(theme.breakpoints.up('mobile'));

  return (
    <Grid
      container
      direction={isMinWidthMobile ? 'row' : 'column'}
      sx={{
        paddingTop: 16
      }}
    >
      <Grid item xs={5}>
        <GridRowStyled>
          <BookingLocation
            location={{
              name: booking.startCompanyName,
              city: booking.startAddressCity,
              country: booking.startAddressCountry
            }}
          />
        </GridRowStyled>
        <GridRowStyled>
          {startDate && (
            <BookingLocationDate
              label={startDate.label}
              date={startDate.date}
            />
          )}
        </GridRowStyled>
      </Grid>
      <Grid item xs={1}>
        {(booking.startCompanyName || booking.endCompanyName) && (
          <DashboardBookingArrow isMinWidthMobile={isMinWidthMobile} />
        )}
      </Grid>

      <Grid item xs={5}>
        <GridRowStyled>
          <BookingLocation
            location={{
              name: booking.endCompanyName,
              city: booking.endAddressCity,
              country: booking.endAddressCountry
            }}
          />
        </GridRowStyled>
        <GridRowStyled>
          {endDate && (
            <BookingLocationDate label={endDate.label} date={endDate.date} />
          )}
        </GridRowStyled>
      </Grid>
    </Grid>
  );
}

export default BookingTransportLogisticPlanned;
