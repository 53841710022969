import { Box, TextField, TextFieldProps, styled } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/app/components/Button';

import { translations } from '../../../../../../locales/translations';

const EditNoteBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

const EditNoteTextField = styled((props: TextFieldProps) => (
  <TextField
    multiline
    rows={2}
    maxRows={2}
    inputProps={{
      'data-testid': 'note-text-textarea'
    }}
    {...props}
  />
))({
  width: '100%',
  marginTop: 20
});

const ButtonBoxStyled = styled(Box)({
  textAlign: 'right',
  paddingTop: 14
});

type Props = {
  note: string;
  handleChange: (value?: string) => void;
};

function EditNote(props: Props): JSX.Element {
  const { t } = useTranslation();
  const noteTranslations = translations.pages.booking.features.note;

  const [note, setNote] = useState<string>(props.note);
  return (
    <EditNoteBoxStyled>
      <EditNoteTextField
        value={note}
        onChange={(value) => setNote(value.target.value)}
        placeholder={t(noteTranslations.notePlaceholder.title)}
      />
      <ButtonBoxStyled>
        <Button
          label={t(noteTranslations.cancel.title)}
          onClick={() => props.handleChange(note)}
        />
        <Button
          variant="plain"
          dataTestid="note-save-button"
          additionalClasses="cvo-ml-xs"
          label={t(noteTranslations.sendNote.title)}
          onClick={() => props.handleChange(note)}
        />
      </ButtonBoxStyled>
    </EditNoteBoxStyled>
  );
}

export default EditNote;
