import { UploadResult, Uppy, UppyFile } from '@uppy/core';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useAppSelector } from '../../../../store/hooks';
import { BookingStatusType } from '../../../../types/BookingType';
import { FileCategoryType } from '../../../../types/FileCategoryType';
import { getFileNameWithUuid } from '../../../../utils/helpers/FileHelper';
import { useRefreshToken } from '../../../hooks/useRefreshToken';
import UploadApi from '../../../services/UploadFileApi';
import { selectAccessToken } from '../../../slices/Auth';
import { FileToUpload } from '../../../types/booking/File';
import { useUppyHelper } from './useUppyHelper';

type UploadHook = {
  isLoading: boolean;
  canUpload: boolean;
  isUploadAuthorized: boolean;
  isComplete: boolean;
  isCompleteOk: boolean;
  resetUploadState: () => void;
  upload: () => Promise<void>;
  filesToUploads: FileToUpload[];
  uppyGetFiles: () => UppyFile<
    Record<string, unknown>,
    Record<string, unknown>
  >[];
  uppyAddFiles: (uploadFiles: FileToUpload[]) => void;
  addUploadFiles: (files: File[], category: FileCategoryType) => void;
  fetchAccessTokenError: unknown;
  resetFiles: () => void;
};

export function useUploadFile(
  bookingId: string,
  status: BookingStatusType | undefined,
  version: number,
  uploadOnComplete?: () => void
): UploadHook {
  const bearer: string | null = useAppSelector(selectAccessToken);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [canUpload, setCanUpload] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [isCompleteOk, setIsCompleteOk] = useState<boolean>(false);
  const [filesToUploads, setFilesToUploads] = useState<FileToUpload[]>([]);
  const { generateUppyEndpoint } = useUppyHelper();
  const { checkOrRefreshAccessToken, fetchAccessTokenError } =
    useRefreshToken();

  const resetFiles = (): void => {
    setFilesToUploads([]);
    setIsLoading(false);
    setCanUpload(false);
  };

  const resetUploadState = (): void => {
    setIsComplete(false);
    setIsCompleteOk(false);
  };

  const onComplete = useCallback(
    (successfulIds: string[], failIds: string[]) => {
      const files = [...filesToUploads];

      files.forEach((f) => {
        if (failIds.includes(f.id)) {
          f.error = true;
        }
        if (successfulIds.includes(f.id)) {
          f.uploadOk = true;
        }
      });

      setCanUpload(canBeUpload(files.filter((f) => f.error)));
      setFilesToUploads(files);
      setIsCompleteOk(failIds.length === 0);
      if (uploadOnComplete) uploadOnComplete();
    },
    [filesToUploads, uploadOnComplete]
  );

  const [uppy] = useState<Uppy>(() =>
    UploadApi('JOB_COMPLETED', bookingId, version)
  );

  useEffect(() => {
    const completeHandler = (result: UploadResult) => {
      const failIds = result.failed.map(({ meta }) => meta.id as string);
      const successfulIds = result.successful.map(
        ({ meta }) => meta.id as string
      );
      onComplete(successfulIds, failIds);
    };

    uppy.on('complete', completeHandler);

    return () => {
      uppy.off('complete', completeHandler);
    };
  }, [uppy, onComplete]);

  const uppyGetFiles = (): UppyFile<
    Record<string, unknown>,
    Record<string, unknown>
  >[] => {
    return uppy?.getFiles();
  };
  const uppyAddFiles = (uploadFiles: FileToUpload[]): void => {
    for (const uploadFile of uploadFiles) {
      try {
        if (!uppy) return;

        uppy.addFile({
          id: uploadFile.id,
          source: 'file input',
          name: getFileNameWithUuid(uploadFile.file.name, uploadFile.id),
          type: uploadFile.file.type,
          data: uploadFile.file,
          meta: {
            id: uploadFile.id,
            endpoint: generateUppyEndpoint(bookingId, uploadFile.category)
          }
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const canBeUpload = (files: FileToUpload[]): boolean => {
    return files.length > 0;
  };

  const isUploadAuthorized = (): boolean => {
    return (
      status !== undefined &&
      status !== BookingStatusType.NEW &&
      status !== BookingStatusType.COMPLETED_READ_ONLY
    );
  };

  const addUploadFiles = (
    newFiles: File[],
    category: FileCategoryType
  ): void => {
    const files = [...filesToUploads]
      .filter((f) => f.category !== category)
      .concat(
        newFiles.map(
          (f) =>
            ({
              file: f,
              uploadOk: false,
              id: uuidv4(),
              category
            } as FileToUpload)
        )
      );
    resetUploadState();
    setFilesToUploads(files);
    setCanUpload(canBeUpload(files));
  };

  const beforeUpload = (): void => {
    resetUploadState();
    setIsLoading(true);
  };

  const afterUpload = (): void => {
    setIsComplete(true);
    setIsLoading(false);
  };

  const upload = async (): Promise<void> => {
    if (filesToUploads.length === 0) return;
    const result = await checkOrRefreshAccessToken();
    const accessToken = result?.access_token || bearer;

    Promise.resolve(beforeUpload()).then(async () => {
      uppy.getPlugin('XHRUpload')?.setOptions({
        headers: {
          'Acess-Control-Allow-Origin': '*',
          authorization: `Bearer ${accessToken}`
        }
      });

      uppyAddFiles(filesToUploads.filter((f) => !f.uploadOk));
      try {
        await uppy.upload();
      } catch (err) {
        console.error(err);
      } finally {
        afterUpload();
      }
    });
  };

  return {
    upload,
    isUploadAuthorized: isUploadAuthorized(),
    isLoading,
    isComplete,
    isCompleteOk,
    canUpload,
    resetUploadState,
    addUploadFiles,
    uppyGetFiles,
    uppyAddFiles,
    filesToUploads,
    fetchAccessTokenError,
    resetFiles
  };
}
