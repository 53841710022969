import styles from '@convelio/stylio/styles.module.scss';
import { Paper } from '@mui/material';
import { SxProps, styled } from '@mui/system';

type Props = {
  elevation?: number | undefined;
  children: JSX.Element;
  sx?: SxProps;
};

const defaultSx: SxProps = {
  backgroundColor: 'white',
  paddingX: styles.cvoSpacingS,
  paddingTop: styles.cvoSpacingG,
  paddingBottom: styles.cvoSpacingG,
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: 'primary.light'
};

const MerchandisePaper = (props: Props): JSX.Element => {
  const { children }: { children: JSX.Element } = props;
  const elevation: number = props.elevation ?? 0;
  const sx: SxProps = props.sx ?? defaultSx;

  return (
    <Wrapper elevation={elevation} sx={sx}>
      {children}
    </Wrapper>
  );
};

export default MerchandisePaper;

const Wrapper = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down('tablet')]: {
    borderRadius: 0
  }
}));
