import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/app/components/Button';

import { translations } from '../../../../../../locales/translations';
import MailTo from '../../../../../components/MailTo';
import { useResponsiveness } from '../../../../../hooks/useResponsive';

type Props = {
  bookingId: string;
};

function MailActivity(props: Props): JSX.Element {
  const { t } = useTranslation();
  const { isMaxWidthTablet } = useResponsiveness();

  const labelProps = isMaxWidthTablet
    ? {}
    : { label: t(translations.contact.title) };

  return (
    <Box>
      {process.env.REACT_APP_ORDER_CONVELIO_MAIL && (
        <MailTo
          email={process.env.REACT_APP_ORDER_CONVELIO_MAIL}
          subject={props.bookingId}
          body=""
        >
          <Box
            data-testid="mail-to"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
              justifyContent: 'right'
            }}
          >
            <Button
              color="neutral"
              startIcon={<EmailOutlinedIcon fontSize="inherit" />}
              additionalClasses={`${isMaxWidthTablet ? '-icon' : ''}`}
              {...labelProps}
            />
          </Box>
        </MailTo>
      )}
    </Box>
  );
}

export default MailActivity;
