import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Box, Collapse, Typography } from '@mui/material';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/app/components/Button';

import { translations } from '../../../../../../locales/translations';
import { BookingType } from '../../../../../../types/BookingType';
import { MerchandiseDimensionType } from '../../../../../../types/MerchandiseDimensionType';
import { BookingMerchandise } from '../../../../../types/Booking';
import MerchandiseDimensions from './MerchandiseDimension/MerchandiseDimensions';
import MerchandiseDivider from './MerchandiseDivider';
import MerchandiseEoriNumber from './MerchandiseEoriNumber';
import MerchandisePackDetails from './MerchandisePackDetail/MerchandisePackDetails';

type Props = {
  merchandise: BookingMerchandise | undefined;
  bookingType: BookingType | undefined;
};

function MerchandiseDescription({
  merchandise,
  bookingType
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [showDescription, setShowDescription] = useState(false);
  const [title, setTitle] = useState('');

  useLayoutEffect(() => {
    const mediaQueryList = window.matchMedia('print');
    setShowDescription(mediaQueryList.matches);
  }, []);

  const getTitle = (): string => {
    if (BookingType.PICKUP === bookingType) {
      return t(
        translations.pages.booking.features.merchandise.description.title.pickup
      );
    }

    return t(
      translations.pages.booking.features.merchandise.description.title.default
    );
  };

  useEffect(() => {
    setTitle(getTitle());
  }, [bookingType]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '1rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Box
            sx={{
              lineHeight: '1.36rem',
              fontWeight: 600,
              color: `grey.500`,
              textAlign: 'left',
              width: '50%'
            }}
          >
            {title}
          </Box>
          <Box
            style={{ cursor: 'pointer' }}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <Button
              dataTestid="merchandise_description_toggle"
              color="neutral"
              variant="outlined"
              additionalClasses="-icon"
              onClick={() => setShowDescription(!showDescription)}
              startIcon={
                showDescription ? (
                  <UnfoldLessIcon fontSize="inherit" />
                ) : (
                  <UnfoldMoreIcon fontSize="inherit" />
                )
              }
            />
          </Box>
        </Box>
      </Box>
      {merchandise?.orderLoadDescription && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '0.2rem'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: '0.2rem'
            }}
          >
            <Typography
              whiteSpace="pre-line"
              paragraph={true}
              sx={{
                fontSize: '0.875rem',
                lineHeight: '1.36rem',
                fontWeight: 600,
                color: 'grey.500',
                width: 'inherit',
                wordWrap: 'break-word',
                pt: 4
              }}
            >
              {merchandise.orderLoadDescription}
            </Typography>
          </Box>
        </Box>
      )}
      <Collapse in={showDescription}>
        <Box data-testid="merchandise_description_details">
          {merchandise?.packLoadDimensions?.length && (
            <>
              <MerchandiseDimensions
                dimensions={merchandise.packLoadDimensions}
                type={MerchandiseDimensionType.PACK_LOAD}
              />
              <MerchandiseDivider />
            </>
          )}
          {merchandise?.packLoadDetails &&
            merchandise.packLoadDetails.length !== 0 && (
              <>
                <MerchandisePackDetails details={merchandise.packLoadDetails} />
                <MerchandiseDivider />
              </>
            )}
          {merchandise?.orderLoadDimensions &&
            merchandise.orderLoadDimensions.length !== 0 && (
              <>
                <MerchandiseDimensions
                  dimensions={merchandise.orderLoadDimensions}
                  type={MerchandiseDimensionType.ORDER_LOAD}
                />
                <MerchandiseDivider />
              </>
            )}
          {merchandise?.eoriNumber && (
            <>
              <MerchandiseEoriNumber eoriNumber={merchandise.eoriNumber} />
              <MerchandiseDivider />
            </>
          )}
        </Box>
      </Collapse>
    </Box>
  );
}

export default MerchandiseDescription;
