import styles from '@convelio/stylio/styles.module.scss';
import { Avatar, Typography, styled } from '@mui/material';

interface AvatarStepProps {
  color?: string;
  bgColor?: string;
  step: string | number | undefined;
}

function AvatarStep({ bgColor, color, step }: AvatarStepProps): JSX.Element {
  const backgroundColor = bgColor ? bgColor : 'grey.600';
  return (
    <AvatarStyled sx={{ backgroundColor }}>
      <Typography
        sx={{
          fontSize: '0.8rem',
          lineHeight: '1.09rem',
          fontWeight: 700,
          color: color ? color : 'white'
        }}
      >
        {step}
      </Typography>
    </AvatarStyled>
  );
}

export default AvatarStep;

const AvatarStyled = styled(Avatar)({
  width: styles.cvoSpacingG,
  height: styles.cvoSpacingG,
  alignSelf: 'center',
  alignItems: 'center'
});
