import HistoryIcon from '@mui/icons-material/History';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import TodayIcon from '@mui/icons-material/Today';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { BookingMacroType } from 'src/types/BookingType';
import { routes } from 'src/types/Routes';

import { Dashboard } from '../../components/Dashboard';
import {
  PackingPagesBuilder,
  usePackingPagesBuilder
} from '../../hooks/Packing/usePackingPagesBuilder';
import { BookingJobType, DashboardMenu, DashboardMenuItem } from '../../types';

const PACKING_MENU_ITEMS: DashboardMenu = {
  all: {
    type: BookingJobType.TODO,
    path: routes.packing,
    icon: <PlaylistAddCheckIcon />
  },
  planning: {
    type: BookingJobType.PLANNED,
    path: routes.packingPlanning,
    icon: <TodayIcon />
  },
  history: {
    type: BookingJobType.HISTORY,
    path: routes.packingHistory,
    icon: <HistoryIcon />
  }
};

export function Packing(): JSX.Element {
  const { subMenu } = useParams();
  const { pages }: PackingPagesBuilder = usePackingPagesBuilder();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const menuItems: DashboardMenuItem[] = Object.values(PACKING_MENU_ITEMS);
  const [jobType, setBookingJobType] = useState<BookingJobType>(
    BookingJobType.TODO
  );

  useEffect(() => {
    const DEFAULT_MENU_ITEM_KEY: keyof DashboardMenu = 'all';
    const dashboardMenuKey: keyof DashboardMenu =
      subMenu ?? DEFAULT_MENU_ITEM_KEY;
    const type: BookingJobType = PACKING_MENU_ITEMS[dashboardMenuKey].type;
    setBookingJobType(type);
    setActiveIndex(
      menuItems.findIndex(
        (menu: DashboardMenuItem): boolean => menu.type === type
      )
    );
  }, [subMenu]);

  return (
    <>
      {pages && (
        <Dashboard
          key={jobType}
          tabs={pages[jobType].tabs}
          jobType={jobType}
          activeIndex={activeIndex}
          bookings={pages[jobType].bookings}
          isLoading={pages[jobType].isLoading}
          macroType={BookingMacroType.PACKING}
          menuItems={Object.values(PACKING_MENU_ITEMS)}
          onMenuItemClicked={setActiveIndex}
        />
      )}
    </>
  );
}
