import Files from 'src/app/pages/Booking/Features/Files';
import { BookingItem } from 'src/app/types/Booking';

import Activity from '../Activity';
import LateralPanelWrapper from './components/LateralPanelWrapper';

type Props = {
  version?: number;
  isLoading?: boolean;
  booking: BookingItem;
};

function LateralPanel({ booking }: Props): JSX.Element {
  return (
    <LateralPanelWrapper
      files={<Files booking={booking} />}
      activity={<Activity booking={booking} />}
    />
  );
}

export default LateralPanel;
