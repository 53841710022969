import { store } from '../../../../../store/store';
import { BookingListRequest } from '../../../../../types/Booking';
import { bookingApi } from '../../../../services/BookingApi';
import { BookingSearchFailedError } from '../Core/Domain/Error/BookingSearchFailedError';
import { SearchBooking } from '../Core/Domain/Model/SearchBooking';
import { SearchBookingsRepository } from '../Core/Domain/SearchBookingsRepository';
import { SearchValue } from '../Core/Domain/ValueObject/SearchValue';
import { BookingMapper } from './BookingMapper';

export class RTKQuerySearchBookingsRepository
  implements SearchBookingsRepository
{
  async search(searchValue: SearchValue): Promise<SearchBooking[]> {
    const request: BookingListRequest = {
      search: searchValue.value
    };

    const result = await store.dispatch(
      bookingApi.endpoints.getBookings.initiate(request)
    );

    if (result.error) {
      throw new BookingSearchFailedError();
    }

    if (!result.data) {
      return [];
    }

    return BookingMapper.toDomainList(result.data);
  }
}
