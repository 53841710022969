import { useEffect, useState } from 'react';
import { useGetBookingsQuery } from 'src/app/services/BookingApi';
import { BookingStatusType } from 'src/types/BookingType';

import { BOOKING_DEPOT_TYPES } from '../../../../utils/helpers/BookingHelper';
import { BookingJobType, BookingPage } from '../types';

export function useDepotBookings() {
  const [pages, setPages] = useState<BookingPage>();
  const [depotJobsInHouseTotal, setDepotJobsInHouseTotal] = useState<number>(0);
  const [depotJobsInComingTotal, setDepotJobsInComingTotal] =
    useState<number>(0);

  const { data: incomingBookings, isLoading: isDepotIncomingLoading } =
    useGetBookingsQuery({
      types: BOOKING_DEPOT_TYPES,
      statuses: [BookingStatusType.PENDING],
      sortmode: 'desc',
      sortparam: 'freightSpeed'
    });

  const { data: inHouseBookings, isLoading: isDepotInHouseLoading } =
    useGetBookingsQuery({
      types: BOOKING_DEPOT_TYPES,
      statuses: [BookingStatusType.IN_PROGRESS],
      sortmode: 'desc',
      sortparam: 'freightSpeed'
    });
  const { data: historyBookings, isLoading: isLoadingHistory } =
    useGetBookingsQuery({
      types: BOOKING_DEPOT_TYPES,
      statuses: [BookingStatusType.JOB_COMPLETED, BookingStatusType.COMPLETED],
      sortmode: 'desc',
      sortparam: 'freightSpeed'
    });

  useEffect(() => {
    setPages({
      [BookingJobType.TODO]: {
        tabs: {
          [BookingJobType.OVERALL]: {
            total:
              (incomingBookings?.total || 0) + (inHouseBookings?.total || 0)
          },
          [BookingJobType.INCOMING]: {
            bookings: incomingBookings?.data || [],
            isLoading: isDepotIncomingLoading,
            total: incomingBookings?.total,
            anchor: 'incoming'
          },
          [BookingJobType.IN_HOUSE]: {
            bookings: inHouseBookings?.data || [],
            isLoading: isDepotInHouseLoading,
            total: inHouseBookings?.total,
            anchor: 'in_house'
          }
        }
      },
      [BookingJobType.HISTORY]: {
        bookings: historyBookings?.data || [],
        isLoading: isLoadingHistory,
        total: historyBookings?.total
      }
    } as BookingPage);
    setDepotJobsInHouseTotal(inHouseBookings?.total || 0);
    setDepotJobsInComingTotal(incomingBookings?.total || 0);
  }, [incomingBookings, inHouseBookings, historyBookings]);

  return {
    pages,
    depotJobsInHouseTotal,
    depotJobsInComingTotal,
    isDepotIncomingLoading,
    isDepotInHouseLoading
  };
}
