import { Snackbar } from '@mui/material';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import * as React from 'react';
import { useEffect } from 'react';

type Props = {
  open: boolean;
  message?: string;
  severity: AlertColor;
  onClose?: () => void;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function NotificationSnackbar(props: Props): JSX.Element {
  const [open, setOpen] = React.useState(props.open);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (props.onClose && !open && open !== props.open) props.onClose();
  }, [open]);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert
        data-testid={`alert-${props.severity}`}
        onClose={handleClose}
        severity={props.severity}
        sx={{ width: '100%', color: 'common.white' }}
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
}

export default NotificationSnackbar;
