import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import { bookingApi } from '../app/services/BookingApi';
import { forgottenPasswordApi } from '../app/services/ForgottenPasswordAPI';
import { loginApi } from '../app/services/LoginApi';
import { recoveryPasswordApi } from '../app/services/RecoveryPasswordAPI';
import { refreshTokenApi } from '../app/services/RefreshTokenApi';
import { settingsApi } from '../app/services/SettingsApi';
import authReducer from '../app/slices/Auth';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [bookingApi.reducerPath]: bookingApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [recoveryPasswordApi.reducerPath]: recoveryPasswordApi.reducer,
    [forgottenPasswordApi.reducerPath]: forgottenPasswordApi.reducer,
    [refreshTokenApi.reducerPath]: refreshTokenApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(loginApi.middleware)
      .concat(bookingApi.middleware)
      .concat(refreshTokenApi.middleware)
      .concat(recoveryPasswordApi.middleware)
      .concat(forgottenPasswordApi.middleware)
      .concat(settingsApi.middleware)
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
