import { useMemo } from 'react';

import { EventMessageBuilder } from '../EventMessageBuilder';
import { useBookingAcceptedMessageBuilder } from './builders/useBookingAcceptedMessageBuilder';
import { useBookingCancelledMessageBuilder } from './builders/useBookingCancelledMessageBuilder';
import { useBookingCreatedMessageBuilder } from './builders/useBookingCreatedMessageBuilder';
import { useBookingFileAddedMessageBuilder } from './builders/useBookingFileAddedMessageBuilder';
import { useBookingFileDeletedMessageBuilder } from './builders/useBookingFileDeletedMessageBuilder';
import { useBookingNoteEditedMessageBuilder } from './builders/useBookingNoteEditedMessageBuilder';
import { useBookingUpdatedMessageBuilder } from './builders/useBookingUpdatedMessageBuilder';
import { useCrateCreatedMessageBuilder } from './builders/useCrateCreatedMessageBuilder';
import { useCrateUpdatedMessageBuilder } from './builders/useCrateUpdatedMessageBuilder';
import { useEndDateScheduledMessageBuilder } from './builders/useEndDateScheduledMessageBuilder';
import { useEndDateSetMessageBuilder } from './builders/useEndDateSetMessageBuilder';
import { useEstimationCrateCreatedMessageBuilder } from './builders/useEstimationCrateCreatedMessageBuilder';
import { useEstimationCrateUpdatedMessageBuilder } from './builders/useEstimationCrateUpdatedMessageBuilder';
import { usePackingDateScheduledMessageBuilder } from './builders/usePackingDateScheduledMessageBuilder';
import { usePackingDateSetMessageBuilder } from './builders/usePackingDateSetMessageBuilder';
import { useReadyForCollectionDateSetMessageBuilder } from './builders/useReadyForCollectionDateSetMessageBuilder';
import { useStartDateScheduledMessageBuilder } from './builders/useStartDateScheduledMessageBuilder';
import { useStartDateSetMessageBuilder } from './builders/useStartDateSetMessageBuilder';
import { useTransportEndDateScheduledMessageBuilder } from './builders/useTransportEndDateScheduledMessageBuilder';
import { useTransportEndDateSetMessageBuilder } from './builders/useTransportEndDateSetMessageBuilder';
import { useTransportStartDateScheduledMessageBuilder } from './builders/useTransportStartDateScheduledMessageBuilder';
import { useTransportStartDateSetMessageBuilder } from './builders/useTransportStartDateSetMessageBuilder';

export const useMainMessageBuilders = (): EventMessageBuilder[] => {
  const bookingUpdateMessageBuilder: EventMessageBuilder =
    useBookingCancelledMessageBuilder();
  const bookingDateMessageBuilder: EventMessageBuilder =
    useBookingUpdatedMessageBuilder();
  const bookingAcceptedMessageBuilder: EventMessageBuilder =
    useBookingAcceptedMessageBuilder();
  const bookingCreatedMessageBuilder: EventMessageBuilder =
    useBookingCreatedMessageBuilder();
  const bookingNoteEditedMessageBuilder: EventMessageBuilder =
    useBookingNoteEditedMessageBuilder();
  const fileAddedMessageBuilder: EventMessageBuilder =
    useBookingFileAddedMessageBuilder();
  const fileDeletedMessageBuilder: EventMessageBuilder =
    useBookingFileDeletedMessageBuilder();
  const transportEndDateScheduledMessageBuilder: EventMessageBuilder =
    useTransportEndDateScheduledMessageBuilder();
  const transportEndDateSetMessageBuilder: EventMessageBuilder =
    useTransportEndDateSetMessageBuilder();
  const transportStartDateScheduledMessageBuilder: EventMessageBuilder =
    useTransportStartDateScheduledMessageBuilder();
  const transportStartDateSetMessageBuilder: EventMessageBuilder =
    useTransportStartDateSetMessageBuilder();
  const readyForCollectionDateSetMessageBuilder: EventMessageBuilder =
    useReadyForCollectionDateSetMessageBuilder();
  const startDateSetMessageBuilder: EventMessageBuilder =
    useStartDateSetMessageBuilder();
  const endDateSetMessageBuilder: EventMessageBuilder =
    useEndDateSetMessageBuilder();
  const endDateScheduledMessageBuilder: EventMessageBuilder =
    useEndDateScheduledMessageBuilder();
  const startDateScheduledMessageBuilder: EventMessageBuilder =
    useStartDateScheduledMessageBuilder();
  const estimatedCrateCreatedMessageBuilder: EventMessageBuilder =
    useCrateUpdatedMessageBuilder();
  const packingDateScheduledMessageBuilder: EventMessageBuilder =
    usePackingDateScheduledMessageBuilder();
  const packingDateSetMessageBuilder: EventMessageBuilder =
    usePackingDateSetMessageBuilder();
  const estimationCrateCreatedMessageBuilder: EventMessageBuilder =
    useEstimationCrateCreatedMessageBuilder();
  const crateCreatedMessageBuilder: EventMessageBuilder =
    useCrateCreatedMessageBuilder();
  const estimationCrateUpdatedMessageBuilder: EventMessageBuilder =
    useEstimationCrateUpdatedMessageBuilder();

  return useMemo(
    () => [
      bookingUpdateMessageBuilder,
      bookingDateMessageBuilder,
      bookingAcceptedMessageBuilder,
      bookingCreatedMessageBuilder,
      bookingNoteEditedMessageBuilder,
      fileAddedMessageBuilder,
      fileDeletedMessageBuilder,
      transportEndDateScheduledMessageBuilder,
      transportEndDateSetMessageBuilder,
      readyForCollectionDateSetMessageBuilder,
      transportStartDateScheduledMessageBuilder,
      transportStartDateSetMessageBuilder,
      startDateSetMessageBuilder,
      endDateSetMessageBuilder,
      endDateScheduledMessageBuilder,
      startDateScheduledMessageBuilder,
      estimatedCrateCreatedMessageBuilder,
      packingDateScheduledMessageBuilder,
      packingDateSetMessageBuilder,
      estimationCrateCreatedMessageBuilder,
      crateCreatedMessageBuilder,
      estimationCrateUpdatedMessageBuilder
    ],
    [
      bookingUpdateMessageBuilder,
      bookingDateMessageBuilder,
      bookingAcceptedMessageBuilder,
      bookingCreatedMessageBuilder,
      bookingNoteEditedMessageBuilder,
      fileAddedMessageBuilder,
      transportEndDateScheduledMessageBuilder,
      transportEndDateSetMessageBuilder,
      readyForCollectionDateSetMessageBuilder,
      transportStartDateScheduledMessageBuilder,
      transportStartDateSetMessageBuilder,
      startDateSetMessageBuilder,
      endDateSetMessageBuilder,
      endDateScheduledMessageBuilder,
      startDateScheduledMessageBuilder,
      estimatedCrateCreatedMessageBuilder,
      packingDateScheduledMessageBuilder,
      packingDateSetMessageBuilder,
      estimationCrateCreatedMessageBuilder,
      crateCreatedMessageBuilder,
      estimationCrateUpdatedMessageBuilder
    ]
  );
};
