import PaymentsIcon from '@mui/icons-material/Payments';
import { Typography, styled } from '@mui/material';

const TypographyStyled = styled(Typography)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});

const Icon = styled(PaymentsIcon)({
  marginRight: 6
});

type Props = {
  budget: string;
  label: string;
};

function BookingTransportBudget({ label, budget }: Props): JSX.Element {
  return (
    <TypographyStyled variant="h6" color="grey.500">
      <Icon fontSize="small" />
      {`${label}: ${budget}`}
    </TypographyStyled>
  );
}

export default BookingTransportBudget;
