import styles from '@convelio/stylio/styles.module.scss';
import { ArrowDropDown } from '@mui/icons-material';
import { Fade, Menu, MenuItem, MenuProps, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { useState } from 'react';
import * as React from 'react';

import { KeyValue } from '../../../types/KeyValue';

type Props = {
  lists: readonly KeyValue[];
  current: KeyValue;
  handleChange: (value: KeyValue) => void;
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(12),
    minWidth: 118,
    color: theme.palette.primary,
    boxShadow: styles.cvoPanelShadowLight,
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.grey['600'],
        marginRight: theme.spacing(8)
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
      }
    }
  }
}));

function Dropdown(props: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (current: KeyValue) => {
    props.handleChange(current);
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Typography component={'span'} variant="body1">
        <Box
          sx={{ display: 'flex', flexDirection: 'row', marginLeft: 10 }}
          onClick={handleClick}
        >
          <Box
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {props.current.value}
          </Box>
          <Box>
            <ArrowDropDown />
          </Box>
        </Box>

        <StyledMenu
          color="secondary"
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button'
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {props.lists.map((item) => (
            <MenuItem
              selected={item.key === props.current.key}
              key={item.key}
              onClick={() => handleChange(item)}
            >
              {item.value}
            </MenuItem>
          ))}
        </StyledMenu>
      </Typography>
    </div>
  );
}

export default Dropdown as React.FC<Props>;
