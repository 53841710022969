import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BookingItem } from 'src/app/types/Booking';
import { translations } from 'src/locales/translations';
import { BookingActionStatusType } from 'src/types/BookingType';
import {
  CrateChangeMethodType,
  PackingCrate,
  PackingCrateOperation
} from 'src/types/Packing';

import AccordionEdit from '../../components/AccordionEdit';
import AccordionStepWrapper from '../../components/AccordionStepWrapper';
import { usePacking } from '../../hooks/usePacking';
import PackingEstimationEdit from './PackingEstimationEdit';

type Props = {
  booking: BookingItem;
  handleSubmit: (
    feature: string,
    action: BookingActionStatusType,
    date?: Date | null
  ) => void;
  handleChangeCrate: (
    crate: PackingCrate,
    method: CrateChangeMethodType,
    operation?: PackingCrateOperation
  ) => Promise<PackingCrate>;
};

function PackingEstimation({
  booking,
  handleSubmit,
  handleChangeCrate
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(true);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isBeingEdited, setIsBeingEdited] = useState<boolean>(false);
  const translationPacking = translations.pages.booking.pages.packing;
  const {
    estimationStepDisabled,
    estimationStepEditable,
    estimationStepIsBeingEdited
  } = usePacking();

  const handleEdit = () => {
    setIsExpanded(true);
    setIsBeingEdited(true);
  };

  const handleSubmitPackingEstimation = (date?: Date): void => {
    handleSubmit(
      'packingEstimation',
      BookingActionStatusType.SCHEDULE_PACKING,
      date
    );
    setIsBeingEdited(false);
  };

  const handleEstimationIsDone = (isDone: boolean) => {
    setIsDone(isDone);
  };

  useEffect(() => {
    setDisabled(estimationStepDisabled(booking));
    setIsBeingEdited(estimationStepIsBeingEdited(booking, isDone));
  }, [booking, isDone]);

  return (
    <AccordionStepWrapper
      withDivider={true}
      disabled={disabled}
      isExpanded={isExpanded && !disabled}
      step={{
        stepBadge: 2,
        stepName: t(translationPacking.features.stepper.PACKING_ESTIMATION)
      }}
      edit={
        estimationStepEditable(isDone, isBeingEdited) ? (
          <AccordionEdit handleEdit={handleEdit} />
        ) : undefined
      }
      message={<></>}
      content={
        <PackingEstimationEdit
          bookingId={booking.id}
          disabled={disabled}
          crates={booking.crates}
          status={booking.status}
          isBeingEdited={isBeingEdited}
          handleChangeCrate={handleChangeCrate}
          packExpectedOn={booking.packExpectedOn}
          handleEstimationIsDone={handleEstimationIsDone}
          handleSubmitPackingEstimation={handleSubmitPackingEstimation}
        />
      }
    />
  );
}

export default PackingEstimation;
