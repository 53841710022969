import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../../locales/translations';
import {
  BookingType,
  PackingConditionType
} from '../../../../../../../types/BookingType';

function useTitle({
  packingCondition,
  bookingType
}: {
  packingCondition: PackingConditionType | undefined;
  bookingType: BookingType | undefined;
}): string {
  const { t } = useTranslation();
  const [title, setTitle] = useState<string>('');

  function computeTitle(
    bookingType: BookingType | undefined,
    packingCondition: PackingConditionType | undefined
  ): string {
    if (packingCondition && bookingType === BookingType.PICKUP) {
      return `${t(translations.merchandise.title)} (${t(
        translations.packingCondition[packingCondition]
      )})`;
    }

    return t(translations.merchandise.title);
  }

  useEffect(() => {
    setTitle(computeTitle(bookingType, packingCondition));
  }, [bookingType, packingCondition]);

  return title;
}

export default useTitle;
