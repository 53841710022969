import styles from '@convelio/stylio/styles.module.scss';
import AdapterDateFns from '@date-io/date-fns';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography, styled } from '@mui/material';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../locales/translations';
import { Address } from '../../../../../../types/Address';
import { Button } from '../../../../../components/Button';
import MainAddress from '../../../components/MainAddress';

interface ScheduleDateModalProps {
  title: string;
  description: string;
  date?: Date | null;
  onDateChange?: (date?: Date) => void;
  address?: Address;
  isOpen: boolean;
  isLoading?: boolean;
  setOpen: (isOpen: boolean) => void;
  minDate?: Date;
  maxDate?: Date;
}

export const ScheduleDateModal: FC<ScheduleDateModalProps> = ({
  title,
  description,
  date,
  address,
  onDateChange,
  isOpen,
  isLoading,
  setOpen,
  minDate,
  maxDate
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<Date>(date || new Date());
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setValue(date || new Date());
  }, [date]);

  const handleConfirm = () => {
    if (onDateChange) {
      onDateChange(value);
    }
    handleClose();
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ModalContainer>
          <ContentContainer>
            <HeaderContainer>
              <Title data-testid="modal-title">{title}</Title>
              <CloseButton>
                <Button
                  variant="outlined"
                  color="neutral"
                  startIcon={<CloseIcon fontSize="inherit" />}
                  onClick={handleClose}
                  additionalClasses="-icon"
                />
              </CloseButton>
            </HeaderContainer>
            <InfoContainer>
              {address && (
                <MainAddress
                  address={address}
                  isLoading={isLoading}
                  compactMode
                />
              )}
              <Description>{description}</Description>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box data-testid="date-picker-container">
                  <StyledDatePicker
                    showToolbar={false}
                    label="Date picker"
                    value={value}
                    componentsProps={{
                      actionBar: { sx: { display: 'none' } }
                    }}
                    onAccept={handleConfirm}
                    onChange={(newValue) => setValue(newValue as Date)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          m: 0,
                          width: '100%'
                        }}
                      />
                    )}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                </Box>
              </LocalizationProvider>
            </InfoContainer>
          </ContentContainer>
          <Button
            dataTestid="confirm-schedule-date-btn"
            additionalClasses="cvo-mt-g"
            onClick={handleConfirm}
            autoFocus
            variant="plain"
            color="valid"
            label={t(translations.confirm.title)}
          />
        </ModalContainer>
      </Modal>
    </>
  );
};

const StyledDatePicker = styled(StaticDatePicker)(({ theme }) => ({
  '& .MuiPickersModal-dialogActions': {
    display: 'none'
  },
  '& .Mui-selected': {
    backgroundColor: 'transparent !important',
    border: `1px solid ${theme.palette.primary.dark}`,
    color: `${theme.palette.primary.dark} !important`,
    fontWeight: '600 !important',
    borderRadius: styles.cvoBorderRadius
  },
  '& .MuiPickersCalendarHeader-root, & .MuiCalendarOrClockPicker-root > div, & .MuiCalendarPicker-root':
    {
      padding: 0,
      margin: 0,
      width: '100%'
    },
  '& .MuiDayPicker-header, & .MuiDayPicker-weekContainer': {
    justifyContent: 'space-evenly'
  },
  '& .MuiPickersDay-root, & .MuiDayPicker-weekDayLabel': {
    fontSize: styles.cvoBodySmLineHeight
  }
}));

const ModalContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.paper,
  padding: styles.cvoSpacingM
}));

const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

const HeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: styles.cvoSpacingM
});

const Title = styled(Typography)({
  fontSize: styles.cvoTitleSmFontSize,
  fontWeight: 600
});

const CloseButton = styled(IconButton)({});

const InfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: styles.cvoSpacingXs
});

const Description = styled(Typography)({
  fontWeight: 600,
  marginTop: styles.cvoSpacingM
});
