import { styled, useMediaQuery, useTheme } from '@mui/material';

import { DashboardColor } from '../../../types/menu';
import { LogoHamburgerMenu } from './HamburgerMenu/LogoHamburgerMenu';
import { LogoImage } from './LogoImage';

interface LogoHeaderProps {
  activeMenuColor: DashboardColor;
}

export const LogoHeader = ({ activeMenuColor }: LogoHeaderProps) => {
  const theme = useTheme();
  const isMinWidthLaptop = useMediaQuery(theme.breakpoints.up('laptop'));

  return (
    <LogoWrapper activeMenuColor={activeMenuColor}>
      {isMinWidthLaptop ? <LogoImage /> : <LogoHamburgerMenu />}
    </LogoWrapper>
  );
};

interface LogoWrapperProps {
  activeMenuColor: DashboardColor;
}

const LogoWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'activeMenuColor'
})<LogoWrapperProps>(({ activeMenuColor, theme }) => ({
  width: '4.5rem',
  height: '4.5rem',
  display: 'flex',
  justifyContent: 'center',
  position: 'fixed',
  alignItems: 'center',
  backgroundColor: 'white',
  color: activeMenuColor.color,
  '&:hover': {
    backgroundColor: activeMenuColor.backgroundColor,
    cursor: 'pointer'
  },
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: theme.palette.primary.light
}));
