import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../locales/translations';
import { Contact } from '../../../../../types/Contact';
import { useDate } from '../../../../hooks/useDate';
import { useContact } from '../../../../pages/Booking/Pages/TransportBooking/Features/hooks/useContact';
import { BookingItem } from '../../../../types/Booking';
import { LogisticsView } from '../../Page/Section/LogisticsView';

interface PackingDropOffViewProps {
  booking: BookingItem;
}

export const PackingDropOffView = ({
  booking
}: PackingDropOffViewProps): JSX.Element => {
  const { t } = useTranslation();
  const { formatIsoToFullDateString } = useDate();
  const translationPacking = translations.pages.booking.pages.packing;
  const dropOffDate = formatIsoToFullDateString(booking.startDate);
  const etaDropOffDate = formatIsoToFullDateString(booking.startExpectedOn);

  const dropOffContact: Contact = useContact({
    firstname: booking.startContactFirstname,
    lastname: booking.startContactLastname,
    emails: booking.startContactEmails,
    phones: booking.startContactPhones
  });

  const title = t(translationPacking.features.stepper.PACKING_DROPOFF);
  const confirmTitle = t(translationPacking.features.dropOff.dropOffDate);
  const etaTitle = t(translationPacking.features.dropOff.etaFlag);

  return (
    <LogisticsView
      testId="packing-drop-off-view"
      title={title}
      etaTitle={etaTitle}
      etaDate={etaDropOffDate}
      confirmTitle={confirmTitle}
      confirmDate={dropOffDate}
      contact={dropOffContact}
      stacked
    />
  );
};
