import { createApi } from '@reduxjs/toolkit/query/react';

import { LoginRequest, LoginResponse } from '../types/Login';
import { baseQuery } from './BaseQueryApi';

export const loginApi = createApi({
  reducerPath: 'loginApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: `/auth/login`,
        method: 'POST',
        body: credentials
      })
    }),
    logout: builder.mutation({
      query: () => ({
        url: `/auth/logout`,
        method: 'POST'
      })
    })
  })
});

export const { useLoginMutation, useLogoutMutation } = loginApi;
