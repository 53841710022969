import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextFieldProps
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../locales/translations';

function EmailInput(props: TextFieldProps): JSX.Element {
  const { t } = useTranslation();
  const email = t(translations.emailAddress.title);

  return (
    <FormControl sx={props.sx} variant="outlined">
      <InputLabel htmlFor="email" color={props.error ? 'error' : 'primary'}>
        {email}
      </InputLabel>
      <OutlinedInput
        id="email"
        type="email"
        label={email}
        placeholder={email}
        value={props.value}
        onChange={props.onChange}
        error={!!props.error}
        onBlur={props.onBlur}
        inputProps={{
          'data-testid': 'email-input'
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="Error in the email address"
              edge="end"
              color="error"
            >
              {props.error && <ErrorOutlineIcon />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}

export default EmailInput;
