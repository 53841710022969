import { useTranslation } from 'react-i18next';
import NotificationSnackbar from 'src/app/components/SnackBar';
import StepBody from 'src/app/pages/Booking/components/StepBody';
import StepWrapper from 'src/app/pages/Booking/components/StepWrapper';
import { BookingItem } from 'src/app/types/Booking';
import { translations } from 'src/locales/translations';
import {
  BookingActionStatusType,
  BookingStatusType,
  LogisticActionType
} from 'src/types/BookingType';

import ScheduleDate from '../../../../Features/ScheduleDate';
import { useBookingAction } from '../hooks/useBookingAction';
import { useStepBackgroundColor } from '../hooks/useStepBackgroundColor';
import {
  DeliveryDateInputModeReturnType,
  useDeliveryDateInputMode
} from './hooks/useDeliveryDateInputMode';
import {
  DeliveryDateManagementReturnType,
  useDeliveryDateManagement
} from './hooks/useDeliveryDateManagement';
import { useDeliveryState } from './hooks/useDeliveryState';
import { useFlags } from './hooks/useFlags';

interface TransportDeliveryProps {
  version: number;
  isLoading?: boolean;
  booking: BookingItem;
}

function TransportDelivery({
  booking,
  version,
  isLoading
}: TransportDeliveryProps): JSX.Element {
  const { t } = useTranslation();
  const { handleChangeStatus, bookingAction, isSuccess, isError } =
    useBookingAction({ booking, version });
  const { flags } = useFlags({
    deliveryOptions: booking.deliveryOptions,
    endDeadline: booking.endDeadline
  });

  const { deliveryAddress, deliveryContact, deliveryLocation } =
    useDeliveryState(booking);

  const {
    deliveryDate,
    etaDeliveryDate,
    minDeliveryDate,
    minEtaDeliveryDate,
    validateDeliveryDate,
    validateEtaDeliveryDate
  }: DeliveryDateManagementReturnType = useDeliveryDateManagement({
    readyForCollectionDateString: booking.startReadyOn,
    pickupDateString: booking.startDate,
    etaPickupDateString: booking.startExpectedOn,
    deliveryDateString: booking.endDate,
    etaDeliveryDateString: booking.endExpectedOn
  });

  const {
    isEtaDeliveryDateInEditMode,
    isDeliveryShowConfirmDate,
    deliveryDateShow,
    isDeliveryDateInEditMode,
    isEtaDeliveryDone,
    isDeliveryDone
  }: DeliveryDateInputModeReturnType = useDeliveryDateInputMode({
    bookingStatus: booking.status
  });

  const handlePlannedDelivery = async (date?: Date): Promise<void> => {
    await handleChangeStatus(BookingActionStatusType.SCHEDULE_END, date);
  };

  const handleConfirmedDelivery = async (date?: Date): Promise<void> => {
    await handleChangeStatus(BookingActionStatusType.SET_END_DATE, date);
  };

  const getEtaDeliveryTitle = (isEtaEditMode: boolean): string => {
    const pickupDateKey = isEtaEditMode
      ? 'planDeliveryDate'
      : 'plannedDeliveryDate';
    return t(translations[pickupDateKey].title);
  };

  const getDeliveryTitle = (isConfirmEditMode: boolean): string => {
    const pickupDateKey = isConfirmEditMode
      ? 'confirmDeliveryDate'
      : 'confirmedDeliveryDate';
    return t(translations[pickupDateKey].title);
  };

  const { deliveryBackgroundColor, deliveryColor } = useStepBackgroundColor({
    status: booking.status,
    etaPickupDate: booking.startExpectedOn
  });

  return (
    <>
      <StepWrapper bgColor={deliveryBackgroundColor}>
        <StepBody
          isLoading={isLoading}
          title={{
            stepBadge: 'B',
            stepName: t(translations.delivery.title),
            color: deliveryColor,
            location: deliveryLocation
          }}
          address={deliveryAddress}
          contact={{
            value: deliveryContact,
            hideContact: booking.status === BookingStatusType.NEW
          }}
          flags={flags}
          scheduleDate={
            <>
              {deliveryDateShow && (
                <ScheduleDate
                  validateEtaInputDate={validateEtaDeliveryDate}
                  validateConfirmInputDate={validateDeliveryDate}
                  address={deliveryAddress}
                  logisticActionType={LogisticActionType.Delivery}
                  dataTestId="delivery-date-input"
                  isLoading={isLoading}
                  minConfirmDate={minDeliveryDate}
                  minEtaDate={minEtaDeliveryDate}
                  etaDate={etaDeliveryDate}
                  getEtaTitle={getEtaDeliveryTitle}
                  etaButtonLabel={t(translations.planDelivery.title)}
                  isEtaDateEditMode={isEtaDeliveryDateInEditMode}
                  isEtaDateDone={isEtaDeliveryDone}
                  handlePlanned={handlePlannedDelivery}
                  handleConfirmed={handleConfirmedDelivery}
                  confirmDate={deliveryDate}
                  showConfirmDate={isDeliveryShowConfirmDate}
                  getConfirmTitle={getDeliveryTitle}
                  isConfirmDateEditMode={isDeliveryDateInEditMode}
                  isCompleted={isDeliveryDone}
                  confirmButtonLabel={t(translations.confirmDelivery.title)}
                />
              )}
            </>
          }
        />
      </StepWrapper>

      {bookingAction && (isSuccess || isError) && (
        <NotificationSnackbar
          open={true}
          message={t(
            translations.delivery[bookingAction]?.[
              isError ? 'failedMessage' : 'successMessage'
            ]
          )}
          severity={isError ? 'error' : 'success'}
        />
      )}
    </>
  );
}

export default TransportDelivery;
