import styles from '@convelio/stylio/styles.module.scss';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Typography,
  styled
} from '@mui/material';
import { Link } from 'react-router-dom';
import { LevelType } from 'src/types/LevelType';

const BannerMainBoxStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: '4%',
  paddingRight: 24,
  height: 72,
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    padding: `${styles.cvoSpacingM} ${styles.cvoSpacingG}`,
    height: 'auto'
  }
}));

const BannerContentBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: styles.cvoSpacingS
});

const BannerMessageBoxStyled = styled((props: BoxProps) => (
  <Box data-testid="banner-message" component="span" {...props} />
))({});

interface BannerButtonProps extends ButtonProps {
  to: string;
  level: LevelType;
}

const BannerButtonStyled = styled(
  ({ level, to, children }: BannerButtonProps) => (
    <Button
      component={Link}
      to={to}
      variant="contained"
      sx={{
        height: 40,
        width: '20%',
        px: 2,
        boxShadow: 'none',
        backgroundColor: 'white',
        color: `${level}.main`,
        '&:hover': {
          backgroundColor: `grey.100`,
          color: `${level}.dark`
        }
      }}
    >
      {children}
    </Button>
  )
)({});

const ICONS = {
  [LevelType.ERROR]: <ErrorOutlineIcon />,
  [LevelType.WARNING]: <WarningIcon />,
  [LevelType.SUCCESS]: <CheckCircleIcon />
};

type Props = {
  icon?: string;
  message: string;
  type?: LevelType;
  buttonLink?: string;
  buttonText?: string;
};

const Banner = ({
  buttonLink = undefined,
  buttonText = undefined,
  type = LevelType.ERROR,
  message = '',
  icon
}: Props): JSX.Element => {
  const color = `${type}.dark`;
  const backgroundColor = `${type}.light`;
  const stepIcon = icon ? icon : ICONS[type];

  return (
    <BannerMainBoxStyled color={color} bgcolor={backgroundColor}>
      <BannerContentBoxStyled color={color}>
        {stepIcon}
        <Typography>
          <BannerMessageBoxStyled>{message}</BannerMessageBoxStyled>
        </Typography>
      </BannerContentBoxStyled>
      {buttonLink && buttonText && (
        <BannerButtonStyled level={type} to={buttonLink}>
          {buttonText}
        </BannerButtonStyled>
      )}
    </BannerMainBoxStyled>
  );
};

export default Banner;
