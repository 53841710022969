import styles from '@convelio/stylio/styles.module.scss';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../locales/translations';
import { Address } from '../../../../../types/Address';
import { Contact } from '../../../../../types/Contact';
import { formatAddress, formatContact } from '../../helpers/Formater';

interface LogisticsSubSectionProps {
  etaTitle?: string;
  etaDate?: string;
  confirmTitle: string;
  confirmDate: string;
  address?: Address;
  contact: Contact;
  stacked?: boolean;
}

export const LogisticsSubView = ({
  etaDate,
  etaTitle,
  confirmDate,
  confirmTitle,
  address,
  contact,
  stacked
}: LogisticsSubSectionProps): JSX.Element => {
  const { t } = useTranslation();

  const hasEta: boolean = !!etaTitle && etaDate !== undefined;

  return (
    <View style={stacked ? style.viewStacked : style.view}>
      {hasEta && (
        <View style={style.firstSubView}>
          <Text style={style.title}>{etaTitle}</Text>
          <Text>{etaDate}</Text>
          {address && (
            <>
              <Text style={style.title}>{t(translations.address.title)}</Text>
              <Text>{formatAddress(address)}</Text>
            </>
          )}
        </View>
      )}
      <View style={!hasEta ? style.firstSubView : {}}>
        <Text style={style.title}>{confirmTitle}</Text>
        <Text>{confirmDate}</Text>
        <Text style={style.title}>{t(translations.contact.title)}</Text>
        <Text>{formatContact(contact)}</Text>
      </View>
    </View>
  );
};

const style = StyleSheet.create({
  view: {
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  viewStacked: {
    flexDirection: 'column'
  },
  title: {
    fontSize: styles.cvoBodySmFontSize,
    fontFamily: 'Helvetica-Bold',
    marginTop: styles.cvoSpacingM,
    marginBottom: styles.cvoSpacingXs
  },
  firstSubView: {
    marginRight: styles.cvoSpacingXl
  }
});
