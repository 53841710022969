import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { translations } from '../../../locales/translations';
import { useAppDispatch } from '../../../store/hooks';
import { resetAppStateAndStorage } from '../../../utils/helpers/ResetAppStateAndStorage';
import { useLogoutMutation } from '../../services/LoginApi';

interface LogoutReturnType {
  handleLogout: () => Promise<void>;
  error: string | null;
  resetError: () => void;
}

export const useLogout = (): LogoutReturnType => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  const handleLogout = async (): Promise<void> => {
    try {
      await logout(null).unwrap();
      resetAppStateAndStorage(dispatch);

      navigate('/', { replace: true });
    } catch (error) {
      setError(t(translations.logout.error));
      console.debug(error);
    }
  };

  return { handleLogout, error, resetError: () => setError(null) };
};
