import { BuilderDecisionParams } from '../BuilderDecisionParams';
import { EventMessageBuilder } from '../EventMessageBuilder';
import { useContextMessageBuilders } from './useContextMessageBuilders';

export const useContextMessagesBuilder = (params: BuilderDecisionParams) => {
  const builders: EventMessageBuilder[] = useContextMessageBuilders();

  const build = (): string[] => {
    const externalEventMessages: string[] = [];

    for (const builder of builders) {
      if (builder.supports(params)) {
        externalEventMessages.push(builder.build(params.eventResult.data));
      }
    }

    return externalEventMessages;
  };

  return {
    build
  };
};
