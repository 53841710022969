import styles from '@convelio/stylio/styles.module.scss';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import TitleSm from 'src/styles/components/Title/TitleSm';

import {
  BookingType,
  PackingConditionType
} from '../../../../../../../types/BookingType';
import useTitle from './useTitle';

type Props = {
  packingCondition: PackingConditionType | undefined;
  bookingType: BookingType | undefined;
};

function MerchandiseTitle(props: Props): JSX.Element {
  const title = useTitle(props);

  return (
    <MerchandiseTitleBoxStyled>
      <TitleSm color="grey.500">
        <>{title}</>
      </TitleSm>
    </MerchandiseTitleBoxStyled>
  );
}

export default MerchandiseTitle;

const MerchandiseTitleBoxStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: styles.cvoSpacingXxs,
  [theme.breakpoints.down('tablet')]: {
    marginBottom: styles.cvoSpacingM,
    paddingLeft: styles.cvoSpacingS,
    paddingRight: styles.cvoSpacingS
  }
}));
