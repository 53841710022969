import { differenceInMinutes } from 'date-fns';
import jwt_decode from 'jwt-decode';
import { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  DEFAULT_MIN_MINUTES_FOR_REFRESH,
  DecodedToken
} from '../../types/User';
import { useRefreshTokenMutation } from '../services/RefreshTokenApi';
import { selectAccessToken, setTokens } from '../slices/Auth';

type RefreshTokenHook = {
  isAccessTokenAboutToExpire: (
    accessToken: string | null,
    minInMinutes?: number
  ) => boolean;
  fetchAccessTokenError: Error | undefined;
  checkOrRefreshAccessToken: (
    minInMinutes?: number
  ) => Promise<RefreshTokenResponse | undefined>;
};

interface RefreshTokenResponse {
  access_token: string;
  refresh_token: string;
}

export function useRefreshToken(): RefreshTokenHook {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(selectAccessToken);
  const [refreshToken] = useRefreshTokenMutation();
  const [fetchAccessTokenError, setFetchAccessTokenError] = useState<
    Error | undefined
  >();

  const checkOrRefreshAccessToken = async (): Promise<
    RefreshTokenResponse | undefined
  > => {
    if (!isAccessTokenAboutToExpire(accessToken)) return;
    return await refreshTokens();
  };

  const refreshTokens = async (): Promise<RefreshTokenResponse> => {
    try {
      const result = (await refreshToken(
        'RefreshToken'
      ).unwrap()) as RefreshTokenResponse;
      if (result) {
        dispatch(setTokens(result));
      }

      return result;
    } catch (e: unknown) {
      if (e instanceof Error) {
        setFetchAccessTokenError(e);
      }
      return Promise.reject(e);
    }
  };

  const isAccessTokenAboutToExpire = (accessToken: string | null): boolean => {
    const minInMinutes: number = DEFAULT_MIN_MINUTES_FOR_REFRESH;
    if (!accessToken) {
      return true;
    }
    return (
      differenceInMinutes(
        (jwt_decode(accessToken) as DecodedToken).exp * 1000,
        Date.now()
      ) < minInMinutes
    );
  };

  return {
    fetchAccessTokenError,
    checkOrRefreshAccessToken,
    isAccessTokenAboutToExpire
  };
}
