import { BookingListItem } from 'src/types/Booking';

export enum BookingJobType {
  TODO = 'jobToDo',
  OVERALL = 'jobAll',
  OVERDUE = 'jobOverdue',
  FILE_TO_UPLOAD = 'jobFileUpload',
  TO_SCHEDULE = 'jobToSchedule',
  TO_CONFIRM = 'jobToConfirm',
  REQUEST = 'jobRequest',
  PLANNED = 'jobPlanned',
  HISTORY = 'jobHistory',
  ON_HOLD = 'jobOnHold',
  CONFIRM_PACKING = 'jobConfirmPacking',
  SCHEDULE_PACKING = 'jobSchedulePacking',
  CONFIRM_PICKUP = 'jobConfirmPickup',
  CONFIRM_DROP_OFF = 'jobConfirmDropOff',
  PENDING = 'jobPending',
  IN_PROGRESS = 'jobInProgress',
  INCOMING = 'jobIncoming',
  IN_HOUSE = 'jobInHouse'
}

export type TabItem = {
  value: Tab;
  type: BookingJobType;
};

export type Tab = {
  total?: number;
  isLoading?: boolean;
  tabs?: BookingPage;
  bookings?: BookingListItem[];
  anchor?: string;
};

export type BookingPage = {
  [x: string]: Tab;
};

export type DashboardMenu = {
  [x: string]: DashboardMenuItem;
};
export type DashboardMenuItem = {
  type: BookingJobType;
  path: string;
  icon: JSX.Element;
};
