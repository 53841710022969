import styles from '@convelio/stylio/styles.module.scss';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../locales/translations';
import { PackingCrate } from '../../../../../types/Packing';
import { useDate } from '../../../../hooks/useDate';
import { BookingItem } from '../../../../types/Booking';
import { PackingCratesTable } from './PackingTable';

interface PackingConfirmationViewProps {
  booking: BookingItem;
  confirmedCrates: PackingCrate[];
}

export const PackingConfirmationView = ({
  booking,
  confirmedCrates
}: PackingConfirmationViewProps): JSX.Element | null => {
  const { t } = useTranslation();
  const { formatIsoToFullDateString } = useDate();

  const translationPacking = translations.pages.booking.pages.packing;
  const packDate = formatIsoToFullDateString(booking.packDate);

  return (
    <View style={style.view} data-testid="packing-confirmation-view">
      <Text style={style.title}>
        {t(translationPacking.features.stepper.PACKING_CONFIRMATION)}
      </Text>
      <View style={style.button}>
        <Text>
          {t(translations.pages.booking.pages.packing.packingTypeMessage)}
        </Text>
      </View>
      <PackingCratesTable crates={confirmedCrates} isConfirmation />
      <Text style={style.subTitle}>
        {t(translationPacking.features.packingConfirmation.titleDateForm)}
      </Text>
      <Text>{packDate}</Text>
    </View>
  );
};

const style = StyleSheet.create({
  view: {
    flexDirection: 'column',
    marginBottom: styles.cvoSpacingS,
    padding: styles.cvoSpacingS
  },
  title: {
    fontSize: styles.cvoBodyLgFontSize,
    fontFamily: 'Helvetica-Bold',
    marginTop: styles.cvoSpacingS
  },
  subTitle: {
    fontSize: styles.cvoBodySmFontSize,
    fontFamily: 'Helvetica-Bold',
    marginTop: styles.cvoSpacingM,
    marginBottom: styles.cvoSpacingXs
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: styles.cvoSpacingXxs,
    paddingHorizontal: styles.cvoSpacingXs,
    borderRadius: styles.cvoBorderRadiusFull,
    borderWidth: '1px',
    alignSelf: 'flex-start',
    marginTop: styles.cvoSpacingS
  }
});
