import LateralPanel from '../../Features/LateralPanel';
import Notes from '../../Features/Notes';
import MobileTabletViewWrapper from '../../components/MobileTabletViewWrapper';
import { BookingPageProps } from '../../types/BookingPage';
import TransportHeader from './Features/Header/TransportHeader';
import TransportMainPanel from './Features/MainPanel/TransportMainPanel';

function TransportMobileTabletView({
  version,
  booking,
  isLoading
}: BookingPageProps): JSX.Element {
  return (
    <MobileTabletViewWrapper
      header={<TransportHeader booking={booking} isLoading={isLoading} />}
      firstTab={
        <TransportMainPanel
          version={version}
          booking={booking}
          isLoading={isLoading}
        />
      }
      secondTab={<LateralPanel booking={booking} />}
      notes={
        <Notes version={version} booking={booking} isLoading={isLoading} />
      }
    />
  );
}

export default TransportMobileTabletView;
