import { Typography, styled } from '@mui/material';

const ShowNoteTyprography = styled(Typography)({
  marginTop: 24,
  maxHeight: 100,
  overflow: 'auto',
  whiteSpace: 'pre-wrap',
  fontStyle: 'italic'
});

type Props = {
  note: string;
};

function ShowNote({ note }: Props): JSX.Element {
  return (
    <ShowNoteTyprography data-testid="note-text-field" variant={'body2'}>
      {note}
    </ShowNoteTyprography>
  );
}

export default ShowNote;
