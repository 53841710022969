import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, styled } from '@mui/material';

const ArrowIconRight = styled(ArrowForwardIcon)({
  height: 14,
  width: 14
});
const ArrowIconDown = styled(ArrowDownwardIcon)({
  height: 14,
  width: 14
});

const MiddleArrowBoxStyled = styled(Box)(({ theme }) => ({
  paddingLeft: 14,
  paddingRight: 14,
  color: theme.palette.common.black,
  display: 'flex',
  alignItems: 'center'
}));

type Props = {
  isMinWidthMobile: boolean;
};
function DashboardBookingArrow({ isMinWidthMobile }: Props): JSX.Element {
  return (
    <MiddleArrowBoxStyled>
      {isMinWidthMobile ? <ArrowIconRight /> : <ArrowIconDown />}
    </MiddleArrowBoxStyled>
  );
}

export default DashboardBookingArrow;
