import { Address } from '../../../../types/Address';
import { Contact } from '../../../../types/Contact';
import { Location } from '../../../../types/Location';
import { BookingItem } from '../../../types/Booking';

type StepHook = {
  getEndContact: (booking: BookingItem) => Contact;
  getEndAddress: (booking: BookingItem) => Address;
  getEndLocation: (booking: BookingItem) => Location;
  getStartContact: (booking: BookingItem) => Contact;
  getStartAddress: (booking: BookingItem) => Address;
  getStartLocation: (booking: BookingItem) => Location;
};

export function useStep(): StepHook {
  const getContact = (booking: BookingItem, type: 'end' | 'start'): Contact => {
    if (!booking) return {} as Contact;
    return {
      firstname: booking[`${type}ContactFirstname`],
      lastname: booking[`${type}ContactLastname`],
      emails: booking[`${type}ContactEmails`],
      phones: booking[`${type}ContactPhones`]
    };
  };

  const getAddress = (booking: BookingItem, type: 'end' | 'start'): Address => {
    if (!booking) return {} as Address;
    return {
      name: booking[`${type}CompanyName`],
      street: `${booking[`${type}AddressLine1`] || ''}  ${
        booking[`${type}AddressLine2`] || ''
      }`,
      zipCode: booking[`${type}AddressZipcode`],
      city: booking[`${type}AddressCity`],
      country: booking[`${type}AddressCountry`]
    };
  };

  const getLocation = (
    booking: BookingItem,
    type: 'end' | 'start'
  ): Location => {
    if (!booking) return {} as Location;
    return {
      name: booking[`${type}CompanyName`],
      city: booking[`${type}AddressCity`],
      country: booking[`${type}AddressCountry`]
    } as Location;
  };

  const getStartLocation = (booking: BookingItem) => {
    return getLocation(booking, 'start');
  };

  const getStartAddress = (booking: BookingItem): Address => {
    return getAddress(booking, 'start');
  };

  const getStartContact = (booking: BookingItem): Contact => {
    return getContact(booking, 'start');
  };

  const getEndLocation = (booking: BookingItem) => {
    return getLocation(booking, 'end');
  };

  const getEndAddress = (booking: BookingItem): Address => {
    return getAddress(booking, 'end');
  };

  const getEndContact = (booking: BookingItem): Contact => {
    return getContact(booking, 'end');
  };

  return {
    getEndContact,
    getEndAddress,
    getEndLocation,
    getStartContact,
    getStartAddress,
    getStartLocation
  };
}
