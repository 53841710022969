import {
  BookingListItem,
  BookingListRequest
} from '../../../../../types/Booking';
import { PaginatedResponse } from '../../../../../types/PaginatedResponse';
import { BOOKING_PACKING_TYPES } from '../../../../../utils/helpers/BookingHelper';
import { useGetBookingsQuery } from '../../../../services/BookingApi';

interface PackingBookingSearchRequest {
  data: PaginatedResponse<BookingListItem> | undefined;
  isLoading: boolean;
}

export const usePackingBookingSearchRequest = ({
  overdue,
  statuses
}: BookingListRequest): PackingBookingSearchRequest => {
  const request: BookingListRequest = {
    types: BOOKING_PACKING_TYPES,
    sortmode: 'desc',
    sortparam: 'freightSpeed'
  };

  if (overdue) {
    request.overdue = overdue;
  }

  if (statuses) {
    request.statuses = statuses;
  }

  const { data, isLoading } = useGetBookingsQuery(request);

  return { data, isLoading };
};
