import { useState } from 'react';
import { useChangeStatusMutation } from 'src/app/services/BookingApi';
import { BookingItem } from 'src/app/types/Booking';
import { BookingActionStatusType } from 'src/types/BookingType';

type Props = {
  booking: BookingItem;
  version: number;
};

export const useBookingAction = ({ booking, version }: Props) => {
  const [bookingAction, setBookingAction] = useState<BookingActionStatusType>();
  const [changeStatus, { isError, isSuccess }] = useChangeStatusMutation();

  const handleChangeStatus = async (
    action: BookingActionStatusType,
    date?: Date
  ): Promise<void> => {
    if (!booking || !date || version === undefined) return;
    setBookingAction(action);
    try {
      await changeStatus({
        bookingId: booking.id,
        action,
        version,
        date
      }).unwrap();
    } catch (e) {
      // do nothing
    }
  };

  return { handleChangeStatus, bookingAction, isError, isSuccess };
};
