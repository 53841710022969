import { useEffect, useState } from 'react';
import { Location, useLocation } from 'react-router-dom';

export const useEmailTokenFromQueryString = (): {
  email: string | null;
  token: string | null;
} => {
  const location: Location = useLocation();
  const [email, setEmail] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const params: URLSearchParams = new URLSearchParams(location.search);
    setEmail(params.get('email') || null);
    setToken(params.get('token') || null);
  }, [location]);

  return { email, token };
};
