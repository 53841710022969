import styles from '@convelio/stylio/styles.module.scss';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../locales/translations';
import isDetailRelevant from '../../../../../pages/Booking/Features/Merchandise/components/MerchandisePackDetail/isDetailRelevant';

interface MerchandisePackDetailsSectionProps {
  details: string[];
}

export const MerchandisePackDetailsView = ({
  details
}: MerchandisePackDetailsSectionProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View>
      <Text style={style.title}>
        {t(translations.pages.booking.features.merchandise.loadDetails)}
      </Text>
      {details.map((detail, index) => {
        if (!isDetailRelevant(detail)) {
          return;
        }

        return <Text key={index}>{detail}</Text>;
      })}
    </View>
  );
};

const style = StyleSheet.create({
  title: {
    fontSize: styles.cvoBodySmFontSize,
    fontFamily: 'Helvetica-Bold',
    marginTop: styles.cvoSpacingM,
    marginBottom: styles.cvoSpacingXs
  }
});
