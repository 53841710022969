import { useNavigate } from 'react-router-dom';
import { getBookingUrl } from 'src/utils/helpers/BookingHelper';

import { SearchBookingResponse } from '../../../Core/Application/SearchBookingsResponse';
import { useSearchInput } from '../../hooks/useSearchInput';
import { useSearchResults } from '../../hooks/useSearchResults';

export const useSearchBarLogic = () => {
  const navigate = useNavigate();
  const { inputHandlers, resetSearchInput, isResultsVisible } =
    useSearchInput();

  const navigateToBookingDetails = (booking: SearchBookingResponse): void => {
    resetSearchInput();
    navigate(getBookingUrl(booking.type, booking.id));
  };

  const {
    bookings,
    isLoading,
    handleKeyDown,
    selectedBookingIndex,
    setSelectedBookingIndex
  } = useSearchResults({
    inputValue: inputHandlers.value,
    navigateToBookingDetails
  });

  const handleCloseIconClicked = () => {
    resetSearchInput();
    setSelectedBookingIndex(0);
  };

  return {
    isLoading,
    bookings,
    isResultsVisible,
    inputHandlers,
    handleKeyDown,
    navigateToBookingDetails,
    handleCloseIconClicked,
    selectedBookingIndex,
    setSelectedBookingIndex
  };
};
