import { useEffect, useState } from 'react';

import { BookingType } from '../../../../types/BookingType';
import { useDate } from '../../../hooks/useDate';
import { EventResult } from '../../../types/Booking';
import { ActivityMessage } from './MessageBuilder/ActivityMessage';
import { useActivityMessageBuilder } from './MessageBuilder/useActivityMessageBuilder';

type ActivityHook = {
  eventDate: string;
  eventUser: string | undefined;
  activityMessage: ActivityMessage;
};

export function useBookingActivityEvent(
  eventResult: EventResult,
  bookingType: BookingType
): ActivityHook {
  const { fromIsoToDateFormat } = useDate();
  const [eventDate, setEventDate] = useState<string>('');
  const [eventUser, setEventUser] = useState<string>();
  const [activityMessage, setActivityMessage] = useState<ActivityMessage>({
    mainMessage: '',
    contextMessages: []
  });

  const { build: buildActivityMessage } = useActivityMessageBuilder({
    eventResult,
    bookingType
  });

  const getEventDate = (eventResult: EventResult): string =>
    fromIsoToDateFormat(eventResult.created_at);

  useEffect(() => {
    if (!eventResult) return;
    setActivityMessage(buildActivityMessage());
    setEventDate(getEventDate(eventResult));
    setEventUser(eventResult.userDetails.name);
  }, [eventResult]);

  return {
    activityMessage,
    eventDate,
    eventUser
  };
}
