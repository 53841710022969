import { FreightSpeedType } from '../../../../../../../types/BookingType';
import { SearchBooking } from '../Model/SearchBooking';

const FREIGHT_SPEED_ORDER = {
  ULTRA_EXPRESS: 1,
  EXPRESS_SPEED: 2,
  REGULAR_SPEED: 3,
  NULL: 4
};

const getSortFreightSpeedOrder = (
  freightSpeed: FreightSpeedType | null
): number =>
  freightSpeed ? FREIGHT_SPEED_ORDER[freightSpeed] : FREIGHT_SPEED_ORDER.NULL;

const sortBookingByFreightSpeed = (
  a: SearchBooking,
  b: SearchBooking
): number =>
  getSortFreightSpeedOrder(a.freightSpeed) -
  getSortFreightSpeedOrder(b.freightSpeed);

export const sortByFreightSpeed = (
  bookings: SearchBooking[]
): SearchBooking[] => {
  return bookings.sort(sortBookingByFreightSpeed);
};
