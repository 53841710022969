import styles from '@convelio/stylio/styles.module.scss';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Skeleton, styled } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../locales/translations';
import TitleSm from '../../../../../styles/components/Title/TitleSm';
import { useGetHistoryQuery } from '../../../../services/BookingApi';
import { BookingItem, EventResult } from '../../../../types/Booking';
import BookingActivity from './components/BookingActivity';
import MailActivity from './components/MailActivity';

interface ActivityProps {
  booking: BookingItem;
}

function Activity({ booking }: ActivityProps): JSX.Element | null {
  const { t } = useTranslation();
  const [events, setEvent] = useState<EventResult[]>();

  const { data, isLoading } = useGetHistoryQuery(booking.id ?? skipToken);

  useEffect(() => {
    if (!data) return;
    setEvent(data.data.slice().reverse());
  }, [data]);

  return (
    <Wrapper>
      <Header>
        {isLoading ? (
          <Skeleton
            variant="text"
            height="1.7rem"
            width="40%"
            sx={{ textAlign: 'left' }}
          />
        ) : (
          <TitleBox>
            <AccessTimeIcon
              sx={{
                ml: 2,
                width: styles.cvoSpacingG,
                height: styles.cvoSpacingG
              }}
            />
            <TitleSm noWrap={true} ml={styles.cvoSpacingXxs}>
              {t(translations.pages.booking.features.activity.title)}
            </TitleSm>
          </TitleBox>
        )}

        {isLoading ? (
          <Skeleton variant="text" width="30%" sx={{ ml: '30%' }} />
        ) : (
          <MailContactStyled>
            <MailActivity bookingId={booking.bookingNumber} />
          </MailContactStyled>
        )}
      </Header>
      <ActivityListBox>
        {(isLoading ? Array.from(new Array(5)) : events)?.map(
          (event, index) => (
            <BookingActivity
              key={index}
              event={event}
              isLoading={isLoading}
              bookingType={booking.type}
            />
          )
        )}
      </ActivityListBox>
    </Wrapper>
  );
}

export default Activity;

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

const Header = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: 28
});

const TitleBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '45%',
  justifyContent: 'left'
});

const ActivityListBox = styled(Box)(({ theme }) => ({
  maxHeight: 'calc(100vh - 580px)',
  overflowY: 'auto',
  [theme.breakpoints.down('tablet')]: {
    maxHeight: 'calc(100vh - 500px)'
  }
}));

const MailContactStyled = styled(Box)({
  width: '55%'
});
