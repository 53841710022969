import LateralPanel from '../../Features/LateralPanel';
import Notes from '../../Features/Notes';
import DesktopViewWrapper from '../../components/DesktopViewWapper';
import { BookingPageProps } from '../../types/BookingPage';
import DepotHeader from '../DepotBooking/Features/Header/DepotHeader';
import DepotMainPanel from './Features/MainPanel/DepotMainPanel';

function DepotDesktopView({
  version,
  booking,
  isLoading,
  next,
  previous
}: BookingPageProps): JSX.Element {
  return (
    <DesktopViewWrapper
      header={
        <DepotHeader
          next={next}
          previous={previous}
          booking={booking}
          isLoading={isLoading}
        />
      }
      mainPanel={
        <DepotMainPanel
          version={version}
          booking={booking}
          isLoading={isLoading}
        />
      }
      lateralPanel={<LateralPanel booking={booking} />}
      notes={
        <Notes version={version} booking={booking} isLoading={isLoading} />
      }
    />
  );
}

export default DepotDesktopView;
