import { AlertColor } from '@mui/material/Alert';

import { UploadedFile } from '../../../../types/booking/File';
import { useDeleteFile } from './useDeleteFile';
import { useDownloadFile } from './useDownloadFile';
import { useGetFile } from './useGetFile';

interface VisualizeFileDataProps {
  fileIds: string[];
  selectedFileIndex: number;
  setOpen: (open: boolean) => void;
  resetSelectedIndex: () => void;
}

export interface VisualizeFileDataReturnType {
  files: UploadedFile[] | undefined;
  currentIndex: number | undefined;
  isGetFileLoading: boolean;
  isDeleteConfirmationModalOpened: boolean;
  closeDocumentVisualizer: () => void;
  downloadFile: () => void;
  openConfirmationDeleteFileModal: () => void;
  deleteFile: () => Promise<void>;
  setCurrentIndex: (index: number) => void;
  closeConfirmationDeleteFileModal: () => void;
  deleteFileNotificationMessage: string;
  deleteFileNotificationSeverity: AlertColor;
  isDeleteFileNotificationOpened: boolean;
  downloadFileTranslation: string;
  deleteFileTranslation: string;
}

export const useVisualizeFileData = ({
  fileIds,
  selectedFileIndex,
  resetSelectedIndex,
  setOpen
}: VisualizeFileDataProps): VisualizeFileDataReturnType => {
  const closeDocumentVisualizer = () => {
    setFiles(undefined);
    setCurrentIndex(undefined);
    resetSelectedIndex();
    setOpen(false);
  };

  const { files, setFiles, currentIndex, isGetFileLoading, setCurrentIndex } =
    useGetFile({ fileIds, selectedFileIndex });

  const {
    deleteFile,
    deleteFileNotificationMessage,
    deleteFileNotificationSeverity,
    isDeleteFileNotificationOpened,
    deleteFileTranslation,
    closeConfirmationDeleteFileModal,
    openConfirmationDeleteFileModal,
    isDeleteConfirmationModalOpened
  } = useDeleteFile({
    currentIndex,
    files,
    closeDocumentVisualizer
  });

  const { downloadFile, downloadFileTranslation } = useDownloadFile({
    files,
    currentIndex
  });

  return {
    isGetFileLoading,
    files,
    currentIndex,
    isDeleteConfirmationModalOpened,
    downloadFile,
    openConfirmationDeleteFileModal,
    deleteFile,
    closeDocumentVisualizer,
    setCurrentIndex,
    closeConfirmationDeleteFileModal,
    deleteFileNotificationMessage,
    deleteFileNotificationSeverity,
    isDeleteFileNotificationOpened,
    downloadFileTranslation,
    deleteFileTranslation
  };
};
