import { BookingListItem } from 'src/types/Booking';
import { BookingMacroType } from 'src/types/BookingType';

import BookingDepotCard from '../pages/Depot/components/BookingDepotCard';
import { BookingPackingCard } from '../pages/Packing/Components/BookingPackingCard';
import BookingTransportCard from '../pages/Transport/components/BookingTransportCard';
import { BookingJobType } from '../types';

type Props = {
  isLoading?: boolean;
  jobType: BookingJobType;
  booking: BookingListItem;
  macroType: BookingMacroType;
};

function DashboardBookingCard({
  macroType,
  jobType,
  booking,
  isLoading
}: Props): JSX.Element {
  const getCard = (type: BookingMacroType) => {
    switch (type) {
      case BookingMacroType.DEPOT:
        return (
          <BookingDepotCard
            jobType={jobType}
            booking={booking}
            isLoading={isLoading}
          />
        );
      case BookingMacroType.PACKING:
        return (
          <BookingPackingCard
            jobType={jobType}
            booking={booking}
            isLoading={isLoading}
          />
        );
      case BookingMacroType.TRANSPORT:
      default:
        return (
          <BookingTransportCard
            jobType={jobType}
            booking={booking}
            isLoading={isLoading}
          />
        );
    }
  };

  return getCard(macroType);
}

export default DashboardBookingCard;
