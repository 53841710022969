import { Box, Grid } from '@mui/material';
import { SxProps } from '@mui/system';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../locales/translations';
import { useAppDispatch } from '../../../store/hooks';
import ErrorPageBackToHomeButton from '../../components/ErrorPageBackToHomeButton/ErrorPageBackToHomeButton';
import ErrorPageImage from '../../components/ErrorPageImage/ErrorPageImage';
import ErroPageMessage from '../../components/ErrorPageMessage/ErrorPageMessage';
import Logo from '../../components/Logo';
import { setUnAuthorized } from '../../slices/Auth';

const mainBoxSx: SxProps = {
  display: 'flex',
  flexDirection: 'column'
};

const mainGridSx: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  height: '100%',
  paddingX: 72
};

const itemGridSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start'
};

function PageForbidden(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(setUnAuthorized({ unAuthorized: false }));
    };
  }, []);

  return (
    <Box sx={mainBoxSx}>
      <Logo />
      <Grid container spacing={0} sx={mainGridSx}>
        <Grid item xs={7} sx={itemGridSx}>
          <ErroPageMessage
            title={t(translations.pages.pageForbidden.title)}
            subTitle={t(translations.pages.pageForbidden.subtilte)}
          />
          <ErrorPageBackToHomeButton />
        </Grid>
        <Grid item xs={5} sx={itemGridSx}>
          <ErrorPageImage
            src={'/convelio-403.png'}
            alt={'Convelio - 403 no result'}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PageForbidden;
