import { Accept } from 'react-dropzone';

import { FileCategoryType } from '../../../types/FileCategoryType';

export interface FileToUpload {
  id: string;
  file: File;
  error?: boolean;
  uploadOk?: boolean;
  category: FileCategoryType;
}

export type SelectFileParam = {
  accept: string;
  category: FileCategoryType;
  isMandatory?: boolean;
};

export const ALLOWED_IMAGE_FORMATS = 'image/*,.pdf';

export const ALLOWED_CRATING_IMAGE_FORMATS: Accept = {
  'image/*': [],
  'application/pdf': []
};

export interface UploadedFile {
  id: string;
  externalId: string;
  bookingId: string;
  name: string;
  category: FileCategoryType;
  type: string;
  size: number;
  url?: string;
  expires?: number;
  extension?: string;
  gcsFileName?: string;
  sharedCategory?: string;
  source?: string;
}

export const TRANSPORT_FILE_CATEGORIES = [
  FileCategoryType.PICTURE_START,
  FileCategoryType.PICTURE_END,
  FileCategoryType.LEGAL_DOCUMENT_SIGNED,
  FileCategoryType.OTHER
];

export const PACKING_FILE_CATEGORIES = [
  FileCategoryType.PICTURE_CRATE_IN,
  FileCategoryType.PICTURE_CRATE_OUT,
  FileCategoryType.OTHER
];

export const DEPOT_FILE_CATEGORIES = [FileCategoryType.OTHER];

export interface FileResponse<T> {
  data: T[];
}
