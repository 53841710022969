import { Address } from '../../../../../../../../types/Address';
import { Contact } from '../../../../../../../../types/Contact';
import { Location } from '../../../../../../../../types/Location';
import { BookingItem } from '../../../../../../../types/Booking';
import { useAddress } from '../../hooks/useAddress';
import { useContact } from '../../hooks/useContact';
import { useLocation } from '../../hooks/useLocation';

interface PickupDetailsReturnType {
  pickupContact: Contact;
  pickupAddress: Address;
  pickupLocation: Location;
}

export const usePickupState = (
  booking: BookingItem
): PickupDetailsReturnType => {
  const pickupContact: Contact = useContact({
    firstname: booking.startContactFirstname,
    lastname: booking.startContactLastname,
    emails: booking.startContactEmails,
    phones: booking.startContactPhones
  });
  const pickupAddress: Address = useAddress({
    name: booking.startCompanyName,
    addressLine1: booking.startAddressLine1,
    addressLine2: booking.startAddressLine2,
    zipCode: booking.startAddressZipcode,
    city: booking.startAddressCity,
    country: booking.startAddressCountry
  });
  const pickupLocation: Location = useLocation({
    name: booking.startCompanyName,
    city: booking.startAddressCity,
    country: booking.startAddressCountry
  });

  return {
    pickupContact,
    pickupAddress,
    pickupLocation
  };
};
