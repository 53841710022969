import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../../locales/translations';
import { BookingActionStatusType } from '../../../../../../../types/BookingType';
import {
  CrateChangeMethodType,
  PackingCrate,
  PackingCrateOperation,
  getCrateDimension
} from '../../../../../../../types/Packing';
import {
  useChangeCrateMutation,
  useChangeStatusMutation,
  useGetVersionMutation
} from '../../../../../../services/BookingApi';
import { BookingItem } from '../../../../../../types/Booking';

export const usePackingMainPanelLogic = (booking: BookingItem) => {
  const { t } = useTranslation();
  const [feature, setFeature] = useState<string>();
  const [crateMethod, setCrateMethod] = useState<CrateChangeMethodType>();
  const [bookingAction, setBookingAction] = useState<BookingActionStatusType>();

  const [getVersion] = useGetVersionMutation();
  const [changeCrate, { isError: isErrorCrate, isSuccess: isSuccessCrate }] =
    useChangeCrateMutation();
  const [changeStatus, { isError: isErrorStatus, isSuccess: isSuccessStatus }] =
    useChangeStatusMutation();

  const updateStatus = async (
    feature: string,
    action: BookingActionStatusType,
    date?: Date | null
  ): Promise<void> => {
    const { version } = await getVersion(booking.id).unwrap();
    setFeature(feature);
    setBookingAction(action);
    await changeStatus({
      bookingId: booking.id,
      action,
      version,
      date
    }).unwrap();
  };

  const handleChangeStatus = async (
    feature: string,
    action: BookingActionStatusType,
    date?: Date | null
  ): Promise<void> => {
    if (!booking || !date || !feature) return;
    await updateStatus(feature, action, date);
  };

  const handlePackingEstimationSubmit = async (
    feature: string,
    action: BookingActionStatusType,
    date?: Date | null
  ): Promise<void> => {
    if (!booking || !feature) return;

    await updateStatus(feature, action, date);
  };

  const handleChangeCrate = async (
    crate: PackingCrate,
    method: CrateChangeMethodType,
    operation?: PackingCrateOperation
  ): Promise<PackingCrate> => {
    const { version } = await getVersion(booking.id).unwrap();
    setCrateMethod(method);
    const updatedCrate = {
      ...crate,
      version
    };

    const { data } = await changeCrate({
      method,
      operation,
      crateId: updatedCrate.id,
      bookingId: booking.id,
      crateDimension: getCrateDimension(updatedCrate)
    }).unwrap();
    return data;
  };

  const statusMessage = useMemo(() => {
    if (!feature || !bookingAction) return '';
    return t(
      translations.pages.booking.pages.packing.features[feature][
        bookingAction
      ]?.[isErrorStatus ? 'failedMessage' : 'successMessage']
    );
  }, [t, feature, bookingAction, isErrorStatus]);

  const crateMessage = useMemo(() => {
    if (!crateMethod) return '';
    return t(
      translations.pages.booking.pages.packing.crate[crateMethod][
        isErrorCrate ? 'failedMessage' : 'successMessage'
      ]
    );
  }, [t, crateMethod, isErrorCrate]);

  return {
    handleChangeStatus,
    handlePackingEstimationSubmit,
    handleChangeCrate,
    feature,
    bookingAction,
    isSuccessStatus,
    isErrorStatus,
    crateMethod,
    isSuccessCrate,
    isErrorCrate,
    statusMessage,
    crateMessage
  };
};
