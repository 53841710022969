import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../locales/translations';
import PasswordInput from '../../../../../components/PasswordInput';
import { CONFIRM_PASSWORD_KEY } from '../constants';
import { FormData } from '../types';

type Props = {
  control: Control<FormData>;
  errorMessage?: string;
};

export const RecoverPasswordConfirmInput: FunctionComponent<Props> = ({
  control,
  errorMessage
}): JSX.Element => {
  const { t } = useTranslation();
  const confirmPassword: string = t(
    translations.recoveryPassword.confirmPassword
  );

  return (
    <>
      <Controller
        name={CONFIRM_PASSWORD_KEY}
        control={control}
        defaultValue=""
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error }
        }) => (
          <PasswordInput
            sx={{
              marginTop: 16
            }}
            datatestid="confirm-password-input"
            label={confirmPassword}
            placeholder={confirmPassword}
            value={value}
            onChange={onChange}
            error={!!error}
            onBlur={onBlur}
          />
        )}
      />
      <Typography color="error" data-testid="confirm-password-error-message">
        {errorMessage}
      </Typography>
    </>
  );
};
