import { store } from '../../../../../../../../../store/store';
import {
  CrateChangeMethodType,
  PackingCrate,
  PackingCrateOperation
} from '../../../../../../../../../types/Packing';
import { bookingApi } from '../../../../../../../../services/BookingApi';
import { DefaultCrateSaveError } from '../../Core/Domain/Error/DefaultCrateSaveError';
import { CrateDimension } from '../../Core/Domain/Model/CrateDimension';
import { CrateRepository } from '../../Core/Domain/Repository/CrateRepository';

export class RTKQueryCrateRepository implements CrateRepository {
  public async save(
    crateDimension: CrateDimension,
    bookingId: string
  ): Promise<PackingCrate> {
    const result = await store.dispatch(
      bookingApi.endpoints.changeCrate.initiate({
        method: CrateChangeMethodType.POST,
        operation: PackingCrateOperation.ESTIMATION,
        bookingId,
        crateDimension
      })
    );

    if ('error' in result && result.error) {
      throw new DefaultCrateSaveError();
    }

    return (result as { data: { data: PackingCrate } }).data.data;
  }
}
