import { useEffect, useMemo, useState } from 'react';

import { BookingMacroType, BookingType } from '../../../../types/BookingType';
import { FileCategoryType } from '../../../../types/FileCategoryType';
import {
  BOOKING_DEPOT_TYPES,
  BOOKING_PACKING_TYPES,
  BOOKING_TRANSPORT_TYPES
} from '../../../../utils/helpers/BookingHelper';
import {
  DEPOT_FILE_CATEGORIES,
  PACKING_FILE_CATEGORIES,
  TRANSPORT_FILE_CATEGORIES
} from '../../../types/booking/File';
import { FileCategoryMap } from '../types/FileCategoryMap';

const fileCategoryMap: FileCategoryMap = {
  [BookingMacroType.TRANSPORT]: TRANSPORT_FILE_CATEGORIES,
  [BookingMacroType.PACKING]: PACKING_FILE_CATEGORIES,
  [BookingMacroType.DEPOT]: DEPOT_FILE_CATEGORIES
};

const macroTypeToBookingTypeMap: Map<BookingMacroType, BookingType[]> = new Map(
  [
    [BookingMacroType.TRANSPORT, BOOKING_TRANSPORT_TYPES],
    [BookingMacroType.PACKING, BOOKING_PACKING_TYPES],
    [BookingMacroType.DEPOT, BOOKING_DEPOT_TYPES]
  ]
);

export const useFileCategoryTypes = (
  bookingType: BookingType
): FileCategoryType[] => {
  const [macroType, setMacroType] = useState<BookingMacroType | null>(null);

  useEffect(() => {
    if (bookingType) {
      setMacroType(getBookingMacroType(bookingType));
    }
  }, [bookingType]);

  return useMemo(() => {
    if (!macroType) return [];
    return fileCategoryMap[macroType] || [];
  }, [macroType]);
};

const getBookingMacroType = (bookingType: BookingType): BookingMacroType => {
  const [macroType]: [BookingMacroType, BookingType[]] | [] =
    Array.from(macroTypeToBookingTypeMap.entries()).find(([_, types]) =>
      types.includes(bookingType)
    ) || [];

  if (!macroType) {
    throw new Error(`Unknown booking type: ${bookingType}`);
  }

  return macroType;
};
