import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../locales/translations';
import { useDate } from '../../../../hooks/useDate';
import { usePickupDateManagement } from '../../../../pages/Booking/Pages/TransportBooking/Features/Pickup/hooks/usePickupDateManagement';
import { usePickupState } from '../../../../pages/Booking/Pages/TransportBooking/Features/Pickup/hooks/usePickupState';
import { BookingItem } from '../../../../types/Booking';
import { LogisticsView } from '../../Page/Section/LogisticsView';
import { formatLocation } from '../../helpers/Formater';
import { useEtaPickup } from './useEtaPickup';

interface PickupViewProps {
  booking: BookingItem;
}

export const PickupView = ({ booking }: PickupViewProps): JSX.Element => {
  const { t } = useTranslation();
  const { formatIsoToFullDateString } = useDate();

  const { readyForCollectionDate, etaPickupDate } = usePickupDateManagement({
    readyForCollectionDateString: booking.startReadyOn,
    etaPickupDateString: booking.startExpectedOn,
    pickupDateString: booking.startDate
  });

  const { pickupAddress, pickupContact, pickupLocation } =
    usePickupState(booking);

  const { etaTitle, etaDate } = useEtaPickup(
    etaPickupDate,
    readyForCollectionDate
  );
  const pickupDate = formatIsoToFullDateString(booking.startDate);

  const title = useMemo((): string => {
    return `${t(translations.pickup.title)}: ${formatLocation(pickupLocation)}`;
  }, [
    booking.startCompanyName,
    booking.startAddressCity,
    booking.startAddressCountry
  ]);

  return (
    <LogisticsView
      title={title}
      etaTitle={etaTitle}
      etaDate={etaDate}
      confirmTitle={t(translations.confirmPickupDate.title)}
      confirmDate={pickupDate}
      address={pickupAddress}
      contact={pickupContact}
    />
  );
};
