import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../../locales/translations';
import { useCurrency } from '../../../../../../hooks/useCurrency';

interface OriginalBudgetDataProps {
  budgetEur: number;
  budgetUsd: number;
  budgetGbp: number;
}

interface OriginalBudgetDataReturnType {
  originalBudgetFormatted: string;
  tooltipTitle: string;
}

export const useOriginalBudgetData = ({
  budgetEur,
  budgetUsd,
  budgetGbp
}: OriginalBudgetDataProps): OriginalBudgetDataReturnType => {
  const { t } = useTranslation();
  const { getFormattedBudget } = useCurrency();

  const originalBudgetFormatted = useMemo(
    () =>
      getFormattedBudget({
        budgetEur,
        budgetUsd,
        budgetGbp
      }),
    [budgetEur, budgetUsd, budgetGbp]
  );

  return {
    originalBudgetFormatted,
    tooltipTitle: `${t(translations.pages.booking.header.originalBudget)} (${t(
      translations.pages.booking.header.excludeTax
    )})`
  };
};
