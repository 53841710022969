import { useEffect, useState } from 'react';

const DEFAULT_DELAY = 300;

export const useDebouncedSearch = (query: string, delay = DEFAULT_DELAY) => {
  const [debouncedQuery, setDebouncedQuery] = useState(query);

  useEffect(() => {
    const timerId = setTimeout((): void => {
      setDebouncedQuery(query);
    }, delay);

    return (): void => {
      clearTimeout(timerId);
    };
  }, [query, delay]);

  return debouncedQuery;
};
