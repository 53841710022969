import { Box, Chip, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { translations } from 'src/locales/translations';
import { BookingListItem } from 'src/types/Booking';
import { BookingStatusType } from 'src/types/BookingType';

type Props = {
  booking: BookingListItem;
};

const WrapperBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row'
});

const ChipStyled = styled(Chip)({
  paddingX: 8,
  paddingY: 4,
  fontSize: '0.8rem',
  lineHeight: '1.09rem',
  fontWeight: 600,
  height: 24
});

const StatusChip = styled(ChipStyled)({
  backgroundColor: 'grey.100',
  color: 'black'
});

const DeliveryOptionChip = styled(ChipStyled)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.secondary.main,
  marginLeft: 4
}));

function BookingTransportFlags({ booking }: Props): JSX.Element {
  const { t } = useTranslation();
  const isDeliveryOption = [
    BookingStatusType.ACCEPTED,
    BookingStatusType.TO_BE_SCHEDULED
  ].includes(booking?.status);
  return (
    <WrapperBoxStyled>
      <StatusChip label={t(translations.status[booking?.status])} />
      {isDeliveryOption && (
        <DeliveryOptionChip
          label={`${t(translations.deliveryOptions[booking.deliveryOptions])}`}
        />
      )}
    </WrapperBoxStyled>
  );
}

export default BookingTransportFlags;
