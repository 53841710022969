import { HamburgerMenu } from './HamburgerMenu';
import { IconHamburgerMenuButton } from './IconHamburgerMenuButton';
import { useHamburgerMenu } from './useHambugerMenu';

export const LogoHamburgerMenu = () => {
  const { handleClose, anchorRef, handleToggle, open } = useHamburgerMenu();

  return (
    <>
      <IconHamburgerMenuButton
        anchorRef={anchorRef}
        handleToggle={handleToggle}
        open={open}
      />
      <HamburgerMenu
        handleClose={handleClose}
        anchorRef={anchorRef}
        open={open}
      />
    </>
  );
};
