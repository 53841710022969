import { useMediaQuery, useTheme } from '@mui/material';

type ResponsivenessHook = {
  isTabletView: boolean;
  isMobileView: boolean;
  isSmallScreen: boolean;
  isMediumScreen: boolean;
  isMinWidthLaptop: boolean;
  isMaxWidthTablet: boolean;
};

export function useResponsiveness(): ResponsivenessHook {
  const theme = useTheme();
  const isTabletView = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMinWidthLaptop = useMediaQuery(theme.breakpoints.up('tablet'));
  const isMaxWidthTablet = useMediaQuery(theme.breakpoints.down('tablet'));

  return {
    isTabletView,
    isMobileView,
    isSmallScreen,
    isMediumScreen,
    isMinWidthLaptop,
    isMaxWidthTablet
  };
}
