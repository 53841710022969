import { Box, styled } from '@mui/material';
import { useState } from 'react';

import EditNote from './EditNote';
import NoteBodyLodaer from './NoteBodyLoader';
import NoteTitle from './NoteTitle';
import NoteTitleLoader from './NoteTitleLoader';
import ShowNote from './ShowNote';

const NoteBodyMainBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  padding: 16
});

type Props = {
  note: string;
  isEditable: boolean;
  nbrNote: number;
  isLoading?: boolean;
  handleNoteChange: (value?: string) => void;
};

function NoteBody({
  note,
  isEditable,
  nbrNote,
  isLoading,
  handleNoteChange
}: Props): JSX.Element {
  const [editMode, setEditMode] = useState<boolean>(false);

  const handleChange = (value?: string) => {
    setEditMode(false);
    handleNoteChange(value);
  };
  return (
    <NoteBodyMainBoxStyled>
      {isLoading ? (
        <NoteTitleLoader />
      ) : (
        <NoteTitle
          isEditable={isEditable}
          editMode={editMode}
          nbrNote={nbrNote}
          handleChange={setEditMode}
        />
      )}
      {isLoading ? (
        <NoteBodyLodaer />
      ) : editMode ? (
        <EditNote note={note} handleChange={handleChange} />
      ) : (
        <ShowNote note={note} />
      )}
    </NoteBodyMainBoxStyled>
  );
}

export default NoteBody;
