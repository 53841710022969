import { Box, styled } from '@mui/material';
import { useParams } from 'react-router';

import { BookingMacroType } from '../../../types/BookingType';
import { Header, LogoHeader } from '../../components/Header';
import { useResponsiveness } from '../../hooks/useResponsive';
import { BookingItem } from '../../types/Booking';
import { DashboardColor } from '../../types/menu';
import { useDashboardColor } from '../Dashboard/hooks/useDashboardColor';
import PageNotFound from '../PageNotFound';
import BookingWrapper from './components/BookingWrapper';
import { useBookingData } from './hooks/useBookingData';
import { useRedirectOnBookingTypeMismatch } from './hooks/useRedirectOnBookingTypeMismatch';
import { getBookingPage } from './types/BookingPage';

export const Booking = (): JSX.Element => {
  const { bookingId, bookingMacroType } = useParams();
  const dashboardColor: DashboardColor = useDashboardColor();
  const { isMinWidthLaptop } = useResponsiveness();
  const {
    isBookingNotFound,
    booking,
    bookingVersion,
    nextBooking,
    previousBooking,
    isLoading
  } = useBookingData(bookingId);

  useRedirectOnBookingTypeMismatch({
    bookingMacroType: bookingMacroType as BookingMacroType,
    booking,
    isLoading
  });

  if (isBookingNotFound) {
    return <PageNotFound />;
  }

  return (
    <StyledBox>
      {isMinWidthLaptop && (
        <Header logo={<LogoHeader activeMenuColor={dashboardColor} />} />
      )}
      <BookingWrapper
        page={getBookingPage(bookingMacroType)}
        pageProps={{
          version: bookingVersion,
          booking: booking || ({} as BookingItem),
          isLoading,
          next: nextBooking,
          previous: previousBooking
        }}
      />
    </StyledBox>
  );
};

const StyledBox = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
});
