import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import {
  Box,
  Fade,
  Menu,
  MenuItem,
  MenuProps,
  Typography,
  styled
} from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AvatarStep from 'src/app/components/AvatarStep';
import { translations } from 'src/locales/translations';

import { TabItem } from '../types';

const MainBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  paddingRight: 20
});

const MainBoxTitleStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    color="secondary"
    id="fade-menu"
    MenuListProps={{
      'aria-labelledby': 'fade-button'
    }}
    elevation={1}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.shape.borderRadius,
    marginTop: 25,
    minWidth: 118,
    color: theme.palette.primary,
    outerWidth: '100%',
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.grey['600'],
        marginRight: 10
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
      }
    }
  }
}));

const MenuItemStyled = styled(MenuItem)({
  display: 'flex',
  justifyContent: 'end'
});

const IconArrow = styled(({ open }: { open: boolean }) =>
  open ? <ArrowDropDown /> : <ArrowLeftIcon />
)({
  marginLeft: 12
});

const AvatarStyled = styled(
  ({ step }: { step: string | number | undefined }) => (
    <AvatarStep step={step} color="primary.main" bgColor="primary.light" />
  )
)({});

type Props = {
  index: number;
  items: TabItem[];
  handleMenuChange: (index: number) => void;
};

function DashboardTabMenuTabletMobile({
  index,
  items,
  handleMenuChange
}: Props): JSX.Element | null {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentTotal, setCurrentTotal] = useState<string | undefined>();
  const [currentTitle, setCurrentTitle] = useState<string | undefined>();

  useEffect(() => {
    if (!items || !items[index]) return;
    setCurrentTitle(items[index]?.type);
    setCurrentTotal(`${items[index]?.value.total}`);
  }, [index]); //TODO/ Check why removing item here make it work great

  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleChange = (target: number) => {
    setCurrentTotal(`${items[target].value.total}`);
    setCurrentTitle(items[target].type);
    handleMenuChange(target);
    setAnchorEl(null);
  };

  return (
    <MainBoxStyled>
      <MainBoxTitleStyled onClick={handleClick}>
        {currentTitle && (
          <Typography variant="h6" color={'primary.main'}>
            {t(translations[currentTitle].title)}
          </Typography>
        )}
        {currentTotal && (
          <Typography variant="h6">
            <AvatarStyled step={currentTotal} />
          </Typography>
        )}
        <IconArrow open={open} />
      </MainBoxTitleStyled>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {items.map((job, index) => (
          <MenuItemStyled
            key={`${job.type} ${index}`}
            onClick={() => handleChange(index)}
          >
            {t(translations[job.type].title)}
            <AvatarStyled step={job.value?.total} />
          </MenuItemStyled>
        ))}
      </StyledMenu>
    </MainBoxStyled>
  );
}

export default DashboardTabMenuTabletMobile;
