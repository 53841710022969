import styles from '@convelio/stylio/styles.module.scss';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { Address } from '../../../../../types/Address';
import { Contact } from '../../../../../types/Contact';
import { LogisticsSubView } from './LogisticsSubView';

interface LogisticsSectionProps {
  title: string;
  etaTitle?: string;
  etaDate?: string;
  confirmTitle: string;
  confirmDate: string;
  address?: Address;
  contact: Contact;
  stacked?: boolean;
  testId?: string;
}

export const LogisticsView = ({
  title,
  etaTitle,
  etaDate,
  confirmTitle,
  confirmDate,
  address,
  contact,
  testId,
  stacked = false
}: LogisticsSectionProps): JSX.Element => (
  <View style={style.view} data-testid={testId ? testId : 'logistics-view'}>
    <Text style={style.title}>{title}</Text>
    <LogisticsSubView
      etaTitle={etaTitle}
      etaDate={etaDate}
      confirmTitle={confirmTitle}
      confirmDate={confirmDate}
      address={address}
      contact={contact}
      stacked={stacked}
    />
  </View>
);

const style = StyleSheet.create({
  view: {
    flexDirection: 'column',
    marginBottom: styles.cvoSpacingS,
    padding: styles.cvoSpacingS
  },
  title: {
    fontSize: styles.cvoBodyLgFontSize,
    fontFamily: 'Helvetica-Bold',
    marginTop: styles.cvoSpacingS
  }
});
