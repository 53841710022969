import {
  Box,
  Grid,
  GridProps,
  Paper,
  Skeleton,
  Stack,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';

const WrapperPaperStyled = styled(Paper)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  marginTop: 16,
  borderRadius: '8px',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.primary.light
}));

const GridCardFirstRowStyled = styled((props: GridProps) => (
  <Grid container spacing={0} {...props} />
))({
  display: 'flex',
  flexDirection: 'column',
  padding: 16
});

const GridCardRowStyled = styled((props: GridProps) => (
  <Grid container spacing={0} {...props} />
))({
  display: 'flex',
  flexDirection: 'row'
});

const GridCardLastRowStyled = styled((props: GridProps) => (
  <Grid container spacing={0} {...props} />
))(({ theme }) => ({
  padding: 16,
  display: 'flex',
  flexDirection: 'row',
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderTopColor: theme.palette.primary.light
}));

const BookingNumberBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

const CvoCustomerSkeleton = styled(Skeleton)({
  height: 24,
  width: '15%',
  marginRight: 6
});

const ActionSkeleton = styled(Skeleton)({
  height: 32,
  width: '50%'
});

const DateSkeleton = styled(Skeleton)({
  height: 24,
  width: '55%'
});

const FlagSkeleton = styled(Skeleton)({
  height: 32,
  width: '25%'
});

const StackSkeleton = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center'
});

const LogisticSkeleton = (): JSX.Element => (
  <StackSkeleton>
    <Skeleton height={32} width={'30%'} />
    <Skeleton height={20} width={12} sx={{ marginX: 16 }} />
    <Skeleton height={32} width={'30%'} />
  </StackSkeleton>
);

const CustomerRefBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

const GridCvoCustomerRef = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'start',
  justifyContent: 'start'
});

const GridEnd = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end'
});

type Props = {
  link: string;
  isLoading?: boolean;
  flags?: JSX.Element;
  actions?: JSX.Element;
  logistic?: JSX.Element;
  subComment?: JSX.Element;
  customerRef?: JSX.Element;
  bookingNumber?: JSX.Element;
};

function DashboardBookingCardWrapper({
  link,
  flags,
  actions,
  logistic,
  isLoading,
  subComment,
  customerRef,
  bookingNumber
}: Props): JSX.Element {
  const theme = useTheme();
  const isMinWidthMobile = useMediaQuery(theme.breakpoints.up('mobile'));
  return (
    <WrapperPaperStyled elevation={0}>
      <GridCardFirstRowStyled>
        <GridCardRowStyled>
          <GridCvoCustomerRef item xs={8}>
            {isLoading ? (
              <CvoCustomerSkeleton variant="text" />
            ) : (
              <Link to={link}>
                <BookingNumberBoxStyled>{bookingNumber}</BookingNumberBoxStyled>
              </Link>
            )}
            {isMinWidthMobile &&
              (isLoading ? (
                <CvoCustomerSkeleton variant="text" />
              ) : (
                <Link to={link}>
                  <CustomerRefBoxStyled>{customerRef}</CustomerRefBoxStyled>
                </Link>
              ))}
          </GridCvoCustomerRef>
          {isMinWidthMobile && (
            <GridEnd item xs={4}>
              {isLoading ? <ActionSkeleton variant="text" /> : actions}
            </GridEnd>
          )}
        </GridCardRowStyled>
        <GridCardRowStyled>
          <Grid item xs={12}>
            {isLoading ? (
              <LogisticSkeleton />
            ) : (
              <Link to={link}>{logistic}</Link>
            )}
          </Grid>
        </GridCardRowStyled>
      </GridCardFirstRowStyled>
      {(subComment || (isMinWidthMobile && flags)) && (
        <GridCardLastRowStyled>
          {isMinWidthMobile && (
            <Grid item xs={6}>
              {isLoading ? <FlagSkeleton /> : <Link to={link}>{flags}</Link>}
            </Grid>
          )}
          <GridEnd item xs={isMinWidthMobile ? 6 : 12}>
            {isLoading ? (
              <DateSkeleton variant="text" />
            ) : (
              <Link to={link}>{subComment}</Link>
            )}
          </GridEnd>
        </GridCardLastRowStyled>
      )}
    </WrapperPaperStyled>
  );
}

export default DashboardBookingCardWrapper;
