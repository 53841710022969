import styles from '@convelio/stylio/styles.module.scss';
import { Box, Typography } from '@mui/material';

import AvatarStep from '../../../../../components/AvatarStep';

type Props = {
  title: string;
  nbrFile: string | number;
};

function AccordionFileTitle({ title, nbrFile }: Props): JSX.Element {
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'row', gap: styles.cvoSpacingXs }}
    >
      <Typography sx={{ fontWeight: '600' }}>{title}</Typography>
      <AvatarStep step={nbrFile} bgColor={styles.cvoB50} color="common.white" />
    </Box>
  );
}

export default AccordionFileTitle;
