import styles from '@convelio/stylio/styles.module.scss';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { MerchandiseDimensionType } from '../../../../../../types/MerchandiseDimensionType';
import { MerchandiseDimensionText } from './MerchandiseDimensionText';
import { useMerchandiseDimensionsTitle } from './useMerchandiseDimensionsTitle';

interface MerchandiseDimensionsSectionProps {
  dimensions: string[];
  type: MerchandiseDimensionType;
}

export const MerchandiseDimensionsView = ({
  dimensions,
  type
}: MerchandiseDimensionsSectionProps) => {
  const { merchandiseDimensionsTitle } = useMerchandiseDimensionsTitle(type);

  return (
    <View>
      <Text style={style.title}>{merchandiseDimensionsTitle}</Text>
      {dimensions.map((merchandiseDimension, index) => (
        <MerchandiseDimensionText
          key={index}
          dimension={merchandiseDimension}
        />
      ))}
    </View>
  );
};

const style = StyleSheet.create({
  title: {
    fontSize: styles.cvoBodySmFontSize,
    fontFamily: 'Helvetica-Bold',
    marginTop: styles.cvoSpacingM,
    marginBottom: styles.cvoSpacingXs
  }
});
