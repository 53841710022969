import styles from '@convelio/stylio/styles.module.scss';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import {
  extractFromPixel,
  extractLineHeightPixel
} from 'src/utils/helpers/StylioHelper';

export default {
  htmlFontSize: 16,
  fontFamily: styles.cvoFontFamily,
  fontSize: 15,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontFamily: styles.cvoFontFamily,
    fontWeight: 700,
    fontSize: extractFromPixel(styles.cvoTitleXlFontSize),
    lineHeight: extractLineHeightPixel(styles.cvoTitleXlLineHeight),
    letterSpacing: 'none'
  },
  h2: {
    fontFamily: styles.cvoFontFamily,
    fontWeight: 700,
    fontSize: extractFromPixel(styles.cvoTitleLgFontSize),
    lineHeight: extractLineHeightPixel(styles.cvoTitleLgLineHeight),
    letterSpacing: 'none'
  },
  h3: {
    fontFamily: styles.cvoFontFamily,
    fontWeight: 600,
    fontSize: extractFromPixel(styles.cvoTitleMdFontSize),
    lineHeight: extractLineHeightPixel(styles.cvoTitleMdLineHeight),
    letterSpacing: 'none'
  },
  h4: {
    fontFamily: styles.cvoFontFamily,
    fontWeight: 600,
    fontSize: extractFromPixel(styles.cvoTitleSmFontSize),
    lineHeight: extractLineHeightPixel(styles.cvoTitleSmLineHeight),
    letterSpacing: 'none'
  },
  h5: {
    fontFamily: styles.cvoFontFamily,
    fontWeight: 600,
    fontSize: extractFromPixel(styles.cvoBodyLgFontSize),
    lineHeight: extractLineHeightPixel(styles.cvoBodyLgLineHeight),
    letterSpacing: 'none'
  },
  h6: {
    fontFamily: styles.cvoFontFamily,
    fontWeight: 600,
    fontSize: extractFromPixel(styles.cvoBodyMdFontSize),
    lineHeight: extractLineHeightPixel(styles.cvoBodyMdLineHeight),
    letterSpacing: 'none'
  },
  subtitle1: {
    fontFamily: styles.cvoFontFamily,
    fontWeight: 600,
    fontSize: extractFromPixel(styles.cvoBodySmFontSize),
    lineHeight: extractLineHeightPixel(styles.cvoBodySmLineHeight),
    letterSpacing: 'none'
  },
  body1: {
    fontFamily: styles.cvoFontFamily,
    fontWeight: 400,
    fontSize: extractFromPixel(styles.cvoBodyLgFontSize),
    lineHeight: extractLineHeightPixel(styles.cvoBodyLgLineHeight),
    letterSpacing: 'none'
  },
  body2: {
    fontFamily: styles.cvoFontFamily,
    fontWeight: 400,
    fontSize: extractFromPixel(styles.cvoBodyMdFontSize),
    lineHeight: extractLineHeightPixel(styles.cvoBodyMdLineHeight),
    letterSpacing: 'none'
  },
  body3: {
    fontFamily: styles.cvoFontFamily,
    fontWeight: 400,
    fontSize: extractFromPixel(styles.cvoBodySmFontSize),
    lineHeight: extractLineHeightPixel(styles.cvoBodySmLineHeight),
    letterSpacing: 'none'
  },
  button: {
    fontFamily: styles.cvoFontFamily,
    fontWeight: 400,
    fontSize: extractFromPixel(styles.cvoBodyMdFontSize),
    lineHeight: extractLineHeightPixel(styles.cvoBodyMdLineHeight),
    letterSpacing: 'none',
    textTransform: 'none'
  },
  caption: {
    fontFamily: styles.cvoFontFamily,
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    letterSpacing: 'none'
  },
  overline: {
    fontFamily: styles.cvoFontFamily,
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 2.66,
    letterSpacing: 'none',
    textTransform: 'uppercase'
  }
} as TypographyOptions;
