import { useTranslation } from 'react-i18next';
import TabPanel from 'src/app/components/TabPanel';
import { translations } from 'src/locales/translations';
import { BookingMacroType } from 'src/types/BookingType';

import { TabItem } from '../types';
import { DashboardBookingList } from './DashboardBookingList';
import DashboardTabBodyOverall from './DashboardTabBodyOverall';

type Props = {
  items: TabItem[];
  openedTabIndex: number;
  macroType: BookingMacroType;
  handleChange: (index: number) => void;
};

function DashboardTabBody({
  items,
  macroType,
  handleChange,
  openedTabIndex
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      {items.map((tab, index) => (
        <TabPanel key={index} value={openedTabIndex} index={index}>
          <>
            {index === 0 ? (
              <DashboardTabBodyOverall
                items={items}
                macroType={macroType}
                handleChange={handleChange}
              />
            ) : (
              <DashboardBookingList
                jobType={tab.type}
                macroType={macroType}
                bookings={tab.value.bookings}
                isLoading={tab.value.isLoading}
                title={t(translations[tab.type].title)}
              />
            )}
          </>
        </TabPanel>
      ))}
    </>
  );
}

export default DashboardTabBody;
