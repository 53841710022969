import { Chip, ChipProps } from '@mui/material';

function HeaderChip(props: ChipProps): JSX.Element {
  return (
    <Chip
      sx={{
        lineHeight: '1.6rem',
        fontWeight: '700',
        height: (theme) => theme.spacing(32),
        marginX: 4,
        backgroundColor: `${props.color}.light`,
        color: `${props.color}.dark`
      }}
      {...props}
    />
  );
}

export default HeaderChip;
