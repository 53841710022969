import styles from '@convelio/stylio/styles.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DownloadIcon from '@mui/icons-material/Download';
import { AppBar, Box, Dialog, Fade, Toolbar, styled } from '@mui/material';
import { Button } from 'src/app/components/Button';

import NotificationSnackbar from '../../../../components/SnackBar';
import { DeleteConfirmationDocumentModal } from '../DeleteConfirmationDocumentModal';
import { MediaDisplay } from './MediaDisplay';
import {
  VisualizeFileDataReturnType,
  useVisualizeFileData
} from './useVisualizeFileData';

interface VisualizeFileProps {
  type: string;
  open: boolean;
  fileIds: string[];
  selectedFileIndex: number;
  setOpen: (open: boolean) => void;
  resetSelectedIndex: () => void;
}

export const VisualizeFile = ({
  type,
  open,
  setOpen,
  fileIds,
  selectedFileIndex,
  resetSelectedIndex
}: VisualizeFileProps): JSX.Element => {
  const {
    files,
    currentIndex,
    setCurrentIndex,
    isGetFileLoading,
    isDeleteConfirmationModalOpened,
    closeDocumentVisualizer,
    downloadFile,
    openConfirmationDeleteFileModal,
    deleteFile,
    closeConfirmationDeleteFileModal,
    deleteFileNotificationMessage,
    deleteFileNotificationSeverity,
    isDeleteFileNotificationOpened,
    downloadFileTranslation,
    deleteFileTranslation
  }: VisualizeFileDataReturnType = useVisualizeFileData({
    fileIds,
    selectedFileIndex,
    resetSelectedIndex,
    setOpen
  });

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={closeDocumentVisualizer}
        TransitionComponent={Fade}
        PaperProps={{
          style: {
            background: 'rgba(250, 250, 250, 0.6)'
          }
        }}
      >
        <AppBarStyled position="static">
          <ToolbarStyled>
            {currentIndex !== null && currentIndex !== undefined && (
              <ActionStyled>
                <Button
                  variant="outlined"
                  label={downloadFileTranslation}
                  startIcon={<DownloadIcon fontSize="inherit" />}
                  onClick={downloadFile}
                  dataTestid="download-file"
                />
                <Button
                  variant="outlined"
                  color="danger"
                  dataTestid="delete-file"
                  label={deleteFileTranslation}
                  startIcon={<DeleteOutlineIcon fontSize="inherit" />}
                  onClick={openConfirmationDeleteFileModal}
                />
              </ActionStyled>
            )}
            <Button
              additionalClasses="-icon"
              onClick={closeDocumentVisualizer}
              startIcon={<CloseIcon fontSize="inherit" />}
            />
          </ToolbarStyled>
        </AppBarStyled>
        <MediaDisplay
          type={type}
          files={files}
          currentIndex={currentIndex}
          isLoading={isGetFileLoading}
          selectedFileIndex={selectedFileIndex}
          setCurrentIndex={setCurrentIndex}
        />
      </Dialog>
      <DeleteConfirmationDocumentModal
        open={isDeleteConfirmationModalOpened}
        handleDeleteFile={deleteFile}
        handleClose={closeConfirmationDeleteFileModal}
      />
      <NotificationSnackbar
        open={isDeleteFileNotificationOpened}
        message={deleteFileNotificationMessage}
        severity={deleteFileNotificationSeverity}
      />
    </div>
  );
};

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  color: 'grey.600',
  backgroundColor: theme.palette.background.paper,
  height: '72px',
  paddingLeft: styles.cvoSpacingXxs,
  paddingRight: styles.cvoSpacingXxs,
  [theme.breakpoints.up('tablet')]: {
    paddingLeft: styles.cvoSpacingL,
    paddingRight: styles.cvoSpacingL
  }
}));

const ToolbarStyled = styled(Toolbar)({
  marginBottom: styles.cvoSpacingXxs,
  marginTop: styles.cvoSpacingXxs,
  display: 'flex',
  justifyContent: 'space-between'
});

const ActionStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: styles.cvoSpacingXs,
  [theme.breakpoints.up('mobile')]: {
    gap: styles.cvoSpacingM
  }
}));
