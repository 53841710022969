import { measurementSystems } from 'src/app/pages/Setting/constants';
import { MeasurementSystem } from 'src/app/pages/Setting/types';
import { BookingStatusType } from 'src/types/BookingType';
import { FileCategoryType } from 'src/types/FileCategoryType';
import { LengthMetricType, WeightMetricType } from 'src/types/MetricType';
import * as yup from 'yup';

export enum PackingCrateStatus {
  ESTIMATED = 'ESTIMATED',
  CONFIRMED = 'CONFIRMED'
}

export type CrateFormFileCategory =
  | FileCategoryType.PICTURE_CRATE_IN
  | FileCategoryType.PICTURE_CRATE_OUT;

export type PackingBookingFile = {
  id: string;
  bookingId: string;
  externalId: string;
  crateId: string;
  gcsFileName: string;
  name: string;
  category: FileCategoryType;
  type: 'JPEG' | 'PNG' | 'PDF';
  size: number;
  url: string;
  expires: number;
};

export type PackingCrateDimension = {
  length: number;
  depth: number;
  height: number;
  quantity: number;
  weight: number;
  version?: number;
  lengthMetric: LengthMetricType;
  weightMetric?: WeightMetricType;
};

export type PackingCrate = PackingCrateDimension & {
  id?: string;
  name?: string;
  bookingId?: string;
  externalId?: string;
  files?: PackingBookingFile[];
  status?: PackingCrateStatus;
};

export type CrateFormFile = {
  id: string;
  file: File;
  category: CrateFormFileCategory;
};

export type CrateForm = PackingCrate & {
  notEditable?: boolean;
  isLoading?: boolean;
};

export const INIT_CRATE: PackingCrate = {
  length: 0,
  depth: 0,
  height: 0,
  quantity: 1,
  weight: 0,
  lengthMetric: LengthMetricType.CM,
  weightMetric: WeightMetricType.KG
};

export const getInitCrate = (
  measureSystem?: MeasurementSystem
): PackingCrate => {
  const crate = Object.assign({}, INIT_CRATE);
  if (!measureSystem || measureSystem !== measurementSystems.imperial)
    return crate;
  crate.lengthMetric = LengthMetricType.INCH;
  crate.weightMetric = WeightMetricType.LB;
  return crate;
};

export const getCrateDimension = ({
  version,
  length,
  depth,
  height,
  quantity,
  weight,
  lengthMetric,
  weightMetric
}: PackingCrate): PackingCrateDimension => {
  return {
    version,
    length,
    depth,
    height,
    quantity,
    weight,
    lengthMetric,
    weightMetric
  };
};

export const PACKING_ESTIMATE_FORM_KEYS: string[] = [
  'length',
  'depth',
  'height',
  'quantity'
];

export const PACKING_STATUS_ENABLED_DROPOFF: BookingStatusType[] = [
  BookingStatusType.PENDING,
  BookingStatusType.IN_PROGRESS
];

export const PACKING_STATUS_ENABLED_ESTIMATION: BookingStatusType[] = [
  BookingStatusType.IN_PROGRESS,
  BookingStatusType.PACKING_SCHEDULED
];

export const PACKING_STATUS_ENABLED_CONFIRMATION: BookingStatusType[] = [
  BookingStatusType.PACKING_SCHEDULED,
  BookingStatusType.PACKING_COMPLETED
];

export const PACKING_STATUS_ENABLED_PICKUP: BookingStatusType[] = [
  BookingStatusType.PACKING_COMPLETED,
  BookingStatusType.COMPLETED
];

export enum CrateChangeMethodType {
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE'
}

export const PACKING_CONFIRMATION_FORM_KEYS: Partial<
  keyof PackingCrateDimension
>[] = ['length', 'depth', 'height', 'weight', 'quantity'];

export enum PackingCrateOperation {
  ESTIMATION = 'ESTIMATION',
  CONFIRMATION = 'CONFIRMATION'
}

export interface PackingCrateRequest {
  crateId?: string;
  bookingId: string;
  method: CrateChangeMethodType;
  operation?: PackingCrateOperation;
  crateDimension: PackingCrateDimension;
}

export const SCHEMA_VALIDATION_FORM_CRATE = {
  [PackingCrateOperation.ESTIMATION]: yup.object().shape({
    crates: yup.array().of(
      yup.object().shape({
        length: yup.number().required().positive().min(1),
        depth: yup.number().required().positive().min(1),
        height: yup.number().required().positive().min(1),
        quantity: yup.number().required().positive().min(1)
      })
    )
  }),
  [PackingCrateOperation.CONFIRMATION]: yup.object().shape({
    crates: yup.array().of(
      yup.object().shape({
        length: yup.number().required().positive().min(1),
        depth: yup.number().required().positive().min(1),
        height: yup.number().required().positive().min(1),
        quantity: yup.number().required().positive().min(1),
        weight: yup.number().required().positive().min(1)
      })
    )
  })
};
