import { AlertColor } from '@mui/material/Alert';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../locales/translations';
import {
  useDeleteFileMutation,
  useGetVersionMutation
} from '../../../../services/BookingApi';
import { UploadedFile } from '../../../../types/booking/File';

interface DeleteFileDataProps {
  currentIndex: number | undefined;
  files: UploadedFile[] | undefined;
  closeDocumentVisualizer: () => void;
}

export const useDeleteFile = ({
  currentIndex,
  files,
  closeDocumentVisualizer
}: DeleteFileDataProps) => {
  const [isDeleteConfirmationModalOpened, setIsDeleteConfirmationModalOpened] =
    useState<boolean>(false);
  const [
    doDeleteFile,
    { isError: isDeletedError, isSuccess: isDeletedSuccess }
  ] = useDeleteFileMutation();
  const [getVersion] = useGetVersionMutation();
  const { t } = useTranslation();

  const openConfirmationDeleteFileModal = (): void => {
    setIsDeleteConfirmationModalOpened(true);
  };

  const closeConfirmationDeleteFileModal = (): void => {
    setIsDeleteConfirmationModalOpened(false);
  };

  const deleteFile = async (): Promise<void> => {
    if (!files || currentIndex === undefined || !files[currentIndex]) return;
    const { version } = await getVersion(
      files[currentIndex].bookingId
    ).unwrap();

    await doDeleteFile({
      fileId: files[currentIndex].id,
      bookingId: files[currentIndex].bookingId,
      version
    });

    closeConfirmationDeleteFileModal();
    closeDocumentVisualizer();
  };

  const deleteFileNotificationMessage = useMemo(() => {
    return t(
      translations.pages.booking.features.deleteFile[
        isDeletedSuccess ? 'successMessage' : 'failedMessage'
      ]
    );
  }, [isDeletedSuccess]);

  const deleteFileNotificationSeverity: AlertColor = useMemo(() => {
    return isDeletedSuccess ? 'success' : 'error';
  }, [isDeletedSuccess]);

  return {
    openConfirmationDeleteFileModal,
    closeConfirmationDeleteFileModal,
    isDeleteConfirmationModalOpened,
    deleteFileNotificationMessage,
    deleteFileNotificationSeverity,
    isDeleteFileNotificationOpened: isDeletedError || isDeletedSuccess,
    deleteFileTranslation: t(
      translations.pages.booking.features.deleteFile.title
    ),
    deleteFile
  };
};
