import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Skeleton,
  Typography,
  styled
} from '@mui/material';
import { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { Button } from 'src/app/components/Button';

import { getFileNameWithoutUuid } from '../../../../utils/helpers/FileHelper';
import { UploadedFile } from '../../../types/booking/File';

type Props = {
  isLoading?: boolean;
  images: UploadedFile[];
  selectedIndex: number;
  handleActiveStepChange: (index: number) => void;
};

function Carousel(props: Props): JSX.Element {
  const [activeStep, setActiveStep] = useState<number>();

  useEffect(() => {
    setActiveStep(props.selectedIndex);
  }, [props.selectedIndex]);

  const handleNext = () => {
    if (!activeStep && activeStep !== 0) return;
    setActiveStep(activeStep + 1);
    props.handleActiveStepChange(activeStep + 1);
  };

  const handleBack = () => {
    if (!activeStep && activeStep !== 0) return;
    setActiveStep(activeStep - 1);
    props.handleActiveStepChange(activeStep - 1);
  };

  return (
    <CarouselContainer data-testid="carousel">
      {props.images && (
        <ImageContainer>
          <Box>
            <Button
              additionalClasses="-icon"
              disabled={activeStep === 0}
              onClick={handleBack}
              startIcon={<KeyboardArrowLeft fontSize="inherit" />}
            />
          </Box>
          <SwipeableContainer>
            <StyledSwipeableViews index={activeStep}>
              {props.images.map((image) =>
                props.isLoading ? (
                  <LoadingBox key={image.id}>
                    <CircularProgress color={'primary'} />
                  </LoadingBox>
                ) : (
                  <ImageWrapper key={image.id}>
                    {image.url && (
                      <ResponsiveImage
                        src={image.url}
                        alt={getFileNameWithoutUuid(image.name)}
                      />
                    )}
                  </ImageWrapper>
                )
              )}
            </StyledSwipeableViews>
            <TextContainer>
              {props.isLoading ? (
                <LoadingBox>
                  <LoadingText variant="text" />
                </LoadingBox>
              ) : (
                activeStep !== null &&
                activeStep !== undefined &&
                props.images[activeStep]?.name && (
                  <ImageCaption>
                    {getFileNameWithoutUuid(props.images[activeStep].name)}
                  </ImageCaption>
                )
              )}
            </TextContainer>
          </SwipeableContainer>

          <Box>
            <Button
              additionalClasses="-icon"
              disabled={activeStep === props.images.length - 1}
              onClick={handleNext}
              startIcon={<KeyboardArrowRight fontSize="inherit" />}
            />
          </Box>
        </ImageContainer>
      )}
    </CarouselContainer>
  );
}

export default Carousel;

const ResponsiveImage = styled('img')({
  margin: 'auto',
  maxHeight: '100%',
  maxWidth: '100%',
  objectFit: 'contain'
});

const ImageWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80vh',
  width: '80vw'
});

const CarouselContainer = styled(Box)({
  height: 'calc(100% - 72px)',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center'
});

const ImageContainer = styled(Box)({
  height: '90%',
  width: '80%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center'
});

const SwipeableContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  marginLeft: 25,
  marginRight: 25
});

const StyledSwipeableViews = styled(SwipeableViews)({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  '& > div': {
    height: '100%',
    width: '100%'
  }
});

const LoadingBox = styled(Box)({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const TextContainer = styled(Box)({
  textAlign: 'center',
  paddingTop: 8,
  height: '5%'
});

const LoadingText = styled(Skeleton)({
  width: '40%'
});

const ImageCaption = styled(Typography)({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '1.6rem',
  color: `grey.500`
});
