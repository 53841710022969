import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../../locales/translations';
import {
  BookingStatusType,
  BookingUserType
} from '../../../../../../../types/BookingType';
import { ActivityData } from '../../ActivityData';
import { BuilderDecisionParams } from '../../BuilderDecisionParams';
import { EventMessageBuilder } from '../../EventMessageBuilder';

export const useStatusMessageBuilder = (): EventMessageBuilder => {
  const { t } = useTranslation();

  const supports = ({
    eventResult: { data, userType }
  }: BuilderDecisionParams): boolean => {
    return (
      userType === BookingUserType.SF &&
      (data.status === BookingStatusType.CANCELLED ||
        data.status === BookingStatusType.ON_HOLD ||
        data.status === BookingStatusType.ACCEPTED)
    );
  };

  const build = (data: ActivityData): string => {
    switch (data.status) {
      case BookingStatusType.CANCELLED:
        return t(translations.pages.booking.features.activity.statusCancelled);
      case BookingStatusType.ON_HOLD:
        return t(translations.pages.booking.features.activity.statusOnHold);
      case BookingStatusType.ACCEPTED:
        return t(translations.pages.booking.features.activity.statusAccepted);
      default:
        throw new Error('Unsupported status');
    }
  };

  return {
    build,
    supports
  };
};
