import styles from '@convelio/stylio/styles.module.scss';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../locales/translations';
import { useDate } from '../../../../hooks/useDate';
import { BookingItem } from '../../../../types/Booking';
import { PackingCratesTable } from './PackingTable';

interface PackingEstimationViewProps {
  booking: BookingItem;
}

export const PackingEstimationView = ({
  booking
}: PackingEstimationViewProps): JSX.Element => {
  const { t } = useTranslation();
  const { formatIsoToFullDateString } = useDate();
  const translationPacking = translations.pages.booking.pages.packing;
  const packExpectedOn = formatIsoToFullDateString(booking.packExpectedOn);
  const estimatedCrates = booking.crates?.filter((crate) => crate.weight === 0);
  const isEstimationInProgress =
    booking.crates?.length === estimatedCrates?.length;

  return (
    <View style={style.view} data-testid="packing-estimation-view">
      <Text style={style.title}>
        {t(translationPacking.features.stepper.PACKING_ESTIMATION)}
      </Text>
      {isEstimationInProgress && (
        <View style={style.button} data-testid="packing-message-btn">
          <Text>
            {t(translations.pages.booking.pages.packing.packingTypeMessage)}
          </Text>
        </View>
      )}
      {estimatedCrates && estimatedCrates.length > 0 && (
        <PackingCratesTable crates={estimatedCrates} />
      )}
      <Text style={style.subTitle}>
        {t(translationPacking.features.packingEstimation.plannedPacking)}
      </Text>
      <Text>{packExpectedOn}</Text>
    </View>
  );
};

const style = StyleSheet.create({
  view: {
    flexDirection: 'column',
    marginBottom: styles.cvoSpacingS,
    padding: styles.cvoSpacingS
  },
  title: {
    fontSize: styles.cvoBodyLgFontSize,
    fontFamily: 'Helvetica-Bold',
    marginTop: styles.cvoSpacingS
  },
  subTitle: {
    fontSize: styles.cvoBodySmFontSize,
    fontFamily: 'Helvetica-Bold',
    marginTop: styles.cvoSpacingM,
    marginBottom: styles.cvoSpacingXs
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: styles.cvoSpacingXxs,
    paddingHorizontal: styles.cvoSpacingXs,
    borderRadius: styles.cvoBorderRadiusFull,
    borderWidth: '1px',
    alignSelf: 'flex-start',
    marginTop: styles.cvoSpacingS
  }
});
