import LateralPanel from '../../Features/LateralPanel';
import Notes from '../../Features/Notes';
import MobileTabletViewWrapper from '../../components/MobileTabletViewWrapper';
import { BookingPageProps } from '../../types/BookingPage';
import PackingHeader from './Features/Header/PackingHeader';
import { PackingMainPanel } from './Features/MainPanel/PackingMainPanel';

function PackingMobileTabletView({
  version,
  booking,
  isLoading
}: BookingPageProps): JSX.Element {
  return (
    <MobileTabletViewWrapper
      firstTab={
        <>
          <PackingHeader booking={booking} isLoading={isLoading} />
          <PackingMainPanel
            version={version}
            booking={booking}
            isLoading={isLoading}
          />
        </>
      }
      secondTab={<LateralPanel booking={booking} />}
      notes={
        <Notes version={version} booking={booking} isLoading={isLoading} />
      }
    />
  );
}

export default PackingMobileTabletView;
