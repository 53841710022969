import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Tooltip, Typography, styled } from '@mui/material';

const TitleWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});

const IconInfo = styled(InfoOutlinedIcon)({
  marginLeft: 5
});

type Props = {
  title: string;
  tooltip?: string;
};

function AccordionSubTitle({ title, tooltip }: Props): JSX.Element {
  return (
    <TitleWrapper>
      <Typography variant="h5">{title}</Typography>
      {tooltip && (
        <Tooltip title={tooltip}>
          <IconInfo fontSize="small" />
        </Tooltip>
      )}
    </TitleWrapper>
  );
}

export default AccordionSubTitle;
