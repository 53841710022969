export enum FileCategoryType {
  OTHER = 'OTHER',
  PICTURE_START = 'PICTURE_START',
  PICTURE_END = 'PICTURE_END',
  PICTURE_LOAD_IN = 'PICTURE_LOAD_IN',
  PICTURE_LOAD_OUT = 'PICTURE_LOAD_OUT',
  LEGAL_DOCUMENT_UNSIGNED = 'LEGAL_DOCUMENT_UNSIGNED',
  LEGAL_DOCUMENT_SIGNED = 'LEGAL_DOCUMENT_SIGNED',
  CONVELIO = 'CONVELIO',
  PICTURE_CRATE_IN = 'PICTURE_CRATE_IN',
  PICTURE_CRATE_OUT = 'PICTURE_CRATE_OUT'
}
