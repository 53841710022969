import { FinancialValue } from '../FinancialValue';
import { useTotalCostData } from './useTotalCostData';

interface TotalCostProps {
  totalCost: number | null;
  budget: number;
}

export const TotalCost = ({
  totalCost,
  budget
}: TotalCostProps): JSX.Element => {
  const { totalCostFormatted, textColor, tooltipTitle } = useTotalCostData({
    totalCost,
    budget
  });

  return (
    <FinancialValue
      value={totalCostFormatted}
      testId="total-cost"
      textColor={textColor}
      tooltipTitle={tooltipTitle}
    />
  );
};
