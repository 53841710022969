import { BookingItem } from '../../../../types/Booking';
import { BookingPdfDownloadLink } from '../BookingPdfDownloadLink';
import { DepotBookingDocument } from './DepotBookingDocument';

interface DepotBookingPdfDownloadLinkProps {
  booking: BookingItem;
}

export const DepotBookingPdfDownloadLink = ({
  booking
}: DepotBookingPdfDownloadLinkProps) => {
  return (
    <BookingPdfDownloadLink
      document={<DepotBookingDocument booking={booking} />}
      filename={`booking_${booking.id}.pdf`}
    />
  );
};
