import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../store/hooks';
import { CurrencyType } from '../../types/BookingType';
import { selectUserCurrency } from '../slices/Auth';
import { BookingBudget } from '../types/Booking';

interface CurrencyReturnType {
  getFormattedBudget: (
    bookingBudget: BookingBudget,
    currency?: CurrencyType | null
  ) => string;
  getFormattedAmount: (
    amount: number | undefined,
    currencyType: CurrencyType | undefined
  ) => string;
  getFormattedTotalCost: (
    totalCost: number | null,
    currency?: CurrencyType | null
  ) => string;
}

const DEFAULT_CURRENCY = CurrencyType.EUR;

export const useCurrency = (): CurrencyReturnType => {
  const { i18n } = useTranslation();
  const userCurrency = useAppSelector(selectUserCurrency);

  const getFormattedBudget = (
    bookingBudget: BookingBudget,
    currency?: CurrencyType | null
  ): string => {
    const renderCurrency = getCurrency(currency ?? userCurrency);
    let budget = bookingBudget.budgetEur;
    if (renderCurrency === CurrencyType.GBP) budget = bookingBudget.budgetGbp;
    if (renderCurrency === CurrencyType.USD) budget = bookingBudget.budgetUsd;

    return getFormattedAmount(budget, renderCurrency);
  };

  const getFormattedTotalCost = (
    totalCost: number | null,
    currency?: CurrencyType | null
  ): string => {
    const renderCurrency = getCurrency(currency ?? userCurrency);
    return getFormattedAmount(totalCost, renderCurrency);
  };

  const getCurrency = (currency?: CurrencyType | null): CurrencyType => {
    return currency ?? DEFAULT_CURRENCY;
  };

  const getFormattedAmount = (
    amount?: number | null,
    currencyType?: CurrencyType | null
  ): string => {
    if (!amount || !currencyType) return '';
    const fraction = amount - Math.floor(amount);

    return amount.toLocaleString(i18n.language, {
      style: 'currency',
      currency: currencyType,
      minimumFractionDigits: fraction === 0 ? 0 : 2,
      maximumFractionDigits: fraction === 0 ? 0 : 2
    });
  };

  return {
    getFormattedBudget,
    getFormattedAmount,
    getFormattedTotalCost
  };
};
