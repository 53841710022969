import { ReactNode, createContext, useContext } from 'react';

import { AddDefaultCrateUseCase } from '../Core/Application/AddDefaultCrateUseCase';
import { RTKQueryCrateRepository } from './Repository/RTKQueryCrateRepository';
import { RTKQueryVersionRepository } from './Repository/RTKQueryVersionRepository';

const useCaseInstance = new AddDefaultCrateUseCase(
  new RTKQueryVersionRepository(),
  new RTKQueryCrateRepository()
);

export const DefaultCrateContext = createContext(useCaseInstance);

interface DefaultCrateProviderProps {
  children: ReactNode;
}

export const DefaultCrateProvider = ({
  children
}: DefaultCrateProviderProps): JSX.Element => {
  return (
    <DefaultCrateContext.Provider value={useCaseInstance}>
      {children}
    </DefaultCrateContext.Provider>
  );
};

export const useAddDefaultCrateUseCase = () => {
  const context = useContext(DefaultCrateContext);
  if (!context) {
    throw new Error('useDefaultCrate must be used within a UseCaseProvider');
  }
  return context;
};
