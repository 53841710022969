import { Divider } from '@mui/material';

const MerchandiseDivider = (): JSX.Element => (
  <Divider
    sx={{
      marginTop: '1rem',
      marginBottom: '1rem'
    }}
  />
);

export default MerchandiseDivider;
