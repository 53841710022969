import AddTaskIcon from '@mui/icons-material/AddTask';
import HistoryIcon from '@mui/icons-material/History';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import TodayIcon from '@mui/icons-material/Today';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BookingMacroType } from 'src/types/BookingType';
import { routes } from 'src/types/Routes';

import { Dashboard } from '../../components/Dashboard';
import { useTransportBookings } from '../../hooks/useTransportBookings';
import { BookingJobType, DashboardMenu } from '../../types';

const TRANSPORT_MENU_ITEMS: DashboardMenu = {
  all: {
    type: BookingJobType.TODO,
    path: routes.transport,
    icon: <PlaylistAddCheckIcon />
  },
  requests: {
    type: BookingJobType.REQUEST,
    path: routes.transportRequests,
    icon: <AddTaskIcon />
  },
  planning: {
    type: BookingJobType.PLANNED,
    path: routes.transportPlanning,
    icon: <TodayIcon />
  },
  history: {
    type: BookingJobType.HISTORY,
    path: routes.transportHistory,
    icon: <HistoryIcon />
  }
};

export function Transport(): JSX.Element {
  const { subMenu } = useParams();
  const { pages } = useTransportBookings();
  const [activeIndex, setActiveIndex] = useState(0);
  const menuItems = Object.values(TRANSPORT_MENU_ITEMS);
  const [jobType, setBookingJobType] = useState<BookingJobType>(
    BookingJobType.TODO
  );

  useEffect(() => {
    const type: BookingJobType =
      TRANSPORT_MENU_ITEMS[subMenu as keyof DashboardMenu]?.type ||
      BookingJobType.TODO;
    setBookingJobType(type);
    setActiveIndex(menuItems.findIndex((menu) => menu.type === type));
  }, [subMenu]);

  return (
    <>
      {pages && (
        <Dashboard
          jobType={jobType}
          menuItems={menuItems}
          activeIndex={activeIndex}
          tabs={pages[jobType]?.tabs}
          bookings={pages[jobType]?.bookings}
          isLoading={pages[jobType]?.isLoading}
          macroType={BookingMacroType.TRANSPORT}
          onMenuItemClicked={setActiveIndex}
        />
      )}
    </>
  );
}
