import { Box, Typography, styled } from '@mui/material';
import { useDate } from 'src/app/hooks/useDate';

const WrapperBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start'
});
type Props = {
  isLoading?: boolean;
  label?: string;
  date: string | null;
};

function BookingLocationDate({ date, label }: Props): JSX.Element {
  const { fromIsoToDateFormat } = useDate();
  return (
    <WrapperBoxStyled>
      <Typography variant="subtitle1" color="grey.400">
        {label}
      </Typography>
      <Typography variant="h5" color={'grey.600'}>
        {date ? fromIsoToDateFormat(date) : '--'}
      </Typography>
    </WrapperBoxStyled>
  );
}

export default BookingLocationDate;
