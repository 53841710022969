import { ConvertedToObjectType, TranslationJsonType } from './types';

export const translations: ConvertedToObjectType<TranslationJsonType> =
  {} as ConvertedToObjectType<TranslationJsonType>;

export const convertLanguageJsonToObject = (
  // eslint-disable-next-line
  json: any,
  objToConvertTo = translations,
  current?: string
) => {
  Object.keys(json).forEach((key) => {
    const currentLookupKey = current ? `${current}.${key}` : key;
    if (typeof json[key] === 'object') {
      objToConvertTo[key] = {};
      convertLanguageJsonToObject(
        json[key],
        objToConvertTo[key],
        currentLookupKey
      );
    } else {
      objToConvertTo[key] = currentLookupKey;
    }
  });
};

export enum LanguageType {
  en = 'English',
  fr = 'Français'
}

export const convertLanguageToString = (key: string) => {
  return LanguageType[key?.split('-')[0] as keyof typeof LanguageType];
};
