import styles from '@convelio/stylio/styles.module.scss';
import { Box, Grid, GridProps, styled } from '@mui/material';

export const GridRowStyled = styled((props: GridProps) => (
  <Grid container direction="row" {...props} />
))(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  marginTop: 5,
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    gap: styles.cvoSpacingG
  }
}));

export const GridColumnContactStyled = styled((props: GridProps) => (
  <Grid item xs={6} {...props} />
))({});

export const GridColumnAddressStyled = styled((props: GridProps) => (
  <Grid item xs={6} {...props} />
))({
  display: 'flex',
  flexDirection: 'column'
});

export const FlagBoxStyled = styled(Box)({
  marginTop: 8,
  marginRight: 5
});
