import AdapterDateFns from '@date-io/date-fns';
import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';

import { useDate } from '../../hooks/useDate';

type Props = {
  date?: Date | null;
  minDate?: Date;
  maxDate?: Date;
  handleDateChange: (newValue: Date | null) => void;
  datatestid?: string;
  validateInputDate: (date: Date) => void;
};

function DatePickerInput({
  date,
  minDate,
  maxDate,
  validateInputDate,
  handleDateChange,
  datatestid
}: Props): JSX.Element {
  const { dateFormat } = useDate();
  const [value, setValue] = useState<Date | null>(new Date());

  useEffect(() => {
    if (date) setValue(date);
  }, [date]);

  const handleChange = (newValue: Date | null) => {
    if (newValue) {
      newValue.setHours(0, 0, 0, 0);
      validateInputDate(newValue);
    }
    setValue(newValue);
    handleDateChange(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={value}
        disableMaskedInput={true}
        inputFormat={dateFormat}
        onChange={(newValue) => handleChange(newValue)}
        renderInput={(params) => {
          return (
            <TextField
              size="small"
              sx={{
                paddingRight: 17,
                width: '70%'
              }}
              {...params}
              inputProps={{
                ...params.inputProps,
                'data-testid': datatestid ?? 'date-picker-input'
              }}
            />
          );
        }}
        maxDate={maxDate}
        minDate={minDate}
      />
    </LocalizationProvider>
  );
}

export default DatePickerInput;
