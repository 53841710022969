import { BookingItem, LinkedBooking } from 'src/app/types/Booking';

import DepotDesktopView from '../Pages/DepotBooking/DepotDesktopView';
import DepotMobileTabletView from '../Pages/DepotBooking/DepotMobileTabletView';
import PackingDesktopView from '../Pages/PackingBooking/PackingDesktopView';
import PackingMobileTabletView from '../Pages/PackingBooking/PackingMobileTabletView';
import TransportDesktopView from '../Pages/TransportBooking/TransportDesktopView';
import TransportMobileTabletView from '../Pages/TransportBooking/TransportMobileTabletView';

export type BookingPageProps = {
  isLoading?: boolean;
  version: number;
  booking: BookingItem;
  next?: LinkedBooking;
  previous?: LinkedBooking;
};
export type BookingPage = {
  mobileTabletView: (props: BookingPageProps) => JSX.Element;
  desktopView: (props: BookingPageProps) => JSX.Element;
};

export type BookingPageRegister = {
  depot: BookingPage;
  packing: BookingPage;
  transport: BookingPage;
};

export const getBookingPage = (type: string | undefined): BookingPage => {
  const bookingPage = BOOKING_PAGE_REGISTER[type as keyof BookingPageRegister];

  return bookingPage ? bookingPage : DEFAULT_BOOKING_PAGE;
};

const depotBookingPage: BookingPage = {
  desktopView: DepotDesktopView,
  mobileTabletView: DepotMobileTabletView
};

const transportBookingPage: BookingPage = {
  desktopView: TransportDesktopView,
  mobileTabletView: TransportMobileTabletView
};

const packingBookingPage: BookingPage = {
  desktopView: PackingDesktopView,
  mobileTabletView: PackingMobileTabletView
};

const DEFAULT_BOOKING_PAGE: BookingPage = transportBookingPage;

const BOOKING_PAGE_REGISTER: BookingPageRegister = {
  depot: depotBookingPage,
  transport: transportBookingPage,
  packing: packingBookingPage
};
