import { skipToken } from '@reduxjs/toolkit/query';
import { useMemo } from 'react';

import { useGetBookingQuery } from '../../../services/BookingApi';
import { ApiError, UUID_VALIDATION_ERROR } from '../../../types/ApiError';
import { BookingItem, LinkedBooking } from '../../../types/Booking';

interface BookingDataReturnType {
  isBookingNotFound: boolean;
  booking: BookingItem | undefined;
  bookingVersion: number;
  nextBooking: LinkedBooking | undefined;
  previousBooking: LinkedBooking | undefined;
  isLoading: boolean;
}

export const useBookingData = (
  bookingId: string | undefined
): BookingDataReturnType => {
  const {
    error,
    data: bookingResponse,
    isLoading,
    isFetching
  } = useGetBookingQuery(bookingId ?? skipToken, {
    refetchOnMountOrArgChange: true
  });

  const isBookingNotFound: boolean = useMemo(() => {
    if (isLoading) {
      return false;
    }

    if (error && 'status' in error) {
      const status = (error as ApiError).status;
      const message = (error as ApiError).data?.message;

      return (
        (status === 400 && message === UUID_VALIDATION_ERROR) || status === 404
      );
    }

    return !bookingResponse || bookingResponse.data === undefined;
  }, [bookingResponse, isLoading, error]);

  return {
    isBookingNotFound,
    booking: bookingResponse?.data,
    bookingVersion: bookingResponse?.version ?? 0,
    nextBooking: bookingResponse?.next,
    previousBooking: bookingResponse?.previous,
    isLoading: isLoading || isFetching
  };
};
