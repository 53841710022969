import { useMemo } from 'react';

interface AddressProps {
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  zipCode: string;
  country: string;
}

interface AddressReturnType {
  name: string;
  street: string;
  zipCode: string;
  city: string;
  country: string;
}

export const useAddress = ({
  name,
  addressLine1,
  addressLine2,
  city,
  zipCode,
  country
}: AddressProps): AddressReturnType => {
  return useMemo(() => {
    const street = `${addressLine1 || ''} ${addressLine2 || ''}`.trim();
    return {
      name,
      street,
      zipCode,
      city,
      country
    };
  }, [name, addressLine1, addressLine2, city, zipCode, country]);
};
