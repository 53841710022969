import { ChangeEvent, useEffect, useState } from 'react';

import { isSearchValueValid } from '../../Core/Domain/Validator/IsSearchValueValid';

interface SearchInputReturnType {
  isResultsVisible: boolean;
  resetSearchInput: () => void;
  inputHandlers: {
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onFocus: () => void;
    onBlur: () => void;
  };
}

export const useSearchInput = (): SearchInputReturnType => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isResultsVisible, setIsResultsVisible] = useState(false);

  useEffect((): void => {
    setIsResultsVisible(isSearchValueValid(inputValue));
  }, [inputValue]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value);
  };

  const handleInputFocus = (): void => {
    if (isSearchValueValid(inputValue)) setIsResultsVisible(true);
  };

  const handleInputBlur = (): void => {
    setIsResultsVisible(false);
  };

  const resetSearchInput = (): void => {
    setInputValue('');
  };

  return {
    isResultsVisible,
    resetSearchInput,
    inputHandlers: {
      value: inputValue,
      onChange: handleInputChange,
      onFocus: handleInputFocus,
      onBlur: handleInputBlur
    }
  };
};
