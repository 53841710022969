import { BookingItem } from '../../../../types/Booking';
import { BookingPdfDownloadLink } from '../BookingPdfDownloadLink';
import { PackingBookingDocument } from './PackingBookingDocument';

interface PackingBookingPdfDownloadLinkProps {
  booking: BookingItem;
}

export const PackingBookingPdfDownloadLink = ({
  booking
}: PackingBookingPdfDownloadLinkProps) => {
  return (
    <BookingPdfDownloadLink
      document={<PackingBookingDocument booking={booking} />}
      filename={`booking_${booking.id}.pdf`}
    />
  );
};
