import { isAfter, isBefore, isSameDay } from 'date-fns';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../../../locales/translations';
import { useDate } from '../../../../../../../hooks/useDate';

interface DeliveryDateManagementProps {
  readyForCollectionDateString: string | null;
  pickupDateString: string | null;
  etaPickupDateString: string | null;
  deliveryDateString: string | null;
  etaDeliveryDateString: string | null;
}

export interface DeliveryDateManagementReturnType {
  minDeliveryDate: Date;
  minEtaDeliveryDate: Date;
  deliveryDate: Date | null;
  etaDeliveryDate: Date | null;
  validateDeliveryDate: (date: Date) => string[];
  validateEtaDeliveryDate: (date: Date) => string[];
}

export const useDeliveryDateManagement = ({
  readyForCollectionDateString,
  pickupDateString,
  etaPickupDateString,
  deliveryDateString,
  etaDeliveryDateString
}: DeliveryDateManagementProps): DeliveryDateManagementReturnType => {
  const { t } = useTranslation();
  const { parseISODateOrNull, today } = useDate();
  const pickupDateRef = useRef<Date | null>(
    parseISODateOrNull(pickupDateString)
  );
  const readyForCollectionDateRef = useRef<Date | null>(
    parseISODateOrNull(readyForCollectionDateString)
  );
  const etaPickupDateRef = useRef<Date | null>(
    parseISODateOrNull(etaPickupDateString)
  );

  const getErrorMessage = (errorType: string) =>
    t(
      translations.pages.booking.pages.transport.features.delivery.errors[
        errorType
      ]
    );

  const etaDeliveryDate: Date | null = useMemo(() => {
    return parseISODateOrNull(etaDeliveryDateString);
  }, [etaDeliveryDateString]);

  const deliveryDate: Date | null = useMemo(
    () => parseISODateOrNull(deliveryDateString),
    [deliveryDateString]
  );

  const minEtaDeliveryDate = useMemo((): Date => {
    if (pickupDateRef.current) {
      return isBefore(pickupDateRef.current, today)
        ? today
        : pickupDateRef.current;
    }
    if (readyForCollectionDateRef.current) {
      return isBefore(readyForCollectionDateRef.current, today)
        ? today
        : readyForCollectionDateRef.current;
    }

    return today;
  }, [pickupDateRef.current, readyForCollectionDateRef.current, today]);

  const minDeliveryDate = useMemo((): Date => {
    return pickupDateRef.current ? pickupDateRef.current : minEtaDeliveryDate;
  }, [pickupDateRef.current, minEtaDeliveryDate]);

  const isReadyForCollection = (date: Date): boolean => {
    if (!readyForCollectionDateRef.current) return true;

    return (
      isAfter(date, readyForCollectionDateRef.current) ||
      isSameDay(date, readyForCollectionDateRef.current)
    );
  };

  const validateEtaDeliveryDate = (date: Date): string[] => {
    if (pickupDateRef.current) {
      return isBefore(date, pickupDateRef.current)
        ? [getErrorMessage('etaDateBeforePickupDateError')]
        : [];
    }

    if (etaPickupDateRef.current) {
      return isBefore(date, etaPickupDateRef.current)
        ? [getErrorMessage('etaDateBeforeEtaPickupDateError')]
        : [];
    }

    if (!isReadyForCollection(date)) {
      return [getErrorMessage('etaDateBeforeReadyForCollectionDateError')];
    }

    return [];
  };

  const validateDeliveryDate = (date: Date): string[] => {
    if (pickupDateRef.current) {
      return isBefore(date, pickupDateRef.current)
        ? [getErrorMessage('dateBeforePickupDateError')]
        : [];
    }

    if (etaPickupDateRef.current) {
      return isBefore(date, etaPickupDateRef.current)
        ? [getErrorMessage('dateBeforeEtaPickupDateError')]
        : [];
    }

    if (!isReadyForCollection(date)) {
      return [getErrorMessage('dateBeforeReadyForCollectionDateError')];
    }

    return [];
  };

  useEffect(() => {
    pickupDateRef.current = parseISODateOrNull(pickupDateString);
  }, [pickupDateString]);

  useEffect(() => {
    readyForCollectionDateRef.current = parseISODateOrNull(
      readyForCollectionDateString
    );
  }, [readyForCollectionDateString]);

  useEffect(() => {
    etaPickupDateRef.current = parseISODateOrNull(etaPickupDateString);
  }, [etaPickupDateString]);

  return {
    minDeliveryDate,
    minEtaDeliveryDate,
    deliveryDate,
    etaDeliveryDate,
    validateDeliveryDate: (date) => validateDeliveryDate(date),
    validateEtaDeliveryDate: (date) => validateEtaDeliveryDate(date)
  };
};
