import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../locales/translations';
import { Contact } from '../../../../../types/Contact';
import { Location } from '../../../../../types/Location';
import { useDate } from '../../../../hooks/useDate';
import { useContact } from '../../../../pages/Booking/Pages/TransportBooking/Features/hooks/useContact';
import { useLocation } from '../../../../pages/Booking/Pages/TransportBooking/Features/hooks/useLocation';
import { BookingItem } from '../../../../types/Booking';
import { LogisticsView } from '../../Page/Section/LogisticsView';
import { formatLocation } from '../../helpers/Formater';

interface DepotPickupViewProps {
  booking: BookingItem;
}

export const DepotPickupView = ({
  booking
}: DepotPickupViewProps): JSX.Element => {
  const { t } = useTranslation();
  const { formatIsoToFullDateString } = useDate();

  const pickupDate = formatIsoToFullDateString(booking.endDate);

  const pickupLocation: Location = useLocation({
    name: booking.endCompanyName,
    city: booking.endAddressCity,
    country: booking.endAddressCountry
  });

  const pickupContact: Contact = useContact({
    firstname: booking.endContactFirstname,
    lastname: booking.endContactLastname,
    emails: booking.endContactEmails,
    phones: booking.endContactPhones
  });

  const title = useMemo((): string => {
    return `${t(
      translations.pages.booking.pages.depot.features.pickup.title
    )}: ${formatLocation(pickupLocation)}`;
  }, [
    booking.endCompanyName,
    booking.endAddressCity,
    booking.endAddressCountry
  ]);

  return (
    <LogisticsView
      title={title}
      confirmTitle={t(
        translations.pages.booking.pages.depot.features.pickup.datePickerLabel
      )}
      confirmDate={pickupDate}
      contact={pickupContact}
    />
  );
};
