import { CircularProgress } from '@mui/material';
import { Box, styled } from '@mui/system';
import { FormEvent, ReactNode, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/app/components/Button';

import { translations } from '../../../../locales/translations';
import NotificationSnackbar from '../../../components/SnackBar';
import { settingFormSentMessage } from '../constants';
import { SettingItem } from '../types';
import { FormControlSettings } from './FormControlSettings';
import {
  SettingFormHelper,
  useSettingFormHelper
} from './useSettingFormHelper';

interface SettingFormProps {
  settings: SettingItem[];
  isLoading: boolean;
}

export const SettingForm = ({
  settings,
  isLoading
}: SettingFormProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    settingValues,
    handleChange,
    handleSubmit,
    isSuccess,
    isError,
    isUpdateInProgress
  }: SettingFormHelper = useSettingFormHelper(settings);

  return (
    <FormStyled onSubmit={handleSubmit} noValidate>
      {(isError || isSuccess) && (
        <NotificationSnackbar
          open={true}
          message={t(
            translations.pages.setting.form[
              isError
                ? settingFormSentMessage.error
                : settingFormSentMessage.success
            ]
          )}
          severity={isError ? 'error' : 'success'}
        />
      )}
      {!isLoading && (
        <FormControlSettings
          settings={settingValues}
          handleChange={handleChange}
        />
      )}
      <SendBlockStyled>
        <Button
          additionalClasses="cvo-mt-g"
          type="submit"
          variant="plain"
          disabled={isUpdateInProgress}
          label={t(translations.pages.setting.form.submitButton)}
          isLoading={isUpdateInProgress}
          startIcon={<CircularProgressStyled size={20} />}
        />
      </SendBlockStyled>
    </FormStyled>
  );
};

type FormBoxProps = {
  children: ReactNode;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  noValidate?: boolean;
};

const FormBox = forwardRef(function FormBox(props: FormBoxProps, ref) {
  return <Box ref={ref} component="form" {...props} />;
});

const FormStyled = styled(FormBox)({
  display: 'flex',
  flexDirection: 'column',
  gap: 30,
  padding: 30
});

const SendBlockStyled = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.primary.light}`,
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('tablet')]: {
    justifyContent: 'center'
  }
}));

const CircularProgressStyled = styled(CircularProgress)({
  color: 'white',
  marginRight: 10
});
