import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { translations } from 'src/locales/translations';
import { BookingMacroType } from 'src/types/BookingType';

import { TabItem } from '../types';
import { DashboardBookingList } from './DashboardBookingList';

type Props = {
  items: TabItem[];
  macroType: BookingMacroType;
  handleChange: (index: number) => void;
};

function DashboardTabBodyOverall({
  items,
  macroType,
  handleChange
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Box>
      {items
        .filter((item, index) => index !== 0)
        .map((item, index) => (
          <DashboardBookingList
            key={index}
            tabIndex={index + 1}
            jobType={item.type}
            macroType={macroType}
            bookings={item.value.bookings}
            isLoading={item.value.isLoading}
            handleSeeMore={handleChange}
            title={t(translations[item.type].title)}
          />
        ))}
    </Box>
  );
}

export default DashboardTabBodyOverall;
