import LineStyleIcon from '@mui/icons-material/LineStyle';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PagesIcon from '@mui/icons-material/Pages';
import { Typography, styled, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { translations } from 'src/locales/translations';
import { routes } from 'src/types/Routes';

import { DashboardCard } from '../../components/DashboardCard';
import { useComputeTotalBookings } from '../../hooks/useComputeTotalBookings';

export const MainDashboard = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const translationMainDashboard =
    translations.pages.dashboard.pages.mainDashboard;
  const { transport, packing, depot } = useComputeTotalBookings();

  return (
    <Wrapper>
      <DashboardContainer>
        <TitleSection>
          <Title>{t(translationMainDashboard.dashboard)}</Title>
        </TitleSection>
      </DashboardContainer>
      <CardSection>
        <Link to={routes.transport}>
          <DashboardCard
            title={t(translationMainDashboard.transport)}
            isLoading={transport.isLoading}
            icon={<LocalShippingIcon />}
            jobsTotal={transport.bookingsTotal}
            metric1={{
              label: t(translationMainDashboard.jobToUpdate),
              value: transport.metric1
            }}
            metric2={{
              label: t(translationMainDashboard.jobRequest),
              value: transport.metric2
            }}
            titleColor={theme.palette.common.black}
            totalItemColor={theme.palette.common.black}
          />
        </Link>
        <Link to={routes.depot}>
          <DashboardCard
            title={t(translationMainDashboard.depot)}
            isLoading={depot.isLoading}
            icon={<PagesIcon />}
            jobsTotal={depot.bookingsTotal}
            metric1={{
              label: t(translationMainDashboard.jobInHouse),
              value: depot.metric1
            }}
            metric2={{
              label: t(translationMainDashboard.jobIncoming),
              value: depot.metric2
            }}
            titleColor={theme.palette.common.black}
            totalItemColor={theme.palette.common.black}
          />
        </Link>
        <Link to={routes.packing}>
          <DashboardCard
            title={t(translationMainDashboard.packing)}
            isLoading={packing.isLoading}
            icon={<LineStyleIcon />}
            jobsTotal={packing.bookingsTotal}
            metric1={{
              label: t(translationMainDashboard.jobToUpdate),
              value: packing.metric1
            }}
            metric2={{
              label: t(translationMainDashboard.jobIncoming),
              value: packing.metric2
            }}
            titleColor={theme.palette.common.black}
            totalItemColor={theme.palette.common.black}
          />
        </Link>
      </CardSection>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.grey['100']
}));

const DashboardContainer = styled(Box)({
  margin: '24px 0 24px 64px'
});

const TitleSection = styled(Box)({
  variant: 'h1',
  height: '100%'
});

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '1rem'
}));

const CardSection = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  width: '100%',
  gap: '24px',
  padding: '0 20px'
});
