import styles from '@convelio/stylio/styles.module.scss';
import { Box, styled } from '@mui/material';

import FileTitleLoader from './FileTitleLoader';
import FileUploadTitleLoader from './FileUploadTitleLoader';

const FileMainBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: styles.cvoSpacingG
});

const FileTitleLineBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});

const FileTitleBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'left'
});

const FileTitleUploadFileBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  justifyContent: 'right'
});

const FileTitleLineColumnBoxStyled = styled(Box)({
  width: '50%'
});

const FileAccordionBoxStyled = styled(Box)({
  paddingTop: styles.cvoSpacingM,
  overflowY: 'auto'
});

type Props = {
  isLoading?: boolean;
  fileTitle?: JSX.Element;
  uploadFile?: JSX.Element;
  fileList?: JSX.Element;
  fileModal?: JSX.Element;
};

function FilesWrapper({
  isLoading,
  uploadFile,
  fileTitle,
  fileList,
  fileModal
}: Props): JSX.Element {
  return (
    <FileMainBoxStyled>
      <FileTitleLineBoxStyled>
        <FileTitleLineColumnBoxStyled>
          <FileTitleBoxStyled>
            {isLoading ? <FileTitleLoader /> : fileTitle}
          </FileTitleBoxStyled>
        </FileTitleLineColumnBoxStyled>
        <FileTitleLineColumnBoxStyled>
          <FileTitleUploadFileBoxStyled>
            {isLoading ? <FileUploadTitleLoader /> : uploadFile}
          </FileTitleUploadFileBoxStyled>
        </FileTitleLineColumnBoxStyled>
      </FileTitleLineBoxStyled>
      <FileAccordionBoxStyled>{fileList}</FileAccordionBoxStyled>
      {fileModal}
    </FileMainBoxStyled>
  );
}

export default FilesWrapper;
