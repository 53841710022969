import { BookingItem } from '../../../../types/Booking';
import { BookingPdfDownloadLink } from '../BookingPdfDownloadLink';
import { TransportBookingDocument } from './TransportBookingDocument';

interface TransportBookingPdfDownloadLinkProps {
  booking: BookingItem;
}

export const TransportBookingPdfDownloadLink = ({
  booking
}: TransportBookingPdfDownloadLinkProps) => {
  return (
    <BookingPdfDownloadLink
      document={<TransportBookingDocument booking={booking} />}
      filename={`booking_${booking.id}.pdf`}
    />
  );
};
