import { StyleSheet, View } from '@react-pdf/renderer';
import { ReactNode } from 'react';

interface TableRowProps {
  children: ReactNode;
}

export const TableRow = ({ children }: TableRowProps) => {
  return <View style={style.tableRow}>{children}</View>;
};

const style = StyleSheet.create({
  tableRow: {
    flexDirection: 'row',
    borderStyle: 'solid',
    borderTopWidth: '1px',
    borderLeftWidth: '1px'
  }
});
