import LateralPanel from 'src/app/pages/Booking/Features/LateralPanel';
import Notes from 'src/app/pages/Booking/Features/Notes';
import MobileTabletViewWrapper from 'src/app/pages/Booking/components/MobileTabletViewWrapper';
import { BookingPageProps } from 'src/app/pages/Booking/types/BookingPage';

import DepotHeader from './Features/Header/DepotHeader';
import DepotMainPanel from './Features/MainPanel/DepotMainPanel';

function DepotMobileTabletView({
  version,
  booking,
  isLoading
}: BookingPageProps): JSX.Element {
  return (
    <MobileTabletViewWrapper
      firstTab={
        <>
          <DepotHeader booking={booking} isLoading={isLoading} />
          <DepotMainPanel
            version={version}
            booking={booking}
            isLoading={isLoading}
          />
        </>
      }
      secondTab={<LateralPanel booking={booking} />}
      notes={
        <Notes version={version} booking={booking} isLoading={isLoading} />
      }
    />
  );
}

export default DepotMobileTabletView;
