import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BookingJobType, BookingPage } from '../types';

interface TabNavigatorProps {
  tabs?: BookingPage;
}

const getTabItems = (tabs: BookingPage) =>
  Object.entries(tabs).map(([type, value]) => ({
    type: type as BookingJobType,
    value
  }));

const getMatchingTabIndex = (tabs: BookingPage, anchor: string) =>
  Object.entries(tabs).findIndex(([_, value]) => value.anchor === anchor);

const getHashAnchor = () => window.location.hash.substring(1);

export const useTabNavigator = ({ tabs }: TabNavigatorProps) => {
  const navigate = useNavigate();
  const [anchor, setAnchor] = useState<string>(getHashAnchor());
  const [openedTabIndex, setOpenedTabIndex] = useState<number>(0);

  useEffect(() => {
    const handleHashChange = () => {
      setAnchor(getHashAnchor());
    };

    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  useEffect(() => {
    if (tabs === undefined) {
      setOpenedTabIndex(0);
      return;
    }

    const matchingTabIndex = getMatchingTabIndex(tabs, anchor);
    setOpenedTabIndex(matchingTabIndex === -1 ? 0 : matchingTabIndex);
  }, [anchor, tabs]);

  const handleTabChange = useCallback(
    (index: number) => {
      setOpenedTabIndex(index);

      if (tabs === undefined) return;
      const newAnchor = tabs[Object.keys(tabs)[index]].anchor;

      if (newAnchor === undefined) {
        navigate('#');
        window.location.hash = '';
        return;
      }

      navigate(`#${newAnchor}`);
      window.location.hash = `#${newAnchor}`;
    },
    [navigate, tabs]
  );

  return {
    anchor,
    openedTabIndex,
    tabIndex: getTabItems(tabs || {}),
    handleTabChange
  };
};
