import styled from '@emotion/styled';
import PagesIcon from '@mui/icons-material/Pages';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Flag from 'src/app/pages/Booking/components/Flag';
import { translations } from 'src/locales/translations';
import TitleSm from 'src/styles/components/Title/TitleSm';
import { PackType } from 'src/types/BookingType';

type Props = {
  packType: PackType;
};

const PackingTypeBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 24
});

function MerchandisePackType({ packType }: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <PackingTypeBoxStyled>
      <TitleSm color={`grey.500`} mb={12}>
        <>{t(translations.packingCondition.title)}</>
      </TitleSm>
      <Flag
        icon={<PagesIcon fontSize="small" />}
        label={t(translations.packingCondition[packType])}
      />
    </PackingTypeBoxStyled>
  );
}

export default MerchandisePackType;
