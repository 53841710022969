import { store } from '../../../../../../../../../store/store';
import { bookingApi } from '../../../../../../../../services/BookingApi';
import { BookingVersionError } from '../../Core/Domain/Error/BookingVersionError';
import { VersionRepository } from '../../Core/Domain/Repository/VersionRepository';

export class RTKQueryVersionRepository implements VersionRepository {
  public async getVersion(bookingId: string): Promise<number> {
    const result = await store.dispatch(
      bookingApi.endpoints.getVersion.initiate(bookingId)
    );

    if ('error' in result && result.error) {
      throw new BookingVersionError();
    }

    return (result as { data: { version: number } }).data.version;
  }
}
