import styles from '@convelio/stylio/styles.module.scss';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useMemo } from 'react';

import { BookingType } from '../../../../types/BookingType';
import { getMacroBookingType } from '../../../../utils/helpers/BookingHelper';

interface PageFooterProps {
  bookingType: BookingType | undefined;
}

const capitalizeWord = (macroType: string): string => {
  return macroType.charAt(0).toUpperCase() + macroType.slice(1);
};

export const FooterView = ({ bookingType }: PageFooterProps) => {
  if (!bookingType) return null;
  const bookingMacroType: string = useMemo(() => {
    const macroType = getMacroBookingType(bookingType);
    if (!macroType) return capitalizeWord(bookingType.toLowerCase());
    return capitalizeWord(macroType.toLowerCase());
  }, [bookingType]);

  return (
    <View style={style.footer}>
      <View style={style.footerText}>
        <Text>{bookingMacroType} • Planning</Text>
        <Text style={style.grey}>Convelio Partners</Text>
      </View>
      <View style={style.footerLine} />
    </View>
  );
};

const style = StyleSheet.create({
  footer: {
    flexDirection: 'column',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingBottom: styles.cvoSpacingS
  },
  footerText: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: styles.cvoSpacingS,
    paddingHorizontal: styles.cvoSpacingG
  },
  footerLine: {
    height: '1px',
    backgroundColor: styles.cvoG60,
    marginHorizontal: styles.cvoSpacingG,
    marginBottom: styles.cvoSpacingG
  },
  grey: {
    color: styles.cvoG60
  }
});
