import EditIcon from '@mui/icons-material/Edit';
import { Button } from 'src/app/components/Button';

type Props = {
  handleEdit: () => void;
};

function AccordionEdit({ handleEdit }: Props): JSX.Element {
  const handleEditClick = () => handleEdit();
  return (
    <Button
      variant="outlined"
      color="neutral"
      startIcon={<EditIcon fontSize="inherit" />}
      onClick={handleEditClick}
      additionalClasses="-icon"
    />
  );
}

export default AccordionEdit;
