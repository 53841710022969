import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../locales/translations';
import { BookingType } from '../../../../../../types/BookingType';
import { getBookingMerchandise } from '../../../../../../utils/helpers/BookingHelper';
import { MeasurementSystem } from '../../../../../pages/Setting/types';
import { BookingItem, BookingMerchandise } from '../../../../../types/Booking';

interface MerchandiseProps {
  booking: BookingItem;
  measurementSystem: MeasurementSystem;
}

interface MerchandiseReturnType {
  merchandise: BookingMerchandise;
  merchandiseDescriptionTitle: string;
}

export const useMerchandise = ({
  booking,
  measurementSystem
}: MerchandiseProps): MerchandiseReturnType => {
  const { t } = useTranslation();

  const merchandise: BookingMerchandise = useMemo(
    () => getBookingMerchandise(booking, measurementSystem),
    [booking, measurementSystem]
  );

  const merchandiseDescriptionTitle: string = useMemo(() => {
    if (BookingType.PICKUP === booking.type) {
      return t(
        translations.pages.booking.features.merchandise.description.title.pickup
      );
    }

    return t(
      translations.pages.booking.features.merchandise.description.title.default
    );
  }, [booking.type]);

  return {
    merchandise,
    merchandiseDescriptionTitle
  };
};
