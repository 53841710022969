import styles from '@convelio/stylio/styles.module.scss';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useDate } from 'src/app/hooks/useDate';
import MainDatePicker from 'src/app/pages/Booking/Features/ScheduleDate/components/MainDatePicker';
import { translations } from 'src/locales/translations';

import { Address } from '../../../../../../../../types/Address';
import WarningMessage from './WarningMessage';

interface ReadyForCollectionDateProps {
  isDone: boolean;
  editMode: boolean;
  isLoading?: boolean;
  startReadyOn: string | null;
  handleDateChange: (date?: Date) => Promise<void>;
  validateInputDate: (date: Date) => string[];
  address?: Address;
}

function ReadyForCollectionDate({
  address,
  isLoading,
  startReadyOn,
  isDone,
  editMode,
  handleDateChange,
  validateInputDate
}: ReadyForCollectionDateProps): JSX.Element {
  const { t } = useTranslation();
  const { fromIsoToDate, isDateAfterToday } = useDate();
  const translationReadyForCollectionDate =
    translations.pages.booking.features.readyForCollectionDate;
  return (
    <Box pr={16}>
      {isDateAfterToday(startReadyOn) && <WarningMessage />}
      <StyledBox>
        <MainDatePicker
          datatestid={'ready-for-collection-date'}
          address={address}
          modalTitle={t(translationReadyForCollectionDate.collectReady.title)}
          modalDescription={t(
            translationReadyForCollectionDate.collectReady.description
          )}
          minDate={new Date()}
          label={t(translationReadyForCollectionDate.confirmationButton.title)}
          isLoading={isLoading}
          isDone={isDone}
          isEditMode={editMode}
          handleSubmit={handleDateChange}
          date={startReadyOn ? fromIsoToDate(startReadyOn) : undefined}
          validateInputDate={validateInputDate}
          getTitle={() =>
            `${t(translationReadyForCollectionDate.collectReadyFrom.title)}`
          }
        />
      </StyledBox>
    </Box>
  );
}

export default ReadyForCollectionDate;

const StyledBox = styled(Box)(({ theme }) => ({
  width: '50%',
  paddingTop: 0,
  paddingBottom: 0,
  [theme.breakpoints.down('tablet')]: {
    width: '100%',
    paddingTop: styles.cvoSpacingG,
    paddingBottom: styles.cvoSpacingG
  }
}));
