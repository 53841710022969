import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/app/components/Button';
import { translations } from 'src/locales/translations';
import { BookingActionStatusType } from 'src/types/BookingType';

const WrapperBoxStyled = styled(Box)({
  display: 'flex',
  justifyContent: 'end'
});

type Props = {
  handleAction: (action: BookingActionStatusType) => void;
};

function BookingDepotAction({ handleAction }: Props): JSX.Element {
  const { t } = useTranslation();
  const actionTranslations =
    translations.pages.dashboard.pages.todo.bookingCardAction;

  return (
    <WrapperBoxStyled>
      <Button
        label={t(actionTranslations[`seeJobAction`])}
        dataTestid={`depot-see-job-button`}
        onClick={() => handleAction(BookingActionStatusType.SEE_JOB)}
      />
    </WrapperBoxStyled>
  );
}

export default BookingDepotAction;
