import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PaymentsIcon from '@mui/icons-material/Payments';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/app/components/Button';
import { useCurrency } from 'src/app/hooks/useCurrency';
import { BookingItem } from 'src/app/types/Booking';

import { translations } from '../../../../../locales/translations';
import { BookingActionStatusType } from '../../../../../types/BookingType';
import NotificationSnackbar from '../../../../components/SnackBar';
import { useChangeStatusMutation } from '../../../../services/BookingApi';

type Props = {
  version?: number;
  booking: BookingItem;
};

function AcceptJob({ booking, version }: Props): JSX.Element {
  const { t } = useTranslation();
  const { getFormattedBudget } = useCurrency();

  const [accept, setAccept] = useState<boolean>(false);
  const [changeStatus, { isError, isSuccess }] = useChangeStatusMutation();

  const handleAcceptJob = async (value: boolean) => {
    if (version === undefined || !booking?.bookingNumber) return;
    setAccept(value);
    try {
      await changeStatus({
        accept: value,
        version: version,
        bookingId: booking.id,
        action: BookingActionStatusType.ACCEPT
      }).unwrap();
    } catch (e) {
      console.debug(e);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'grey.100',
        paddingLeft: '4%',
        paddingRight: 24,
        paddingY: '0.5rem'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          textAlign: 'left',
          width: '60%'
        }}
      >
        <PaymentsIcon />
        <Typography
          sx={{
            marginLeft: 9,
            lineHeight: '1.3rem',
            fontWeight: 600,
            color: `grey.600`
          }}
        >
          {`${t(translations.budgetedJob.title)} ${getFormattedBudget({
            budgetEur: booking.budgetEur,
            budgetUsd: booking.budgetUsd,
            budgetGbp: booking.budgetGbp
          })}`}
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'right', width: '80%' }}>
        <Button
          dataTestid="btn-accept-job"
          variant="plain"
          label={t(translations.acceptJob.title)}
          startIcon={<CheckCircleIcon fontSize="inherit" />}
          onClick={() => handleAcceptJob(true)}
        />
      </Box>
      {isError && (
        <NotificationSnackbar
          open={true}
          message={
            accept
              ? t(translations.acceptedJob.failedAcceptMessage)
              : t(translations.acceptedJob.failedDeniedMessage)
          }
          severity={'error'}
        />
      )}
      {isSuccess && (
        <NotificationSnackbar
          open={true}
          message={
            accept
              ? t(translations.acceptedJob.successAcceptMessage)
              : t(translations.acceptedJob.successDeniedMessage)
          }
          severity={'success'}
        />
      )}
    </Box>
  );
}

export default AcceptJob;
