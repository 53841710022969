import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { translations } from '../../../locales/translations';
import { Button } from '../Button';

function ErrorPageBackToHomeButton(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  return (
    <Button
      additionalClasses="cvo-mt-g"
      onClick={handleClick}
      variant="plain"
      label={t(translations.components.backToHomeButton)}
    />
  );
}

export default ErrorPageBackToHomeButton;
