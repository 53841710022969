import { useMemo } from 'react';

interface LocationProps {
  name: string;
  city: string;
  country: string;
}

interface LocationReturnType {
  name: string;
  city: string;
  country: string;
}

export const useLocation = ({
  name,
  city,
  country
}: LocationProps): LocationReturnType => {
  return useMemo(() => {
    return {
      name,
      city,
      country
    };
  }, [name, city, country]);
};
