import LateralPanel from '../../Features/LateralPanel';
import Notes from '../../Features/Notes';
import DesktopViewWrapper from '../../components/DesktopViewWapper';
import { BookingPageProps } from '../../types/BookingPage';
import TransportHeader from './Features/Header/TransportHeader';
import TransportMainPanel from './Features/MainPanel/TransportMainPanel';

function TransportDesktopView({
  version,
  booking,
  isLoading,
  next,
  previous
}: BookingPageProps): JSX.Element {
  return (
    <DesktopViewWrapper
      header={
        <TransportHeader
          next={next}
          previous={previous}
          booking={booking}
          isLoading={isLoading}
        />
      }
      mainPanel={
        <TransportMainPanel
          version={version}
          booking={booking}
          isLoading={isLoading}
        />
      }
      lateralPanel={<LateralPanel booking={booking} />}
      notes={
        <Notes version={version} booking={booking} isLoading={isLoading} />
      }
    />
  );
}

export default TransportDesktopView;
