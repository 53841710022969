import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'src/locales/translations';

import { Button } from '../Button';

type Props = {
  title: string;
  isOpen: boolean;
  message?: string;
  handleResponse: (response: boolean) => void;
};

function AlertDialog({ title, isOpen, message, handleResponse }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = (response: boolean) => {
    handleResponse(response);
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle variant="h4">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose(false)}
            autoFocus
            label={t(translations.cancel.title)}
          />
          <Button
            onClick={() => handleClose(true)}
            autoFocus
            label={t(translations.confirm.title)}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AlertDialog;
