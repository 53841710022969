import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { translations } from 'src/locales/translations';

function WarningMessage(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'grey.100',
        color: 'grey.600',
        paddingX: 13.67,
        paddingY: 8,
        marginBottom: 12
      }}
    >
      <ErrorOutlineIcon fontSize="small" />
      <Typography
        sx={{
          fontWeight: 600,
          lineHeight: '1.33rem',
          paddingLeft: 9.67
        }}
      >
        {t(
          translations.pages.booking.features.readyForCollectionDate
            .warningMessage.title
        )}
      </Typography>
    </Box>
  );
}

export default WarningMessage;
