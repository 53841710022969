import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../locales/translations';
import { CurrencyType } from '../../../../../../types/BookingType';
import { useCurrency } from '../../../../../hooks/useCurrency';

type Props = {
  value: number;
  currencyType: CurrencyType | undefined;
};

function MerchandiseValue(props: Props): JSX.Element {
  const { value, currencyType } = props;
  const { t } = useTranslation();
  const { getFormattedAmount } = useCurrency();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '0.2rem'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: '0.2rem'
        }}
      >
        <Typography
          data-testid="merchandise-value"
          sx={{
            lineHeight: '1.36rem',
            fontWeight: 600,
            color: `grey.500`,
            textAlign: 'left'
          }}
        >
          {t(translations.pages.booking.features.merchandise.value)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Typography
          sx={{
            lineHeight: '1.36rem',
            textAlign: 'left',
            fontSize: '0.9rem'
          }}
        >
          {getFormattedAmount(value, currencyType)}
        </Typography>
      </Box>
    </Box>
  );
}

export default MerchandiseValue;
