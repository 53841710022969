import EditIcon from '@mui/icons-material/Edit';
import { Grid, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/app/components/Button';
import { translations } from 'src/locales/translations';
import { PackingCrate, PackingCrateStatus } from 'src/types/Packing';

import { usePackingCrateDimensions } from '../hooks/usePackingCrateDimensions';

const MainGridContainerStyled = styled(Grid)({
  backgroundColor: 'white',
  borderWidth: 1,
  borderStyle: 'dashed',
  borderColor: '#535E80',
  paddingTop: 16,
  paddingBottom: 16,
  paddingLeft: 12,
  paddingRight: 12,
  borderRadius: '4px',
  marginTop: 16
});

const GridItemColumnStyled = styled(Grid)({
  display: 'flex',
  flexDirection: 'column'
});

const GridItemRowEndStyled = styled(Grid)({
  display: 'flex',
  justifyContent: 'end'
});

const BoxQuantityStyled = styled(Grid)({
  display: 'flex',
  flexDirection: 'row'
});

type Props = {
  index?: number;
  isDone: boolean;
  isBeingEdited: boolean;
  crate: PackingCrate;
  handleClick: () => void;
  packLoadVolume?: number | null;
};

function PackingCrateSummary({
  index,
  isDone,
  crate,
  isBeingEdited,
  handleClick,
  packLoadVolume
}: Props): JSX.Element {
  const { t } = useTranslation();
  const translationPacking = translations.pages.booking.pages.packing;
  const isConfirmation = crate.status === PackingCrateStatus.CONFIRMED;

  const packingCrateDimensions = usePackingCrateDimensions(
    crate,
    isConfirmation
  );

  return (
    <MainGridContainerStyled container direction={'row'}>
      <GridItemColumnStyled item xs={3}>
        <BoxQuantityStyled>
          <Typography
            data-testid={
              index !== undefined ? `crate-quantity-${index}` : 'crate-quantity'
            }
            variant="h5"
            color={isDone ? 'grey.400' : 'grey.500'}
            mr={4}
          >
            {`${crate.quantity}x`}
          </Typography>
          <Typography
            variant="h5"
            color={isDone ? 'grey.500' : 'primary.dark'}
            data-testid={
              index !== undefined ? `crate-name-${index}` : 'crate-name'
            }
          >
            {t(translationPacking.crate.name)}
          </Typography>
        </BoxQuantityStyled>

        <Typography
          variant="body2"
          color={'grey.500'}
          data-testid={
            index !== undefined
              ? `crate-dimensions-${index}`
              : 'crate-dimensions'
          }
        >
          {packingCrateDimensions}
        </Typography>
      </GridItemColumnStyled>
      <Grid item xs={packLoadVolume ? 4 : 8} color={'grey.500'}>
        {crate.name}
      </Grid>
      {!!packLoadVolume && (
        <Grid item xs={4} color={'grey.500'}>
          <Typography variant="body2" color={'grey.500'}>
            {t(translationPacking.totalVolume)}
          </Typography>
          <Typography variant="h6" color={'grey.500'}>
            {`${packLoadVolume}cbm`}
          </Typography>
        </Grid>
      )}

      <GridItemRowEndStyled item xs={1}>
        {isBeingEdited && (
          <Button
            color="neutral"
            variant="outlined"
            startIcon={<EditIcon fontSize="inherit" />}
            onClick={() => handleClick()}
            additionalClasses="-icon"
          />
        )}
      </GridItemRowEndStyled>
    </MainGridContainerStyled>
  );
}

export default PackingCrateSummary;
