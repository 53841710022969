import styles from '@convelio/stylio/styles.module.scss';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../locales/translations';

function RecoverPasswordMessage(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        textAlign: 'start',
        width: (theme) => theme.spacing(428)
      }}
    >
      <Typography
        sx={{
          fontSize: styles.cvoTitleMdFontSize,
          lineHeight: '1.86rem',
          fontWeight: 700
        }}
      >
        {t(translations.recoveryPassword.title)}?
      </Typography>
      <Typography
        sx={{
          lineHeight: '1.86rem',
          fontWeight: 400,
          marginTop: 24
        }}
      >
        {t(translations.recoveryPassword.description)}?
      </Typography>
    </Box>
  );
}

export default RecoverPasswordMessage;
