import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './en/translation.json';
import fr from './fr/translation.json';
import { convertLanguageJsonToObject } from './translations';

export const translationsJson = {
  en: {
    translation: en
  },
  fr: {
    translation: fr
  }
};

convertLanguageJsonToObject(en);

export const i18n = i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: translationsJson,
    fallbackLng: 'en',
    debug:
      process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
    interpolation: {
      escapeValue: false
    }
  });
