import styles from '@convelio/stylio/styles.module.scss';
import { Box, styled } from '@mui/material';
import { useMemo } from 'react';
import { BookingItem } from 'src/app/types/Booking';
import { getBookingMerchandise } from 'src/utils/helpers/BookingHelper';

import { SettingsHook, useSettings } from '../../../Setting/useSettings';
import MerchandiseDescription from './components/MerchandiseDescription';
import MerchandiseLoader from './components/MerchandiseLoader';
import MerchandiseMeasurement from './components/MerchandiseMeasurement';
import MerchandisePackType from './components/MerchandisePackType';
import MerchandisePaper from './components/MerchandisePaper';
import MerchandiseTitle from './components/MerchandiseTitle';
import MerchandiseValue from './components/MerchandiseValue';

interface MerchandiseProps {
  isLoading?: boolean;
  booking: BookingItem;
}

function Merchandise({ isLoading, booking }: MerchandiseProps): JSX.Element {
  const { measurementSystem }: SettingsHook = useSettings();
  const merchandise = useMemo(
    () => getBookingMerchandise(booking, measurementSystem),
    [booking, measurementSystem]
  );

  return (
    <MerchandiseMainStyled>
      {isLoading ? (
        <MerchandiseLoader />
      ) : (
        <>
          <MerchandiseTitle
            packingCondition={merchandise?.packingCondition}
            bookingType={booking.type}
          />
          <MerchandisePaper>
            <MerchandisePaperBoxStyled>
              <MerchandiseDescription
                merchandise={merchandise}
                bookingType={booking.type}
              />

              {merchandise?.packLoadVolume && merchandise?.packLoadWeight ? (
                <MerchandiseMeasurement
                  volume={merchandise.packLoadVolume}
                  weight={merchandise.packLoadWeight}
                />
              ) : null}
              {merchandise?.value && (
                <MerchandiseValue
                  value={merchandise.value}
                  currencyType={merchandise?.currency}
                />
              )}
            </MerchandisePaperBoxStyled>
          </MerchandisePaper>
          {booking.packType && (
            <MerchandisePackType packType={booking.packType} />
          )}
        </>
      )}
    </MerchandiseMainStyled>
  );
}

export default Merchandise;

const MerchandiseMainStyled = styled(Box)({
  height: '100%',
  marginBottom: styles.cvoSpacingXxl
});

const MerchandisePaperBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});
