import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../locales/translations';
import { DeliveryMethodType } from '../../../../../types/BookingType';
import { DELIVERY_METHOD_VAN } from '../../../../types/booking/DeliveryMethod';
import HeaderCategory from './HeaderCategory';
import HeaderChip from './HeaderChip';

const WrapperBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  flexWrap: 'wrap'
});
type Props = {
  deliveryMethod: DeliveryMethodType;
  customerRef: string;
  orderAccountName: string;
};

function HeaderFlags({
  deliveryMethod,
  customerRef,
  orderAccountName
}: Props): JSX.Element {
  const { t } = useTranslation();
  const isDeliveryMethodVan = DELIVERY_METHOD_VAN.includes(deliveryMethod);
  return (
    <WrapperBoxStyled>
      {isDeliveryMethodVan && (
        <HeaderChip
          label={t(translations.deliveryMethod[deliveryMethod])}
          color={'warning'}
        />
      )}
      {customerRef && (
        <HeaderCategory
          label={`${orderAccountName?.toUpperCase()} - ${customerRef?.toLocaleUpperCase()}`}
        />
      )}
    </WrapperBoxStyled>
  );
}

export default HeaderFlags;
