import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

interface FinancialSeparatorProps {
  variant?: Variant;
}

export const FinancialSeparator = ({
  variant = 'h3'
}: FinancialSeparatorProps): JSX.Element => {
  return (
    <Typography
      component="span"
      variant={variant}
      data-testid="financial-separator"
    >
      &nbsp;/&nbsp;
    </Typography>
  );
};
