import { Box } from '@mui/system';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';

import RecoveryPasswordButton from '../../../components/RecoveryPasswordButton';
import useRecoveryPasswordResolver from '../hooks/useRecoveryPasswordResolver';
import { FormData, Resolver } from '../types';
import { RecoverPasswordConfirmInput } from './RecoverPasswordConfirmInput';
import { RecoverPasswordInput } from './RecoverPasswordInput';

type Props = {
  email: string;
  token: string;
  onFormSubmit: (password: string) => void;
};

export const RecoverPasswordForm: FunctionComponent<Props> = ({
  email,
  token,
  onFormSubmit
}): JSX.Element => {
  const resolver: Resolver = useRecoveryPasswordResolver();

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid, errors }
  } = useForm<FormData>({
    mode: 'onChange',
    resolver
  });

  return (
    <form
      onSubmit={handleSubmit((data: FormData) =>
        onFormSubmit(data.confirmPassword)
      )}
      data-testid="recover-password-form"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 16,
          width: (theme) => theme.spacing(428),
          px: {
            xs: '4%',
            sm: 2
          }
        }}
      >
        <RecoverPasswordInput
          control={control}
          errorMessage={errors.newPassword?.message}
        />
        <RecoverPasswordConfirmInput
          control={control}
          errorMessage={errors.confirmPassword?.message}
        />
        {email && token && (
          <RecoveryPasswordButton
            data-testid="recovery-password-button"
            type="submit"
            disabled={!isDirty || !isValid}
          />
        )}
      </Box>
    </form>
  );
};
