import InfoIcon from '@mui/icons-material/Info';
import { Alert, AlertProps, styled } from '@mui/material';

const AlertStyled = styled(
  ({ error, ...props }: AlertProps & { error?: boolean }) => (
    <Alert
      {...props}
      icon={
        <InfoIcon
          fontSize="small"
          sx={{ color: error ? 'error.dark' : 'grey.500' }}
        />
      }
      sx={{
        backgroundColor: error ? 'error.light' : 'grey.100',
        color: error ? 'error.dark' : 'grey.500'
      }}
    />
  )
)({ alignItems: 'center', height: 'fit-content', width: 'fit-content' });

type Props = {
  message: string;
  error?: boolean;
};
function AccordionMessage({ message, error }: Props): JSX.Element {
  return <AlertStyled error={error}>{message}</AlertStyled>;
}

export default AccordionMessage;
