import { Document } from '@react-pdf/renderer';

import { BookingStatusType } from '../../../../../types/BookingType';
import { PACKING_STATUS_ENABLED_PICKUP } from '../../../../../types/Packing';
import { BookingItem } from '../../../../types/Booking';
import { ConvelioLayoutPage } from '../../Page';
import { MerchandiseView } from '../../Page/Section/Merchandise/MerchandiseView';
import { PackingConfirmationView } from './PackingConfirmationView';
import { PackingDropOffView } from './PackingDropOffView';
import { PackingEstimationView } from './PackingEstimationView';
import { PackingPickupView } from './PackingPickupView';

interface PackingBookingDocumentProps {
  booking: BookingItem;
}

export const PackingBookingDocument = ({
  booking
}: PackingBookingDocumentProps): JSX.Element => {
  const confirmedCrates = booking.crates?.filter((crate) => crate.weight !== 0);

  return (
    <Document>
      <ConvelioLayoutPage booking={booking}>
        <PackingDropOffView booking={booking} />
        {booking.status !== BookingStatusType.PENDING && (
          <PackingEstimationView booking={booking} />
        )}
      </ConvelioLayoutPage>
      {confirmedCrates && confirmedCrates.length > 0 && (
        <ConvelioLayoutPage booking={booking}>
          <PackingConfirmationView
            booking={booking}
            confirmedCrates={confirmedCrates}
          />
          {PACKING_STATUS_ENABLED_PICKUP.includes(booking.status) && (
            <PackingPickupView booking={booking} />
          )}
        </ConvelioLayoutPage>
      )}
      <ConvelioLayoutPage booking={booking}>
        <MerchandiseView booking={booking} />
      </ConvelioLayoutPage>
    </Document>
  );
};
