import { Grid, styled } from '@mui/material';

const GridMainStyled = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  height: '100%'
});

const GridLateralStyled = styled(Grid)({
  height: '100%',
  backgroundColor: 'white',
  padding: [24, 0, 24, 16]
});

type Props = {
  header: JSX.Element;
  mainPanel: JSX.Element;
  lateralPanel: JSX.Element;
  notes?: JSX.Element;
};

function DesktopViewWrapper({
  header,
  mainPanel,
  lateralPanel,
  notes
}: Props): JSX.Element {
  return (
    <Grid container direction="row" justifyContent="start" height="100%">
      <GridMainStyled
        data-testid="desktop-main-panel"
        item
        xs={9}
        className="scrollable"
      >
        {header}
        {mainPanel}
      </GridMainStyled>
      <GridLateralStyled item xs={3}>
        {lateralPanel}
        {notes}
      </GridLateralStyled>
    </Grid>
  );
}

export default DesktopViewWrapper;
