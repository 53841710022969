import { BuilderDecisionParams } from '../BuilderDecisionParams';
import { EventMessageBuilder } from '../EventMessageBuilder';
import { useMainMessageBuilders } from './useMainMessageBuilders';

export const useMainMessageBuilder = (params: BuilderDecisionParams) => {
  const builders: EventMessageBuilder[] = useMainMessageBuilders();

  const build = (): string => {
    for (const builder of builders) {
      if (!builder.supports(params)) {
        continue;
      }

      return builder.build(params.eventResult.data);
    }

    console.warn('No builder found for params', params);

    return '';
  };

  return {
    build
  };
};
