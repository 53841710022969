import { useState } from 'react';

import { PackingCrate } from '../../../../../../../../types/Packing';
import { AddDefaultCrateRequest } from '../Core/Application/AddDefaultCrateRequest';
import { useAddDefaultCrateUseCase } from '../Infrastructure/DefaultCrateContext';

export const useAddDefaultCrate = ({
  bookingId,
  status,
  measureSystem
}: AddDefaultCrateRequest) => {
  const addDefaultCrateUseCase = useAddDefaultCrateUseCase();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newCrate, setNewCrate] = useState<PackingCrate | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const addDefaultCrate = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const result = await addDefaultCrateUseCase.execute({
        bookingId,
        status,
        measureSystem
      });
      setNewCrate(result);
      setError(null);
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      } else {
        setError(new Error('An unexpected error occurred'));
      }
      setNewCrate(null);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    addDefaultCrate,
    newCrate
  };
};
