import { Box, Typography } from '@mui/material';

type Props = {
  category: string;
  children: JSX.Element;
};
function AccordionFileCategory({ category, children }: Props): JSX.Element {
  return (
    <Box
      key={category}
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography
        sx={{
          fontWeight: '600',
          fontSize: '0.93rem',
          lineHeight: '1.6rem'
        }}
      >
        {category}
      </Typography>
      {children}
    </Box>
  );
}

export default AccordionFileCategory;
