import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { styled } from '@mui/system';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../locales/translations';
import { transformDateFormat } from '../SettingHelper';
import { settingName } from '../constants';
import { SettingItem } from '../types';

interface FormControlSettingItemProps {
  setting: SettingItem;
  handleChange: (event: SelectChangeEvent, settingId: string) => void;
  date?: Date;
}

export const FormControlSettingItem = ({
  setting,
  handleChange,
  date
}: FormControlSettingItemProps): JSX.Element => {
  const { t } = useTranslation();
  const settingNameLowerCase: string = setting.name.toLowerCase();

  const settingNameTranslated: string = t(
    translations.pages.setting.general[settingNameLowerCase]
  );

  const settingNameFormatted = useMemo<string>(
    (): string => settingNameLowerCase.replaceAll(/_/g, '-'),
    [settingNameLowerCase]
  );

  const labelId = `${settingNameFormatted}-select-label`;

  const formatDate = (possibleDate: string) => {
    if (!date) {
      throw new Error('Date is not defined');
    }

    return `${possibleDate} (${format(
      date,
      transformDateFormat(possibleDate)
    )})`;
  };

  return (
    <FormControlStyled data-testid="setting-item" key={setting.id}>
      <InputLabel data-testid="setting-item-label" id={labelId}>
        {settingNameTranslated}
      </InputLabel>
      <Select
        labelId={labelId}
        id={`${settingNameFormatted}-select`}
        value={setting.value}
        label={settingNameTranslated}
        onChange={(event) => handleChange(event, setting.id)}
      >
        {setting.possibleValues.map((possibleValue: string) => (
          <MenuItem key={possibleValue} value={possibleValue}>
            {setting.name === settingName.dateFormat && date
              ? formatDate(possibleValue)
              : t(
                  translations.pages.setting.general[
                    `${settingNameLowerCase}_options`
                  ][possibleValue.toLowerCase()]
                )}
          </MenuItem>
        ))}
      </Select>
    </FormControlStyled>
  );
};

const FormControlStyled = styled(FormControl)(({ theme }) => ({
  width: 300,
  [theme.breakpoints.down('tablet')]: {
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));
