import { Box, Typography } from '@mui/material';

type Props = {
  dimension: string;
};

const MerchandiseDimension = ({ dimension }: Props): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column'
    }}
  >
    <Typography
      sx={{
        lineHeight: '1.36rem',
        textAlign: 'left',
        fontSize: '0.875rem'
      }}
    >
      {dimension}
    </Typography>
  </Box>
);

export default MerchandiseDimension;
