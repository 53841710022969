import { useNavigate } from 'react-router-dom';

import { BookingListItem } from '../../../../../../types/Booking';
import { routes } from '../../../../../../types/Routes';
import { BookingNumber } from '../../../../../components/BookingNumber';
import CustomerRef from '../../../components/CustomerRef';
import DashboardBookingCardWrapper from '../../../components/DashboardBookingCardWrapper';
import { BookingJobType } from '../../../types';
import { BookingPackingAction } from './BookingPackingAction';
import { BookingPackingFlags } from './BookingPackingFlags';
import { BookingPackingLogistic } from './BookingPackingLogistic';

interface BookingPackingCardProps {
  isLoading?: boolean;
  jobType: BookingJobType;
  booking: BookingListItem;
}

export const BookingPackingCard = ({
  isLoading,
  booking,
  jobType
}: BookingPackingCardProps): JSX.Element => {
  const navigate = useNavigate();

  const generateBookingLink = (bookingId: string): string => {
    return `${routes.packingBooking}/${bookingId}`;
  };

  const handleAction = (): void => {
    navigate(generateBookingLink(booking?.id));
  };

  const getPackingFlags = (): JSX.Element | undefined => {
    if (booking && booking.packType !== null) {
      return <BookingPackingFlags packType={booking.packType} />;
    }
  };

  return (
    <DashboardBookingCardWrapper
      isLoading={isLoading}
      bookingNumber={<BookingNumber bookingNumber={booking?.bookingNumber} />}
      flags={getPackingFlags()}
      customerRef={<CustomerRef booking={booking} />}
      logistic={<BookingPackingLogistic booking={booking} />}
      actions={
        <BookingPackingAction
          handleAction={handleAction}
          jobType={jobType}
          bookingType={booking?.type}
          bookingStatus={booking?.status}
        />
      }
      link={generateBookingLink(booking?.id)}
    />
  );
};
