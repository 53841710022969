import { useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationSnackbar from 'src/app/components/SnackBar';
import { useDate } from 'src/app/hooks/useDate';
import MainDatePicker from 'src/app/pages/Booking/Features/ScheduleDate/components/MainDatePicker';
import StepBody from 'src/app/pages/Booking/components/StepBody';
import StepWrapper from 'src/app/pages/Booking/components/StepWrapper';
import { useStep } from 'src/app/pages/Booking/hooks/useStep';
import { BookingItem } from 'src/app/types/Booking';
import { translations } from 'src/locales/translations';
import {
  BookingActionStatusType,
  BookingStatusType
} from 'src/types/BookingType';
import { LevelType } from 'src/types/LevelType';
import { DEFAULT_BOOKING_DEPOT_COMPLETED } from 'src/utils/helpers/BookingHelper';

import { useBookingAction } from '../../../TransportBooking/Features/hooks/useBookingAction';

type Props = {
  version: number;
  isLoading?: boolean;
  booking: BookingItem;
};

function DepotPickup({ booking, version, isLoading }: Props): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleChangeStatus, bookingAction, isSuccess, isError } =
    useBookingAction({ booking, version });

  const { fromIsoToDate, fromIsoToDateFormat } = useDate();
  const { getEndContact, getEndLocation } = useStep();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  useEffect(() => {
    setIsEditMode(booking.status === BookingStatusType.IN_PROGRESS);
  }, [booking]);

  const handlePickup = async (date?: Date): Promise<void> => {
    await handleChangeStatus(BookingActionStatusType.SET_END_DATE, date);
  };

  const pickupDate = useMemo((): Date | null => {
    return booking.endDate ? fromIsoToDate(booking.endDate) : null;
  }, [booking.endDate]);

  return (
    <>
      <StepWrapper>
        <StepBody
          isLoading={isLoading}
          title={{
            stepBadge: 'B',
            stepName: 'Pickup',
            color: theme.palette.grey['400'],
            location: getEndLocation(booking)
          }}
          contact={{
            value: getEndContact(booking),
            hideContact: booking.status === BookingStatusType.NEW
          }}
          flags={
            booking.endExpectedOn &&
            !DEFAULT_BOOKING_DEPOT_COMPLETED.includes(booking.status)
              ? [
                  {
                    level:
                      booking.status === BookingStatusType.IN_PROGRESS
                        ? LevelType.SUCCESS
                        : undefined,
                    label: `${t(
                      translations.pages.booking.pages.depot.features.pickup
                        .etaFlag
                    )}: ${fromIsoToDateFormat(booking.endExpectedOn)}`
                  }
                ]
              : undefined
          }
          scheduleDate={
            booking.status !== BookingStatusType.PENDING ? (
              <MainDatePicker
                isLoading={isLoading}
                isEditMode={isEditMode}
                handleSubmit={handlePickup}
                datatestid={`confirm-depot-pickup-date`}
                date={pickupDate}
                getTitle={() =>
                  t(
                    translations.pages.booking.pages.depot.features.pickup
                      .datePickerLabel
                  )
                }
                label={t(
                  translations.pages.booking.pages.depot.features.pickup
                    .buttonLabel
                )}
                modalTitle={t(
                  translations.pages.booking.features.dateModal.confirm.pickup
                )}
                modalDescription={t(
                  translations.pages.booking.features.dateModal.pickup
                )}
              />
            ) : undefined
          }
        />
      </StepWrapper>
      {bookingAction && (isSuccess || isError) && (
        <NotificationSnackbar
          open={true}
          message={t(
            translations.pages.booking.pages.depot.features.pickup[
              bookingAction
            ]?.[isError ? 'failedMessage' : 'successMessage']
          )}
          severity={isError ? 'error' : 'success'}
        />
      )}
    </>
  );
}

export default DepotPickup;
