import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  Box,
  BoxProps,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AlertDialog from 'src/app/components/AlertDialog';
import BasicInput from 'src/app/components/BasicInput';
import { settingName } from 'src/app/pages/Setting/constants';
import { selectSettings } from 'src/app/slices/Auth';
import { translations } from 'src/locales/translations';
import { useAppSelector } from 'src/store/hooks';
import { FileCategoryType } from 'src/types/FileCategoryType';
import {
  CrateForm,
  CrateFormFile,
  PackingCrate,
  PackingCrateOperation
} from 'src/types/Packing';

import { BookingStatusType } from '../../../../../../../../types/BookingType';
import { Button } from '../../../../../../../components/Button/Button';
import { useCrates } from '../../../hooks/useCrates';
import AccordionMessage from '../../AccordionMessage';
import PackingCratePicture from '../../PackingCratePicture';
import PackingCrateSummary from '../../PackingCrateSummary';
import { DefaultCrateProvider } from '../Infrastructure/DefaultCrateContext';
import { useAddDefaultCrate } from './useAddDefaultCrate';

type Props = {
  bookingId: string;
  isDone: boolean;
  subTitle: string;
  formKeys: string[];
  isLoading?: boolean;
  isBeingEdited: boolean;
  packLoadVolume?: number | null;
  formType: PackingCrateOperation;
  crates: CrateForm[] | null | undefined;
  handleCrateFileDelete?: (fileId: string) => void;
  handleCrateFormValid?: (crates: CrateForm[]) => void;
  handleDeleteCrate: (crate: PackingCrate) => Promise<void>;
  handleConfirmCrate: (crate: PackingCrate) => Promise<PackingCrate>;
  handleCrateFileUpload?: (crateId: string, files: CrateFormFile[]) => void;
  status: BookingStatusType;
};

export const PackingCrateForm = ({
  bookingId,
  isDone,
  subTitle,
  isLoading,
  formKeys,
  formType,
  isBeingEdited,
  packLoadVolume,
  handleDeleteCrate,
  handleCrateFormValid,
  handleConfirmCrate,
  handleCrateFileUpload,
  handleCrateFileDelete,
  status,
  ...props
}: Props): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);
  const [crates, setCrates] = useState<CrateForm[]>([]);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number | null>();
  const packingTranslations = translations.pages.booking.pages.packing;
  const isMinWidthMobile = useMediaQuery(theme.breakpoints.up('mobile'));
  const isMaxWidthMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const areMetricsVisible = useMediaQuery(theme.breakpoints.up(1320));
  const [measureSystem, setMeasureSystem] = useState<string | undefined>();
  const {
    getSchemaForm,
    isFilesChanged,
    getEndAdornment,
    cratesUpdateFiles,
    cratesInitialization
  } = useCrates();

  const {
    isLoading: isAddCrateLoading,
    addDefaultCrate,
    newCrate
  } = useAddDefaultCrate({ bookingId, status, measureSystem });

  const {
    control,
    setValue,
    getValues,
    trigger,
    clearErrors,
    formState: { isValid, errors }
  } = useForm<{ crates: CrateForm[] }>({
    mode: 'onChange',
    resolver: yupResolver(getSchemaForm(formType))
  });

  useEffect(() => {
    const initialCrates = cratesInitialization(props.crates, measureSystem);
    setCrates(initialCrates);
    setValue('crates', initialCrates);
  }, [bookingId]);

  useEffect(() => {
    if (
      crates.length > 0 &&
      (currentIndex === null || currentIndex === undefined)
    ) {
      return;
    }

    let newValues: CrateForm[] = [];

    if (crates.length === 0 && currentIndex === undefined) {
      newValues = cratesInitialization(props.crates, measureSystem);
    }

    if (isFilesChanged(currentIndex, props.crates, crates)) {
      newValues = cratesUpdateFiles(props.crates, crates, currentIndex);
      setCurrentIndex(null);
    }

    if (newValues.length > 0) {
      setCrates(newValues);
      setValue('crates', newValues);
    }
  }, [props.crates, measureSystem]);

  useEffect(() => {
    if (
      currentIndex !== null &&
      currentIndex !== undefined &&
      !!crates[currentIndex]
    ) {
      const updatedCrates = crates.map((crate, index) => {
        if (index !== currentIndex) return crate;
        return {
          ...crate,
          isLoading: isLoading
        };
      });

      setCrates(updatedCrates);
    }
  }, [isLoading, crates]);

  useEffect(() => {
    if (handleCrateFormValid) handleCrateFormValid(crates);
  }, [crates]);

  useEffect(() => {
    setMeasureSystem(
      settings?.find((e) => e.name === settingName.measurementSystem)?.value
    );
  }, [settings]);

  useEffect(() => {
    if (!newCrate) return;
    const updatedCrates = [...getValues().crates, newCrate];
    setCrates(updatedCrates);
    setValue('crates', updatedCrates);
  }, [newCrate]);

  const removeCrate = (index: number) => {
    clearErrors(`crates.${index}`);
    const newValues: CrateForm[] = [...getValues().crates].reduce(
      (values: PackingCrate[], current: PackingCrate, i) => {
        if (i !== index) {
          return [...values, { ...crates[index], ...current }];
        }
        return values;
      },
      []
    );
    setCrates(newValues);
    setValue('crates', newValues);
  };

  const handleRemoveCrate = (index: number): void => {
    if (!crates[index]?.id) {
      removeCrate(index);
      return;
    }
    setCurrentIndex(index);
    setAlertOpen(true);
  };

  const handleDeleteCrateResponse = async (
    response: boolean
  ): Promise<void> => {
    setAlertOpen(false);
    if (!response) return;
    if (currentIndex !== null && currentIndex !== undefined) {
      await handleDeleteCrate(crates[currentIndex]);
      removeCrate(currentIndex);
    }
    setCurrentIndex(null);
  };

  const handleEdit = (index: number, edit: boolean) => {
    const newValues = getValues().crates.map((c, i) => ({
      ...crates[i],
      ...c
    }));
    newValues[index].notEditable = edit;
    setCrates(newValues);
    setValue('crates', newValues);
  };

  const handleConfirm = async (index: number) => {
    if (!(await trigger(`crates.${index}`))) return;
    const newValues = [...getValues().crates];
    newValues[index].isLoading = true;
    setCrates(newValues);

    try {
      const crate = await handleConfirmCrate({
        ...crates[index],
        ...newValues[index]
      });
      if (crate) {
        newValues[index] = { ...crate, notEditable: true };
      }
    } finally {
      newValues[index].isLoading = false;
      setCrates([...newValues]);
      setValue('crates', [...newValues]);
    }
  };

  const handleUploadFile = (index: number, files: CrateFormFile[]): void => {
    const crate = crates[index];
    if (!crate || !crate.id) return;
    setCurrentIndex(index);
    if (handleCrateFileUpload) handleCrateFileUpload(crate.id, files);
  };

  const handleDeleteUploadFile = (index: number, fileId: string): void => {
    const crate = crates[index];
    if (!crate || !crate.id) return;
    setCurrentIndex(index);
    if (handleCrateFileDelete) handleCrateFileDelete(fileId);
  };

  const getFormSubLabel = (crate: PackingCrate, key: string): string => {
    const endAdornment = getEndAdornment(crate, key);
    if (!areMetricsVisible && !isMaxWidthMobile && endAdornment) {
      return endAdornment;
    }
    return '';
  };

  const getMetricInputsEndAdornment = (
    crate: PackingCrate,
    key: string
  ): string => {
    if (areMetricsVisible || isMaxWidthMobile) {
      return getEndAdornment(crate, key) || '';
    }
    return '';
  };

  return (
    <DefaultCrateProvider>
      <MainFormWrapper>
        <FormStyledBox>
          {!isValid && errors.crates && (
            <AccordionMessage
              error={true}
              message={t(packingTranslations.crateFormErrors)}
            />
          )}
          {crates.length === 0 && (
            <AccordionMessage
              error={true}
              message={t(packingTranslations.noCrates)}
            />
          )}
          <form>
            {crates.map((crate, index) => (
              <FormLineStyledBox
                key={index}
                data-testid={`crate-line-${index}`}
              >
                {isBeingEdited && (
                  <FormCoreLineStyledBox>
                    {crate.name && (
                      <Box sx={{ paddingBottom: 20 }}>
                        <Typography variant="body2" color={'grey.400'}>
                          {t(packingTranslations.crateNumber)}
                        </Typography>
                        <Typography variant="h5" color={'grey.600'}>
                          {crate.name}
                        </Typography>
                      </Box>
                    )}
                    <CrateTitleStyledBox>
                      <Typography
                        data-testid={`title-crate-${index}`}
                        variant="body2"
                        color={'grey.400'}
                      >
                        {subTitle}
                      </Typography>

                      <CrateDeleteStyledBox>
                        <Button
                          additionalClasses="-icon"
                          color="neutral"
                          dataTestid={`remove-crate-${index}`}
                          onClick={() => handleRemoveCrate(index)}
                          startIcon={<HighlightOffIcon fontSize="small" />}
                        />
                      </CrateDeleteStyledBox>
                    </CrateTitleStyledBox>
                  </FormCoreLineStyledBox>
                )}
                {crate.isLoading && <LinearProgress />}
                {(crate.notEditable || !isBeingEdited) && (
                  <PackingCrateSummary
                    index={index}
                    crate={crate}
                    isDone={isDone}
                    isBeingEdited={isBeingEdited}
                    packLoadVolume={packLoadVolume}
                    handleClick={() => handleEdit(index, false)}
                  />
                )}
                {!crate.notEditable && isBeingEdited && (
                  <FormLineSubWrapper>
                    <FormLineSubStyledBox
                      data-testid={`crate-form-row-${index}`}
                      isMinWidthMobile={isMinWidthMobile}
                    >
                      {formKeys.map((key) => (
                        <Controller
                          key={key}
                          control={control}
                          defaultValue={crate[key as keyof PackingCrate]}
                          name={`crates.${index}.${key as keyof PackingCrate}`}
                          render={({
                            field: { onChange, onBlur, value },
                            fieldState: { error }
                          }) => (
                            <BasicInputStyledBox
                              isMinWidthMobile={isMinWidthMobile}
                            >
                              <BasicInput
                                id={`${key}-${index}`}
                                sx={{
                                  width: isMinWidthMobile ? 'inherit' : '100%'
                                }}
                                type="number"
                                value={value}
                                onChange={(event) =>
                                  onChange(
                                    event.target?.value
                                      ? parseFloat(event.target?.value)
                                      : ''
                                  )
                                }
                                error={!!error}
                                onBlur={onBlur}
                                endAdornment={getMetricInputsEndAdornment(
                                  crate,
                                  key
                                )}
                                label={`${t(packingTranslations.crate[key])} *`}
                              />
                              <FormSubLabel>
                                {getFormSubLabel(crate, key)}
                              </FormSubLabel>
                            </BasicInputStyledBox>
                          )}
                        />
                      ))}
                      <Box>
                        <Button
                          variant="plain"
                          dataTestid={`confirm-crate-${index}`}
                          onClick={() => handleConfirm(index)}
                          label={t(packingTranslations.crateConfirm)}
                        />
                      </Box>
                    </FormLineSubStyledBox>
                  </FormLineSubWrapper>
                )}
                {handleCrateFileUpload && isBeingEdited && (
                  <Box>
                    <Grid
                      container
                      direction={isMinWidthMobile ? 'row' : 'column'}
                    >
                      <Grid item xs={6}>
                        <PackingCratePicture
                          index={index}
                          files={crate.files}
                          handleAddFile={handleUploadFile}
                          category={FileCategoryType.PICTURE_CRATE_IN}
                          title={t(packingTranslations.insidePictureCrate)}
                          handleDeleteFile={handleDeleteUploadFile}
                          disabled={crate.id === undefined}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <PackingCratePicture
                          index={index}
                          files={crate.files}
                          handleAddFile={handleUploadFile}
                          category={FileCategoryType.PICTURE_CRATE_OUT}
                          title={t(packingTranslations.outsidePictureCrate)}
                          handleDeleteFile={handleDeleteUploadFile}
                          disabled={crate.id === undefined}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </FormLineStyledBox>
            ))}

            {isBeingEdited && (
              <AnotherCrateStyledBox>
                <Button
                  variant="outlined"
                  startIcon={
                    isAddCrateLoading ? (
                      <CircularProgress size={16} />
                    ) : (
                      <AddIcon fontSize="inherit" />
                    )
                  }
                  onClick={addDefaultCrate}
                  label={t(packingTranslations.anotherCrate)}
                  dataTestid={`crate-form-another-crate`}
                  disabled={isAddCrateLoading}
                />
              </AnotherCrateStyledBox>
            )}
          </form>
        </FormStyledBox>
        <AlertDialog
          isOpen={alertOpen}
          title={t(packingTranslations.deletionCrateTitle)}
          message={t(packingTranslations.deletionCrateQuestion)}
          handleResponse={handleDeleteCrateResponse}
        />
      </MainFormWrapper>
    </DefaultCrateProvider>
  );
};

const MainFormWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

const FormStyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

const FormLineStyledBox = styled(Box)({
  paddingTop: 20,
  display: 'flex',
  flexDirection: 'column'
});

const CrateTitleStyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
});

const CrateDeleteStyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end'
});

const AnotherCrateStyledBox = styled(Box)({
  width: '100%',
  paddingTop: 24,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end'
});

const FormLineSubWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

const FormSubLabel = styled(Box)({
  fontStyle: 'italic'
});

interface MinWidthMobileBoxProps extends BoxProps {
  isMinWidthMobile: boolean;
}

const FormLineSubStyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMinWidthMobile'
})<MinWidthMobileBoxProps>(({ isMinWidthMobile }) => ({
  paddingTop: 8,
  display: 'flex',
  flexDirection: isMinWidthMobile ? 'row' : 'column',
  justifyContent: 'space-around'
}));

const FormCoreLineStyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

const BasicInputStyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMinWidthMobile'
})<MinWidthMobileBoxProps>(({ isMinWidthMobile }) => ({
  paddingRight: 8,
  paddingBottom: isMinWidthMobile ? 0 : 8
}));
