import { Box, Chip, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../locales/translations';
import { PackType } from '../../../../../../types/BookingType';

interface BookingPackingFlagsProps {
  packType: PackType;
}

export const BookingPackingFlags = ({ packType }: BookingPackingFlagsProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper data-testid="booking-packing-flags">
      <PackTypeChip
        label={t(
          translations.pages.dashboard.pages.packing.flag[
            packType.toLowerCase()
          ]
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row'
});

const PackTypeChip = styled(Chip)({
  backgroundColor: 'grey.100',
  color: 'black',
  paddingX: 8,
  paddingY: 4,
  fontSize: '0.8rem',
  lineHeight: '1.09rem',
  fontWeight: 600,
  height: 24
});
