export interface ApiError {
  status: number;
  data: {
    message: string;
    error: string;
    statusCode: number;
  };
}

export const UUID_VALIDATION_ERROR = 'Validation failed (uuid is expected)';
