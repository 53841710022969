import styles from '@convelio/stylio/styles.module.scss';
import { Box, Link, Skeleton, Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../locales/translations';
import { Contact } from '../../../../types/Contact';

const ContactMainStyledBox = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
});

const TypographyStyled = styled(Typography)({
  display: 'flex',
  flexDirection: 'column'
});

const TitleLoader = styled(() => (
  <Box sx={{ width: '20%', overflow: 'hidden' }}>
    <Skeleton variant="text" />
  </Box>
))({
  width: '100%'
});

const ContactLoader = styled(() => (
  <Stack sx={{ width: '85%', overflow: 'hidden' }} spacing={1}>
    <Skeleton variant="text" width="100%" />
    <Skeleton variant="text" width="100%" />
    <Skeleton variant="text" width="100%" />
  </Stack>
))({
  width: '100%'
});

type Props = {
  contact: Contact;
  disabled?: boolean;
  isLoading?: boolean;
};

function MainContact({ isLoading, contact, disabled }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <ContactMainStyledBox>
      {isLoading ? (
        <TitleLoader />
      ) : (
        <Typography
          variant={'body1'}
          color="grey.500"
          fontSize={styles.cvoBodyMdFontSize}
        >
          {t(translations.contact.title)}
        </Typography>
      )}
      <Box data-testid="contact-details">
        {isLoading ? (
          <ContactLoader />
        ) : (
          <>
            <TypographyStyled
              variant={'body1'}
              color="common.black"
              whiteSpace="pre-wrap"
              noWrap={true}
              paragraph={true}
            >
              <span>{`${contact.firstname || ''} ${
                contact.lastname || ''
              }`}</span>
              {contact.phones?.map((phone) => (
                <span key={phone}>{phone}</span>
              ))}
              {contact.emails?.map((email) => (
                <Link key={email} color={disabled ? 'grey.200' : 'primary'}>
                  {email}
                </Link>
              ))}
            </TypographyStyled>
          </>
        )}
      </Box>
    </ContactMainStyledBox>
  );
}

export default MainContact;
