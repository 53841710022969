import { configureStore } from '@reduxjs/toolkit';
import { Uppy } from '@uppy/core';
import * as ReduxStore from '@uppy/store-redux';
import XHRUpload from '@uppy/xhr-upload';

import { useUppyHelper } from '../pages/Booking/hooks/useUppyHelper';

const store = new ReduxStore.ReduxStore({
  store: configureStore({
    reducer: {
      uppy: ReduxStore.reducer
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
      })
  })
});

const debugLogger = {
  debug: (...args: string[]) =>
    console.debug(`[Uppy] [${Date.now()}]`, ...args),
  warn: (...args: string[]) => console.warn(`[Uppy] [${Date.now()}]`, ...args),
  error: (...args: string[]) => console.error(`[Uppy] [${Date.now()}]`, ...args)
};

const UploadApi = (id: string, bookingId: string, version: number): Uppy => {
  const { generateUppyEndpoint } = useUppyHelper();

  const endpoint: string = generateUppyEndpoint(bookingId);

  const uppy = new Uppy({
    id: `uploadFile${id}`,
    logger: debugLogger,
    meta: { version },
    restrictions: { maxNumberOfFiles: 100 },
    autoProceed: false,
    allowMultipleUploadBatches: true,
    infoTimeout: 5000,
    store
  }).use(XHRUpload, {
    endpoint,
    method: 'post',
    formData: true,
    limit: 3
  });

  uppy.on('file-added', (file) => {
    uppy.setFileState(file.id, {
      xhrUpload: { endpoint: file.meta.endpoint || endpoint }
    });
  });

  return uppy;
};

export default UploadApi;
