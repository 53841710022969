import { Box, Container } from '@mui/material';

import LangDropdown from '../../Features/LangDropdown';
import Logo from '../../components/Logo';
import LoginForm from './Features/Form';
import LoginMessage from './components/LoginMessage';

function Login(): JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Logo />
      <LangDropdown />
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <LoginMessage />
        <LoginForm />
      </Container>
    </Box>
  );
}

export default Login;
