import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/app/components/Button';
import { translations } from 'src/locales/translations';
import { BookingMacroType } from 'src/types/BookingType';
import { routes } from 'src/types/Routes';

import { useResponsiveness } from '../../../../hooks/useResponsive';

const WrapperBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  cursor: 'pointer',
  marginRight: 12
});

type Props = {
  type: BookingMacroType;
};

function ReturnToBoard({ type }: Props): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMinWidthLaptop } = useResponsiveness();

  return (
    <WrapperBoxStyled onClick={() => navigate(`${routes.dashboard}${type}`)}>
      <Button
        variant="ghost"
        color="neutral"
        startIcon={<ArrowBackIcon fontSize="inherit" />}
        additionalClasses="-icon cvo-mr-xs"
      />
      {isMinWidthLaptop && (
        <Typography variant="h5">
          {t(translations.pages.booking.header.returnToDashboard)}
        </Typography>
      )}
    </WrapperBoxStyled>
  );
}

export default ReturnToBoard;
