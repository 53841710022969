import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../store/hooks';
import { selectSettings } from '../../slices/Auth';
import { getSettingsFromStorage } from './SettingHelper';
import { measurementSystems, settingName } from './constants';
import { MeasurementSystem, UpdatedSettingItem } from './types';

export interface SettingsHook {
  settings: UpdatedSettingItem[];
  measurementSystem: MeasurementSystem;
  refreshAppLanguage: () => Promise<void>;
  getSettingValue: (
    settingName: string,
    updatedSettings?: UpdatedSettingItem[]
  ) => string | undefined;
}

export const useSettings = (): SettingsHook => {
  const settings: UpdatedSettingItem[] = useAppSelector(selectSettings) || [];
  const { i18n } = useTranslation();

  const getMeasurementSystem = (): MeasurementSystem => {
    return (
      getSettingValue(settingName.measurementSystem) ||
      measurementSystems.metric
    );
  };

  const refreshAppLanguage = async (): Promise<void> => {
    const lang: string =
      getSettingValue(
        settingName.language,
        getSettingsFromStorage()
      )?.toLowerCase() || 'en';

    if (lang === i18n.language) {
      return;
    }

    await i18n.changeLanguage(lang);
  };

  const getSettingValue = (
    settingName: string,
    updatedSettings?: UpdatedSettingItem[]
  ): string | undefined => {
    updatedSettings = updatedSettings || settings;

    return updatedSettings.find((setting) => setting.name === settingName)
      ?.value;
  };

  return {
    settings,
    measurementSystem: getMeasurementSystem(),
    refreshAppLanguage,
    getSettingValue
  };
};
