import EditIcon from '@mui/icons-material/Edit';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/app/components/Button';
import TitleSm from 'src/styles/components/Title/TitleSm';

import { translations } from '../../../../../../locales/translations';

const NoteTitleBoxStyled = styled(Box)({
  paddingY: 4,
  paddingLeft: 3,
  display: 'flex',
  flexDirection: 'row'
});

const NoteTitleFirstColumnBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '50%'
});

const NoteTitleTyprography = styled(TitleSm)({
  marginLeft: 12
});

const NoteIcon = styled(NoteAltIcon)({
  width: 15,
  height: 16.67
});

type Props = {
  nbrNote: number;
  editMode: boolean;
  isEditable: boolean | undefined;
  handleChange?: (editMode: boolean) => void;
};

function NoteTitle(props: Props): JSX.Element {
  const { t } = useTranslation();

  const handleChange = (editMode: boolean) => {
    if (!props.handleChange) return;
    props.handleChange(editMode);
  };

  return (
    <NoteTitleBoxStyled>
      <NoteTitleFirstColumnBoxStyled>
        <NoteIcon />
        <NoteTitleTyprography>
          <>
            {t(translations.notes.title)} {`(${props.nbrNote})`}
          </>
        </NoteTitleTyprography>
      </NoteTitleFirstColumnBoxStyled>
      {!props.editMode && props.isEditable && (
        <Button
          dataTestid="write-note-edit-icon"
          startIcon={<EditIcon fontSize="inherit" />}
          onClick={() => handleChange(!props.editMode)}
          label={t(translations.writeNote.title)}
        />
      )}
    </NoteTitleBoxStyled>
  );
}

export default NoteTitle;
