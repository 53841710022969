import { useEffect, useState } from 'react';

import { BookingType } from '../../../../types/BookingType';
import { FileCategoryType } from '../../../../types/FileCategoryType';
import { FileResponse, UploadedFile } from '../../../types/booking/File';
import { useFileCategoryTypes } from './useFileCategoryTypes';

type FileHook = {
  convelioFiles: UploadedFile[];
  supplierFiles: UploadedFile[];
  getFileIdsByType: (extension: string) => string[];
};

export function useFile(
  bookingType: BookingType,
  response?: FileResponse<UploadedFile>
): FileHook {
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [supplierFiles, setSupplierFiles] = useState<UploadedFile[]>([]);
  const [convelioFiles, setConvelioFiles] = useState<UploadedFile[]>([]);
  const fileCategoryTypes = useFileCategoryTypes(bookingType);

  const getFileIdsByType = (type: string): string[] =>
    files
      .filter((file) => {
        return type.toLowerCase() === 'pdf'
          ? file.type.toLowerCase() === type.toLowerCase()
          : file.type.toLowerCase() !== 'pdf';
      })
      .map((file) => file.id);

  useEffect(() => {
    if (!response) return;
    setFiles(response.data);
    setConvelioFiles(
      response.data.filter((f) => f.category === FileCategoryType.CONVELIO)
    );
    setSupplierFiles(
      response.data.filter((f) => fileCategoryTypes.includes(f.category))
    );
  }, [response]);

  return {
    convelioFiles,
    supplierFiles,
    getFileIdsByType
  };
}
