import styles from '@convelio/stylio/styles.module.scss';
import { Box, styled } from '@mui/material';
import ContactLock from 'src/app/pages/Booking/components/ContactLock';
import MainAddress from 'src/app/pages/Booking/components/MainAddress';
import MainContact from 'src/app/pages/Booking/components/MainContact';
import StepTitle from 'src/app/pages/Booking/components/StepTitle';
import { Address } from 'src/types/Address';
import { Contact } from 'src/types/Contact';
import { LevelType } from 'src/types/LevelType';
import { Location } from 'src/types/Location';

import ReadyForCollectionDate from '../Pages/TransportBooking/Features/Pickup/components/ReadyForCollectionDate';
import ReadyForCollectionDateLoader from '../Pages/TransportBooking/Features/Pickup/components/ReadyForCollectionDateLoader';
import Flag from './Flag';
import {
  FlagBoxStyled,
  GridColumnAddressStyled,
  GridColumnContactStyled,
  GridRowStyled
} from './StepBookingStyled';

const StepBodyStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  paddingLeft: styles.cvoSpacingXl,
  paddingTop: styles.cvoSpacingS,
  [theme.breakpoints.down('tablet')]: {
    paddingLeft: 0
  }
}));

type Props = {
  isLoading?: boolean;
  title: {
    color: string;
    stepBadge: string;
    stepName: string;
    location: Location;
  };
  flags?: { level?: LevelType; label: string }[];
  address?: Address;
  contact?: {
    hideContact: boolean;
    value: Contact;
  };
  readyForCollectionDate?: {
    isAvailable: boolean;
    isDone: boolean;
    startReadyOn: string | null;
    editMode: boolean;
    handleDateChange: (date?: Date) => Promise<void>;
    validateDate: (date: Date) => string[];
  };
  scheduleDate?: JSX.Element;
};

function StepBody({
  flags,
  isLoading,
  title,
  address,
  contact,
  scheduleDate,
  readyForCollectionDate
}: Props): JSX.Element {
  return (
    <>
      <StepTitle
        color={title.color}
        location={title.location}
        stepBadge={title.stepBadge}
        stepName={title.stepName}
        isLoading={isLoading}
      />
      <StepBodyStyled>
        {flags && (
          <FlagBoxStyled>
            {flags.map((flag, index) => (
              <Flag key={index} level={flag.level} label={flag.label} />
            ))}
          </FlagBoxStyled>
        )}
        {readyForCollectionDate &&
          (isLoading ? (
            <ReadyForCollectionDateLoader />
          ) : (
            readyForCollectionDate.isAvailable && (
              <ReadyForCollectionDate
                address={address}
                isDone={readyForCollectionDate.isDone}
                startReadyOn={readyForCollectionDate.startReadyOn}
                editMode={readyForCollectionDate.editMode}
                handleDateChange={readyForCollectionDate.handleDateChange}
                validateInputDate={readyForCollectionDate.validateDate}
              />
            )
          ))}
        <Box sx={{ height: 'auto' }}>
          <GridRowStyled>
            {address && (
              <GridColumnAddressStyled>
                <MainAddress isLoading={isLoading} address={address} />
              </GridColumnAddressStyled>
            )}
            {contact && (
              <GridColumnContactStyled>
                {contact.hideContact ? (
                  <ContactLock />
                ) : (
                  <MainContact isLoading={isLoading} contact={contact.value} />
                )}
              </GridColumnContactStyled>
            )}
          </GridRowStyled>
        </Box>
        <>{scheduleDate}</>
      </StepBodyStyled>
    </>
  );
}

export default StepBody;
