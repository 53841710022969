import styles from '@convelio/stylio/styles.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  LinearProgress,
  Typography,
  styled
} from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/app/components/Button';

import { translations } from '../../../../../locales/translations';
import { FileCategoryType } from '../../../../../types/FileCategoryType';
import { FileToUpload, SelectFileParam } from '../../../../types/booking/File';
import SelectFile from './components/SelectFile';

interface Props {
  open: boolean;
  handleClose: (event: unknown, reason?: string) => void;
  isLoading: boolean;
  canUpload: boolean;
  isComplete: boolean;
  selectFileParams: SelectFileParam[];
  addUploadFiles: (files: File[], target: FileCategoryType) => void;
  filesToUploads: FileToUpload[];
  handleSubmit: () => void;
  isCompleteOk: boolean;
}

export const UploadFileModal = ({
  open,
  handleClose,
  isLoading,
  canUpload,
  selectFileParams,
  addUploadFiles,
  filesToUploads,
  handleSubmit
}: Props) => {
  const { t } = useTranslation();
  const uploadTranslations = translations.pages.booking.features.uploadFile;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid="upload-files-modal"
    >
      <ModalBodyBox>
        <ModalBodyTitleBox>
          <UploadFileTitle variant="h3">
            <>{t(uploadTranslations.label)}</>
          </UploadFileTitle>
          <Button
            color="neutral"
            additionalClasses="-icon"
            dataTestid="close-icon-button"
            onClick={handleClose}
            startIcon={
              <IconBox>
                <CloseIcon fontSize="inherit" />
              </IconBox>
            }
          />
        </ModalBodyTitleBox>
        <ModalBodyContainer>
          <ModalBodyFilesBox>
            <ModalBodyEmptyResultBox>
              {isLoading && <LinearProgress />}
            </ModalBodyEmptyResultBox>
            {selectFileParams.map((selectFileParam) => (
              <SelectFile
                key={selectFileParam.category}
                category={selectFileParam.category}
                handleAddFile={addUploadFiles}
                isLoading={isLoading}
                files={filesToUploads.filter(
                  (fileToUpload) =>
                    fileToUpload.category === selectFileParam.category
                )}
                label={`${t(uploadTranslations[selectFileParam.category])}`}
              />
            ))}
          </ModalBodyFilesBox>
          <Button
            variant="plain"
            dataTestid="submit-btn"
            type="submit"
            onClick={handleSubmit}
            disabled={!canUpload || isLoading}
            label={t(uploadTranslations.submitButton)}
            isLoading={isLoading}
            startIcon={<CircularProgressStyled size={24} />}
            sx={{ width: '100%' }}
          />
        </ModalBodyContainer>
      </ModalBodyBox>
    </Modal>
  );
};

const IconBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey['500']}`,
  borderRadius: styles.cvoBorderRadius,
  padding: styles.cvoSpacingXxs,
  display: 'flex'
}));

const ModalBodyContainer = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'baseline',
  justifyContent: 'space-between'
}));

const ModalBodyBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  maxWidth: 800,
  backgroundColor: theme.palette.common.white,
  padding: styles.cvoSpacingG,
  boxShadow: styles.cvoMediumShadow,
  borderRadius: styles.cvoBorderRadius,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('tablet')]: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    transform: 'none',
    width: '100%',
    height: '100%',
    maxWidth: '100%'
  }
}));

const ModalBodyTitleBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: styles.cvoSpacingM,
  marginTop: styles.cvoSpacingM
});

const UploadFileTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '20px',
  fontWeight: '600'
}));

const ModalBodyEmptyResultBox = styled(Box)({
  paddingTop: styles.cvoSpacingXxs
});

const ModalBodyFilesBox = styled(Box)({
  marginBottom: styles.cvoSpacingM,
  overflowY: 'auto',
  width: '100%'
});

const CircularProgressStyled = styled(CircularProgress)(({ theme }) => ({
  marginRight: styles.cvoSpacingXs,
  color: theme.palette.common.white
}));
