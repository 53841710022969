import styles from '@convelio/stylio/styles.module.scss';
import { Box, Skeleton, Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../locales/translations';
import { Address } from '../../../../types/Address';

const AddressMainBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

const TitleLoader = styled(() => (
  <Box sx={{ width: '20%', overflow: 'hidden' }}>
    <Skeleton variant="text" />
  </Box>
))({
  width: '100%'
});

const AddressLoader = styled(() => (
  <Stack sx={{ width: '85%', overflow: 'hidden' }} spacing={1}>
    <Skeleton variant="text" width="100%" />
    <Skeleton variant="text" width="100%" />
    <Skeleton variant="text" width="100%" />
  </Stack>
))({
  width: '100%'
});

interface MainAddressProps {
  address: Address;
  isLoading?: boolean;
  compactMode?: boolean;
}

function MainAddress({
  address,
  isLoading,
  compactMode = false
}: MainAddressProps): JSX.Element {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <AddressMainBoxStyled>
        {!compactMode && <TitleLoader />}
        <AddressLoader />
      </AddressMainBoxStyled>
    );
  }

  return (
    <AddressMainBoxStyled>
      {!compactMode && (
        <Typography
          variant="body1"
          color="grey.500"
          fontSize={styles.cvoBodyMdFontSize}
        >
          {t(translations.address.title)}
        </Typography>
      )}
      <Typography
        variant="body1"
        color="common.black"
        style={{ fontWeight: compactMode ? 600 : 'normal' }}
      >
        {address.name || ''}
      </Typography>
      <Typography variant="body1" color="common.black">
        {address.street} <br />
        {address.zipCode || ''} {address.city || ''}
        {address.country ? `, ${address.country}` : ''}
      </Typography>
    </AddressMainBoxStyled>
  );
}

export default MainAddress;
