import { SelectChangeEvent } from '@mui/material';

import { settingName } from '../constants';
import { SettingItem } from '../types';
import { FormControlSettingItem } from './FormControlSettingItem';

interface FormControlSettingsProps {
  settings: SettingItem[];
  handleChange: (event: SelectChangeEvent, settingId: string) => void;
  date?: Date;
}

const today: Date = new Date();

export const FormControlSettings = ({
  settings,
  handleChange,
  date = today
}: FormControlSettingsProps): JSX.Element => (
  <>
    {settings.map((setting: SettingItem) => (
      <FormControlSettingItem
        key={setting.id}
        setting={setting}
        handleChange={handleChange}
        date={setting.name === settingName.dateFormat ? date : undefined}
      />
    ))}
  </>
);
