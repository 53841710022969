import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../locales/translations';
import { Contact } from '../../../../../types/Contact';
import { useDate } from '../../../../hooks/useDate';
import { useContact } from '../../../../pages/Booking/Pages/TransportBooking/Features/hooks/useContact';
import { BookingItem } from '../../../../types/Booking';
import { LogisticsView } from '../../Page/Section/LogisticsView';

interface PackingPickupViewProps {
  booking: BookingItem;
}

export const PackingPickupView = ({
  booking
}: PackingPickupViewProps): JSX.Element => {
  const { t } = useTranslation();
  const { formatIsoToFullDateString } = useDate();
  const translationPacking = translations.pages.booking.pages.packing;
  const pickupdate = formatIsoToFullDateString(booking.endDate);

  const pickupContact: Contact = useContact({
    firstname: booking.endContactFirstname,
    lastname: booking.endContactLastname,
    emails: booking.endContactEmails,
    phones: booking.endContactPhones
  });

  const title = t(translationPacking.features.stepper.PACKING_PICKUP);
  const confirmTitle = t(translationPacking.features.pickup.datePickerLabel);

  return (
    <LogisticsView
      testId="packing-pickup-view"
      title={title}
      confirmTitle={confirmTitle}
      confirmDate={pickupdate}
      contact={pickupContact}
      stacked
    />
  );
};
