import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getBookingUrl,
  getMacroBookingType
} from '../../../../utils/helpers/BookingHelper';
import { BookingItem } from '../../../types/Booking';

interface BookingRedirectProps {
  bookingMacroType: string | undefined;
  booking: BookingItem | undefined;
  isLoading: boolean;
}

export const useRedirectOnBookingTypeMismatch = ({
  bookingMacroType,
  booking,
  isLoading
}: BookingRedirectProps): void => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !isLoading &&
      booking &&
      bookingMacroType !== getMacroBookingType(booking.type)
    ) {
      const { type, id }: BookingItem = booking;
      navigate(getBookingUrl(type, id), { replace: true });
    }
  }, [booking, bookingMacroType, navigate]);
};
