import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../../locales/translations';
import { MerchandiseDimensionType } from '../../../../../../../types/MerchandiseDimensionType';
import MerchandiseDimension from './MerchandiseDimension';

type Props = {
  dimensions: string[];
  type: MerchandiseDimensionType;
};

const MerchandiseDimensions = ({ dimensions, type }: Props) => {
  const { t } = useTranslation();

  const getTitle = (): string => {
    if (type === MerchandiseDimensionType.PACK_LOAD)
      return t(
        translations.pages.booking.features.merchandise.dimension.packLoad
      );
    if (type === MerchandiseDimensionType.ORDER_LOAD)
      return t(
        translations.pages.booking.features.merchandise.dimension.orderLoad
      );

    return '';
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: '0.5rem'
        }}
      >
        <Typography
          sx={{
            lineHeight: '1.36rem',
            fontWeight: 600,
            color: `grey.500`,
            textAlign: 'left'
          }}
        >
          {getTitle()}
        </Typography>
      </Box>
      {dimensions.map((merchandiseDimension, index) => (
        <MerchandiseDimension key={index} dimension={merchandiseDimension} />
      ))}
    </Box>
  );
};

export default MerchandiseDimensions;
