import { BoxProps, styled, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { Outlet } from 'react-router-dom';

import { Header, LogoHeader } from '../../../components/Header';
import { MainNavBar } from '../../../components/MainNavBar';
import { useDashboardColor } from '../hooks/useDashboardColor';

export const DashboardLayout = (): JSX.Element => {
  const theme = useTheme();
  const isMinWidthLaptop = useMediaQuery(theme.breakpoints.up('laptop'));
  const dashboardColor = useDashboardColor();

  return (
    <Wrapper>
      <Header logo={<LogoHeader activeMenuColor={dashboardColor} />} />
      <Body>
        {isMinWidthLaptop && <MainNavBar dashboardColor={dashboardColor} />}
        <Main isMinWidthLaptop={isMinWidthLaptop}>
          <Outlet />
        </Main>
      </Body>
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
});

const Body = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  height: 'calc(100% - 72px)'
});

interface MainProps extends BoxProps {
  isMinWidthLaptop: boolean;
}

const Main = styled('div')((props: MainProps) => ({
  width: props.isMinWidthLaptop ? 'calc(100% - 72px)' : '100%',
  height: '100%'
}));
