import { styled, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';

import { DashboardMenuItem } from '../types';
import { SubMenuLargeScreen } from './SubMenuLargeScreen';
import { SubMenuSmallScreen } from './SubMenuSmallScreen';

interface SubMenuProps {
  activeIndex: number;
  menuItems: DashboardMenuItem[];
  onMenuItemClicked: (index: number) => void;
}

export const SubMenu = ({
  menuItems,
  onMenuItemClicked,
  activeIndex
}: SubMenuProps): JSX.Element => {
  const theme = useTheme();
  const isMinWidthLaptop = useMediaQuery(theme.breakpoints.up('laptop'));
  const navigate = useNavigate();

  const handleMenuItemClick = (index: number) => {
    onMenuItemClicked(index);
    navigate(menuItems[index].path);
  };

  return (
    <Wrapper>
      {!isMinWidthLaptop ? (
        <SubMenuSmallScreen
          menuItems={menuItems}
          activeIndex={activeIndex}
          onMenuItemClicked={handleMenuItemClick}
        />
      ) : (
        <SubMenuLargeScreen
          menuItems={menuItems}
          activeIndex={activeIndex}
          onMenuItemClicked={handleMenuItemClick}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
  display: 'flex',
  width: '100%'
});
