import { SearchBooking } from '../Domain/Model/SearchBooking';
import {
  SearchBookingListResponse,
  SearchBookingResponse
} from './SearchBookingsResponse';

export const searchBookingModelToResponse = (
  searchBookingList: SearchBooking[]
): SearchBookingListResponse => {
  return searchBookingList.map(
    (booking: SearchBooking): SearchBookingResponse => {
      return {
        id: booking.id,
        type: booking.type,
        freightSpeed: booking.freightSpeed,
        bookingNumber: booking.bookingNumber,
        customerReference: booking.customerReference,
        startExpectedOn: booking.startExpectedOn,
        endExpectedOn: booking.endExpectedOn,
        accountId: booking.account.id,
        accountName: booking.account.name,
        startCompanyName: booking.startCompany.name,
        startAddressCity: booking.startCompany.addressCity,
        startAddressCountry: booking.startCompany.addressCountry,
        endCompanyName: booking.endCompany.name,
        endAddressCity: booking.endCompany.addressCity,
        endAddressCountry: booking.endCompany.addressCountry
      };
    }
  );
};
