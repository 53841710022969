import { useEffect, useState } from 'react';
import { useDate } from 'src/app/hooks/useDate';
import { BookingListItem } from 'src/types/Booking';
import { BookingStatusType } from 'src/types/BookingType';

type PlannedDateHook = {
  plannedDate: string | undefined;
  plannedDateLabel: string;
};

export function useTransportScheduleDate(
  booking?: BookingListItem
): PlannedDateHook {
  const { fromIsoToDateFormat } = useDate();
  const [plannedDate, setPlannedDate] = useState<string>();
  const [plannedDateLabel, setPlannedDateLabel] = useState<string>('');

  useEffect(() => {
    if (!booking || !booking.status) return;
    switch (booking.status) {
      case BookingStatusType.JOB_COMPLETED:
        setPlannedDateLabel('deliveryDate');
        if (booking.endDate) setPlannedDate(booking.endDate);
        break;
      case BookingStatusType.SCHEDULED:
        setPlannedDateLabel('plannedPickupDate');
        if (booking.startExpectedOn) setPlannedDate(booking.startExpectedOn);
        break;
      case BookingStatusType.IN_PROGRESS:
        setPlannedDateLabel('plannedDeliveryDate');
        if (booking.endExpectedOn) setPlannedDate(booking.endExpectedOn);
        break;
      case BookingStatusType.ACCEPTED:
      case BookingStatusType.TO_BE_SCHEDULED:
        setPlannedDateLabel('deliveryDeadline');
        if (booking.endDeadline) setPlannedDate(booking.endDeadline);
        break;
    }
  }, [booking]);

  return {
    plannedDate: plannedDate ? fromIsoToDateFormat(plannedDate) : undefined,
    plannedDateLabel
  };
}
