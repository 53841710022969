import { Tab, TabProps, styled } from '@mui/material';

const AntTab = styled((props: TabProps) => (
  <Tab
    disableRipple
    iconPosition="end"
    {...props}
    sx={{
      width: 'fit-content'
    }}
  />
))(({ theme }) => ({
  width: 'fit-content',
  fontWeight: 700,
  fontSize: '1rem',
  lineHeight: '1.86rem',
  justifyContent: 'left',
  color: theme.palette.grey['600'],
  '&.Mui-selected': {
    color: theme.palette.grey['600']
  }
}));

export default AntTab;
