import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { translations } from 'src/locales/translations';
import { BookingListItem } from 'src/types/Booking';

import { BookingNumber } from '../../../../../components/BookingNumber';
import CustomerRef from '../../../components/CustomerRef';
import DashboardBookingCardWrapper from '../../../components/DashboardBookingCardWrapper';
import { BookingJobType } from '../../../types';
import BookingTransportAction from './BookingTransportAction';
import BookingTransportFlags from './BookingTransportFlags';
import BookingTransportLogistic from './BookingTransportLogistic';
import BookingTransportLogisticPlanned from './BookingTransportLogisticPlanned';
import BookingTransportSubComment from './BookingTransportSubComment';

type Props = {
  isLoading?: boolean;
  jobType: BookingJobType;
  booking: BookingListItem;
};

function BookingTransportCard({
  booking,
  jobType,
  isLoading
}: Props): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleAction = (): void =>
    navigate(`/dashboard/transport/booking/${booking?.id}`);

  return (
    <DashboardBookingCardWrapper
      isLoading={isLoading}
      bookingNumber={<BookingNumber bookingNumber={booking?.bookingNumber} />}
      flags={<BookingTransportFlags booking={booking} />}
      customerRef={<CustomerRef booking={booking} />}
      subComment={
        <BookingTransportSubComment jobType={jobType} booking={booking} />
      }
      actions={
        <BookingTransportAction
          jobType={jobType}
          booking={booking}
          handleAction={handleAction}
        />
      }
      logistic={
        jobType === BookingJobType.PLANNED ? (
          <BookingTransportLogisticPlanned
            booking={booking}
            startDate={{
              label: t(translations.etaPickup.title),
              date: booking?.startExpectedOn
            }}
            endDate={{
              label: t(translations.etaDropOff.title),
              date: booking?.endExpectedOn
            }}
          />
        ) : (
          <BookingTransportLogistic booking={booking} />
        )
      }
      link={`/dashboard/transport/booking/${booking?.id}`}
    />
  );
}

export default BookingTransportCard;
