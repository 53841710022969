import { UpdatedSettingItem } from '../app/pages/Setting/types';
import { CurrencyType } from './BookingType';

export interface Account {
  id: number;
  externalId: string;
  name: string;
  billingCurrency: CurrencyType | null;
}

export interface User {
  id: number;
  email: string;
  name: string;
  account: Account;
}

export interface DecodedToken {
  userId: number;
  iat: number;
  exp: number;
  settings: UpdatedSettingItem[];
  role?: string;
}

export const DEFAULT_MIN_MINUTES_FOR_REFRESH = 5;
