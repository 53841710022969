import { translations } from '../../../../../../../locales/translations';
import { BookingActionType } from '../../../../../../../types/BookingType';
import { ActivityData } from '../../ActivityData';
import { BuilderDecisionParams } from '../../BuilderDecisionParams';
import { EventMessageBuilder } from '../../EventMessageBuilder';
import { useFormatTranslationWithDate } from '../useFormatTranslationWithDate';

export const useReadyForCollectionDateSetMessageBuilder =
  (): EventMessageBuilder => {
    const { formatTranslationWithDate } = useFormatTranslationWithDate();

    const supports = ({ eventResult }: BuilderDecisionParams): boolean => {
      return (
        eventResult.type ===
        BookingActionType.BOOKING_READY_FOR_COLLECTION_DATE_SET
      );
    };

    const build = ({ date }: ActivityData): string => {
      return formatTranslationWithDate(
        translations.pages.booking.features.activity.readyForCollectionDateSet,
        date
      );
    };

    return {
      supports,
      build
    };
  };
