import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextFieldProps
} from '@mui/material';
import { ReactNode } from 'react';

function BasicInput(
  props: TextFieldProps & {
    id: string;
    endAdornment?: ReactNode;
  }
): JSX.Element {
  return (
    <FormControl sx={props.sx} variant="outlined">
      <InputLabel
        htmlFor={`${props.id}-outlined`}
        color={props.error ? 'error' : 'primary'}
      >
        {props.label}
      </InputLabel>
      <OutlinedInput
        sx={{ height: 40 }}
        type={`${props.type}`}
        label={props.label}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        error={!!props.error}
        onBlur={props.onBlur}
        inputProps={{
          'data-testid': `${props.id}-outlined`
        }}
        endAdornment={
          props.endAdornment && (
            <InputAdornment position="end">{props.endAdornment}</InputAdornment>
          )
        }
      />
    </FormControl>
  );
}

export default BasicInput;
