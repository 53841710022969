import { useState } from 'react';
import UploadApi from 'src/app/services/UploadFileApi';
import { selectAccessToken } from 'src/app/slices/Auth';
import { useAppSelector } from 'src/store/hooks';
import { CrateFormFile } from 'src/types/Packing';
import { getFileNameWithUuid } from 'src/utils/helpers/FileHelper';

import { useRefreshToken } from '../../../../../hooks/useRefreshToken';
import { useUppyHelper } from '../../../hooks/useUppyHelper';

type UploadFileCrateHook = {
  error: unknown;
  isLoading: boolean;
  upload: (
    bookingId: string,
    version: number,
    crateId: string,
    files: CrateFormFile[],
    onComplete?: () => void
  ) => Promise<void>;
};

export function useUploadFileCrate(): UploadFileCrateHook {
  const bearer = useAppSelector(selectAccessToken);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const { generateUppyEndpoint } = useUppyHelper();
  const { checkOrRefreshAccessToken } = useRefreshToken();

  const beforeUpload = (): void => {
    setIsloading(true);
  };

  const afterUpload = (): void => {
    setIsloading(false);
  };

  const upload = async (
    bookingId: string,
    version: number,
    crateId: string,
    files: CrateFormFile[],
    onComplete?: () => void
  ): Promise<void> => {
    if (files.length === 0) return;
    Promise.resolve(beforeUpload()).then(async () => {
      const uppy = UploadApi(crateId, bookingId, version);
      const res = await checkOrRefreshAccessToken();
      const accessToken = res?.access_token || bearer;

      files.forEach((fileToUpload) => {
        if (!uppy) return;

        uppy.addFile({
          id: fileToUpload.id,
          source: 'file input',
          name: getFileNameWithUuid(fileToUpload.file.name, fileToUpload.id),
          type: fileToUpload.file.type,
          data: fileToUpload.file,
          meta: {
            id: fileToUpload.id,
            crateId,
            endpoint: generateUppyEndpoint(bookingId, fileToUpload.category)
          }
        });
      });

      uppy.getPlugin('XHRUpload')?.setOptions({
        headers: {
          'Acess-Control-Allow-Origin': '*',
          authorization: `Bearer ${accessToken}`
        }
      });
      const result = await uppy.upload();
      if (result?.failed?.length > 0) {
        setError(result.failed.map((file) => file.name).join(','));
      }
      if (onComplete) onComplete();
      afterUpload();
    });
  };

  return {
    error,
    upload,
    isLoading
  };
}
