import { Address } from '../../../../../../../../types/Address';
import { Contact } from '../../../../../../../../types/Contact';
import { Location } from '../../../../../../../../types/Location';
import { BookingItem } from '../../../../../../../types/Booking';
import { useAddress } from '../../hooks/useAddress';
import { useContact } from '../../hooks/useContact';
import { useLocation } from '../../hooks/useLocation';

type DeliveryStateReturnType = {
  deliveryContact: Contact;
  deliveryAddress: Address;
  deliveryLocation: Location;
};

export const useDeliveryState = (
  booking: BookingItem
): DeliveryStateReturnType => {
  const deliveryContact: Contact = useContact({
    firstname: booking.endContactFirstname,
    lastname: booking.endContactLastname,
    emails: booking.endContactEmails,
    phones: booking.endContactPhones
  });
  const deliveryAddress: Address = useAddress({
    name: booking.endCompanyName,
    addressLine1: booking.endAddressLine1,
    addressLine2: booking.endAddressLine2,
    zipCode: booking.endAddressZipcode,
    city: booking.endAddressCity,
    country: booking.endAddressCountry
  });
  const deliveryLocation: Location = useLocation({
    name: booking.endCompanyName,
    city: booking.endAddressCity,
    country: booking.endAddressCountry
  });

  return {
    deliveryContact,
    deliveryAddress,
    deliveryLocation
  };
};
