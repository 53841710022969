import AdapterDateFns from '@date-io/date-fns';
import { TextField, styled } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useDate } from 'src/app/hooks/useDate';

const StyledTextField = styled(TextField)({
  paddingRight: 17,
  width: '70%'
});
type Props = {
  value?: Date;
  onChange: (date?: Date | null) => void;
  error?: boolean;
  onBlur: () => void;
  disabled?: boolean;
  datatestid?: string;
};

function BasicDatePickerInput({
  error,
  onBlur,
  datatestid,
  onChange,
  value,
  disabled
}: Props): JSX.Element {
  const { dateFormat } = useDate();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={value}
        disabled={disabled}
        disableMaskedInput={true}
        inputFormat={dateFormat}
        onChange={(newValue) => onChange(newValue)}
        renderInput={(params) => {
          return (
            <StyledTextField
              disabled={disabled}
              onBlur={onBlur}
              {...params}
              size="small"
              error={error}
              inputProps={{
                ...params.inputProps,
                'data-testid': datatestid ?? 'date-picker-input'
              }}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
}

export default BasicDatePickerInput;
