import { BookingItem } from 'src/app/types/Booking';
import { BookingStatusType } from 'src/types/BookingType';
import {
  PACKING_STATUS_ENABLED_CONFIRMATION,
  PACKING_STATUS_ENABLED_DROPOFF,
  PACKING_STATUS_ENABLED_ESTIMATION,
  PACKING_STATUS_ENABLED_PICKUP
} from 'src/types/Packing';

type UsePackingHook = {
  dropOffStepDisabled: (booking: BookingItem) => boolean;
  dropOffStepIsDone: (booking: BookingItem) => boolean;
  estimationStepDisabled: (booking: BookingItem) => boolean;
  estimationStepIsBeingEdited: (
    booking: BookingItem,
    isDone: boolean
  ) => boolean;
  estimationStepIsBeingEditedCrate: (
    isBeingEdited: boolean,
    status: BookingStatusType
  ) => boolean;
  estimationStepEditable: (isDone: boolean, isBeingEdited: boolean) => boolean;
  isEstimationStepDone: (
    status: BookingStatusType,
    disabled?: boolean
  ) => boolean;
  confirmationStepDisabled: (booking: BookingItem) => boolean;
  confirmationStepIsDone: (
    status: BookingStatusType,
    isCrateFormValid: boolean
  ) => boolean;
  confirmationStepExpanded: (booking: BookingItem) => boolean;
  confirmationStepEditable: (
    isDone: boolean,
    isBeingEdited: boolean
  ) => boolean;
  confirmationStepIsBeingEdited: (
    booking: BookingItem,
    isDone: boolean
  ) => boolean;
  pickupStepDisabled: (booking: BookingItem) => boolean;
  pickupStepIsDone: (booking: BookingItem) => boolean;
  pickupStepExpanded: (booking: BookingItem) => boolean;
};

export function usePacking(): UsePackingHook {
  const dropOffStepDisabled = (booking: BookingItem): boolean =>
    !PACKING_STATUS_ENABLED_DROPOFF.includes(booking.status);

  const dropOffStepIsDone = (booking: BookingItem): boolean =>
    booking.status !== BookingStatusType.PENDING;

  const estimationStepDisabled = (booking: BookingItem): boolean => {
    return !PACKING_STATUS_ENABLED_ESTIMATION.includes(booking.status);
  };

  const estimationStepIsBeingEdited = (
    booking: BookingItem,
    isDone: boolean
  ): boolean => {
    return !estimationStepDisabled(booking) && !isDone;
  };

  const estimationStepEditable = (
    isDone: boolean,
    isBeingEdited: boolean
  ): boolean => {
    return isDone && !isBeingEdited;
  };

  const isEstimationStepDone = (
    status: BookingStatusType,
    disabled?: boolean
  ): boolean => {
    return disabled || status === BookingStatusType.PACKING_SCHEDULED;
  };

  const estimationStepIsBeingEditedCrate = (
    isBeingEdited: boolean,
    status: BookingStatusType
  ) => isBeingEdited && status === BookingStatusType.IN_PROGRESS;

  const confirmationStepDisabled = (booking: BookingItem) =>
    !PACKING_STATUS_ENABLED_CONFIRMATION.includes(booking.status);

  const confirmationStepIsDone = (
    status: BookingStatusType,
    isCrateFormValid: boolean
  ): boolean =>
    isCrateFormValid && status === BookingStatusType.PACKING_COMPLETED;

  const confirmationStepExpanded = (booking: BookingItem) =>
    [
      BookingStatusType.COMPLETED,
      ...PACKING_STATUS_ENABLED_CONFIRMATION
    ].includes(booking.status);

  const confirmationStepEditable = (
    isDone: boolean,
    isBeingEdited: boolean
  ): boolean => {
    return isDone && !isBeingEdited;
  };

  const confirmationStepIsBeingEdited = (
    booking: BookingItem,
    isDone: boolean
  ): boolean => {
    return !confirmationStepDisabled(booking) && !isDone;
  };

  const pickupStepDisabled = (booking: BookingItem) =>
    BookingStatusType.PACKING_COMPLETED !== booking.status;

  const pickupStepExpanded = (booking: BookingItem) =>
    PACKING_STATUS_ENABLED_PICKUP.includes(booking.status);

  const pickupStepIsDone = (booking: BookingItem) =>
    PACKING_STATUS_ENABLED_PICKUP.includes(booking.status);

  return {
    dropOffStepDisabled,
    dropOffStepIsDone,
    estimationStepDisabled,
    estimationStepIsBeingEdited,
    estimationStepEditable,
    isEstimationStepDone,
    estimationStepIsBeingEditedCrate,
    confirmationStepDisabled,
    confirmationStepIsDone,
    confirmationStepExpanded,
    confirmationStepEditable,
    confirmationStepIsBeingEdited,
    pickupStepDisabled,
    pickupStepIsDone,
    pickupStepExpanded
  };
}
