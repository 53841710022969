import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import TitleMd from 'src/styles/components/Title/TitleMd';
import TitleSm from 'src/styles/components/Title/TitleSm';

const TitleBoxStyled = styled(Box)({
  marginTop: 40
});

type Props = {
  title: string;
};

function DashboardBookingListTitle({ title }: Props): JSX.Element {
  const theme = useTheme();
  const isMinWidthLaptop = useMediaQuery(theme.breakpoints.up('laptop'));
  return (
    <>
      <TitleBoxStyled>
        {!isMinWidthLaptop && (
          <TitleSm>
            <>{title}</>
          </TitleSm>
        )}
        {isMinWidthLaptop && (
          <TitleMd>
            <>{title}</>
          </TitleMd>
        )}
      </TitleBoxStyled>
    </>
  );
}

export default DashboardBookingListTitle;
