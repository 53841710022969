import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../../locales/translations';
import { BookingUserType } from '../../../../../../../types/BookingType';
import { ActivityData } from '../../ActivityData';
import { BuilderDecisionParams } from '../../BuilderDecisionParams';
import { EventMessageBuilder } from '../../EventMessageBuilder';

export const useBudgetMessageBuilder = (): EventMessageBuilder => {
  const { t } = useTranslation();

  const supports = ({
    eventResult: { data, userType }
  }: BuilderDecisionParams): boolean => {
    return (
      userType === BookingUserType.SF &&
      (data.budgetEur !== undefined ||
        data.budgetGbp !== undefined ||
        data.budgetUsd !== undefined)
    );
  };

  const build = (_: ActivityData): string => {
    return t(
      translations.pages.booking.features.activity.convelioBudgetUpdated
    );
  };

  return {
    build,
    supports
  };
};
