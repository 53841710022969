import { Box } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { convertLanguageToString } from '../../../locales/translations';
import { KeyValue } from '../../../types/KeyValue';
import Dropdown from '../../components/Dropdown';

function LangDropdown(): JSX.Element {
  const { i18n } = useTranslation();
  const langs: KeyValue[] = Object.keys(i18n.services.resourceStore.data).map(
    (lang) => ({ key: lang, value: convertLanguageToString(lang) })
  );
  const [currentLang, setLang] = useState<KeyValue>({
    key: i18n.language,
    value: convertLanguageToString(i18n.language)
  });

  const handleLangChange = (lang: KeyValue): void => {
    i18n.changeLanguage(lang.key);
    setLang(lang);
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        right: '4.17%',
        top: '3%'
      }}
    >
      <Dropdown
        lists={langs}
        current={currentLang}
        handleChange={handleLangChange}
      />
    </Box>
  );
}

export default LangDropdown;
