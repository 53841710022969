import styles from '@convelio/stylio/styles.module.scss';
import { PaletteMode } from '@mui/material';

export default {
  mode: 'light' as PaletteMode,
  common: { black: styles.cvoBlack, white: styles.cvoWhite },
  primary: {
    main: styles.cvoPrimaryColor,
    light: styles.cvoLightPrimaryColor,
    dark: styles.cvoB50,
    contrastText: styles.cvoWhite
  },
  secondary: {
    main: styles.cvoSecondaryColor,
    light: styles.cvoY10,
    dark: styles.cvoY60,
    contrastText: styles.cvoWhite
  },
  error: {
    main: styles.cvoR40,
    light: styles.cvoR10,
    dark: styles.cvoR60,
    contrastText: styles.cvoWhite
  },
  warning: {
    main: styles.cvoSecondaryColor,
    light: styles.cvoY10,
    dark: styles.cvoY60,
    contrastText: styles.cvoWhite
  },
  success: {
    main: styles.cvoGR40,
    light: styles.cvoGR10,
    dark: styles.cvoGR60,
    contrastText: styles.cvoWhite
  },
  grey: {
    '100': styles.cvoG10,
    '200': styles.cvoG20,
    '300': styles.cvoG30,
    '400': styles.cvoG40,
    '500': styles.cvoG60,
    '600': styles.cvoB60
  },
  black: {
    main: styles.cvoBlack,
    light: styles.cvoG20
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: styles.cvoBlack,
    secondary: styles.cvoG60,
    disabled: styles.cvoG40
  },
  divider: styles.cvoG20,
  background: { paper: styles.cvoWhite, default: styles.cvoWhite },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.04,
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(0, 0, 0, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12
  }
};
