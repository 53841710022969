import LateralPanel from '../../Features/LateralPanel';
import Notes from '../../Features/Notes';
import DesktopViewWrapper from '../../components/DesktopViewWapper';
import { BookingPageProps } from '../../types/BookingPage';
import PackingHeader from './Features/Header/PackingHeader';
import { PackingMainPanel } from './Features/MainPanel/PackingMainPanel';

function PackingDesktopView({
  version,
  booking,
  isLoading,
  next,
  previous
}: BookingPageProps): JSX.Element {
  return (
    <DesktopViewWrapper
      header={
        <PackingHeader
          next={next}
          previous={previous}
          booking={booking}
          isLoading={isLoading}
        />
      }
      mainPanel={
        <PackingMainPanel
          version={version}
          booking={booking}
          isLoading={isLoading}
        />
      }
      lateralPanel={<LateralPanel booking={booking} />}
      notes={
        <Notes version={version} booking={booking} isLoading={isLoading} />
      }
    />
  );
}

export default PackingDesktopView;
