import { Theme, createTheme } from '@mui/material';

import { defaultTheme } from './theme';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

export function getMuiTheme(): Theme {
  return createTheme(defaultTheme);
}
