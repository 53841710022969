import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../locales/translations';
import { BookingType } from '../../../../../../types/BookingType';
import Accordion from '../../../../../components/Accordion/Accordion';
import AccordionDetails from '../../../../../components/Accordion/AccordionDetails';
import AccordionSummary from '../../../../../components/Accordion/AccordionSummary';
import { UploadedFile } from '../../../../../types/booking/File';
import { useFileCategoryTypes } from '../../../hooks/useFileCategoryTypes';
import AccordionFileCategory from './AccordionFileCategory';
import AccordionFileDetailsLoader from './AccordionFileDetailsLoader';
import AccordionFileList from './AccordionFileList';
import AccordionFileTitle from './AccordionFileTitle';

type Props = {
  isLoading?: boolean;
  handleclickFile: (file: UploadedFile) => void;
  supplierFiles: UploadedFile[];
  convelioFiles: UploadedFile[];
  bookingType: BookingType;
};

function AccordionFile({
  isLoading,
  handleclickFile,
  convelioFiles,
  supplierFiles,
  bookingType
}: Props): JSX.Element {
  const { t } = useTranslation();
  const handleVisualizationRequest = (file: UploadedFile) => {
    handleclickFile(file);
  };
  const fileCategoryTypes = useFileCategoryTypes(bookingType);

  return (
    <div>
      <Accordion>
        <AccordionSummary aria-controls="panel1d-content">
          {isLoading ? (
            <Skeleton width={'40%'} variant={'text'}></Skeleton>
          ) : (
            <AccordionFileTitle
              title={t(translations.supplierFile.title)}
              nbrFile={supplierFiles.length}
            />
          )}
        </AccordionSummary>
        <AccordionDetails>
          {isLoading ? (
            <AccordionFileDetailsLoader />
          ) : (
            fileCategoryTypes.map((supplierFileCategory) => (
              <AccordionFileCategory
                key={supplierFileCategory}
                category={t(
                  translations.pages.booking.features.uploadFile[
                    supplierFileCategory
                  ]
                )}
              >
                <AccordionFileList
                  files={supplierFiles.filter(
                    (file) => file.category === supplierFileCategory
                  )}
                  handleFileClick={handleVisualizationRequest}
                />
              </AccordionFileCategory>
            ))
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary aria-controls="panel2d-content">
          {isLoading ? (
            <Skeleton width={'40%'} variant={'text'}></Skeleton>
          ) : (
            <AccordionFileTitle
              title={t(translations.convelioFile.title)}
              nbrFile={convelioFiles.length}
            />
          )}
        </AccordionSummary>
        <AccordionDetails>
          {isLoading ? (
            <AccordionFileDetailsLoader />
          ) : (
            <AccordionFileList
              files={convelioFiles}
              handleFileClick={handleVisualizationRequest}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AccordionFile;
