import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../../locales/translations';
import {
  BookingType,
  BookingUserType
} from '../../../../../../../types/BookingType';
import {
  BOOKING_DEPOT_TYPES,
  BOOKING_PACKING_TYPES,
  BOOKING_TRANSPORT_TYPES
} from '../../../../../../../utils/helpers/BookingHelper';
import {
  ContactType,
  isContactUpdated
} from '../../../../Helpers/isContactUpdated';
import { ActivityData } from '../../ActivityData';
import { BuilderDecisionParams } from '../../BuilderDecisionParams';
import { EventMessageBuilder } from '../../EventMessageBuilder';

export const usePickupContactMessageBuilder = (): EventMessageBuilder => {
  const { t } = useTranslation();

  const supports = ({
    bookingType,
    eventResult: { data, userType }
  }: BuilderDecisionParams): boolean => {
    return (
      (userType === BookingUserType.SF &&
        isTransportTypeAndStartContactUpdated(bookingType, data)) ||
      isPackingOrDepotTypeAndEndContactUpdated(bookingType, data)
    );
  };

  const build = (_: ActivityData): string => {
    return t(
      translations.pages.booking.features.activity.convelioPickupContactUpdated
    );
  };

  return {
    build,
    supports
  };
};

const isTransportTypeAndStartContactUpdated = (
  bookingType: BookingType,
  data: ActivityData
): boolean => {
  return (
    BOOKING_TRANSPORT_TYPES.includes(bookingType) &&
    isContactUpdated(data, ContactType.START, ['startNote'])
  );
};

const isPackingOrDepotTypeAndEndContactUpdated = (
  bookingType: BookingType,
  data: ActivityData
): boolean => {
  return (
    (BOOKING_PACKING_TYPES.includes(bookingType) ||
      BOOKING_DEPOT_TYPES.includes(bookingType)) &&
    isContactUpdated(data, ContactType.END, ['endNote'])
  );
};
