import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

interface PdfViewerProps {
  src: string | undefined;
  isLoading?: boolean;
}

export const PdfViewer = ({
  src,
  isLoading
}: PdfViewerProps): JSX.Element | null => {
  if (src === undefined && isLoading === false) return null;

  return (
    <PdfViewerWrapper data-testid="pdf-viewer">
      {isLoading ? (
        <CircularProgress />
      ) : (
        src && (
          <IframeStyled src={src} title="PDF Viewer">
            Your browser does not support iframes.
          </IframeStyled>
        )
      )}
    </PdfViewerWrapper>
  );
};

const PdfViewerWrapper = styled(Box)({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const IframeStyled = styled('iframe')({
  width: '80%',
  height: '100%',
  border: 'none'
});
