import { useEffect, useState } from 'react';

import { useGetFileMutation } from '../../../../services/BookingApi';
import { UploadedFile } from '../../../../types/booking/File';

interface GetFileProps {
  fileIds: string[];
  selectedFileIndex: number;
}

interface GetFileReturnType {
  files: UploadedFile[] | undefined;
  setFiles: (files: UploadedFile[] | undefined) => void;
  currentIndex: number | undefined;
  isGetFileLoading: boolean;
  setCurrentIndex: (index: number | undefined) => void;
}

export const useGetFile = ({
  fileIds,
  selectedFileIndex
}: GetFileProps): GetFileReturnType => {
  const [getFile, { isLoading: isGetFileLoading }] = useGetFileMutation();
  const [files, setFiles] = useState<UploadedFile[] | undefined>();
  const [currentIndex, setCurrentIndex] = useState<number | undefined>();

  useEffect(() => {
    if (currentIndex !== selectedFileIndex) {
      setCurrentIndex(selectedFileIndex);
    }
  }, [selectedFileIndex]);

  useEffect(() => {
    setFiles(fileIds.map((id) => ({ id } as UploadedFile)));
  }, [fileIds]);

  useEffect(() => {
    if (!currentIndex && currentIndex !== 0) return;
    handleFetchImage(currentIndex);
  }, [currentIndex]);

  const handleFetchImage = async (index: number): Promise<void> => {
    if (!files || files[index]?.url) return;
    const uploadedFiles = [...files];
    try {
      uploadedFiles[index] = await getFile(files[index].id).unwrap();
      setFiles(uploadedFiles);
    } catch (e) {
      console.error(e);
    }
  };

  return {
    isGetFileLoading,
    files,
    setFiles,
    currentIndex,
    setCurrentIndex
  };
};
