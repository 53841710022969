import { useEffect, useState } from 'react';
import { BookingItem, TRANSPORT_STEPS } from 'src/app/types/Booking';
import {
  BookingStatusType,
  bookingStatusTypeBlocked
} from 'src/types/BookingType';
import { LevelType } from 'src/types/LevelType';
import { DEFAULT_BOOKING_TRANSPORT_COMPLETED } from 'src/utils/helpers/BookingHelper';

import AcceptJob from '../../../../Features/AcceptJob';
import MessageBanner from '../../../../Features/StatusBanner/components/MessageBanner';
import StatusBannerWrapper from '../../../../Features/StatusBanner/components/StatusBannerWrapper';
import StatusStepper from '../../../../Features/StatusBanner/components/StatusStepper';
import { hasFile } from '../../../../Helpers/hasFile';

type Props = {
  version: number;
  isLoading?: boolean;
  booking: BookingItem;
};

function TransportStatusBanner({
  version,
  isLoading,
  booking
}: Props): JSX.Element {
  const [banner, setBanner] = useState<JSX.Element>();

  useEffect(() => {
    if (
      booking.status === BookingStatusType.JOB_COMPLETED &&
      null === booking.startDate
    ) {
      setBanner(
        <MessageBanner status={booking.status} type={LevelType.WARNING} />
      );
    } else if (bookingStatusTypeBlocked.includes(booking.status)) {
      setBanner(
        <MessageBanner status={booking.status} type={LevelType.ERROR} />
      );
    } else if (booking.status === BookingStatusType.NEW) {
      setBanner(<AcceptJob version={version} booking={booking} />);
    } else {
      setBanner(undefined);
    }
  }, [booking]);

  return (
    <StatusBannerWrapper
      banner={banner}
      isLoading={isLoading}
      stepper={
        !banner ? (
          <StatusStepper
            hasBookingFileUploaded={hasFile(booking)}
            status={booking.status}
            steps={TRANSPORT_STEPS}
            bookingType={'transport'}
            statusCompleted={DEFAULT_BOOKING_TRANSPORT_COMPLETED}
          />
        ) : undefined
      }
    />
  );
}

export default TransportStatusBanner;
