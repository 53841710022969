import styles from '@convelio/stylio/styles.module.scss';
import { Box, Skeleton, Typography } from '@mui/material';

type Props = {
  title: string;
  isValid?: boolean;
  isLoading?: boolean;
};

function ScheduleDateTitle(props: Props): JSX.Element {
  return (
    <Box data-testid="schedule-date-title">
      {!props.isLoading ? (
        <Typography
          sx={{
            fontSize: styles.cvoBodyMdFontSize,
            lineHeight: '1.36rem',
            fontWeight: 400,
            color: 'grey.500'
          }}
        >
          {props.title}
        </Typography>
      ) : (
        <Box sx={{ width: '20%', overflow: 'hidden' }}>
          <Skeleton variant="text" width="100%" />
        </Box>
      )}
    </Box>
  );
}

export default ScheduleDateTitle;
