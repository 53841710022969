import styles from '@convelio/stylio/styles.module.scss';
import FeedIcon from '@mui/icons-material/Feed';
import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { translations } from 'src/locales/translations';
import TitleSm from 'src/styles/components/Title/TitleSm';

const FileTitleMainBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'left'
});

const FileIcon = styled(FeedIcon)(({ theme }) => ({
  marginLeft: 2,
  width: styles.cvoSpacingG,
  height: styles.cvoSpacingG,
  color: theme.palette.common.black
}));

type Props = {
  length: number | undefined;
};

function FileTitle({ length }: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <FileTitleMainBoxStyled>
      <FileIcon />
      <TitleSm noWrap={true} ml={styles.cvoSpacingXxs} color="common.black">
        <>{`${length || '0'} ${t(translations.files.title)}`}</>
      </TitleSm>
    </FileTitleMainBoxStyled>
  );
}

export default FileTitle;
