import { PackingCrate } from '../../../../../../types/Packing';
import { Table } from '../../../Table';
import { PackingCrateRow } from './PackingCrateRow';
import { PackingTableHeader } from './PackingTableHeader';

interface PackingCratesTableProps {
  crates: PackingCrate[];
  isConfirmation?: boolean;
}

export const PackingCratesTable = ({
  crates,
  isConfirmation = false
}: PackingCratesTableProps) => {
  return (
    <Table data-testid="packing-crates-table">
      <PackingTableHeader />
      {crates.map((crate: PackingCrate) => (
        <PackingCrateRow
          key={crate.id}
          crate={crate}
          isConfirmation={isConfirmation}
        />
      ))}
    </Table>
  );
};
