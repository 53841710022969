import {
  MenuItem,
  MenuList,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { translations } from 'src/locales/translations';

import { DashboardMenuItem } from '../types';

interface SubMenuLargeScreenProps {
  onMenuItemClicked: (index: number) => void;
  activeIndex: number;
  menuItems: DashboardMenuItem[];
}

export const SubMenuLargeScreen = ({
  menuItems,
  activeIndex,
  onMenuItemClicked
}: SubMenuLargeScreenProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <MenuWrapper>
      <MenuFixed>
        {menuItems.map((item, index) => (
          <Item
            key={index}
            selected={activeIndex === index}
            onClick={() => onMenuItemClicked(index)}
          >
            <Typography variant="h6">
              <item.icon.type
                {...item.icon.props}
                style={
                  activeIndex === index
                    ? { color: theme.palette.grey['100'] }
                    : {}
                }
              />
            </Typography>
            <Typography variant="h6" ml={9.41}>
              {t(translations[item.type].title)}
            </Typography>
          </Item>
        ))}
      </MenuFixed>
    </MenuWrapper>
  );
};

const MenuWrapper = styled(MenuList)({
  paddingTop: 24,
  width: 188,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

const MenuFixed = styled(Box)({
  position: 'fixed'
});

const Item = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  width: 150,
  alignItems: 'center',
  justifyContent: 'start',
  '&.Mui-selected': {
    borderRadius: '4px',
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.grey['100']
  },
  '&.Mui-selected:hover ': {
    backgroundColor: theme.palette.grey[500]
  }
}));
