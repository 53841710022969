import { Box, Skeleton } from '@mui/material';

function NoteTitleLoader(): JSX.Element {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 14 }}>
      <Skeleton variant="text" width="40%" height="24px" />
      <Skeleton variant="text" width="20%" height={'24px'} sx={{ ml: '40%' }} />
    </Box>
  );
}

export default NoteTitleLoader;
