import { Box, styled } from '@mui/material';

import { SearchBarUseCaseProvider } from '../../../Infrastructure/SearchBarUseCaseContext';
import { SearchInput } from '../SearchInput';
import { SearchSuggestions } from '../SearchSuggestion';
import { useSearchBarLogic } from './useSearchBarLogic';

export const SearchBar = () => {
  const {
    inputHandlers,
    isLoading,
    bookings,
    isResultsVisible,
    handleKeyDown,
    navigateToBookingDetails,
    handleCloseIconClicked,
    selectedBookingIndex,
    setSelectedBookingIndex
  } = useSearchBarLogic();

  return (
    <SearchBarUseCaseProvider>
      <Wrapper>
        <SearchInput
          {...inputHandlers}
          isLoading={isLoading}
          onClose={handleCloseIconClicked}
          handleKeyDown={handleKeyDown}
        />
        <SearchSuggestions
          searchTerm={inputHandlers.value}
          isLoading={isLoading}
          bookings={bookings}
          isVisible={isResultsVisible}
          onSearchResultClicked={navigateToBookingDetails}
          setSelectedBooking={setSelectedBookingIndex}
          selectedBooking={selectedBookingIndex}
        />
      </Wrapper>
    </SearchBarUseCaseProvider>
  );
};

const Wrapper = styled(Box)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column'
});
