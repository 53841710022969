import styles from '@convelio/stylio/styles.module.scss';
import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../../locales/translations';
import {
  SearchBookingListResponse,
  SearchBookingResponse
} from '../../../Core/Application/SearchBookingsResponse';
import { SearchSuggestion } from './SearchSuggestion';
import { usePreventBackgroundScrolling } from './usePreventBackgroundScrolling';

interface SearchResultsProps {
  searchTerm: string;
  isVisible: boolean;
  onSearchResultClicked: (booking: SearchBookingResponse) => void;
  bookings: SearchBookingListResponse;
  isLoading: boolean;
  setSelectedBooking: (index: number) => void;
  selectedBooking: number;
}

export const SearchSuggestions = ({
  searchTerm,
  isVisible,
  onSearchResultClicked,
  bookings,
  isLoading,
  setSelectedBooking,
  selectedBooking
}: SearchResultsProps) => {
  const { t } = useTranslation();
  const inProgressMessage = t(translations.features.searchBar.inProgress, {
    searchTerm
  });
  const noResultMessage = t(translations.features.searchBar.noResult);
  usePreventBackgroundScrolling(isVisible);

  return (
    <Wrapper isVisible={isVisible} data-testid="search-suggestions">
      {isLoading ? (
        <StyledBox data-testid="search-suggestions-in-progress">
          {inProgressMessage}
        </StyledBox>
      ) : null}
      {bookings !== undefined && bookings.length > 0 && (
        <SearchSuggestionsResultStyled data-testid="search-suggestions-results">
          {bookings.map((booking: SearchBookingResponse, index: number) => (
            <SearchSuggestion
              updateSelectedBooking={setSelectedBooking}
              key={booking.id}
              index={index}
              onSearchResultClicked={onSearchResultClicked}
              booking={booking}
              isSelected={index === selectedBooking}
            />
          ))}
        </SearchSuggestionsResultStyled>
      )}
      {bookings !== undefined && bookings.length === 0 && !isLoading && (
        <StyledBox data-testid="search-suggestions-no-results">
          {noResultMessage}
        </StyledBox>
      )}
    </Wrapper>
  );
};

interface isVisibleProps {
  isVisible: boolean;
}

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isVisible'
})<isVisibleProps>(({ theme, isVisible }) => ({
  display: isVisible ? 'flex' : 'none',
  flexDirection: 'column',
  position: 'absolute',
  top: 45,
  left: -85,
  right: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 1,
  width: 'calc(100% + 55px)',
  borderBottomLeftRadius: styles.cvoBorderRadiusMedium,
  borderBottomRightRadius: styles.cvoBorderRadiusMedium,
  margin: styles.cvoSpacingG,
  maxWidth: '648px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  [theme.breakpoints.up('md')]: {
    left: styles.cvoSpacingG
  }
}));

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  padding: styles.cvoSpacingG,
  color: styles.cvoG60
});

const SearchSuggestionsResultStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  maxHeight: '471px',
  borderBottomLeftRadius: styles.cvoBorderRadiusMedium,
  borderBottomRightRadius: styles.cvoBorderRadiusMedium
});
