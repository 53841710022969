import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../locales/translations';
import NotificationSnackbar from '../../../../components/SnackBar';

interface Props {
  isComplete: boolean;
  isCompleteOk: boolean | undefined;
  fetchAccessTokenError: unknown;
  resetUploadState: () => void;
}

export const UploadFileSnackbars = ({
  isComplete,
  isCompleteOk,
  fetchAccessTokenError,
  resetUploadState
}: Props) => {
  const { t } = useTranslation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (isComplete) {
      setSnackbarOpen(true);

      const timer = setTimeout(() => {
        setSnackbarOpen(false);
        if (isCompleteOk) {
          resetUploadState();
        }
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isComplete, isCompleteOk]);

  return (
    <>
      {snackbarOpen && (
        <NotificationSnackbar
          open={true}
          message={t(
            translations.pages.booking.features.uploadFile[
              isCompleteOk ? 'uploadSuccess' : 'uploadFailed'
            ]
          )}
          severity={isCompleteOk ? 'success' : 'error'}
        />
      )}
      {fetchAccessTokenError && (
        <NotificationSnackbar
          open={true}
          message={t(
            translations.pages.booking.features.uploadFile
              .fetchAccessTokenErrorMessage
          )}
          severity={'error'}
        />
      )}
    </>
  );
};
