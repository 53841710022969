import { Box, Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BookingMacroType } from 'src/types/BookingType';

import { translations } from '../../../../locales/translations';
import { BookingListItem } from '../../../../types/Booking';
import { useTabNavigator } from '../hooks/useTabNavigator';
import { BookingJobType, BookingPage, DashboardMenuItem } from '../types';
import { DashboardBookingList } from './DashboardBookingList';
import DashboardPageTitle from './DashboardPageTitle';
import DashboardTab from './DashboardTab';
import DashboardTabBody from './DashboardTabBody';
import { SubMenu } from './SubMenu';
import DashboardTabMenuTabletMobile from './TabMenuTabletMobile';

interface DashboardProps {
  activeIndex: number;
  isLoading?: boolean;
  tabs?: BookingPage;
  jobType: BookingJobType;
  macroType: BookingMacroType;
  bookings?: BookingListItem[];
  menuItems: DashboardMenuItem[];
  handleTabChange?: (index: number) => void;
  onMenuItemClicked: (index: number) => void;
}

export const Dashboard = ({
  tabs,
  jobType,
  bookings,
  macroType,
  menuItems,
  isLoading,
  activeIndex,
  onMenuItemClicked
}: DashboardProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMinWidthLaptop = useMediaQuery(theme.breakpoints.up('laptop'));
  const isMinWidthTablet = useMediaQuery(theme.breakpoints.up('tablet'));
  const { handleTabChange, tabIndex, openedTabIndex } = useTabNavigator({
    tabs
  });

  return (
    <Wrapper isMinWidthLaptop={isMinWidthLaptop} className="scrollable">
      <Box>
        <SubMenu
          activeIndex={activeIndex}
          onMenuItemClicked={onMenuItemClicked}
          menuItems={menuItems}
        />
      </Box>
      <Container>
        <Grid container direction="row" justifyContent="start">
          <Grid item xs={6}>
            <PageTitleStyled>
              <DashboardPageTitle title={t(translations[jobType].tabTitle)} />
            </PageTitleStyled>
          </Grid>
          {!isMinWidthTablet && tabs && (
            <Grid item xs={6}>
              <DashboardTabMenuTabletMobile
                items={tabIndex}
                index={openedTabIndex}
                handleMenuChange={handleTabChange}
              />
            </Grid>
          )}
        </Grid>
        {isMinWidthTablet && tabs && (
          <DashboardTab
            items={tabIndex}
            index={openedTabIndex}
            handleChange={handleTabChange}
          />
        )}

        <BookingListStyled>
          <Grid container justifyContent="start" spacing={0}>
            <Grid item xs={isMinWidthTablet ? 11 : 12}>
              {tabs && (
                <DashboardTabBody
                  items={tabIndex}
                  macroType={macroType}
                  openedTabIndex={openedTabIndex}
                  handleChange={handleTabChange}
                />
              )}
              {!tabs && (
                <DashboardBookingList
                  jobType={jobType}
                  bookings={bookings}
                  isLoading={isLoading}
                  macroType={macroType}
                />
              )}
            </Grid>
          </Grid>
        </BookingListStyled>
      </Container>
    </Wrapper>
  );
};

interface isMinWidthLaptopProps {
  isMinWidthLaptop: boolean;
}

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMinWidthLaptop'
})<isMinWidthLaptopProps>(({ theme, isMinWidthLaptop }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: isMinWidthLaptop ? 'row' : 'column',
  overflowY: 'auto',
  backgroundColor: theme.palette.grey['100']
}));

const Container = styled(Box)({
  width: '100%',
  paddingTop: 22
});

const PageTitleStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: 16
});

const BookingListStyled = styled(Box)({
  height: 'calc(100%-116px)',
  paddingLeft: 16,
  paddingRight: 16
});
