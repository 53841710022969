import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/app/components/Button';
import { ButtonProps } from 'src/app/types/ButtonProps';

import { translations } from '../../../../locales/translations';

function RecoveryPasswordButton(props: ButtonProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        textAlign: 'right',
        marginTop: 32
      }}
    >
      <Button
        variant="plain"
        label={t(translations.recoveryPassword.button)}
        {...props}
      />
    </Box>
  );
}

export default RecoveryPasswordButton;
