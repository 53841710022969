import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';

import { translations } from '../../../../locales/translations';
import { store } from '../../../../store/store';
import { useResponsiveness } from '../../../hooks/useResponsive';
import { Button } from '../../Button';

interface DownloadBookingPdfProps {
  document: JSX.Element;
  filename?: string;
}

const DEFAULT_FILENAME = 'booking.pdf';

export const BookingPdfDownloadLink = ({
  document,
  filename = DEFAULT_FILENAME
}: DownloadBookingPdfProps) => {
  const { t } = useTranslation();
  const { isMinWidthLaptop } = useResponsiveness();

  const getMessage = (loading: boolean): string => {
    return (
      (isMinWidthLaptop &&
        (loading
          ? t(translations.components.pdf.prepare)
          : t(translations.components.pdf.download))) ||
      ''
    );
  };

  return (
    <PDFDownloadLink
      document={<Provider store={store}>{document}</Provider>}
      fileName={filename}
    >
      {({ loading }) => (
        <Button
          variant="outlined"
          color="neutral"
          label={getMessage(loading)}
          startIcon={<FileDownloadOutlinedIcon fontSize="inherit" />}
        />
      )}
    </PDFDownloadLink>
  );
};
