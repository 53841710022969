import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDate } from 'src/app/hooks/useDate';
import MainDatePicker from 'src/app/pages/Booking/Features/ScheduleDate/components/MainDatePicker';
import Flag from 'src/app/pages/Booking/components/Flag';
import MainContact from 'src/app/pages/Booking/components/MainContact';
import {
  FlagBoxStyled,
  GridColumnContactStyled,
  GridRowStyled
} from 'src/app/pages/Booking/components/StepBookingStyled';
import { useStep } from 'src/app/pages/Booking/hooks/useStep';
import { BookingItem } from 'src/app/types/Booking';
import { translations } from 'src/locales/translations';
import { LevelType } from 'src/types/LevelType';

type Props = {
  isDone: boolean;
  isLoading?: boolean;
  booking: BookingItem;
  handleSubmitDropOff: (date?: Date) => Promise<void>;
};

function PackingDropOffEdit({
  booking,
  isDone,
  isLoading,
  handleSubmitDropOff
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { getStartContact } = useStep();
  const { fromIsoToDate, fromIsoToDateFormat } = useDate();

  const dropOffDate = useMemo((): Date | null => {
    if (booking.startDate) {
      return fromIsoToDate(booking.startDate);
    }
    return null;
  }, [booking.startDate]);

  return (
    <>
      {!isDone && (
        <>
          <FlagBoxStyled>
            {booking.startExpectedOn && (
              <Flag
                level={LevelType.SUCCESS}
                label={`${t(
                  translations.pages.booking.pages.packing.features.dropOff
                    .etaFlag
                )}: ${fromIsoToDateFormat(booking.startExpectedOn)}`}
              />
            )}
          </FlagBoxStyled>
          <GridRowStyled>
            <GridColumnContactStyled>
              <MainContact
                isLoading={isLoading}
                contact={getStartContact(booking)}
              />
            </GridColumnContactStyled>
          </GridRowStyled>
        </>
      )}
      <MainDatePicker
        isDone={isDone}
        isEditMode={!isDone}
        isLoading={isLoading}
        handleSubmit={handleSubmitDropOff}
        datatestid={`packing-confirm-depot-pickup-date`}
        date={dropOffDate}
        getTitle={() =>
          t(
            translations.pages.booking.pages.packing.features.dropOff
              .datePickerLabel
          )
        }
        label={t(
          translations.pages.booking.pages.packing.features.dropOff.buttonLabel
        )}
      />
    </>
  );
}

export default PackingDropOffEdit;
