import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationSnackbar from 'src/app/components/SnackBar';
import {
  useDeleteFileMutation,
  useGetVersionMutation
} from 'src/app/services/BookingApi';
import { BookingItem } from 'src/app/types/Booking';
import { translations } from 'src/locales/translations';
import { BookingActionStatusType } from 'src/types/BookingType';
import {
  CrateChangeMethodType,
  CrateFormFile,
  PackingCrate,
  PackingCrateOperation
} from 'src/types/Packing';

import AccordionEdit from '../../components/AccordionEdit';
import AccordionStepWrapper from '../../components/AccordionStepWrapper';
import { usePacking } from '../../hooks/usePacking';
import { useUploadFileCrate } from '../../hooks/useUploadFileCrate';
import PackingConfirmationEdit from './PackingConfirmationEdit';

type Props = {
  version: number;
  booking: BookingItem;
  handleSubmit: (
    feature: string,
    action: BookingActionStatusType,
    date?: Date
  ) => void;
  handleChangeCrate: (
    crate: PackingCrate,
    method: CrateChangeMethodType,
    operation?: PackingCrateOperation
  ) => Promise<PackingCrate>;
};

function PackingConfirmation({
  version,
  booking,
  handleSubmit,
  handleChangeCrate
}: Props): JSX.Element {
  const { t } = useTranslation();
  const {
    confirmationStepDisabled,
    confirmationStepExpanded,
    confirmationStepEditable,
    confirmationStepIsBeingEdited
  } = usePacking();
  const [getVersion] = useGetVersionMutation();
  const [isDone, setIsDone] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isBeingEdited, setIsBeingEdited] = useState<boolean>(false);
  const translationPacking = translations.pages.booking.pages.packing;
  const [deleteFile, { isError, isLoading: isLoadingDelete }] =
    useDeleteFileMutation();

  useEffect(() => {
    setDisabled(confirmationStepDisabled(booking));
    setIsExpanded(confirmationStepExpanded(booking));
    setIsBeingEdited(confirmationStepIsBeingEdited(booking, isDone));
  }, [booking, isDone]);

  const {
    upload,
    error,
    isLoading: isLoadingUploadFile
  } = useUploadFileCrate();

  const handleVersion = async () => {
    await getVersion(booking.id).unwrap();
  };

  const handleEdit = () => {
    setIsExpanded(true);
    setIsBeingEdited(true);
  };

  const handleFileUpload = async (
    crateId: string,
    files: CrateFormFile[]
  ): Promise<void> => {
    await upload(booking.id, version, crateId, files, handleVersion);
  };

  const handleFileDelete = async (fileId: string): Promise<void> => {
    await deleteFile({ fileId, bookingId: booking.id, version });
  };

  const handleSubmitPackingConfirmation = (date?: Date): void => {
    handleSubmit(
      'packingConfirmation',
      BookingActionStatusType.SET_PACKING_DATE,
      date
    );
  };

  const handleConfirmationIsDone = (isDone: boolean) => {
    setIsDone(isDone);
  };

  return (
    <>
      <AccordionStepWrapper
        disabled={disabled}
        withDivider={true}
        isExpanded={isExpanded}
        step={{
          stepBadge: 3,
          stepName: t(translationPacking.features.stepper.PACKING_CONFIRMATION)
        }}
        edit={
          confirmationStepEditable(isDone, isBeingEdited) ? (
            <AccordionEdit handleEdit={handleEdit} />
          ) : undefined
        }
        message={<></>}
        content={
          <PackingConfirmationEdit
            bookingId={booking.id}
            status={booking.status}
            crates={booking.crates}
            packDate={booking.packDate}
            isBeingEdited={isBeingEdited}
            packLoadVolume={booking.packLoadVolume}
            handleChangeCrate={handleChangeCrate}
            handleFileUpload={handleFileUpload}
            handleFileDelete={handleFileDelete}
            isLoading={isLoadingDelete || isLoadingUploadFile}
            handleConfirmationIsDone={handleConfirmationIsDone}
            handleSubmitPackingConfirmation={handleSubmitPackingConfirmation}
          />
        }
      />

      {(error || isError) && (
        <NotificationSnackbar
          open={true}
          severity={'error'}
          message={
            isError
              ? t(translationPacking.deleteFileFailed)
              : t(translationPacking.uploadFileFailed)
          }
        />
      )}
    </>
  );
}

export default PackingConfirmation;
