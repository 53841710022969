import { Address } from '../../../../types/Address';
import { Contact } from '../../../../types/Contact';
import { LocationWithoutCountry } from '../../../../types/Location';

interface ValueWithDelimiter {
  value: string | null | undefined;
  startDelimiter?: string;
  lastValue?: boolean;
}

const SPACE_DELIMITER = ' ';
const NEW_LINE_DELIMITER = '\n';
const COMMA_DELIMITER = ', ';

export const formatLocation = ({ name, city }: LocationWithoutCountry) => {
  return concatenateValuesWithDelimiter([
    { value: name },
    { value: city, startDelimiter: COMMA_DELIMITER, lastValue: true }
  ]);
};

export const formatAddress = ({
  name,
  street,
  zipCode,
  city,
  country
}: Address): string => {
  return concatenateValuesWithDelimiter([
    { value: name },
    { value: street, startDelimiter: NEW_LINE_DELIMITER },
    { value: zipCode, startDelimiter: NEW_LINE_DELIMITER },
    { value: city, startDelimiter: SPACE_DELIMITER },
    { value: country, startDelimiter: COMMA_DELIMITER, lastValue: true }
  ]);
};

export const formatContact = ({
  firstname,
  phones,
  emails,
  lastname
}: Contact): string => {
  return concatenateValuesWithDelimiter([
    { value: firstname },
    { value: lastname, startDelimiter: SPACE_DELIMITER },
    {
      value: phones?.join(NEW_LINE_DELIMITER),
      startDelimiter: NEW_LINE_DELIMITER
    },
    {
      value: emails?.join(NEW_LINE_DELIMITER),
      startDelimiter: NEW_LINE_DELIMITER
    }
  ]);
};

const concatenateValuesWithDelimiter = (
  values: ValueWithDelimiter[]
): string => {
  const result: string[] = [];
  for (const value of values) {
    appendValueWithDelimiter(result, value);
  }

  return result.join('');
};

const appendValueWithDelimiter = (
  result: string[],
  { value, startDelimiter, lastValue }: ValueWithDelimiter
): void => {
  if (!value) {
    appendNewLineDelimiterIfNeeded(result, lastValue || false);
    return;
  }

  if (
    !startDelimiter ||
    result.length === 0 ||
    isResultEndsWithNewLineDelimiter(result)
  ) {
    result.push(value);
    return;
  }

  result.push(`${startDelimiter}${value}`);
};

const appendNewLineDelimiterIfNeeded = (
  result: string[],
  lastValue: boolean
): void => {
  if (lastValue) {
    return;
  }

  if (result.length !== 0 && !isResultEndsWithNewLineDelimiter(result)) {
    result.push(NEW_LINE_DELIMITER);
  }
};

const isResultEndsWithNewLineDelimiter = (result: string[]): boolean => {
  const lastValue = result[result.length - 1] || '';
  return lastValue.endsWith(NEW_LINE_DELIMITER);
};
