import { BookingListItem } from '../../../../../types/Booking';
import { PaginatedResponse } from '../../../../../types/PaginatedResponse';
import { SearchBooking } from '../Core/Domain/Model/SearchBooking';

export class BookingMapper {
  static toDomain(booking: BookingListItem): SearchBooking {
    return {
      id: booking.id,
      type: booking.type,
      bookingNumber: booking.bookingNumber,
      customerReference: booking.customerRef,
      freightSpeed: booking.freightSpeed,
      startExpectedOn: booking.startExpectedOn
        ? new Date(booking.startExpectedOn)
        : null,
      endExpectedOn: booking.endExpectedOn
        ? new Date(booking.endExpectedOn)
        : null,
      account: {
        id: booking.accountId,
        name: booking.accountName
      },
      startCompany: {
        name: booking.startCompanyName,
        addressCity: booking.startAddressCity,
        addressCountry: booking.startAddressCountry
      },
      endCompany: {
        name: booking.endCompanyName,
        addressCity: booking.endAddressCity,
        addressCountry: booking.endAddressCountry
      }
    };
  }

  static toDomainList(
    response: PaginatedResponse<BookingListItem> | undefined
  ): SearchBooking[] {
    if (!response || !response.data) return [];

    return response.data.map(this.toDomain);
  }
}
