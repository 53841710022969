import { FileCategoryType } from '../../../../types/FileCategoryType';

export const useUppyHelper = () => {
  const generateUppyEndpoint = (
    bookingId: string,
    category?: FileCategoryType
  ) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/bookings/${bookingId}/upload-file`;
    if (!category) {
      return endpoint;
    }

    return `${endpoint}?category=${category}`;
  };

  return { generateUppyEndpoint };
};
