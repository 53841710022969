import { createApi } from '@reduxjs/toolkit/query/react';

import { ForgottenPasswordRequest } from '../types/ForgetPassword';
import { baseQueryWithReauth } from './BaseQueryApi';

export const forgottenPasswordApi = createApi({
  reducerPath: 'forgottenPasswordApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    forgottenPassword: builder.mutation<unknown, ForgottenPasswordRequest>({
      query: (credentials) => ({
        url: '/auth/forgot-password',
        method: 'POST',
        body: credentials
      })
    }),
    protected: builder.mutation<{ message: string }, void>({
      query: () => 'protected'
    })
  })
});

export const { useForgottenPasswordMutation, useProtectedMutation } =
  forgottenPasswordApi;
