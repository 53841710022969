import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../locales/translations';
import { BookingJobType } from '../types';

type Props = {
  type: BookingJobType;
};

function BookingEmptyList(props: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Typography
      data-testid={`${props.type}-emptyMessage`}
      variant="h5"
      sx={{
        paddingX: 12
      }}
    >
      {t(translations[props.type].emptyMessage)}
    </Typography>
  );
}

export default BookingEmptyList;
