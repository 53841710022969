import { ActivityMessage } from './ActivityMessage';
import { BuilderDecisionParams } from './BuilderDecisionParams';
import { useContextMessagesBuilder } from './ContextMessagesBuilder/useContextMessagesBuilder';
import { useMainMessageBuilder } from './MainMessageBuilder/useMainMessageBuilder';

export const useActivityMessageBuilder = ({
  eventResult,
  bookingType
}: BuilderDecisionParams) => {
  const { build: buildMainMessage } = useMainMessageBuilder({
    eventResult,
    bookingType
  });
  const { build: buildContextMessages } = useContextMessagesBuilder({
    eventResult,
    bookingType
  });

  const build = (): ActivityMessage => {
    return {
      mainMessage: buildMainMessage(),
      contextMessages: buildContextMessages()
    };
  };

  return {
    build
  };
};
