import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/app/components/Button';
import { BookingListItem } from 'src/types/Booking';
import { BookingMacroType } from 'src/types/BookingType';

import { translations } from '../../../../locales/translations';
import { BookingJobType } from '../types';
import BookingEmptyList from './BookingEmptyList';
import DashboardBookingListTitle from './DasboardBookingListTitle';
import DashboardBookingCard from './DashboardBookingCard';

const WrapperBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

const HandleSeeMoreBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  paddingTop: 16,
  cursor: 'pointer'
});

type Props = {
  title?: string;
  tabIndex?: number;
  isLoading?: boolean;
  jobType: BookingJobType;
  macroType: BookingMacroType;
  bookings?: BookingListItem[];
  handleSeeMore?: (index: number) => void;
};

export const DashboardBookingList = ({
  title,
  tabIndex,
  handleSeeMore,
  isLoading,
  bookings,
  jobType,
  macroType
}: Props) => {
  const { t } = useTranslation();
  const items = isLoading ? Array.from(new Array(3)) : bookings;

  return (
    <WrapperBoxStyled>
      {title && <DashboardBookingListTitle title={title} />}
      {items && items?.length > 0 ? (
        items.map((booking, index) => (
          <DashboardBookingCard
            key={index}
            jobType={jobType}
            booking={booking}
            macroType={macroType}
            isLoading={isLoading}
          />
        ))
      ) : (
        <BookingEmptyList type={jobType} />
      )}

      {handleSeeMore && items && items.length > 0 && (
        <HandleSeeMoreBoxStyled>
          {tabIndex !== null && tabIndex !== undefined && handleSeeMore && (
            <Button
              onClick={() => handleSeeMore(tabIndex)}
              label={t(translations.seeMore.title)}
            />
          )}
        </HandleSeeMoreBoxStyled>
      )}
    </WrapperBoxStyled>
  );
};
