import { Box, Skeleton, Stack } from '@mui/material';

import MerchandisePaper from './MerchandisePaper';

const MerchandiseLoader = (): JSX.Element => (
  <>
    <Box sx={{ width: '60%', overflow: 'hidden' }}>
      <Skeleton variant="text" width="100%" height="24px" />
    </Box>
    <MerchandisePaper>
      <Stack spacing={1} sx={{ width: '80%', overflow: 'hidden' }}>
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="100%" />
      </Stack>
    </MerchandisePaper>
  </>
);

export default MerchandiseLoader;
