import { validate as uuidValidate } from 'uuid';

export const getFileNameWithUuid = (name: string, uuid: string): string => {
  if (!name) return uuid ? uuid : '';
  return `${name.substring(0, name.lastIndexOf('.'))}_${
    uuid ? uuid : ''
  }${name.substring(name.lastIndexOf('.'), name.length)}`;
};

export const getFileNameWithoutUuid = (name: string): string => {
  if (!name) return '';
  const uuid = name.substring(name.lastIndexOf('_') + 1, name.lastIndexOf('.'));
  if (!uuidValidate(uuid)) {
    return name;
  }
  return `${name.substring(0, name.lastIndexOf('_'))}${name.substring(
    name.lastIndexOf('.'),
    name.length
  )}`;
};
