import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../locales/translations';
import { MerchandiseDimensionType } from '../../../../../../types/MerchandiseDimensionType';

interface MerchandiseDimensionsTitleReturnType {
  merchandiseDimensionsTitle: string;
}

export const useMerchandiseDimensionsTitle = (
  type: MerchandiseDimensionType
): MerchandiseDimensionsTitleReturnType => {
  const { t } = useTranslation();

  const merchandiseDimensionsTitle = useMemo(() => {
    if (type === MerchandiseDimensionType.PACK_LOAD)
      return t(
        translations.pages.booking.features.merchandise.dimension.packLoad
      );
    if (type === MerchandiseDimensionType.ORDER_LOAD)
      return t(
        translations.pages.booking.features.merchandise.dimension.orderLoad
      );

    return '';
  }, [type]);

  return {
    merchandiseDimensionsTitle
  };
};
