import { Box, styled } from '@mui/material';
import { Button } from 'src/app/components/Button';

import {
  BookingStatusType,
  BookingType
} from '../../../../../../types/BookingType';
import { useCallToActionMessageBuilder } from '../../../hooks/useCallToActionMessageBuilder';
import { BookingJobType } from '../../../types';

interface BookingPackingActionProps {
  jobType: BookingJobType;
  bookingType: BookingType;
  bookingStatus: BookingStatusType;
  handleAction: () => void;
}

export const BookingPackingAction = ({
  jobType,
  bookingType,
  bookingStatus,
  handleAction
}: BookingPackingActionProps): JSX.Element => {
  const { callToActionLabel } = useCallToActionMessageBuilder(
    jobType,
    bookingType,
    bookingStatus
  );

  return (
    <Wrapper>
      <Button
        variant="plain"
        label={callToActionLabel}
        dataTestid={`packing-see-job-button`}
        onClick={handleAction}
      />
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'end'
});
