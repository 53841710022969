import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BookingItem } from 'src/app/types/Booking';
import { translations } from 'src/locales/translations';
import { BookingActionStatusType } from 'src/types/BookingType';

import AccordionEdit from '../../components/AccordionEdit';
import AccordionStepWrapper from '../../components/AccordionStepWrapper';
import { usePacking } from '../../hooks/usePacking';
import PackingDropOffEdit from './PackingDropOffEdit';

type Props = {
  booking: BookingItem;
  handleSubmit: (
    feature: string,
    action: BookingActionStatusType,
    date?: Date
  ) => Promise<void>;
};

function PackingDropOff({ booking, handleSubmit }: Props): JSX.Element {
  const { t } = useTranslation();
  const { dropOffStepIsDone, dropOffStepDisabled } = usePacking();
  const [isExpanded, setIsExpanded] = useState(true);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const translationPacking = translations.pages.booking.pages.packing;

  useEffect(() => {
    setIsDone(dropOffStepIsDone(booking));
    setDisabled(dropOffStepDisabled(booking));
  }, [booking]);

  const handleEdit = () => {
    setIsDone(false);
    setIsExpanded(true);
  };

  const handleSubmitDropOff = async (date?: Date): Promise<void> => {
    await handleSubmit('dropOff', BookingActionStatusType.SET_START_DATE, date);

    setIsDone(true);
  };

  return (
    <AccordionStepWrapper
      disabled={disabled}
      step={{
        stepBadge: 1,
        stepName: t(translationPacking.features.stepper.PACKING_DROPOFF),
        location: {
          name: booking.startCompanyName,
          city: booking.startAddressCity,
          country: booking.startAddressCountry
        }
      }}
      isExpanded={isExpanded}
      withDivider={true}
      edit={isDone ? <AccordionEdit handleEdit={handleEdit} /> : undefined}
      content={
        <PackingDropOffEdit
          isDone={isDone || disabled}
          booking={booking}
          handleSubmitDropOff={handleSubmitDropOff}
        />
      }
    />
  );
}

export default PackingDropOff;
