import styles from '@convelio/stylio/styles.module.scss';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';

import UserIcon from './user-icon.png';

interface UserButtonProps {
  accountName: string;
  customerRef: string;
}

export const UserButton = ({
  accountName,
  customerRef
}: UserButtonProps): JSX.Element => {
  return (
    <View style={style.button}>
      <Image src={UserIcon} style={style.icon} />
      <Text>
        {accountName} - {customerRef}
      </Text>
    </View>
  );
};

const style = StyleSheet.create({
  icon: {
    width: styles.cvoSpacingS,
    height: styles.cvoSpacingS,
    marginRight: styles.cvoSpacingXs
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: styles.cvoSpacingXxs,
    paddingHorizontal: styles.cvoSpacingXs,
    borderRadius: styles.cvoBorderRadiusFull,
    borderWidth: '1px'
  }
});
