import { Box, styled } from '@mui/material';
import { Button } from 'src/app/components/Button';
import { BookingListItem } from 'src/types/Booking';

import { useCallToActionMessageBuilder } from '../../../hooks/useCallToActionMessageBuilder';
import { BookingJobType } from '../../../types';

type Props = {
  jobType: BookingJobType;
  booking: BookingListItem;
  handleAction: () => void;
};

function BookingTransportAction({
  jobType,
  booking,
  handleAction
}: Props): JSX.Element {
  const { callToActionLabel, id: callToActionId } =
    useCallToActionMessageBuilder(
      jobType,
      booking.type,
      booking.status,
      booking.startDate
    );
  return (
    <WrapperBoxStyled>
      <Button
        variant="plain"
        label={callToActionLabel}
        dataTestid={callToActionId}
        onClick={handleAction}
      />
    </WrapperBoxStyled>
  );
}

export default BookingTransportAction;

const WrapperBoxStyled = styled(Box)({
  display: 'flex',
  justifyContent: 'end'
});
