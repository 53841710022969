import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../locales/translations';
import { useDate } from '../../../../hooks/useDate';
import { useDeliveryState } from '../../../../pages/Booking/Pages/TransportBooking/Features/Delivery/hooks/useDeliveryState';
import { BookingItem } from '../../../../types/Booking';
import { LogisticsView } from '../../Page/Section/LogisticsView';
import { formatLocation } from '../../helpers/Formater';

interface DeliverySectionProps {
  booking: BookingItem;
}

export const DeliveryView = ({
  booking
}: DeliverySectionProps): JSX.Element => {
  const { t } = useTranslation();
  const { formatIsoToFullDateString } = useDate();

  const { deliveryAddress, deliveryContact, deliveryLocation } =
    useDeliveryState(booking);
  const deliveryDate = formatIsoToFullDateString(booking.endDate);
  const etaDeliveryDate = formatIsoToFullDateString(booking.endExpectedOn);

  const title = useMemo((): string => {
    return `${t(translations.delivery.title)}: ${formatLocation(
      deliveryLocation
    )}`;
  }, [
    booking.endCompanyName,
    booking.endAddressCity,
    booking.endAddressCountry
  ]);

  return (
    <LogisticsView
      title={title}
      etaTitle={t(translations.planDeliveryDate.title)}
      etaDate={etaDeliveryDate}
      confirmTitle={t(translations.confirmDeliveryDate.title)}
      confirmDate={deliveryDate}
      address={deliveryAddress}
      contact={deliveryContact}
    />
  );
};
