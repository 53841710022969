import { Locale, format } from 'date-fns';
import { enGB, enUS, fr } from 'date-fns/locale';

export enum I18nDateFormatType {
  fr = 'dd/MM/yyyy',
  en = 'dd/MM/yyyy',
  enUS = 'MM/dd/yyyy'
}

type DateLocale = {
  fr: Locale;
  en: Locale;
  enUS: Locale;
};

export const dateLocales = { en: enGB, fr, enUS } as DateLocale;

export const getLocale = (id: string) => {
  const key = Object.keys(dateLocales).find(
    (locale) => locale === id || locale.startsWith(id)
  );
  if (!key) return dateLocales.en;
  return dateLocales[key as keyof DateLocale];
};

export const getDateFormatI18n = (lang: string): string => {
  const format = I18nDateFormatType[lang as keyof typeof I18nDateFormatType];
  return format || I18nDateFormatType.en;
};

export function formatI18n(
  date: number | Date,
  locale = dateLocales.en,
  formatStr = 'P'
): string {
  return format(date, formatStr, {
    locale
  });
}
