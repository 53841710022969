import styles from '@convelio/stylio/styles.module.scss';
import { Box, styled } from '@mui/material';

type Props = {
  bgColor?: string;
  children: JSX.Element;
};

function StepWrapper({ bgColor, children }: Props): JSX.Element {
  const backgroundColor = bgColor || 'white';

  return (
    <StepStyled
      sx={{
        backgroundColor,
        border: (theme) => `${theme.palette.grey[200]} 1px solid`,
        borderRadius: '4px'
      }}
    >
      {children}
    </StepStyled>
  );
}

export default StepWrapper;

const StepStyled = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  paddingTop: styles.cvoSpacingG,
  paddingBottom: styles.cvoSpacingG,
  paddingLeft: styles.cvoSpacingM,
  paddingRight: styles.cvoSpacingM,
  marginBottom: styles.cvoSpacingXs
}));
