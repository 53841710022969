import styles from '@convelio/stylio/styles.module.scss';
import { Box, Grid, GridProps, styled } from '@mui/material';
import { useResponsiveness } from 'src/app/hooks/useResponsive';

interface MainPanelWrapperProps {
  bannerContent?: JSX.Element;
  mainContent?: JSX.Element;
  lateralContent?: JSX.Element;
}

function MainPanelWrapper({
  bannerContent,
  mainContent,
  lateralContent
}: MainPanelWrapperProps): JSX.Element {
  const { isMaxWidthTablet } = useResponsiveness();

  return (
    <MainPaneBoxStyled>
      {bannerContent}
      <GridContainer isMaxWidthTablet={isMaxWidthTablet}>
        <GridMainContentStyled>{mainContent}</GridMainContentStyled>
        <GridLateralContentStyled>{lateralContent}</GridLateralContentStyled>
      </GridContainer>
    </MainPaneBoxStyled>
  );
}

export default MainPanelWrapper;

const MainPaneBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: 'auto'
});

interface GridContainerProps extends GridProps {
  isMaxWidthTablet: boolean;
}

const GridContainer = styled(
  ({ isMaxWidthTablet, ...props }: GridContainerProps) => (
    <Grid
      container
      justifyContent="start"
      direction={isMaxWidthTablet ? 'column' : 'row'}
      {...props}
    />
  )
)(({ theme }) => ({
  height: 'auto',
  paddingLeft: '4%',
  [theme.breakpoints.down('tablet')]: {
    marginTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: theme.palette.background.default
  }
}));

const GridMainContentStyled = styled((props: GridProps) => (
  <Grid item xs={12} sm={8} {...props} />
))(({ theme }) => ({
  height: 'auto',
  [theme.breakpoints.down('tablet')]: {
    marginTop: 0,
    paddingLeft: styles.cvoSpacingXs,
    paddingRight: styles.cvoSpacingXs,
    paddingBottom: styles.cvoSpacingXs,
    backgroundColor: theme.palette.background.default,
    width: '100%'
  }
}));

const GridLateralContentStyled = styled((props: GridProps) => (
  <Grid item xs={12} sm={4} {...props} />
))(({ theme }) => ({
  height: 'auto',
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 0,
  [theme.breakpoints.down('tablet')]: {
    paddingTop: styles.cvoSpacingM,
    marginTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: theme.palette.grey[200]
  }
}));
