import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { RefObject } from 'react';

interface IconHamburgerMenuButtonProps {
  anchorRef: RefObject<HTMLButtonElement>;
  handleToggle: () => void;
  open: boolean;
}

export const IconHamburgerMenuButton = ({
  anchorRef,
  handleToggle,
  open
}: IconHamburgerMenuButtonProps): JSX.Element => {
  return (
    <IconButtonWrapper
      data-testid="hamburger-icon"
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      ref={anchorRef}
      role="basic-button"
      onClick={handleToggle}
    >
      {open ? <CloseIcon /> : <MenuIcon />}
    </IconButtonWrapper>
  );
};

const IconButtonWrapper = styled(IconButton)({
  padding: 0,
  color: 'black'
});
