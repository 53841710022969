import { Typography, TypographyProps } from '@mui/material';

interface Props extends TypographyProps {
  children: JSX.Element;
}

function TitleSm({ children, ...others }: Props): JSX.Element {
  return (
    <Typography variant="h4" {...others}>
      {children}
    </Typography>
  );
}

export default TitleSm;
