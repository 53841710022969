import { InvalidBookingNumberError } from '../Error/InvalidBookingNumberError';

const CVO_PREFIX = 'CVO-';

export class BookingNumber {
  private constructor(
    public readonly customerId: string,
    public readonly cvoId: string
  ) {}

  public static fromString(value: string): BookingNumber {
    const bookingNumberParts: string[] = value.split(CVO_PREFIX);

    if (bookingNumberParts.length !== 2) {
      throw new InvalidBookingNumberError();
    }

    return new BookingNumber(
      bookingNumberParts[0],
      `${CVO_PREFIX}${bookingNumberParts[1]}`
    );
  }
}
