import { useMemo } from 'react';

import { BookingStatusType } from '../../../../../../../../types/BookingType';

interface DeliveryDateInputModeProps {
  bookingStatus: BookingStatusType;
}

export interface DeliveryDateInputModeReturnType {
  isEtaDeliveryDateInEditMode: boolean;
  isDeliveryShowConfirmDate: boolean;
  deliveryDateShow: boolean;
  isDeliveryDateInEditMode: boolean;
  isEtaDeliveryDone: boolean;
  isDeliveryDone: boolean;
}

export const useDeliveryDateInputMode = ({
  bookingStatus
}: DeliveryDateInputModeProps): DeliveryDateInputModeReturnType => {
  const isEtaDeliveryDateInEditMode: boolean = useMemo(() => {
    return BookingStatusType.TO_BE_SCHEDULED === bookingStatus;
  }, [bookingStatus]);

  const isDeliveryShowConfirmDate: boolean = useMemo(() => {
    return [
      BookingStatusType.COMPLETED,
      BookingStatusType.COMPLETED_READ_ONLY,
      BookingStatusType.IN_PROGRESS,
      BookingStatusType.JOB_COMPLETED
    ].includes(bookingStatus);
  }, [bookingStatus]);

  const deliveryDateShow: boolean = useMemo(() => {
    return [
      BookingStatusType.COMPLETED,
      BookingStatusType.COMPLETED_READ_ONLY,
      BookingStatusType.SCHEDULED,
      BookingStatusType.IN_PROGRESS,
      BookingStatusType.JOB_COMPLETED,
      BookingStatusType.TO_BE_SCHEDULED
    ].includes(bookingStatus);
  }, [bookingStatus]);

  const isDeliveryDateInEditMode: boolean = useMemo(() => {
    return BookingStatusType.IN_PROGRESS === bookingStatus;
  }, [bookingStatus]);

  const isEtaDeliveryDone: boolean = useMemo(() => {
    return [
      BookingStatusType.JOB_COMPLETED,
      BookingStatusType.COMPLETED,
      BookingStatusType.COMPLETED_READ_ONLY
    ].includes(bookingStatus);
  }, [bookingStatus]);

  const isDeliveryDone: boolean = useMemo(() => {
    return BookingStatusType.COMPLETED_READ_ONLY === bookingStatus;
  }, [bookingStatus]);

  return {
    isEtaDeliveryDateInEditMode,
    isDeliveryShowConfirmDate,
    deliveryDateShow,
    isDeliveryDateInEditMode,
    isEtaDeliveryDone,
    isDeliveryDone
  };
};
