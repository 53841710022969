import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/app/components/Button';

import { translations } from '../../../../../locales/translations';
import { BookingStatusType } from '../../../../../types/BookingType';
import { useResponsiveness } from '../../../../hooks/useResponsive';
import {
  useGetBookingQuery,
  useGetVersionMutation
} from '../../../../services/BookingApi';
import { SelectFileParam } from '../../../../types/booking/File';
import { useUploadFile } from '../../hooks/useUploadFile';
import { UploadFileModal } from './UploadFileModal';
import { UploadFileSnackbars } from './UploadFileSnackbars';
import { useSelectFileParams } from './hooks/useSelectFileParams';

interface UploadFileProps {
  bookingId: string;
}

function UploadFile({ bookingId }: UploadFileProps): JSX.Element | null {
  const { t } = useTranslation();
  const { isMaxWidthTablet } = useResponsiveness();
  const uploadTranslations = translations.pages.booking.features.uploadFile;
  const [getVersion] = useGetVersionMutation();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (event: unknown, reason?: string) => {
    if (reason) return;
    resetUploadState();
    setOpen(false);
  };

  const { data } = useGetBookingQuery(bookingId ?? skipToken);

  const bookingStatus: BookingStatusType | undefined = data?.data?.status;
  const selectFileParams: SelectFileParam[] = useSelectFileParams(
    data?.data?.type
  );
  const version = data ? data.version : 0;

  const handleSubmit = async () => {
    await upload();
  };

  const handleUploadOnComplete = async () => {
    await getVersion(bookingId).unwrap();
    setOpen(false);
    resetFiles();
  };

  const {
    upload,
    canUpload,
    isUploadAuthorized,
    isLoading,
    isComplete,
    resetUploadState,
    isCompleteOk,
    addUploadFiles,
    filesToUploads,
    fetchAccessTokenError,
    resetFiles
  } = useUploadFile(bookingId, bookingStatus, version, handleUploadOnComplete);

  if (!isUploadAuthorized) {
    return null;
  }

  const labelProps = !isMaxWidthTablet
    ? {
        label: (
          <Typography variant="h6" onClick={handleOpen}>
            {t(uploadTranslations.title)}
          </Typography>
        )
      }
    : {};

  return (
    <UploadFileMainBoxStyled>
      <>
        <Button
          dataTestid="upload-files-btn"
          color="neutral"
          startIcon={<UploadFileOutlinedIcon fontSize="inherit" />}
          onClick={handleOpen}
          {...labelProps}
        />
        <UploadFileModal
          open={open}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          canUpload={canUpload}
          isLoading={isLoading}
          isComplete={isComplete}
          isCompleteOk={isCompleteOk}
          filesToUploads={filesToUploads}
          addUploadFiles={addUploadFiles}
          selectFileParams={selectFileParams}
        />
        <UploadFileSnackbars
          resetUploadState={resetUploadState}
          isComplete={isComplete}
          isCompleteOk={isCompleteOk}
          fetchAccessTokenError={fetchAccessTokenError}
        />
      </>
    </UploadFileMainBoxStyled>
  );
}

export default UploadFile;

const UploadFileMainBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  justifyContent: 'right'
});
