import styles from '@convelio/stylio/styles.module.scss';
import { StyleSheet, Text } from '@react-pdf/renderer';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../locales/translations';
import { useCurrency } from '../../../../hooks/useCurrency';

interface BookingNumberAndBudgetProps {
  bookingNumber: string;
  budgetEur: number;
  budgetUsd: number;
  budgetGbp: number;
}

export const BookingNumberAndBudget = ({
  bookingNumber,
  budgetEur,
  budgetUsd,
  budgetGbp
}: BookingNumberAndBudgetProps): JSX.Element => {
  const { t } = useTranslation();
  const { getFormattedBudget } = useCurrency();

  const budget = useMemo(
    () =>
      getFormattedBudget({
        budgetEur,
        budgetUsd,
        budgetGbp
      }),
    [budgetEur, budgetUsd, budgetGbp]
  );

  return (
    <>
      <Text style={style.text}>{bookingNumber}</Text>
      <Text style={style.text}>
        {budget} {t(translations.pages.booking.header.excludeTax)}
      </Text>
    </>
  );
};

const style = StyleSheet.create({
  text: {
    fontSize: styles.cvoTitleSmFontSize,
    fontFamily: 'Helvetica-Bold',
    marginVertical: styles.cvoSpacingM
  }
});
