import { isBefore, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../../../locales/translations';
import { useDate } from '../../../../../../../hooks/useDate';

interface PickupDateCalculationsProps {
  readyForCollectionDateString: string | null;
  etaPickupDateString: string | null;
  pickupDateString: string | null;
}

interface PickupDateCalculationsReturnType {
  etaPickupDate: Date | null;
  pickupDate: Date | null;
  readyForCollectionDate: Date | null;
  minEtaPickupDate: Date;
  minPickupDate?: Date;
  validateEtaPickupDate: (date: Date) => string[];
  validatePickupDate: (date: Date) => string[];
  validateReadyForCollectionDate: (date: Date) => string[];
}

export const usePickupDateManagement = ({
  readyForCollectionDateString,
  etaPickupDateString,
  pickupDateString
}: PickupDateCalculationsProps): PickupDateCalculationsReturnType => {
  const { t } = useTranslation();
  const { parseISODateOrNull, today } = useDate();

  const etaPickupDate = useMemo(
    () => parseISODateOrNull(etaPickupDateString),
    [etaPickupDateString]
  );
  const pickupDate = useMemo(
    () => parseISODateOrNull(pickupDateString),
    [pickupDateString]
  );
  const readyForCollectionDate = useMemo(
    () => parseISODateOrNull(readyForCollectionDateString),
    [readyForCollectionDateString]
  );
  const minEtaPickupDate = useMemo(() => {
    if (readyForCollectionDate) {
      return isBefore(readyForCollectionDate, today)
        ? today
        : readyForCollectionDate;
    }

    return today;
  }, [readyForCollectionDate, today]);
  const minPickupDate = useMemo(() => {
    if (readyForCollectionDate) {
      return readyForCollectionDate;
    }
  }, [readyForCollectionDate]);

  const getErrorMessage = (errorType: string) =>
    t(
      translations.pages.booking.pages.transport.features.pickup.errors[
        errorType
      ]
    );

  const validateEtaPickupDateInput = (date: Date): string[] => {
    const errors: string[] = [];

    if (isBefore(date, today)) {
      errors.push(getErrorMessage('etaDateBeforeTodayError'));
    }

    if (readyForCollectionDateString) {
      const startReadyOn = parseISO(readyForCollectionDateString);
      if (isBefore(date, startReadyOn)) {
        errors.push(
          getErrorMessage('etaDateBeforeReadyForCollectionDateError')
        );
      }
    }

    return errors;
  };

  const validatePickupDateInput = (date: Date): string[] => {
    if (readyForCollectionDateString) {
      const startReadyOn = parseISO(readyForCollectionDateString);
      if (isBefore(date, startReadyOn)) {
        return [getErrorMessage('dateBeforeReadyForCollectionDateError')];
      }
    }

    return [];
  };

  const validateReadyForCollectionDateInput = (date: Date): string[] => {
    const errors: string[] = [];

    if (etaPickupDateString && isBefore(parseISO(etaPickupDateString), date)) {
      errors.push(
        getErrorMessage('readyForCollectionDateBeforeEtaPickupDateError')
      );
    }

    return errors;
  };

  return {
    etaPickupDate,
    pickupDate,
    readyForCollectionDate,
    minEtaPickupDate,
    minPickupDate,
    validateReadyForCollectionDate: (inputDate: Date) =>
      validateReadyForCollectionDateInput(inputDate),
    validateEtaPickupDate: (inputDate: Date) =>
      validateEtaPickupDateInput(inputDate),
    validatePickupDate: (inputDate: Date) => validatePickupDateInput(inputDate)
  };
};
