import { ThemeOptions } from '@mui/material/styles/createTheme';

import toolbarMixin from './mixins/toolbar';
import breakpoints from './partials/breakpoints';
import palette from './partials/palette';
import shadows from './partials/shadows';
import shape from './partials/shape';
import transitions from './partials/transitions';
import typography from './partials/typography';
import zIndex from './partials/zindex';

export const defaultTheme: ThemeOptions = {
  breakpoints: breakpoints,
  direction: 'ltr',
  components: {},
  palette,
  shape,
  status: { danger: '#ff9800' },
  mixins: {
    toolbar: toolbarMixin
  },
  shadows,
  typography,
  transitions,
  zIndex,
  spacing: (factor: number) => `${0.0625 * factor}rem`
};
