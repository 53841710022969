import { createApi } from '@reduxjs/toolkit/query/react';

import { BookingListItem, BookingListRequest } from '../../types/Booking';
import { PackingCrate, PackingCrateRequest } from '../../types/Packing';
import { PaginatedResponse } from '../../types/PaginatedResponse';
import {
  buildEndpointForGetBookings,
  buildEndpointPackingCrate
} from '../../utils/helpers/BookingHelper';
import {
  convertBookingItemListSimulatedUTCDatesToLocale,
  convertBookingItemSimulatedUTCDatesToLocale,
  convertLocalDateToSimulatedMidnightUTCDate
} from '../../utils/helpers/convertDates';
import {
  BookingItem,
  BookingResponse,
  ChangeStatusRequest,
  EventResult
} from '../types/Booking';
import { FileResponse, UploadedFile } from '../types/booking/File';
import { baseQueryWithReauth } from './BaseQueryApi';

export const bookingApi = createApi({
  reducerPath: 'bookingApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Booking', 'Bookings'],
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getBookings: builder.query<
      PaginatedResponse<BookingListItem>,
      BookingListRequest
    >({
      query: (request: BookingListRequest) =>
        buildEndpointForGetBookings(request),
      keepUnusedDataFor: 60,
      transformResponse: (response: PaginatedResponse<BookingListItem>) => {
        convertBookingItemListSimulatedUTCDatesToLocale(response.data);
        return response;
      },
      providesTags: ['Bookings']
    }),
    getBooking: builder.query<BookingResponse<BookingItem>, string>({
      query: (id) => `/bookings/${id}`,
      transformResponse: (response: BookingResponse<BookingItem>) => {
        convertBookingItemSimulatedUTCDatesToLocale(response.data);
        return response;
      },
      providesTags: ['Booking']
    }),
    getHistory: builder.query<BookingResponse<EventResult[]>, string>({
      // @todo add pagination
      query: (id) => `/bookings/${id}/history?page=1&limit=100`,
      providesTags: ['Booking']
    }),
    getFiles: builder.query<FileResponse<UploadedFile>, string>({
      query: (id) => `/bookings/${id}/files`,
      providesTags: ['Booking']
    }),
    getVersion: builder.mutation<{ version: number }, string>({
      query: (bookingId) => `/bookings/${bookingId}/version`,
      invalidatesTags: ['Booking', 'Bookings']
    }),
    changeStatus: builder.mutation<
      BookingResponse<BookingItem>,
      ChangeStatusRequest
    >({
      query: ({ bookingId, action, ...body }) => {
        const bodyWithUTCDate = {
          ...body,
          ...(body.date && {
            date: convertLocalDateToSimulatedMidnightUTCDate(body.date)
          })
        };
        return {
          url: `/bookings/${bookingId}/${action}`,
          method: 'POST',
          body: bodyWithUTCDate
        };
      },
      invalidatesTags: ['Booking', 'Bookings'],
      async onQueryStarted({ bookingId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedBooking } = await queryFulfilled;
          dispatch(
            bookingApi.util.updateQueryData(
              'getBooking',
              bookingId,
              (draft) => {
                Object.assign(draft, updatedBooking);
              }
            )
          );
        } catch {
          console.error('Update Booking failed');
        }
      }
    }),
    getFile: builder.mutation<UploadedFile, string>({
      query: (fileId) => `/files/${fileId}`
    }),
    deleteFile: builder.mutation<
      void,
      { bookingId: string; fileId: string; version: number }
    >({
      query: ({ fileId, bookingId, version }) => ({
        method: 'DELETE',
        url: `/bookings/${bookingId}/file/${fileId}?version=${version}`
      }),
      invalidatesTags: ['Booking', 'Bookings']
    }),
    searchBookings: builder.mutation<
      PaginatedResponse<BookingListItem>,
      BookingListRequest
    >({
      query: (request) => buildEndpointForGetBookings(request)
    }),
    changeCrate: builder.mutation<
      {
        version: number;
        data: PackingCrate;
      },
      PackingCrateRequest
    >({
      query: (request) => buildEndpointPackingCrate(request),
      invalidatesTags: ['Booking', 'Bookings']
    })
  })
});

export const {
  useGetBookingQuery,
  useChangeStatusMutation,
  useGetHistoryQuery,
  useGetBookingsQuery,
  useGetFilesQuery,
  useGetVersionMutation,
  useGetFileMutation,
  useSearchBookingsMutation,
  useChangeCrateMutation,
  useDeleteFileMutation
} = bookingApi;
