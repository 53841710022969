import { useEffect } from 'react';

export const usePreventBackgroundScrolling = (isVisible: boolean): void => {
  const scrollableElems: NodeListOf<HTMLElement> =
    document.querySelectorAll('.scrollable');

  const preventBackgroundScrolling = (isVisible: boolean) => {
    if (!isVisible) {
      resetBackgroundScrolling();
      return;
    }

    document.body.style.overflow = 'hidden';
    scrollableElems.forEach(
      (elem: HTMLElement) => (elem.style.overflowY = 'hidden')
    );
  };

  const resetBackgroundScrolling = (): void => {
    document.body.style.overflow = '';
    scrollableElems.forEach(
      (elem: HTMLElement) => (elem.style.overflowY = 'auto')
    );
  };

  useEffect(() => {
    preventBackgroundScrolling(isVisible);
    return () => {
      resetBackgroundScrolling();
    };
  }, [isVisible]);
};
