import { Navigate } from 'react-router-dom';

import { useAppSelector } from '../../store/hooks';
import { selectIsUnAuthorized } from '../slices/Auth';

type Props = {
  children: JSX.Element;
};

function UnAuthorizedGuard(props: Props): JSX.Element | null {
  const isUnAuthorized = useAppSelector(selectIsUnAuthorized);
  return !isUnAuthorized ? (
    props.children
  ) : (
    <Navigate to="/forbidden" replace />
  );
}

export default UnAuthorizedGuard;
