import styles from '@convelio/stylio/styles.module.scss';
import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../locales/translations';
import { Address } from '../../../../../types/Address';
import { LogisticActionType } from '../../../../../types/BookingType';
import { useResponsiveness } from '../../../../hooks/useResponsive';
import MainDatePicker from './components/MainDatePicker';

type Props = {
  etaDate: Date | null;
  confirmDate: Date | null;
  minEtaDate?: Date;
  maxEtaDate?: Date;
  minConfirmDate?: Date;
  getEtaTitle: (isEtaEditMode: boolean) => string;
  getConfirmTitle: (isEditMode: boolean) => string;
  isLoading?: boolean;
  showConfirmDate: boolean;
  etaButtonLabel: string;
  confirmButtonLabel: string;
  isEtaDateEditMode: boolean;
  isEtaDateDone: boolean;
  isCompleted?: boolean;
  handlePlanned: (value?: Date) => Promise<void>;
  handleConfirmed: (value?: Date) => Promise<void>;
  dataTestId?: string;
  validateEtaInputDate?: (date: Date) => string[];
  validateConfirmInputDate?: (date: Date) => string[];
  isConfirmDateEditMode: boolean;
  address?: Address;
  logisticActionType: LogisticActionType;
};

function ScheduleDate({
  dataTestId,
  isEtaDateEditMode,
  etaDate,
  confirmDate,
  isLoading,
  getEtaTitle,
  logisticActionType,
  getConfirmTitle,
  isEtaDateDone,
  etaButtonLabel,
  confirmButtonLabel,
  handlePlanned,
  handleConfirmed,
  showConfirmDate,
  minEtaDate,
  maxEtaDate,
  minConfirmDate,
  isCompleted,
  validateEtaInputDate,
  validateConfirmInputDate,
  address,
  isConfirmDateEditMode
}: Props): JSX.Element {
  const { isMaxWidthTablet } = useResponsiveness();
  const { t } = useTranslation();

  return (
    <GridWrapper container direction={isMaxWidthTablet ? 'column' : 'row'}>
      <Grid item xs={!showConfirmDate ? 11 : 6}>
        <MainDatePicker
          modalTitle={t(
            translations.pages.booking.features.dateModal.schedule[
              logisticActionType
            ]
          )}
          modalDescription={t(
            translations.pages.booking.features.dateModal[logisticActionType]
          )}
          address={address}
          datatestid={`eta-${dataTestId}`}
          getTitle={getEtaTitle}
          date={etaDate}
          minDate={minEtaDate}
          maxDate={maxEtaDate}
          label={etaButtonLabel}
          isLoading={isLoading}
          isDone={isEtaDateDone}
          isEditMode={isEtaDateEditMode}
          handleSubmit={handlePlanned}
          validateInputDate={validateEtaInputDate}
        />
      </Grid>
      <Grid
        item
        xs={showConfirmDate ? 6 : 1}
        sx={{
          height: '100%'
        }}
      >
        {showConfirmDate && (
          <MainDatePicker
            datatestid={dataTestId}
            modalTitle={t(
              translations.pages.booking.features.dateModal.confirm[
                logisticActionType
              ]
            )}
            modalDescription={t(
              translations.pages.booking.features.dateModal[logisticActionType]
            )}
            address={address}
            minDate={minConfirmDate}
            date={confirmDate}
            getTitle={getConfirmTitle}
            label={confirmButtonLabel}
            isLoading={isLoading}
            isDone={isCompleted}
            isEditMode={isConfirmDateEditMode}
            handleSubmit={handleConfirmed}
            validateInputDate={validateConfirmInputDate}
          />
        )}
      </Grid>
    </GridWrapper>
  );
}

export default ScheduleDate;

const GridWrapper = styled(Grid)(({ theme }) => ({
  paddingTop: styles.cvoSpacingXs,
  gap: 0,
  justifyContent: 'start',
  [theme.breakpoints.down('tablet')]: {
    gap: styles.cvoSpacingG
  }
}));
