import styles from '@convelio/stylio/styles.module.scss';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../locales/translations';

interface MerchandiseEoriNumberSectionProps {
  eoriNumber: string;
}

export const MerchandiseEoriNumberView = ({
  eoriNumber
}: MerchandiseEoriNumberSectionProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View>
      <Text style={style.title}>
        {t(translations.pages.booking.features.merchandise.eoriNumber)}
      </Text>
      <Text>{eoriNumber}</Text>
    </View>
  );
};

const style = StyleSheet.create({
  title: {
    fontSize: styles.cvoBodySmFontSize,
    fontFamily: 'Helvetica-Bold',
    marginTop: styles.cvoSpacingM,
    marginBottom: styles.cvoSpacingXs
  }
});
