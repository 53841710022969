import { useTranslation } from 'react-i18next';
import NotificationSnackbar from 'src/app/components/SnackBar';
import StepBody from 'src/app/pages/Booking/components/StepBody';
import StepWrapper from 'src/app/pages/Booking/components/StepWrapper';
import { BookingItem } from 'src/app/types/Booking';
import { translations } from 'src/locales/translations';
import {
  BookingActionStatusType,
  BookingStatusType,
  LogisticActionType
} from 'src/types/BookingType';
import { DEFAULT_BOOKING_NOT_SCHEDULED } from 'src/utils/helpers/BookingHelper';

import ScheduleDate from '../../../../Features/ScheduleDate';
import { useBookingAction } from '../hooks/useBookingAction';
import { useStepBackgroundColor } from '../hooks/useStepBackgroundColor';
import { usePickupDateInputMode } from './hooks/usePickupDateInputMode';
import { usePickupDateManagement } from './hooks/usePickupDateManagement';
import { usePickupState } from './hooks/usePickupState';

type Props = {
  version: number;
  isLoading?: boolean;
  booking: BookingItem;
};

function TransportPickup({ booking, isLoading, version }: Props): JSX.Element {
  const { t } = useTranslation();
  const { handleChangeStatus, bookingAction, isSuccess, isError } =
    useBookingAction({ booking, version });

  const { pickupAddress, pickupContact, pickupLocation } =
    usePickupState(booking);

  const {
    etaPickupDate,
    pickupDate,
    minEtaPickupDate,
    minPickupDate,
    validateReadyForCollectionDate,
    validateEtaPickupDate,
    validatePickupDate
  } = usePickupDateManagement({
    readyForCollectionDateString: booking.startReadyOn,
    etaPickupDateString: booking.startExpectedOn,
    pickupDateString: booking.startDate
  });

  const {
    isEtaPickupDateInEditMode,
    isPickupShowConfirmDate,
    isPickupDateInEditMode,
    isEtaPickupDone,
    isPickupDone,
    isReadyForCollectionDateAvailable
  } = usePickupDateInputMode({
    bookingStatus: booking.status,
    bookingType: booking.type,
    readyForCollectionDateString: booking.startReadyOn
  });

  const handleEtaPickupDate = async (date?: Date): Promise<void> => {
    await handleChangeStatus(BookingActionStatusType.SCHEDULE_START, date);
  };

  const handlePickupDate = async (date?: Date): Promise<void> => {
    await handleChangeStatus(BookingActionStatusType.SET_START_DATE, date);
  };

  const handleReadyForCollectionDateChange = async (
    date?: Date
  ): Promise<void> => {
    await handleChangeStatus(
      BookingActionStatusType.SET_READY_FOR_COLLECTION_DATE,
      date
    );
  };

  const getEtaPickupTitle = (isEtaEditMode: boolean): string => {
    const pickupDateKey = isEtaEditMode
      ? 'planPickupDate'
      : 'plannedPickupDate';
    return t(translations[pickupDateKey].title);
  };

  const getPickupTitle = (isConfirmEditMode: boolean): string => {
    const pickupDateKey = isConfirmEditMode
      ? 'confirmPickupDate'
      : 'confirmedPickupDate';
    return t(translations[pickupDateKey].title);
  };

  const { pickupBackgroundColor, pickupColor } = useStepBackgroundColor({
    status: booking.status,
    etaPickupDate: booking.startExpectedOn
  });

  return (
    <>
      <StepWrapper bgColor={pickupBackgroundColor}>
        <StepBody
          isLoading={isLoading}
          title={{
            stepBadge: 'A',
            stepName: t(translations.pickup.title),
            color: pickupColor,
            location: pickupLocation
          }}
          address={pickupAddress}
          contact={{
            value: pickupContact,
            hideContact: booking.status === BookingStatusType.NEW
          }}
          readyForCollectionDate={{
            isAvailable: isReadyForCollectionDateAvailable,
            isDone: isEtaPickupDone,
            startReadyOn: booking.startReadyOn,
            editMode: false,
            handleDateChange: handleReadyForCollectionDateChange,
            validateDate: validateReadyForCollectionDate
          }}
          scheduleDate={
            <>
              {!DEFAULT_BOOKING_NOT_SCHEDULED.includes(booking.status) && (
                <ScheduleDate
                  address={pickupAddress}
                  logisticActionType={LogisticActionType.Pickup}
                  minEtaDate={minEtaPickupDate}
                  minConfirmDate={minPickupDate}
                  isLoading={isLoading}
                  etaDate={etaPickupDate}
                  dataTestId="pickup-date"
                  validateEtaInputDate={validateEtaPickupDate}
                  validateConfirmInputDate={validatePickupDate}
                  getEtaTitle={getEtaPickupTitle}
                  etaButtonLabel={t(translations['planPickup'].title)}
                  isEtaDateEditMode={isEtaPickupDateInEditMode}
                  isEtaDateDone={isEtaPickupDone}
                  handlePlanned={handleEtaPickupDate}
                  handleConfirmed={handlePickupDate}
                  confirmDate={pickupDate}
                  showConfirmDate={isPickupShowConfirmDate}
                  getConfirmTitle={getPickupTitle}
                  isCompleted={isPickupDone}
                  isConfirmDateEditMode={isPickupDateInEditMode}
                  confirmButtonLabel={t(translations['confirmPickup'].title)}
                />
              )}
            </>
          }
        />
      </StepWrapper>
      {bookingAction && (isError || isSuccess) && (
        <NotificationSnackbar
          open={true}
          message={t(
            translations.pickup[bookingAction][
              isError ? 'failedMessage' : 'successMessage'
            ]
          )}
          severity={isError ? 'error' : 'success'}
        />
      )}
    </>
  );
}

export default TransportPickup;
