import { BookingStatusType } from '../../../../../../../../../types/BookingType';
import {
  LengthMetricType,
  WeightMetricType
} from '../../../../../../../../../types/MetricType';
import { measurementSystems } from '../../../../../../../Setting/constants';
import { BookingStatusNotAuthorizedError } from '../Domain/Error/BookingStatusNotAuthorizedError';
import { CrateDimension } from '../Domain/Model/CrateDimension';
import { AddDefaultCrateRequest } from './AddDefaultCrateRequest';

const VALID_STATUSES = [
  BookingStatusType.IN_PROGRESS,
  BookingStatusType.PACKING_SCHEDULED,
  BookingStatusType.PACKING_COMPLETED
];

export class DefaultCrateDimensionFactory {
  public static fromRequest(request: AddDefaultCrateRequest): CrateDimension {
    this.validateStatus(request.status);

    const lengthMetric = DefaultCrateDimensionFactory.getLengthMetric(
      request.measureSystem
    );
    const weightMetric = DefaultCrateDimensionFactory.getWeightMetric(
      request.measureSystem
    );

    return {
      length: 0,
      depth: 0,
      height: 0,
      quantity: 0,
      weight: 0,
      lengthMetric,
      weightMetric
    };
  }

  private static validateStatus(status: BookingStatusType) {
    if (!VALID_STATUSES.includes(status)) {
      throw new BookingStatusNotAuthorizedError();
    }
  }

  private static getLengthMetric(system: string | undefined): LengthMetricType {
    return system === measurementSystems.imperial
      ? LengthMetricType.INCH
      : LengthMetricType.CM;
  }

  private static getWeightMetric(system: string | undefined): WeightMetricType {
    return system === measurementSystems.imperial
      ? WeightMetricType.LB
      : WeightMetricType.KG;
  }
}
