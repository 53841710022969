import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';

import './App.scss';
import { AdminLabel } from './components/AdminLabel';
import {
  AuthGuard,
  DashboardGuard,
  RedirectGuard,
  UnAuthorizedGuard
} from './guards';
import { Booking } from './pages/Booking';
import {
  DashboardLayout,
  Depot,
  MainDashboard,
  Packing,
  Transport
} from './pages/Dashboard';
import { AdminDashboard } from './pages/Dashboard/pages/AdminDashboard';
import ForgottenPassword from './pages/ForgottenPassword';
import Login from './pages/Login';
import PageForbidden from './pages/PageForbidden';
import PageNotFound from './pages/PageNotFound';
import RecoveryPassword from './pages/RecoveryPassword';
import { Settings } from './pages/Setting';
import { selectRole } from './slices/Auth';
import { Role } from './types/Role';

function App(): JSX.Element {
  const userRole = useSelector(selectRole);
  const isAdmin = userRole === Role.ADMIN;

  return (
    <div className="App">
      {isAdmin && <AdminLabel />}
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <RedirectGuard>
                <Login />
              </RedirectGuard>
            }
          />
          <Route path="forgot-password" element={<ForgottenPassword />} />
          <Route path="reset-password" element={<RecoveryPassword />} />
          {isAdmin ? (
            <Route
              path={'/dashboard/*'}
              element={
                <AuthGuard>
                  <UnAuthorizedGuard>
                    <AdminDashboard />
                  </UnAuthorizedGuard>
                </AuthGuard>
              }
            />
          ) : (
            <Route
              path={'/dashboard/*'}
              element={
                <AuthGuard>
                  <UnAuthorizedGuard>
                    <DashboardGuard>
                      <DashboardLayout />
                    </DashboardGuard>
                  </UnAuthorizedGuard>
                </AuthGuard>
              }
            >
              <Route path="" element={<MainDashboard />} />
              <Route path="transport/:subMenu" element={<Transport />} />
              <Route path="transport" element={<Transport />} />
              <Route path="packing/:subMenu" element={<Packing />} />
              <Route path="packing" element={<Packing />} />
              <Route path="depot/:subMenu" element={<Depot />} />
              <Route path="depot" element={<Depot />} />
            </Route>
          )}
          <Route
            path="dashboard/:bookingMacroType/booking/:bookingId"
            element={
              <AuthGuard>
                <UnAuthorizedGuard>
                  <Booking />
                </UnAuthorizedGuard>
              </AuthGuard>
            }
          />
          <Route
            path={'/settings'}
            element={
              <AuthGuard>
                <UnAuthorizedGuard>
                  <Settings />
                </UnAuthorizedGuard>
              </AuthGuard>
            }
          />
          <Route path={'/forbidden'} element={<PageForbidden />} />
          <Route path={'/*'} element={<PageNotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
