export const settingName = {
  language: 'LANGUAGE',
  dateFormat: 'DATE_FORMAT',
  measurementSystem: 'MEASUREMENT_SYSTEM'
};

export const settingFormSentMessage = {
  error: 'errorMessage',
  success: 'successMessage'
};

export const measurementSystems = {
  metric: 'METRIC',
  imperial: 'IMPERIAL'
};
