import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Banner from 'src/app/components/Banner';
import { BookingItem, PACKING_STEPS } from 'src/app/types/Booking';
import { translations } from 'src/locales/translations';
import { LevelType } from 'src/types/LevelType';
import {
  DEFAULT_BOOKING_DEPOT_COMPLETED,
  DEFAULT_BOOKING_PACKING_COMPLETED
} from 'src/utils/helpers/BookingHelper';

import StatusBannerWrapper from '../../../../Features/StatusBanner/components/StatusBannerWrapper';
import StatusStepper from '../../../../Features/StatusBanner/components/StatusStepper';
import { hasFile } from '../../../../Helpers/hasFile';

type Props = {
  isLoading?: boolean;
  booking: BookingItem;
};

function PackingStatusBanner({ isLoading, booking }: Props): JSX.Element {
  const { t } = useTranslation();
  const [banner, setBanner] = useState<JSX.Element>();
  const [dataTestId, setDataTestId] = useState<string>();

  useEffect(() => {
    if (DEFAULT_BOOKING_DEPOT_COMPLETED.includes(booking.status)) {
      setDataTestId('banner-packing-job-completed');
      setBanner(
        <Banner
          buttonLink={'/'}
          type={LevelType.SUCCESS}
          message={t(
            translations.pages.booking.pages.packing.features.banner
              .completedMessage
          )}
          buttonText={t(
            translations.pages.booking.pages.packing.features.banner
              .completedButton
          )}
        />
      );
    } else {
      setBanner(undefined);
      setDataTestId('stepper-packing');
    }
  }, [booking]);

  return (
    <StatusBannerWrapper
      isLoading={isLoading}
      banner={banner}
      stepper={
        !banner ? (
          <StatusStepper
            hasBookingFileUploaded={hasFile(booking)}
            status={booking.status}
            steps={PACKING_STEPS}
            bookingType={'packing'}
            statusCompleted={DEFAULT_BOOKING_PACKING_COMPLETED}
          />
        ) : undefined
      }
      dataTestId={dataTestId}
    />
  );
}

export default PackingStatusBanner;
