import styles from '@convelio/stylio/styles.module.scss';
import { Divider, DividerProps, styled } from '@mui/material';
import { useResponsiveness } from 'src/app/hooks/useResponsive';

type PanelProps = {
  isTabletView: boolean;
};

const PanelStyled = styled('div')(({ isTabletView }: PanelProps) => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: isTabletView ? 0 : '3%',
  paddingRight: styles.cvoSpacingXs,
  paddingLeft: styles.cvoSpacingXs
}));

const PanelDividerStyled = styled((props: DividerProps) => (
  <Divider variant="middle" {...props} />
))({
  marginTop: 26,
  marginBottom: 26
});

type Props = {
  files?: JSX.Element;
  activity: JSX.Element;
};

function LateralPanelWrapper({ files, activity }: Props): JSX.Element {
  const { isTabletView } = useResponsiveness();
  return (
    <PanelStyled isTabletView={isTabletView}>
      {files}
      <PanelDividerStyled />
      {activity}
    </PanelStyled>
  );
}

export default LateralPanelWrapper;
