import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery
} from '@reduxjs/toolkit/dist/query';
import { Mutex } from 'async-mutex';

import { RootState } from '../../store/store';
import { resetAppStateAndStorage } from '../../utils/helpers/ResetAppStateAndStorage';
import { setTokens, setUnAuthorized } from '../slices/Auth';

const mutex = new Mutex();
export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.accessToken;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

export const baseQueryRefresh = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.refreshToken;
    if (token) {
      headers.set('authorization', `Refresh ${token}`);
    }
    return headers;
  }
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQueryRefresh(
          '/auth/refresh',
          api,
          extraOptions
        );
        if (refreshResult.data) {
          await api.dispatch(
            setTokens(
              refreshResult.data as {
                access_token: string;
                refresh_token: string;
              }
            )
          );
          result = await baseQuery(args, api, extraOptions);
        } else {
          resetAppStateAndStorage(api.dispatch);
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  } else if (result.error && result.error.status === 409) {
    const params = args as FetchArgs;
    if (
      params?.method === 'POST' &&
      params?.body.version !== undefined &&
      params?.url.indexOf('/bookings/') > -1
    ) {
      const bookingNumber = params.url.split('/')[2];
      const historyResponse = await baseQuery(
        `/bookings/${bookingNumber}/history`,
        api,
        extraOptions
      );
      // eslint-disable-next-line
      const history = historyResponse.data as any;
      console.log(
        '---- history POST 409:',
        history.data.slice(0, -1 * (params.body.version + 1))
      );
    }
  } else if (result.error && result.error.status === 403) {
    api.dispatch(setUnAuthorized({ unAuthorized: true }));
  }
  return result;
};
