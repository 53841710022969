import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { translations } from 'src/locales/translations';
import { BookingListItem } from 'src/types/Booking';
import { BookingStatusType } from 'src/types/BookingType';
import { DEFAULT_BOOKING_PACKING_COMPLETED } from 'src/utils/helpers/BookingHelper';

import BookingLocationDate from '../../../components/BookingLocationDate';
import BookingLocationPartern from '../../../components/BookingLocationPartner';
import DashboardBookingArrow from '../../../components/DashboardBookingArrow';

interface BookingPackingLogisticProps {
  booking: BookingListItem;
}

export const BookingPackingLogistic = ({
  booking
}: BookingPackingLogisticProps): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const translationPacking = translations.pages.dashboard.pages.packing;
  const isMinWidthMobile = useMediaQuery(theme.breakpoints.up('mobile'));

  return (
    <Wrapper isMinWidthMobile={isMinWidthMobile}>
      <BoxRowStyled>
        <DateBoxStyled>
          <BookingLocationDate
            label={t(
              translationPacking.dropOff[
                booking.status === BookingStatusType.PENDING
                  ? 'etaFlag'
                  : 'title'
              ]
            )}
            date={
              booking.status === BookingStatusType.PENDING
                ? booking.startExpectedOn
                : booking.startDate
            }
          />
        </DateBoxStyled>
        <PartnerBoxStyled>
          <BookingLocationPartern
            label={t(translationPacking.dropOff.partner)}
            partner={booking?.startCompanyName}
          />
        </PartnerBoxStyled>
      </BoxRowStyled>
      <ArrowBoxStyled>
        <DashboardBookingArrow isMinWidthMobile={isMinWidthMobile} />
      </ArrowBoxStyled>
      <BoxRowStyled>
        <DateBoxStyled>
          <BookingLocationDate
            label={t(
              translationPacking.pickup[
                DEFAULT_BOOKING_PACKING_COMPLETED.includes(booking.status)
                  ? 'title'
                  : 'etaFlag'
              ]
            )}
            date={
              DEFAULT_BOOKING_PACKING_COMPLETED.includes(booking.status)
                ? booking.endDate
                : booking.endExpectedOn
            }
          />
        </DateBoxStyled>

        <PartnerBoxStyled>
          <BookingLocationPartern
            label={t(translationPacking.pickup.partner)}
            partner={booking?.endCompanyName}
          />
        </PartnerBoxStyled>
      </BoxRowStyled>
    </Wrapper>
  );
};

interface WrapperProps {
  isMinWidthMobile: boolean;
}

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMinWidthMobile'
})<WrapperProps>(({ isMinWidthMobile }) => ({
  paddingTop: 8,
  display: 'flex',
  flexDirection: isMinWidthMobile ? 'row' : 'column'
}));

const BoxRowStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'start'
});

const ArrowBoxStyled = styled(Box)({
  padding: 4
});

const DateBoxStyled = styled(Box)({
  paddingLeft: 4,
  paddingRight: 14
});

const PartnerBoxStyled = styled(Box)({
  paddingLeft: 14,
  paddingRight: 14
});
