import { translations } from '../../../../../../../locales/translations';
import { BookingUserType } from '../../../../../../../types/BookingType';
import { ActivityData } from '../../ActivityData';
import { BuilderDecisionParams } from '../../BuilderDecisionParams';
import { EventMessageBuilder } from '../../EventMessageBuilder';
import { useFormatTranslationWithDate } from '../../MainMessageBuilder/useFormatTranslationWithDate';

export const useReadyForCollectionDateMessageBuilder =
  (): EventMessageBuilder => {
    const { formatTranslationWithDate } = useFormatTranslationWithDate();

    const supports = ({
      eventResult: { data, userType }
    }: BuilderDecisionParams): boolean => {
      return userType === BookingUserType.SF && data.startReadyOn !== undefined;
    };

    const build = ({ startReadyOn }: ActivityData): string => {
      return formatTranslationWithDate(
        translations.pages.booking.features.activity.readyOn,
        startReadyOn
      );
    };

    return {
      build,
      supports
    };
  };
