import { styled } from '@mui/material';
import { ButtonProps } from 'src/app/types/ButtonProps';

export const Button = ({
  variant = 'ghost',
  color = 'primary',
  label,
  startIcon,
  endIcon,
  additionalClasses,
  dataTestid,
  autoFocus,
  isLoading,
  onClick,
  ...props
}: ButtonProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      event.preventDefault();
      onClick(event);
    }
  };

  const StyledButton = styled('button')({
    path: {
      stroke: 'none !important'
    }
  });

  return (
    <StyledButton
      className={`cvo-button -${variant} -${color}${
        additionalClasses ? ` ${additionalClasses}` : ''
      }`}
      data-testid={dataTestid}
      autoFocus={autoFocus}
      onClick={handleClick}
      {...props}
    >
      <>
        {(isLoading === undefined || isLoading) && startIcon && (
          <span className="cvo-button-icon">{startIcon} </span>
        )}
        {label && <span className="cvo-button-label">{label}</span>}
        {endIcon && <span className="cvo-button-icon">{endIcon}</span>}
      </>
    </StyledButton>
  );
};
