import DashboardIcon from '@mui/icons-material/Dashboard';
import LineStyleIcon from '@mui/icons-material/LineStyle';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PagesIcon from '@mui/icons-material/Pages';
import { Box, Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routes } from 'src/types/Routes';

import { translations } from '../../../locales/translations';
import { DashboardColor } from '../../types/menu';

interface MainNavBarProps {
  dashboardColor: DashboardColor;
}

export const MainNavBar = ({ dashboardColor }: MainNavBarProps) => {
  const { t } = useTranslation();
  const mainNavBarTranslations = translations.components.mainNavBar;

  const isActive = (route: string) => {
    if (route === routes.dashboard) {
      return location.pathname === route;
    }
    return location.pathname.startsWith(route);
  };

  const MainMenuItems = [
    {
      label: t(mainNavBarTranslations.dashboard),
      icon: <DashboardIcon />,
      route: routes.dashboard,
      isActive: isActive(routes.dashboard),
      activeMenuColor: dashboardColor
    },
    {
      label: t(mainNavBarTranslations.transport),
      icon: <LocalShippingIcon />,
      route: routes.transport,
      isActive: isActive(routes.transport),
      activeMenuColor: dashboardColor
    },
    {
      label: t(mainNavBarTranslations.packing),
      icon: <LineStyleIcon />,
      route: routes.packing,
      isActive: isActive(routes.packing),
      activeMenuColor: dashboardColor
    },
    {
      label: t(mainNavBarTranslations.depot),
      icon: <PagesIcon />,
      route: routes.depot,
      isActive: isActive(routes.depot),
      activeMenuColor: dashboardColor
    }
  ];

  return (
    <Wrapper>
      <Stack>
        {MainMenuItems.map((item) => (
          <Link to={item.route} key={item.route}>
            <Item isActive={item.isActive} activeMenuColor={dashboardColor}>
              <Icon isActive={item.isActive} activeMenuColor={dashboardColor}>
                {item.icon}
              </Icon>
              <Label isActive={item.isActive} activeMenuColor={dashboardColor}>
                {item.label}
              </Label>
            </Item>
          </Link>
        ))}
      </Stack>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '72px',
  backgroundColor: 'white',
  height: '100%',
  borderRightWidth: 1,
  borderRightStyle: 'solid',
  borderRightColor: theme.palette.primary.light,
  padding: 2
}));

interface IsActiveProps {
  isActive: boolean;
  activeMenuColor: DashboardColor;
}

const Item = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'activeMenuColor'
})<IsActiveProps>(({ isActive, theme, activeMenuColor }) => ({
  ...theme.typography.subtitle1,
  padding: theme.spacing(4),
  textAlign: 'center',
  color: theme.palette.text.primary,
  height: 68,
  width: 68,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: isActive ? 'inherit' : activeMenuColor.backgroundColor,
    cursor: isActive ? 'default' : 'pointer'
  },
  '&:hover > svg': {
    backgroundColor: isActive ? activeMenuColor.backgroundColor : 'inherit'
  }
}));

const Icon = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'activeMenuColor'
})<IsActiveProps>(({ isActive, theme, activeMenuColor }) => ({
  backgroundColor: isActive ? activeMenuColor.backgroundColor : 'white',
  color: isActive ? activeMenuColor.color : 'inherit',
  display: 'flex',
  borderRadius: '5px',
  padding: theme.spacing(4),
  '& > *': {
    fontSize: '1.1rem'
  }
}));

const Label = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'activeMenuColor'
})<IsActiveProps>(({ isActive, activeMenuColor }) => ({
  color: isActive ? activeMenuColor.color : 'inherit',
  fontSize: 'small',
  lineHeight: '1.2'
}));
