import { useMemo } from 'react';

import { BookingNumberInfo } from '../Application/BookingNumberInfo';
import { emptyBookingNumberInfo } from '../Application/EmptyBookingNumberInfo';
import { parseBookingNumber } from '../Application/ParseBookingNumber';
import { InvalidBookingNumberError } from '../Domain/Error/InvalidBookingNumberError';

interface BookingNumberResult {
  bookingNumberInfo: BookingNumberInfo;
  error: Error | undefined;
}

export const useBookingNumber = (
  bookingNumber?: string
): BookingNumberResult => {
  const [bookingNumberInfo, error] = useMemo(() => {
    if (!bookingNumber) {
      return [emptyBookingNumberInfo, undefined];
    }

    try {
      const result = parseBookingNumber(bookingNumber);
      return [result, undefined];
    } catch (error) {
      if (error instanceof InvalidBookingNumberError) {
        return [emptyBookingNumberInfo, error];
      } else {
        return [
          emptyBookingNumberInfo,
          new Error('An unexpected error occurred')
        ];
      }
    }
  }, [bookingNumber]);

  return { bookingNumberInfo, error };
};
