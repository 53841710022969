import { ThemeProvider } from '@mui/material';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';

import App from './app/App';
import './index.scss';
import './locales/i18n';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';
import { getMuiTheme } from './styles';

const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? '';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
      <Provider store={store}>
        <ThemeProvider theme={getMuiTheme()}>
          <App />
        </ThemeProvider>
      </Provider>
    </GoogleReCaptchaProvider>
  </StrictMode>
);

if (module.hot) {
  module.hot.accept(['./locales/i18n']);
}

reportWebVitals();
