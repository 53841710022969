import { Text } from '@react-pdf/renderer';
import React from 'react';

import { PackingCrate } from '../../../../../../types/Packing';
import { usePackingCrateDimensions } from '../../../../../pages/Booking/Pages/PackingBooking/hooks/usePackingCrateDimensions';
import { TableCell, TableRow } from '../../../Table';

interface PackingCrateTableProps {
  crate: PackingCrate;
  isConfirmation?: boolean;
}

export const PackingCrateRow: React.FC<PackingCrateTableProps> = ({
  crate,
  isConfirmation = false
}: PackingCrateTableProps) => {
  const dimensions: string = usePackingCrateDimensions(crate, isConfirmation);

  return (
    <TableRow key={crate.id}>
      <TableCell>
        <Text>{crate.name}</Text>
      </TableCell>
      <TableCell>
        <Text>{crate.quantity}</Text>
      </TableCell>
      <TableCell>
        <Text>{dimensions}</Text>
      </TableCell>
    </TableRow>
  );
};
