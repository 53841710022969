import { useTranslation } from 'react-i18next';

import { useDate } from '../../../../../hooks/useDate';

export const useFormatTranslationWithDate = () => {
  const { t } = useTranslation();
  const { fromIsoToDateFormat } = useDate();

  const formatTranslationWithDate = (
    translationKey: string,
    dateStr: string | undefined
  ): string => {
    if (!dateStr) return '';
    const date = fromIsoToDateFormat(dateStr);

    return t(translationKey, { date });
  };

  return {
    formatTranslationWithDate
  };
};
