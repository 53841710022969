import { FinancialValue } from '../FinancialValue';
import { useOriginalBudgetData } from './useOriginalBudgetData';

interface OriginalBudgetProps {
  budgetEur: number;
  budgetUsd: number;
  budgetGbp: number;
}

export const OriginalBudget = ({
  budgetEur,
  budgetUsd,
  budgetGbp
}: OriginalBudgetProps): JSX.Element => {
  const { originalBudgetFormatted, tooltipTitle } = useOriginalBudgetData({
    budgetEur,
    budgetUsd,
    budgetGbp
  });

  return (
    <FinancialValue
      value={originalBudgetFormatted}
      testId="original-budget"
      tooltipTitle={tooltipTitle}
    />
  );
};
