import { Box, Divider, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'src/locales/translations';
import { BookingStatusType } from 'src/types/BookingType';
import {
  CrateChangeMethodType,
  CrateForm,
  PACKING_ESTIMATE_FORM_KEYS,
  PackingCrate,
  PackingCrateOperation
} from 'src/types/Packing';

import AccordionSubTitle from '../../components/AccordionSubTitle';
import { PackingCrateForm } from '../../components/PackingCrateForm';
import PackingDateForm from '../../components/PackingDateForm';
import { usePacking } from '../../hooks/usePacking';

const MainEditWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

type Props = {
  bookingId: string;
  disabled: boolean;
  isBeingEdited: boolean;
  status: BookingStatusType;
  crates: PackingCrate[] | null;
  packExpectedOn: string | null;
  handleEstimationIsDone: (value: boolean) => void;
  handleSubmitPackingEstimation: (date?: Date) => void;
  handleChangeCrate: (
    crate: PackingCrate,
    method: CrateChangeMethodType,
    operation?: PackingCrateOperation
  ) => Promise<PackingCrate>;
};

function PackingEstimationEdit({
  bookingId,
  status,
  crates,
  disabled,
  isBeingEdited,
  packExpectedOn,
  handleChangeCrate,
  handleEstimationIsDone,
  handleSubmitPackingEstimation
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { isEstimationStepDone, estimationStepIsBeingEditedCrate } =
    usePacking();
  const [isDone, setIsDone] = useState<boolean>(false);
  const [isCrateFormValid, setIsCrateFormValid] = useState<boolean>(false);
  const packingTranslations =
    translations.pages.booking.pages.packing.features.packingEstimation;

  useEffect(() => {
    const value = isEstimationStepDone(status, disabled);
    setIsDone(value);
    handleEstimationIsDone(value);
  }, [disabled, status]);

  const handleConfirmCrate = async (
    crate: PackingCrate
  ): Promise<PackingCrate> => {
    return handleChangeCrate(
      crate,
      crate.id ? CrateChangeMethodType.PUT : CrateChangeMethodType.POST,
      PackingCrateOperation.ESTIMATION
    );
  };

  const handleDateEstimation = ({ date }: { date: Date }) => {
    handleSubmitPackingEstimation(date);
  };

  const handleDeleteCrate = async (crate: PackingCrate): Promise<void> => {
    await handleChangeCrate(crate, CrateChangeMethodType.DELETE);
  };

  const handleCrateFormValid = (crates: CrateForm[]): void => {
    setIsCrateFormValid(crates.every((crate) => crate.id));
  };

  return (
    <MainEditWrapper>
      {!isDone && (
        <AccordionSubTitle
          title={`A - ${t(packingTranslations.titleCrateForm)}`}
          tooltip={t(packingTranslations.tooltioCrateForm)}
        />
      )}
      <PackingCrateForm
        bookingId={bookingId}
        status={status}
        isDone={isDone}
        formKeys={PACKING_ESTIMATE_FORM_KEYS}
        handleDeleteCrate={handleDeleteCrate}
        handleConfirmCrate={handleConfirmCrate}
        handleCrateFormValid={handleCrateFormValid}
        formType={PackingCrateOperation.ESTIMATION}
        subTitle={t(packingTranslations.subTitleCrateForm)}
        isBeingEdited={estimationStepIsBeingEditedCrate(isBeingEdited, status)}
        crates={
          status === BookingStatusType.PACKING_SCHEDULED && crates?.length === 0
            ? null
            : crates?.map((crate) => ({ ...crate, notEditable: !!crate.id }))
        }
      />
      {!isDone && <Divider variant="fullWidth" sx={{ marginY: 24 }} />}
      {!isDone && (
        <AccordionSubTitle
          title={`B - ${t(packingTranslations.titleDateForm)}`}
        />
      )}
      <PackingDateForm
        dataTestId="packing-estimation-date-form"
        isDone={isDone}
        date={packExpectedOn}
        disabled={!isCrateFormValid}
        isBeingEdited={isBeingEdited}
        handleDateChange={handleDateEstimation}
        label={t(packingTranslations.dateFormLabel)}
        buttonLabel={t(packingTranslations.dateFormButtonLabel)}
      />
    </MainEditWrapper>
  );
}

export default PackingEstimationEdit;
