import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { styled } from '@mui/material';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { ReactNode } from 'react';

type AccordionSummaryType = {
  children: ReactNode;
};

const AccordionSummary = styled((props: AccordionSummaryType) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  >
    {props.children}
  </MuiAccordionSummary>
))(({ theme }) => ({
  color: theme.palette.common.black,
  borderRadius: '4px',
  padding: '16px 8px',
  margin: 0,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    margin: 0
  }
}));

export default AccordionSummary;
