import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  title: string;
  subTitle: string;
};
const defaultBoxSx: SxProps = {
  display: 'flex',
  flexDirection: 'column'
};

const typograhyTitleSx: SxProps = {
  fontWeight: 700,
  fontSize: '2rem',
  lineHeight: '2.25rem',
  color: 'grey.600'
};

const typograhySubTitleSx: SxProps = {
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  paddingTop: 24,
  color: 'grey.500'
};

function ErrorPageMessage({ title, subTitle }: Props): JSX.Element {
  return (
    <Box sx={defaultBoxSx}>
      <Typography sx={typograhyTitleSx}>{title}</Typography>
      <Typography sx={typograhySubTitleSx}>{subTitle}</Typography>
    </Box>
  );
}

export default ErrorPageMessage;
