import { translations } from '../../../../../../../locales/translations';
import { BookingUserType } from '../../../../../../../types/BookingType';
import { BOOKING_TRANSPORT_TYPES } from '../../../../../../../utils/helpers/BookingHelper';
import { ActivityData } from '../../ActivityData';
import { BuilderDecisionParams } from '../../BuilderDecisionParams';
import { EventMessageBuilder } from '../../EventMessageBuilder';
import { useFormatTranslationWithDate } from '../../MainMessageBuilder/useFormatTranslationWithDate';

export const usePickupDateMessageBuilder = (): EventMessageBuilder => {
  const { formatTranslationWithDate } = useFormatTranslationWithDate();

  const supports = ({
    bookingType,
    eventResult: { data, userType }
  }: BuilderDecisionParams): boolean => {
    return (
      userType === BookingUserType.SF &&
      data.startDate !== undefined &&
      BOOKING_TRANSPORT_TYPES.includes(bookingType)
    );
  };

  const build = ({ startDate }: ActivityData): string => {
    return formatTranslationWithDate(
      translations.pages.booking.features.activity.startDateUpdated,
      startDate
    );
  };

  return {
    build,
    supports
  };
};
