import * as countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import fr from 'i18n-iso-countries/langs/fr.json';

import flags from '../assets/flags.json';

countries.registerLocale(en);
countries.registerLocale(fr);

export function getFlagFromName(country?: string): string {
  if (!country) return '';
  const countryCode =
    countries.getAlpha2Code(country, 'en') ||
    countries.getAlpha2Code(country, 'fr');
  return flags[countryCode as keyof typeof flags]?.emoji;
}
