import styles from '@convelio/stylio/styles.module.scss';
import { StyleSheet, View } from '@react-pdf/renderer';
import { ReactNode } from 'react';

interface TableProps {
  children: ReactNode;

  [propName: string]: unknown;
}

export const Table = ({ children, ...props }: TableProps) => {
  return (
    <View style={style.table} {...props}>
      {children}
    </View>
  );
};

const style = StyleSheet.create({
  table: {
    display: 'flex',
    width: '100%',
    borderStyle: 'solid',
    borderBottomWidth: '1px',
    marginVertical: styles.cvoSpacingS
  }
});
