import { useNavigate } from 'react-router-dom';
import { BookingListItem } from 'src/types/Booking';

import { BookingNumber } from '../../../../../components/BookingNumber';
import CustomerRef from '../../../components/CustomerRef';
import DashboardBookingCardWrapper from '../../../components/DashboardBookingCardWrapper';
import { BookingJobType } from '../../../types';
import BookingDepotAction from './BookingDepotAction';
import BookingDepotFlags from './BookingDepotFlags';
import BookingDepotLogistic from './BookingDepotLogistic';

type Props = {
  isLoading?: boolean;
  jobType: BookingJobType;
  booking: BookingListItem;
};

function BookingDepotCard({ booking, isLoading }: Props): JSX.Element {
  const navigate = useNavigate();
  const handleAction = (): void =>
    navigate(`/dashboard/depot/booking/${booking?.id}`);
  return (
    <DashboardBookingCardWrapper
      isLoading={isLoading}
      bookingNumber={<BookingNumber bookingNumber={booking?.bookingNumber} />}
      flags={<BookingDepotFlags booking={booking} />}
      customerRef={<CustomerRef booking={booking} />}
      actions={<BookingDepotAction handleAction={handleAction} />}
      logistic={<BookingDepotLogistic booking={booking} />}
      link={`/dashboard/depot/booking/${booking?.id}`}
    />
  );
}

export default BookingDepotCard;
