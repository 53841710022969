import { Box, Chip, styled } from '@mui/material';
import { differenceInDays, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'src/locales/translations';
import { BookingListItem } from 'src/types/Booking';
import { DEFAULT_BOOKING_DEPOT_COMPLETED } from 'src/utils/helpers/BookingHelper';

const WrapperBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row'
});

const ChipStyled = styled(Chip)({
  paddingX: 8,
  paddingY: 4,
  fontSize: '0.8rem',
  lineHeight: '1.09rem',
  fontWeight: 600,
  height: 24
});

const DayOnStorageStyledChip = styled(ChipStyled)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.secondary.main,
  marginLeft: 4
}));

type Props = {
  booking: BookingListItem;
};

function BookingDepotFlags({ booking }: Props): JSX.Element {
  const { t } = useTranslation();
  const tranlationDepot = translations.pages.dashboard.pages.depot;
  const [daysOfStorage, setDaysOfStorage] = useState<number | null>();

  useEffect(() => {
    const isCompleted = DEFAULT_BOOKING_DEPOT_COMPLETED.includes(
      booking.status
    );
    const endDate = booking[isCompleted ? 'endDate' : 'endExpectedOn'];
    const startDate = booking[isCompleted ? 'startDate' : 'startExpectedOn'];

    if (!endDate || !startDate) {
      setDaysOfStorage(null);
      return;
    }
    setDaysOfStorage(differenceInDays(parseISO(endDate), parseISO(startDate)));
  }, [booking]);

  return (
    <WrapperBoxStyled>
      {daysOfStorage !== null && daysOfStorage !== undefined && (
        <DayOnStorageStyledChip
          label={`${daysOfStorage} ${t(
            tranlationDepot[
              DEFAULT_BOOKING_DEPOT_COMPLETED.includes(booking.status)
                ? 'dayOfStorage'
                : 'dayOfStorageEstimated'
            ].title
          )}`}
        />
      )}
    </WrapperBoxStyled>
  );
}

export default BookingDepotFlags;
