type Props = {
  src: string;
  alt: string;
};

function ErrorPageImage({ src, alt }: Props): JSX.Element {
  return <img src={src} alt={alt} />;
}

export default ErrorPageImage;
