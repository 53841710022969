import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../../locales/translations';
import MerchandisePackDetail from './MerchandisePackDetail';
import isDetailRelevant from './isDetailRelevant';

type Props = {
  details: string[];
};

const MerchandisePackDetails = ({ details }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1rem'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: '0.5rem'
        }}
      >
        <Typography
          sx={{
            lineHeight: '1.36rem',
            fontWeight: 600,
            color: `grey.500`,
            textAlign: 'left'
          }}
        >
          {t(translations.pages.booking.features.merchandise.loadDetails)}
        </Typography>
      </Box>
      {details.map((detail, index) => {
        if (!isDetailRelevant(detail)) {
          return;
        }

        return <MerchandisePackDetail key={index} detail={detail} />;
      })}
    </Box>
  );
};

export default MerchandisePackDetails;
