import { KeyboardEvent, useState } from 'react';

import {
  SearchBookingListResponse,
  SearchBookingResponse
} from '../../Core/Application/SearchBookingsResponse';
import { useSearchBookings } from './useSearchBookings';

interface SearchResultsProps {
  inputValue: string;
  navigateToBookingDetails: (booking: SearchBookingResponse) => void;
  initialSelectedBookingIndex?: number;
}

interface SearchResultsReturnType {
  bookings: SearchBookingListResponse;
  isLoading: boolean;
  handleKeyDown: (event: KeyboardEvent) => void;
  selectedBookingIndex: number;
  setSelectedBookingIndex: (index: number) => void;
}

export const useSearchResults = ({
  inputValue,
  navigateToBookingDetails,
  initialSelectedBookingIndex = 0
}: SearchResultsProps): SearchResultsReturnType => {
  const { bookings, isLoading } = useSearchBookings(inputValue);
  const [selectedBookingIndex, setSelectedBookingIndex] = useState<number>(
    initialSelectedBookingIndex
  );

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!bookings) return;
    if (event.code === 'ArrowUp' && selectedBookingIndex > 0) {
      setSelectedBookingIndex((prevState) => prevState - 1);
    } else if (
      event.code === 'ArrowDown' &&
      selectedBookingIndex < bookings.length - 1
    ) {
      setSelectedBookingIndex((prevState) => prevState + 1);
    } else if (event.code === 'Enter') {
      navigateToBookingDetails(bookings[selectedBookingIndex]);
    }
  };

  return {
    bookings,
    isLoading,
    handleKeyDown,
    selectedBookingIndex,
    setSelectedBookingIndex
  };
};
