import { Skeleton, Stack } from '@mui/material';

function NoteBodyLodaer(): JSX.Element {
  return (
    <Stack spacing={1}>
      <Skeleton variant="text" width="100%" />
      <Skeleton variant="text" width="100%" />
    </Stack>
  );
}

export default NoteBodyLodaer;
