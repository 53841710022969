import styles from '@convelio/stylio/styles.module.scss';
import { Box, Grid, GridProps, styled } from '@mui/material';
import { BookingMacroType } from 'src/types/BookingType';

import { CostOverviewLoader } from './CostOverview';
import { HeaderAccountNameLoader } from './HeaderBookingNameLoader';
import HeaderBookingNumberLoader from './HeaderBookingNumberLoader';
import HeaderFlagsLoader from './HeaderFlagLoader';
import HeaderLinkLoader from './HeaderLinkLoader';
import ReturnToBoard from './ReturnToBoard';

interface HeaderWrapperProps {
  type: BookingMacroType;
  accountName: JSX.Element;
  isLoading?: boolean;
  flags?: JSX.Element;
  costOverview?: JSX.Element;
  bookingNumber?: JSX.Element;
  link?: JSX.Element;
  downloadPdf?: JSX.Element;
}

export const HeaderWrapper = ({
  type,
  flags,
  costOverview,
  bookingNumber,
  link,
  isLoading,
  accountName,
  downloadPdf
}: HeaderWrapperProps): JSX.Element => {
  if (isLoading) {
    return (
      <HeaderBoxStyled>
        <GridRowStyled>
          <GridItemLink>
            <ReturnToBoard type={type} />
            <HeaderLinkLoader />
          </GridItemLink>
          <GridItemFlags>
            <HeaderFlagsLoader />
          </GridItemFlags>
        </GridRowStyled>
        <GridRowStyled paddingTop={24}>
          <GridItemBookingTitle>
            <HeaderBookingNumberLoader />
            <HeaderAccountNameLoader />
          </GridItemBookingTitle>
          <GridItemCostOverview>
            <CostOverviewLoader />
          </GridItemCostOverview>
        </GridRowStyled>
      </HeaderBoxStyled>
    );
  }

  return (
    <HeaderBoxStyled>
      <GridRowStyled>
        <GridItemLink>
          <ReturnToBoard type={type} />
          {link}
        </GridItemLink>
        <GridItemFlags>
          {flags} {downloadPdf}
        </GridItemFlags>
      </GridRowStyled>
      <GridRowStyled>
        <GridItemBookingTitle>
          <Box>{bookingNumber}</Box>
          <Box>{accountName}</Box>
        </GridItemBookingTitle>
        <GridItemCostOverview>{costOverview}</GridItemCostOverview>
      </GridRowStyled>
    </HeaderBoxStyled>
  );
};

const HeaderBoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  paddingLeft: '4%',
  paddingRight: 23,
  paddingBottom: 16,
  paddingTop: 14,
  [theme.breakpoints.down('tablet')]: {
    padding: styles.cvoSpacingS
  },
  display: 'flex',
  flexDirection: 'column'
}));

const GridRowStyled = styled((props: GridProps) => (
  <Grid container direction="row" {...props} />
))({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});

const GridItemLink = styled((props: GridProps) => (
  <Grid item xs={7} {...props} />
))({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});

const GridItemFlags = styled((props: GridProps) => (
  <Grid item xs={5} {...props} />
))({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'end',
  paddingTop: styles.cvoSpacingS,
  paddingBottom: styles.cvoSpacingS
});

const GridItemBookingTitle = styled((props: GridProps) => (
  <Grid item xs={12} lg={7} {...props} />
))(({ theme }) => ({
  display: 'flex',
  gap: styles.cvoSpacingXs,
  [theme.breakpoints.up('tablet')]: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    alignItems: 'left',
    paddingTop: styles.cvoSpacingS,
    paddingBottom: styles.cvoSpacingS
  }
}));

const GridItemCostOverview = styled((props: GridProps) => (
  <Grid item xs={12} lg={5} {...props} />
))(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  alignItems: 'end',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'flex-start',
    marginTop: theme.spacing(10)
  }
}));
