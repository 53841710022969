import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextFieldProps
} from '@mui/material';
import { useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../locales/translations';
import { Button } from '../Button';

type PasswordInputProps = TextFieldProps & {
  datatestid?: string;
};

function PasswordInput(props: PasswordInputProps): JSX.Element {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const color = props.error ? 'error' : 'primary';
  return (
    <FormControl sx={props.sx} variant="outlined">
      <InputLabel htmlFor="password" color={color}>
        {props.label ? props.label : t(translations.password.title)}
      </InputLabel>
      <OutlinedInput
        id="password"
        type={showPassword ? 'text' : 'password'}
        inputProps={{ 'data-testid': props.datatestid }}
        endAdornment={
          <InputAdornment position="end">
            <Button
              onMouseDown={handleMouseDownPassword}
              onClick={handleClickShowPassword}
              additionalClasses="-icon"
              startIcon={
                showPassword ? (
                  <VisibilityOff color={color} fontSize="inherit" />
                ) : (
                  <Visibility color={color} fontSize="inherit" />
                )
              }
            />
          </InputAdornment>
        }
        label={props.label ? props.label : t(translations.password.title)}
        placeholder={
          props.placeholder ? props.placeholder : t(translations.password.title)
        }
        value={props.value}
        onChange={props.onChange}
        error={!!props.error}
        onBlur={props.onBlur}
      />
    </FormControl>
  );
}

export default PasswordInput;
