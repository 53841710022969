import { Box, styled } from '@mui/material';

import { useBookingNumber } from './useBookingNumber';

interface BookingNumberProps {
  bookingNumber?: string;
  withStyle?: boolean;
  testId?: string;
  boldCvoId?: boolean;
}

export const BookingNumber = ({
  bookingNumber,
  withStyle = true,
  boldCvoId = false,
  testId
}: BookingNumberProps): JSX.Element => {
  const Wrapper = withStyle ? WrapperBoxStyled : Box;
  const cvoIdStyle = boldCvoId ? { fontWeight: 'bold' } : {};
  const {
    bookingNumberInfo: { customerId, cvoId }
  } = useBookingNumber(bookingNumber);

  return (
    <Wrapper data-testid={testId}>
      <Box component="span" color="grey.500">
        {customerId}
      </Box>
      <Spacer component="span" />
      <Box component="span" color="common.black" style={cvoIdStyle}>
        {cvoId}
      </Box>
    </Wrapper>
  );
};

const WrapperBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontWeight: 'bold'
});

const Spacer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 1)
}));
