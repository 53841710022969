import styles from '@convelio/stylio/styles.module.scss';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../locales/translations';
import { CurrencyType } from '../../../../../../types/BookingType';
import { useCurrency } from '../../../../../hooks/useCurrency';

interface MerchandiseValueSectionProps {
  value: number;
  currencyType: CurrencyType | undefined;
}

export const MerchandiseValueView = ({
  value,
  currencyType
}: MerchandiseValueSectionProps): JSX.Element => {
  const { t } = useTranslation();
  const { getFormattedAmount } = useCurrency();

  return (
    <View>
      <Text style={style.title}>
        {t(translations.pages.booking.features.merchandise.value)}
      </Text>
      <Text>{getFormattedAmount(value, currencyType)}</Text>
    </View>
  );
};

const style = StyleSheet.create({
  title: {
    fontSize: styles.cvoBodySmFontSize,
    fontFamily: 'Helvetica-Bold',
    marginTop: styles.cvoSpacingM,
    marginBottom: styles.cvoSpacingXs
  }
});
