import { Typography } from '@mui/material';
import { Box } from '@mui/system';

type Props = {
  volume: number;
  weight: number;
};

const MerchandiseMeasurement = ({ volume, weight }: Props): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: '0.2rem'
    }}
  >
    <Typography
      sx={{
        lineHeight: '1.36rem',
        fontWeight: 400,
        textAlign: 'left',
        fontSize: '0.875rem'
      }}
    >
      {volume}cmb, {weight}kg
    </Typography>
  </Box>
);

export default MerchandiseMeasurement;
