import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../locales/translations';

type Props = {
  eoriNumber: string;
};

const MerchandiseEoriNumber = ({ eoriNumber }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '0.2rem'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: '0.2rem'
        }}
      >
        <Typography
          data-testid="merchandise-value"
          sx={{
            lineHeight: '1.36rem',
            fontWeight: 600,
            color: `grey.500`,
            textAlign: 'left'
          }}
        >
          {t(translations.pages.booking.features.merchandise.eoriNumber)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Typography
          sx={{
            lineHeight: '1.36rem',
            textAlign: 'left',
            fontSize: '0.875rem'
          }}
        >
          {eoriNumber}
        </Typography>
      </Box>
    </Box>
  );
};

export default MerchandiseEoriNumber;
