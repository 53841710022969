import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { Location } from 'src/types/Location';
import { getFlagFromName } from 'src/utils/helpers/CountryFlags';

type PropsLocation = {
  location: Location;
  variant?: Variant;
  color?: string;
};

function BookingLocation({
  location,
  variant = 'h4',
  color = 'common.black'
}: PropsLocation): JSX.Element {
  const theme = useTheme();
  const city = location?.city;
  const emoji = getFlagFromName(location?.country);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Typography
      variant={variant}
      color={color}
      data-testid="booking-list-location"
    >
      <>
        {location && location.name}
        {city && !isSmallScreen && <> , {city}</>}
        {emoji && ` ${emoji}`}
      </>
    </Typography>
  );
}

export default BookingLocation;
