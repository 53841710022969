import { BookingStatusType } from '../../../../../../../../../types/BookingType';
import { PackingCrate } from '../../../../../../../../../types/Packing';
import { CrateDimension } from '../Domain/Model/CrateDimension';
import { CrateRepository } from '../Domain/Repository/CrateRepository';
import { VersionRepository } from '../Domain/Repository/VersionRepository';
import { AddDefaultCrateRequest } from './AddDefaultCrateRequest';
import { DefaultCrateDimensionFactory } from './DefaultCrateDimensionFactory';

export class AddDefaultCrateUseCase {
  public constructor(
    private readonly versionRepository: VersionRepository,
    private readonly crateRepository: CrateRepository
  ) {}

  public async execute(request: AddDefaultCrateRequest): Promise<PackingCrate> {
    const crateDimension: CrateDimension =
      DefaultCrateDimensionFactory.fromRequest(request);

    if (this.isStatusInProgress(request.status)) {
      return crateDimension;
    }

    crateDimension.version = await this.versionRepository.getVersion(
      request.bookingId
    );

    return await this.crateRepository.save(crateDimension, request.bookingId);
  }

  private isStatusInProgress(status: BookingStatusType): boolean {
    return status === BookingStatusType.IN_PROGRESS;
  }
}
