type Props = {
  email: string;
  subject: string;
  body: string;
  children?: JSX.Element;
};

function MailTo(props: Props): JSX.Element {
  let params = props.subject || props.body ? '?' : '';
  if (props.subject) params += `subject=${encodeURIComponent(props.subject)}`;
  if (props.body)
    params += `${props.subject ? '&' : ''}body=${encodeURIComponent(
      props.body
    )}`;

  return <a href={`mailto:${props.email}${params}`}>{props.children}</a>;
}

export default MailTo;
