import { ReactNode, createContext, useContext } from 'react';

import { SearchBookingsUseCase } from '../Core/Application/SearchBookingsUseCase';
import { RTKQuerySearchBookingsRepository } from './RTKQuerySearchBookingsRepository';

const repository = new RTKQuerySearchBookingsRepository();
const useCaseInstance = new SearchBookingsUseCase(repository);

export const SearchBarUseCaseContext = createContext(useCaseInstance);

interface SearchBarUseCaseProviderProps {
  children: ReactNode;
}

export const SearchBarUseCaseProvider = ({
  children
}: SearchBarUseCaseProviderProps): JSX.Element => {
  return (
    <SearchBarUseCaseContext.Provider value={useCaseInstance}>
      {children}
    </SearchBarUseCaseContext.Provider>
  );
};

export const useSearchBookingsUseCase = () => {
  const context = useContext(SearchBarUseCaseContext);
  if (!context) {
    throw new Error(
      'useSearchBookingsUseCase must be used within a UseCaseProvider'
    );
  }
  return context;
};
