import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../locales/translations';
import PasswordInput from '../../../../../components/PasswordInput';
import { NEW_PASSWORD_KEY } from '../constants';
import { FormData } from '../types';

type Props = {
  control: Control<FormData>;
  errorMessage?: string;
};

export const RecoverPasswordInput: FunctionComponent<Props> = ({
  control,
  errorMessage
}): JSX.Element => {
  const { t } = useTranslation();
  const newPassword: string = t(translations.recoveryPassword.newPassword);

  return (
    <>
      <Controller
        name={NEW_PASSWORD_KEY}
        control={control}
        defaultValue=""
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error }
        }) => (
          <PasswordInput
            sx={{
              marginTop: 16
            }}
            datatestid="new-password-input"
            label={newPassword}
            placeholder={newPassword}
            value={value}
            onChange={onChange}
            error={!!error}
            onBlur={onBlur}
          />
        )}
      />
      <Typography color="error" data-testid="new-password-error-message">
        {errorMessage}
      </Typography>
    </>
  );
};
