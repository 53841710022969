import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  BoxProps,
  Menu,
  MenuItem,
  Typography,
  styled
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/app/components/Button';
import { LinkedBooking } from 'src/app/types/Booking';
import { translations } from 'src/locales/translations';
import { getBookingUrl } from 'src/utils/helpers/BookingHelper';

const OpenNewIcon = styled(OpenInNewIcon)({
  width: 9,
  height: 9,
  marginLeft: 5.5
});

const SelectIcon = styled(KeyboardArrowDownIcon)({
  width: 11,
  height: 11,
  marginLeft: 5.5
});

const MenuItemStyled = styled(MenuItem)({
  minWidth: 150
});

const LinkStyledWrapper = styled((props: BoxProps) => <Box {...props} />)(
  () => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
  })
);

type Props = {
  next?: LinkedBooking;
  previous?: LinkedBooking;
};

function HeaderLink({ next, previous }: Props): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const linkJobTranslation = translations.pages.booking.header.linkJob;

  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (booking: LinkedBooking) => {
    handleClose();
    navigate(getBookingUrl(booking.type, booking.bookingId));
  };

  return (
    <LinkStyledWrapper>
      <Button
        id="basic-button"
        label={
          <Typography variant="h6">{t(linkJobTranslation.title)}</Typography>
        }
        dataTestid={`depot-see-job-button`}
        endIcon={<SelectIcon color="primary" />}
        onClick={handleOpen}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {previous && (
          <MenuItemStyled onClick={() => handleClick(previous)}>
            <Typography variant="body2" color="primary">
              {t(linkJobTranslation.previous)}
              <OpenNewIcon color="primary" />
            </Typography>
          </MenuItemStyled>
        )}
        {next && (
          <MenuItemStyled onClick={() => handleClick(next)}>
            <Typography variant="body2" color="primary">
              {t(linkJobTranslation.next)}
              <OpenNewIcon color="primary" />
            </Typography>
          </MenuItemStyled>
        )}
      </Menu>
    </LinkStyledWrapper>
  );
}

export default HeaderLink;
