import { AppBar, Divider, Toolbar, styled } from '@mui/material';

import { SearchBar, UserMenu } from './';

interface HeaderProps {
  logo: JSX.Element;
}

export const Header = ({ logo }: HeaderProps) => {
  return (
    <Wrapper>
      {logo}
      <MainHeader>
        <ToolbarStyled disableGutters={true}>
          <DividerVerticleStyled />
          <SearchBar />
          <DividerVerticleStyled />
          <UserMenu />
        </ToolbarStyled>
      </MainHeader>
    </Wrapper>
  );
};

const Wrapper = styled('header')({
  display: 'flex',
  justifyContent: 'space-between',
  height: '72px'
});

const MainHeader = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  color: theme.palette.grey['500'],
  backgroundColor: 'white',
  height: '72px',
  width: 'calc(100% - 72px)',
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: theme.palette.primary.light
}));

const ToolbarStyled = styled(Toolbar)({
  marginBottom: 2,
  marginTop: 2
});

const DividerVerticleStyled = styled(() => (
  <Divider orientation="vertical" variant="middle" sx={{ height: 24 }} />
))({});
