import styles from '@convelio/stylio/styles.module.scss';
import { Box, styled } from '@mui/material';

import StatusBannerLoader from './StatusBannerLoader';

type Props = {
  isLoading?: boolean;
  dataTestId?: string;
  banner?: JSX.Element;
  stepper?: JSX.Element;
};

function StatusBannerWrapper({
  isLoading,
  stepper,
  banner,
  dataTestId
}: Props): JSX.Element {
  if (isLoading) {
    return <StatusBannerLoader />;
  }

  return (
    <Wrapper data-testid={dataTestId}>
      <MainContainer>
        {banner}
        {stepper}
      </MainContainer>
    </Wrapper>
  );
}

export default StatusBannerWrapper;

const MainContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: styles.cvoSpacingL,
  marginTop: styles.cvoSpacingG,
  marginBottom: styles.cvoSpacingG,
  borderRadius: styles.cvoBorderRadiusMedium,
  width: '100%',
  [theme.breakpoints.down('tablet')]: {
    borderRadius: '0',
    padding: `${styles.cvoSpacingL} ${styles.cvoSpacingM}`
  }
}));

const Wrapper = styled(Box)(({ theme }) => ({
  paddingLeft: '4%',
  paddingRight: styles.cvoSpacingG,
  [theme.breakpoints.down('tablet')]: {
    borderRadius: '0',
    padding: `0`
  }
}));
