import { BookingListItem } from '../../../../../types/Booking';
import {
  BookingStatusType,
  OverdueType
} from '../../../../../types/BookingType';
import { PaginatedResponse } from '../../../../../types/PaginatedResponse';
import { BookingJobType } from '../../types';
import { usePackingBookingSearchRequest } from './usePackingBookingSearchRequest';

export type PackingRequestBookings =
  | BookingJobType.OVERDUE
  | BookingJobType.CONFIRM_PACKING
  | BookingJobType.SCHEDULE_PACKING
  | BookingJobType.CONFIRM_PICKUP
  | BookingJobType.CONFIRM_DROP_OFF
  | BookingJobType.HISTORY
  | BookingJobType.PLANNED;

export interface PackingRequests {
  bookings: Record<
    PackingRequestBookings,
    PaginatedResponse<BookingListItem> | undefined
  >;
  isLoading: boolean;
}

export const usePackingRequests = (): PackingRequests => {
  const { data: overdueBookings, isLoading: isOverdueLoading } =
    usePackingBookingSearchRequest({
      overdue: [OverdueType.PACK]
    });

  const {
    data: toConfirmPackingBookings,
    isLoading: isToConfirmPackingLoading
  } = usePackingBookingSearchRequest({
    statuses: [BookingStatusType.PACKING_SCHEDULED]
  });

  const { data: toScheduleBookings, isLoading: isToScheduleLoading } =
    usePackingBookingSearchRequest({
      statuses: [BookingStatusType.IN_PROGRESS]
    });

  const { data: toConfirmPickupBookings, isLoading: isToConfirmPickupLoading } =
    usePackingBookingSearchRequest({
      statuses: [BookingStatusType.PACKING_COMPLETED]
    });

  const {
    data: toConfirmDropOffBookings,
    isLoading: isToConfirmDropOffLoading
  } = usePackingBookingSearchRequest({
    statuses: [BookingStatusType.PENDING]
  });

  const { data: plannedBookings, isLoading: isPlannedLoading } =
    usePackingBookingSearchRequest({
      statuses: [BookingStatusType.PACKING_SCHEDULED]
    });

  const { data: historyBookings, isLoading: isHistoryLoading } =
    usePackingBookingSearchRequest({
      statuses: [BookingStatusType.COMPLETED]
    });

  const isLoading: boolean =
    isOverdueLoading ||
    isToConfirmPackingLoading ||
    isToScheduleLoading ||
    isToConfirmPickupLoading ||
    isToConfirmDropOffLoading ||
    isPlannedLoading ||
    isHistoryLoading;

  return {
    bookings: {
      [BookingJobType.OVERDUE]: overdueBookings,
      [BookingJobType.CONFIRM_PACKING]: toConfirmPackingBookings,
      [BookingJobType.SCHEDULE_PACKING]: toScheduleBookings,
      [BookingJobType.CONFIRM_PICKUP]: toConfirmPickupBookings,
      [BookingJobType.CONFIRM_DROP_OFF]: toConfirmDropOffBookings,
      [BookingJobType.PLANNED]: plannedBookings,
      [BookingJobType.HISTORY]: historyBookings
    },
    isLoading
  };
};
