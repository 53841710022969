import PersonIcon from '@mui/icons-material/Person';
import { Chip, ChipProps, styled } from '@mui/material';

const ChipStyled = styled(Chip)(({ theme }) => ({
  paddingX: 12,
  lineHeight: '1.6rem',
  fontWeight: '600',
  height: 32,
  marginRight: 4,
  backgroundColor: theme.palette.grey['100'],
  color: theme.palette.grey['500']
}));

function HeaderCategory(props: ChipProps): JSX.Element {
  return (
    <ChipStyled
      icon={<PersonIcon sx={{ width: 14, height: 14 }} />}
      {...props}
    />
  );
}

export default HeaderCategory;
