import { useEffect, useState } from 'react';

import { BookingStatusType, OverdueType } from '../../../../types/BookingType';
import { BOOKING_TRANSPORT_TYPES } from '../../../../utils/helpers/BookingHelper';
import { useBookingListFilter } from '../../../hooks/useBookingListFilter';
import { useGetBookingsQuery } from '../../../services/BookingApi';
import { BookingJobType, BookingPage } from '../types';

export const useTransportBookings = () => {
  const { filterPlannedBookings, filterConfirmedBookings } =
    useBookingListFilter();
  const [pages, setPages] = useState<BookingPage>();
  const [transportJobRequestTotal, setTransportJobRequestTotal] = useState(0);
  const [transportJobToUpdateTotal, setTransportJobToUpdateTotal] = useState(0);

  const { data: overdueBookings, isLoading: isOverdueLoading } =
    useGetBookingsQuery({
      types: BOOKING_TRANSPORT_TYPES,
      overdue: [OverdueType.START, OverdueType.END],
      sortmode: 'desc',
      sortparam: 'freightSpeed'
    });

  const { data: toFinalizeBookings, isLoading: isToFinalizeLoading } =
    useGetBookingsQuery({
      types: BOOKING_TRANSPORT_TYPES,
      statuses: [BookingStatusType.JOB_COMPLETED],
      sortmode: 'desc',
      sortparam: 'freightSpeed'
    });

  const { data: toScheduleBookings, isLoading: isToScheduleLoading } =
    useGetBookingsQuery({
      types: BOOKING_TRANSPORT_TYPES,
      statuses: [BookingStatusType.ACCEPTED, BookingStatusType.TO_BE_SCHEDULED],
      sortmode: 'desc',
      sortparam: 'freightSpeed'
    });

  const { data: toConfirmBookings, isLoading: isToConfirmLoading } =
    useGetBookingsQuery({
      types: BOOKING_TRANSPORT_TYPES,
      statuses: [BookingStatusType.IN_PROGRESS, BookingStatusType.SCHEDULED],
      sortmode: 'desc',
      sortparam: 'freightSpeed'
    });

  const { data: onHoldBookings, isLoading: isOnHoldLoading } =
    useGetBookingsQuery({
      types: BOOKING_TRANSPORT_TYPES,
      statuses: [BookingStatusType.ON_HOLD, BookingStatusType.PENDING],
      sortmode: 'desc',
      sortparam: 'freightSpeed'
    });

  const { data: requestBookings, isLoading: isRequestLoading } =
    useGetBookingsQuery({
      types: BOOKING_TRANSPORT_TYPES,
      statuses: [BookingStatusType.NEW],
      sortmode: 'desc',
      sortparam: 'freightSpeed'
    });

  const { data: plannedBookings, isLoading: isPlannedLoading } =
    useGetBookingsQuery({
      types: BOOKING_TRANSPORT_TYPES,
      statuses: [BookingStatusType.IN_PROGRESS, BookingStatusType.SCHEDULED],
      sortmode: 'desc',
      sortparam: 'freightSpeed'
    });

  const { data: historyBookings, isLoading: isHistoryLoading } =
    useGetBookingsQuery({
      types: BOOKING_TRANSPORT_TYPES,
      statuses: [
        BookingStatusType.COMPLETED,
        BookingStatusType.COMPLETED_READ_ONLY
      ],
      sortmode: 'desc',
      sortparam: 'freightSpeed'
    });

  const getTotalJobsToUpdate = (): number => {
    return (
      (onHoldBookings?.total || 0) +
      (toScheduleBookings?.total || 0) +
      (toFinalizeBookings?.total || 0) +
      (overdueBookings?.total || 0) +
      filterConfirmedBookings(toConfirmBookings?.data || []).length
    );
  };

  useEffect(() => {
    const plannedBookingList = filterPlannedBookings(
      plannedBookings?.data || []
    );
    const confirmedBookingList = filterConfirmedBookings(
      toConfirmBookings?.data || []
    );

    setPages({
      [BookingJobType.TODO]: {
        tabs: {
          [BookingJobType.OVERALL]: {
            total: getTotalJobsToUpdate()
          },
          [BookingJobType.OVERDUE]: {
            bookings: overdueBookings?.data,
            isLoading: isOverdueLoading,
            total: overdueBookings?.total,
            anchor: 'overdue'
          },
          [BookingJobType.FILE_TO_UPLOAD]: {
            bookings: toFinalizeBookings?.data,
            isLoading: isToFinalizeLoading,
            total: toFinalizeBookings?.total,
            anchor: 'to_finalize'
          },
          [BookingJobType.TO_SCHEDULE]: {
            bookings: toScheduleBookings?.data,
            isLoading: isToScheduleLoading,
            total: toScheduleBookings?.total,
            anchor: 'to_schedule'
          },
          [BookingJobType.TO_CONFIRM]: {
            bookings: confirmedBookingList,
            isLoading: isToConfirmLoading,
            total: confirmedBookingList.length,
            anchor: 'to_confirm'
          },
          [BookingJobType.ON_HOLD]: {
            bookings: onHoldBookings?.data,
            isLoading: isOnHoldLoading,
            total: onHoldBookings?.total,
            anchor: 'on_hold'
          }
        }
      },
      [BookingJobType.REQUEST]: {
        bookings: requestBookings?.data,
        isLoading: isRequestLoading,
        total: requestBookings?.total
      },
      [BookingJobType.PLANNED]: {
        bookings: plannedBookingList,
        isLoading: isPlannedLoading,
        total: plannedBookingList.length
      },
      [BookingJobType.HISTORY]: {
        bookings: historyBookings?.data,
        isLoading: isHistoryLoading,
        total: historyBookings?.total
      }
    });
    setTransportJobToUpdateTotal(getTotalJobsToUpdate());
    setTransportJobRequestTotal(requestBookings?.total || 0);
  }, [
    onHoldBookings,
    toConfirmBookings,
    toScheduleBookings,
    toFinalizeBookings,
    overdueBookings,
    requestBookings,
    plannedBookings,
    historyBookings
  ]);

  return {
    pages,
    transportJobRequestTotal,
    transportJobToUpdateTotal,
    isTransportRequestLoading: isRequestLoading,
    isTransportJobsToUpdateLoading:
      isOverdueLoading ||
      isToFinalizeLoading ||
      isToScheduleLoading ||
      isToConfirmLoading ||
      isOnHoldLoading
  };
};
