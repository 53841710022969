import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useState } from 'react';
import { useGetFilesQuery } from 'src/app/services/BookingApi';
import { BookingItem } from 'src/app/types/Booking';
import { UploadedFile } from 'src/app/types/booking/File';

import { useFile } from '../../hooks/useFile';
import UploadFile from '../UploadFile';
import { VisualizeFile } from '../VisualizeFile';
import AccordionFile from './components/AccordionFile';
import FileTitle from './components/FileTitle';
import FilesWrapper from './components/FilesWrapper';

type Props = {
  booking: BookingItem;
};

function Files({ booking }: Props): JSX.Element {
  const { data: response, isLoading } = useGetFilesQuery(
    booking?.id ?? skipToken
  );

  const { convelioFiles, supplierFiles, getFileIdsByType } = useFile(
    booking?.type,
    response
  );

  const [type, setType] = useState<string>();
  const [visualize, setVisualized] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined
  );

  const [fileIds, setFileIds] = useState<string[]>();

  const handleClickFile = (file: UploadedFile) => {
    const ids = getFileIdsByType(file.type);
    const index = ids.findIndex((id) => file.id === id);

    setVisualized(true);
    setFileIds(ids);
    setSelectedIndex(index);
    setType(file.type.toLowerCase());
  };
  return (
    <FilesWrapper
      isLoading={isLoading}
      uploadFile={<UploadFile bookingId={booking.id} />}
      fileList={
        <AccordionFile
          isLoading={isLoading}
          supplierFiles={supplierFiles}
          convelioFiles={convelioFiles}
          handleclickFile={handleClickFile}
          bookingType={booking?.type}
        />
      }
      fileModal={
        <>
          {fileIds &&
            type &&
            selectedIndex !== undefined &&
            selectedIndex !== null && (
              <VisualizeFile
                type={type}
                open={visualize}
                setOpen={setVisualized}
                fileIds={fileIds}
                selectedFileIndex={selectedIndex}
                resetSelectedIndex={() => setSelectedIndex(undefined)}
              />
            )}
        </>
      }
      fileTitle={<FileTitle length={response?.data?.length} />}
    />
  );
}

export default Files;
