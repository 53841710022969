import { Box, Skeleton } from '@mui/material';

function ReadyForCollectionDateLoader(): JSX.Element {
  return (
    <Box sx={{ width: '30%', overflow: 'hidden' }}>
      <Skeleton variant="text" height="24px" width="100%" />
    </Box>
  );
}

export default ReadyForCollectionDateLoader;
