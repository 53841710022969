import { InvalidBookingNumberError } from '../Domain/Error/InvalidBookingNumberError';
import { BookingNumber } from '../Domain/ValueObject/BookingNumber';
import { BookingNumberInfo } from './BookingNumberInfo';

export const parseBookingNumber = (
  bookingNumberValue?: string
): BookingNumberInfo => {
  if (bookingNumberValue === undefined) {
    throw new InvalidBookingNumberError();
  }

  return BookingNumber.fromString(bookingNumberValue);
};
