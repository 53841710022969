import styles from '@convelio/stylio/styles.module.scss';
import { StyleSheet, View } from '@react-pdf/renderer';
import { ReactNode } from 'react';

interface TableCellProps {
  children: ReactNode;
}

export const TableCell = ({ children }: TableCellProps) => {
  return <View style={style.tableCell}>{children}</View>;
};

const style = StyleSheet.create({
  tableCell: {
    width: '50%',
    borderStyle: 'solid',
    borderRightWidth: '1px',
    padding: styles.cvoSpacingXxs
  }
});
