import { UpdatedSettingItem } from './types';

const SETTINGS_KEY = 'settings';

export const getSettingsFromStorage = (): UpdatedSettingItem[] => {
  const settings = localStorage.getItem(SETTINGS_KEY);
  if (!settings) return [];

  return JSON.parse(settings);
};

export const transformDateFormat = (dateFormat: string): string => {
  return dateFormat.replace(/DD/, 'dd').replace(/YYYY/, 'yyyy');
};
