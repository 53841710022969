import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../../../locales/translations';
import { LevelType } from '../../../../../../../../types/LevelType';
import { useDate } from '../../../../../../../hooks/useDate';

interface FlagsProps {
  deliveryOptions: string | null;
  endDeadline: string | null;
}

interface FlagsReturnType {
  flags?: Flag[];
}

interface Flag {
  level?: LevelType;
  label: string;
}

export const useFlags = ({
  deliveryOptions,
  endDeadline
}: FlagsProps): FlagsReturnType => {
  const { fromIsoToDateFormat } = useDate();
  const { t } = useTranslation();

  const [flags, setFlags] = useState<Flag[]>();

  useEffect(() => {
    if (!deliveryOptions && !endDeadline) {
      setFlags(undefined);
      return;
    }
    const flags: Flag[] = [];
    if (deliveryOptions)
      flags.push({
        label: t(translations.deliveryOptions[deliveryOptions])
      });
    if (endDeadline)
      flags.push({
        level: LevelType.ERROR,
        label: `${t(translations.deadline.title)}: ${fromIsoToDateFormat(
          endDeadline
        )}`
      });
    setFlags(flags);
  }, [deliveryOptions, endDeadline]);

  return {
    flags
  };
};
