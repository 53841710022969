import { Typography, styled } from '@mui/material';

type HeaderAccountNameProps = {
  accountName: string;
};

export const HeaderAccountName = ({
  accountName
}: HeaderAccountNameProps): JSX.Element => {
  return <AccountNameStyled variant="h5">{accountName}</AccountNameStyled>;
};

const AccountNameStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey['500']
}));
