export enum DeliveryMethodType {
  CONSOLIDATED_VAN = 'CONSOLIDATED_VAN',
  DEDICATED_VAN = 'DEDICATED_VAN',
  BLANKET_WRAP_SHUTTLE = 'BLANKET_WRAP_SHUTTLE',
  FINE_ART_SHUTTLE = 'FINE_ART_SHUTTLE',
  REGIONAL_SHUTTLE = 'REGIONAL_SHUTTLE',
  ROAD_COURRIER = 'ROAD_COURRIER',
  TRUCK_LTL = 'TRUCK_LTL',
  AIR_CARGO = 'AIR_CARGO',
  AIR_COURRIER = 'AIR_COURRIER',
  SEA_FREIGHT = 'SEA_FREIGHT'
}

export enum BookingStatusType {
  NEW = 'NEW',
  PENDING = 'PENDING',
  ON_HOLD = 'ON_HOLD',
  CANCELLED = 'CANCELLED',
  BUDGET_REQUEST = 'BUDGET_REQUEST',
  ACCEPTED = 'ACCEPTED',
  TO_BE_SCHEDULED = 'TO_BE_SCHEDULED',
  SCHEDULED = 'SCHEDULED',
  START_OVERDUE = 'START_OVERDUE',
  IN_PROGRESS = 'IN_PROGRESS',
  END_OVERDUE = 'END_OVERDUE',
  JOB_COMPLETED = 'JOB_COMPLETED',
  COMPLETED = 'COMPLETED',
  COMPLETED_READ_ONLY = 'COMPLETED_READ_ONLY',
  PACKING_SCHEDULED = 'PACKING_SCHEDULED',
  PACKING_COMPLETED = 'PACKING_COMPLETED'
}

export const bookingStatusTypeBlocked: string[] = [
  BookingStatusType.ON_HOLD,
  BookingStatusType.CANCELLED,
  BookingStatusType.PENDING
];

export enum FreightSpeedType {
  REGULAR_SPEED = 'REGULAR_SPEED',
  EXPRESS_SPEED = 'EXPRESS_SPEED',
  ULTRA_EXPRESS = 'ULTRA_EXPRESS'
}

export enum BookingType {
  PICKUP = 'PICKUP',
  CRATING = 'CRATING',
  TRUCK_TRANSIT = 'TRUCK_TRANSIT',
  DEPOT = 'DEPOT',
  TRUCK_PRE_FREIGHT = 'TRUCK_PRE_FREIGHT',
  PRE_FREIGHT_TERMINAL = 'PRE_FREIGHT_TERMINAL',
  FREIGHT = 'FREIGHT_ROAD_AIR_SEA',
  POST_FREIGHT_TERMINAL = 'POST_FREIGHT_TERMINAL',
  TRUCK_POST_FREIGHT = 'TRUCK_POST_FREIGHT',
  DEPOT_LAST_MILE = 'DEPOT_LAST_MILE',
  LAST_MILE_DELIVERY = 'LAST_MILE_DELIVERY'
}

export enum CurrencyType {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP'
}

export enum DeliveryOptionType {
  CURBSIDE = 'CURBSIDE',
  WHITE_GLOVE = 'WHITE_GLOVE',
  WHITE_GLOVE_AND_INSTALLATION = 'WHITE_GLOVE_AND_INSTALLATION'
}

export enum PackingConditionType {
  CRATED = 'CRATED',
  NOT_PACKED = 'NOT_PACKED',
  WOOD_PACKED = 'WOOD_PACKED',
  SOFT_PACKED = 'SOFT_PACKED'
}

export enum PackType {
  MASTERPACK = 'MASTERPACK',
  WOODCRATE = 'WOODCRATE',
  CARTON_BOX = 'CARTON_BOX',
  TRI_WALL_CRATE = 'TRI_WALL_CRATE',
  CARTON_BOX_AND_PALLET = 'CARTON_BOX_AND_PALLET',
  T_FRAME_ONLY = 'T_FRAME_ONLY',
  TUBE = 'TUBE',
  T_FRAME_AND_CRATE = 'T_FRAME_AND_CRATE'
}

export enum BookingActionType {
  BOOKING_CREATED = 'BOOKING_CREATED',
  BOOKING_ACCEPTED = 'BOOKING_ACCEPTED',
  BOOKING_START_DATE_SCHEDULED = 'BOOKING_START_DATE_SCHEDULED',
  BOOKING_END_DATE_SCHEDULED = 'BOOKING_END_DATE_SCHEDULED',
  BOOKING_START_DATE_SET = 'BOOKING_START_DATE_SET',
  BOOKING_END_DATE_SET = 'BOOKING_END_DATE_SET',
  BOOKING_NOTE_EDITED = 'BOOKING_NOTE_EDITED',
  FILE_ADDED = 'FILE_ADDED',
  FILE_DELETED = 'FILE_DELETED',
  BOOKING_READY_FOR_COLLECTION_DATE_SET = 'BOOKING_READY_FOR_COLLECTION_DATE_SET',
  BOOKING_UPDATED = 'BOOKING_UPDATED',
  BOOKING_CANCELLED = 'BOOKING_CANCELLED',
  CRATE_ADDED = 'CRATE_ADDED',
  CRATE_UPDATED = 'CRATE_UPDATED',
  PACKING_DATE_SCHEDULED = 'BOOKING_PACKING_DATE_SCHEDULED',
  PACKING_DATE_SET = 'BOOKING_PACKING_DATE_SET'
}

export enum BookingActionStatusType {
  ACCEPT = 'accept',
  SCHEDULE_START = 'schedule-start',
  SCHEDULE_END = 'schedule-end',
  SET_START_DATE = 'set-start-date',
  SET_END_DATE = 'set-end-date',
  EDIT_NOTE = 'edit-note',
  UPLOAD_FILE = 'upload-file',
  SEE_JOB = 'see-job',
  SET_READY_FOR_COLLECTION_DATE = 'set-ready-for-collection-date',
  SCHEDULE_PACKING = 'schedule-packing',
  SET_PACKING_DATE = 'set-packing-date'
}

export enum BookingUserType {
  INTERNAL = 'INTERNAL',
  SF = 'SF'
}

export enum BookingMacroType {
  TRANSPORT = 'transport',
  DEPOT = 'depot',
  PACKING = 'packing'
}

export enum OverdueType {
  START = 'start',
  END = 'end',
  PACK = 'pack'
}

export enum CrateActionType {
  ESTIMATION,
  CONFIRMATION,
  SF
}

export enum LogisticActionType {
  Pickup = 'pickup',
  Delivery = 'delivery'
}
