import { Skeleton, Stack } from '@mui/material';

function AccordionFileDetailsLoader(): JSX.Element {
  return (
    <Stack>
      <Skeleton width={'60%'} variant={'text'}></Skeleton>
      <Skeleton width={'60%'} variant={'text'}></Skeleton>
      <Skeleton width={'60%'} variant={'text'}></Skeleton>
    </Stack>
  );
}

export default AccordionFileDetailsLoader;
