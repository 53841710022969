import {
  CrateChangeMethodType,
  PackingCrateDimension,
  PackingCrateOperation,
  PackingCrateRequest
} from 'src/types/Packing';

import { measurementSystems } from '../../app/pages/Setting/constants';
import { MeasurementSystem } from '../../app/pages/Setting/types';
import { BookingItem, BookingMerchandise } from '../../app/types/Booking';
import { BookingListRequest } from '../../types/Booking';
import {
  BookingMacroType,
  BookingStatusType,
  BookingType,
  CurrencyType,
  FreightSpeedType,
  PackingConditionType
} from '../../types/BookingType';

export const DEFAULT_GET_BOOKINGS_LIMIT = 100;

const DEFAULT_STATUSES: Array<BookingStatusType> = [
  BookingStatusType.NEW,
  BookingStatusType.BUDGET_REQUEST,
  BookingStatusType.ACCEPTED,
  BookingStatusType.TO_BE_SCHEDULED,
  BookingStatusType.SCHEDULED,
  BookingStatusType.IN_PROGRESS,
  BookingStatusType.JOB_COMPLETED,
  BookingStatusType.COMPLETED,
  BookingStatusType.COMPLETED_READ_ONLY
];

export const DEFAULT_BOOKING_DEPOT_COMPLETED: Array<BookingStatusType> = [
  BookingStatusType.COMPLETED,
  BookingStatusType.JOB_COMPLETED,
  BookingStatusType.COMPLETED_READ_ONLY
];

export const DEFAULT_BOOKING_TRANSPORT_COMPLETED: Array<BookingStatusType> = [
  BookingStatusType.COMPLETED,
  BookingStatusType.COMPLETED_READ_ONLY
];

export const DEFAULT_BOOKING_PACKING_COMPLETED: Array<BookingStatusType> = [
  BookingStatusType.COMPLETED,
  BookingStatusType.COMPLETED_READ_ONLY
];

export const DEFAULT_BOOKING_NOT_SCHEDULED: Array<BookingStatusType> = [
  BookingStatusType.NEW,
  BookingStatusType.ON_HOLD,
  BookingStatusType.PENDING
];

export const BOOKING_TRANSPORT_TYPES: BookingType[] = [
  BookingType.PICKUP,
  BookingType.LAST_MILE_DELIVERY,
  BookingType.FREIGHT,
  BookingType.TRUCK_TRANSIT,
  BookingType.TRUCK_PRE_FREIGHT,
  BookingType.TRUCK_POST_FREIGHT
];

export const BOOKING_PACKING_TYPES: BookingType[] = [BookingType.CRATING];

export const BOOKING_DEPOT_TYPES: BookingType[] = [
  BookingType.DEPOT,
  BookingType.PRE_FREIGHT_TERMINAL,
  BookingType.POST_FREIGHT_TERMINAL,
  BookingType.DEPOT_LAST_MILE
];

export function getBookingMerchandise(
  booking?: BookingItem,
  measurementSystem?: MeasurementSystem
): BookingMerchandise {
  if (!booking) return {} as BookingMerchandise;

  const getPackLoadDimensions = (): string | null => {
    if (
      !measurementSystems ||
      measurementSystem === measurementSystems.metric
    ) {
      return booking.packLoadDimensions;
    }

    return booking.packLoadDimensionsUsc;
  };

  return {
    value: booking.orderValue || booking.packLoadValue,
    orderLoadDimensions: transformStringToArray(booking.orderLoadDimensions),
    packLoadDimensions: transformStringToArray(getPackLoadDimensions()),
    packLoadDetails: transformStringToArray(booking.packLoadDetails),
    orderLoadDescription: booking.orderLoadDescription,
    eoriNumber: booking.eoriNumber,
    currency: booking.orderCurrency as CurrencyType,
    packLoadVolume: booking.packLoadVolume,
    packLoadWeight: booking.packLoadWeight,
    packingCondition: booking.orderLoadPacking as PackingConditionType
  };
}

export function isBookingExpress(freightSpeed: FreightSpeedType): boolean {
  return [
    FreightSpeedType.EXPRESS_SPEED,
    FreightSpeedType.ULTRA_EXPRESS
  ].includes(freightSpeed);
}

export function buildEndpointForGetBookings(
  request: BookingListRequest
): string {
  const statuses = request.statuses || DEFAULT_STATUSES;

  let url = `/bookings?page=${request.page ? request.page : 1}`;
  url += request.limit
    ? `&limit=${request.limit}`
    : `&limit=${DEFAULT_GET_BOOKINGS_LIMIT}`;
  if (request.types) url += `&types=${computeTypes(request.types)}`;
  if (request.statuses) url += `&statuses=${statuses.join(',')}`;
  if (request.search) url += `&search=${request.search}`;
  if (request.overdue && request.overdue.length !== 0)
    url += `&overdue=${request.overdue.join(',')}`;

  return url;
}

export function getMacroBookingType(
  type: BookingType
): BookingMacroType | null {
  if (BOOKING_DEPOT_TYPES.includes(type)) return BookingMacroType.DEPOT;
  if (BOOKING_PACKING_TYPES.includes(type)) return BookingMacroType.PACKING;
  if (BOOKING_TRANSPORT_TYPES.includes(type)) return BookingMacroType.TRANSPORT;
  return null;
}

export function getBookingUrl(type: BookingType, bookingId: string): string {
  return `/dashboard/${getMacroBookingType(type)}/booking/${bookingId}`;
}

function transformStringToArray(data: string | null): string[] {
  if (!data) return [];

  return data.split('\n');
}

function computeTypes(types: BookingType[]): string {
  return types.join(',');
}

export function buildEndpointPackingCrate({
  method,
  crateId,
  bookingId,
  crateDimension,
  operation
}: PackingCrateRequest): {
  url: string;
  method: CrateChangeMethodType;
  body: PackingCrateDimension | undefined;
} {
  let ope = '';
  let version = '';
  let body: PackingCrateDimension | undefined = crateDimension;

  if (CrateChangeMethodType.DELETE === method) {
    body = undefined;
    version = `?version=${crateDimension.version}`;
  }

  if (operation === PackingCrateOperation.ESTIMATION) {
    ope = '/estimation';
  }
  return {
    url: `/bookings/${bookingId}/crate${ope}${
      crateId ? `/${crateId}` : ''
    }${version}`,
    method,
    body
  };
}
