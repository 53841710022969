import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { AnyObjectSchema, SchemaOf, object, ref, string } from 'yup';

import { translations } from '../../../../../../locales/translations';
import {
  NEW_PASSWORD_KEY,
  PASSWORD_MIN_LENGTH,
  REGEX_LOWERCASE,
  REGEX_NUMBER,
  REGEX_SPECIAL_CHARACTER,
  REGEX_UPPERCASE
} from '../constants';
import { Credentials, ErrorMessage, Resolver } from '../types';

const useRecoveryPasswordResolver = (): Resolver => {
  const { t } = useTranslation();
  const errorMessage: ErrorMessage =
    translations.pages.recoveryPassword.features.recoveryPasswordForm.hooks
      .useRecoveryPasswordRecover.errorMessage;

  const commonValidationSchema = string()
    .required()
    .min(PASSWORD_MIN_LENGTH, t(errorMessage.minLength))
    .matches(REGEX_UPPERCASE, t(errorMessage.uppercase))
    .matches(REGEX_LOWERCASE, t(errorMessage.lowercase))
    .matches(REGEX_NUMBER, t(errorMessage.number))
    .matches(REGEX_SPECIAL_CHARACTER, t(errorMessage.specialCharacter));

  const schema: SchemaOf<Credentials> = object({
    newPassword: commonValidationSchema,
    confirmPassword: commonValidationSchema.oneOf(
      [ref(NEW_PASSWORD_KEY), null],
      t(errorMessage.match)
    )
  }).required();
  return yupResolver<AnyObjectSchema>(schema);
};

export default useRecoveryPasswordResolver;
