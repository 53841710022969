import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationSnackbar from 'src/app/components/SnackBar';
import { useDate } from 'src/app/hooks/useDate';
import MainDatePicker from 'src/app/pages/Booking/Features/ScheduleDate/components/MainDatePicker';
import StepBody from 'src/app/pages/Booking/components/StepBody';
import StepWrapper from 'src/app/pages/Booking/components/StepWrapper';
import { useStep } from 'src/app/pages/Booking/hooks/useStep';
import { BookingItem } from 'src/app/types/Booking';
import { translations } from 'src/locales/translations';
import {
  BookingActionStatusType,
  BookingStatusType
} from 'src/types/BookingType';
import { LevelType } from 'src/types/LevelType';

import { useBookingAction } from '../../../TransportBooking/Features/hooks/useBookingAction';

type Props = {
  version: number;
  isLoading?: boolean;
  booking: BookingItem;
};

function DepotDropOff({ booking, isLoading, version }: Props): JSX.Element {
  const { t } = useTranslation();
  const { fromIsoToDateFormat, fromIsoToDate } = useDate();
  const { handleChangeStatus, bookingAction, isSuccess, isError } =
    useBookingAction({ booking, version });

  const { getStartContact, getStartLocation } = useStep();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const dropOffDate = useMemo((): Date | null => {
    return booking.startDate ? fromIsoToDate(booking.startDate) : null;
  }, [booking.startDate]);

  useEffect(() => {
    setIsEditMode(booking.status === BookingStatusType.PENDING);
  }, [booking]);

  const handleDropOff = async (date?: Date): Promise<void> => {
    await handleChangeStatus(BookingActionStatusType.SET_START_DATE, date);
  };

  return (
    <>
      <StepWrapper bgColor={'grey.100'}>
        <StepBody
          isLoading={isLoading}
          title={{
            stepBadge: 'A',
            stepName: t(
              translations.pages.booking.pages.depot.features.dropOff.title
            ),
            color: 'primary.dark',
            location: getStartLocation(booking)
          }}
          flags={
            booking.startExpectedOn &&
            booking.status === BookingStatusType.PENDING
              ? [
                  {
                    level: LevelType.SUCCESS,
                    label: `${t(
                      translations.pages.booking.pages.depot.features.dropOff
                        .etaFlag
                    )}: ${fromIsoToDateFormat(booking.startExpectedOn)}`
                  }
                ]
              : undefined
          }
          contact={{
            value: getStartContact(booking),
            hideContact: booking.status === BookingStatusType.NEW
          }}
          scheduleDate={
            <MainDatePicker
              date={dropOffDate}
              datatestid={`confirm-depot-drop-off-date`}
              getTitle={() =>
                t(
                  translations.pages.booking.pages.depot.features.dropOff
                    .datePickerLabel
                )
              }
              label={t(
                translations.pages.booking.pages.depot.features.dropOff
                  .buttonLabel
              )}
              isLoading={isLoading}
              isEditMode={isEditMode}
              handleSubmit={handleDropOff}
              modalTitle={t(
                translations.pages.booking.features.dateModal.confirm.delivery
              )}
              modalDescription={t(
                translations.pages.booking.features.dateModal.delivery
              )}
            />
          }
        />
      </StepWrapper>
      {bookingAction && (isError || isSuccess) && (
        <NotificationSnackbar
          open={true}
          message={t(
            translations.pages.booking.pages.depot.features.dropOff[
              bookingAction
            ][isError ? 'failedMessage' : 'successMessage']
          )}
          severity={isError ? 'error' : 'success'}
        />
      )}
    </>
  );
}

export default DepotDropOff;
