import styles from '@convelio/stylio/styles.module.scss';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { translations } from '../../../../locales/translations';

function LoginMessage(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        textAlign: 'start'
      }}
    >
      <Typography
        sx={{
          fontSize: styles.cvoTitleMdFontSize,
          lineHeight: '1.86rem',
          fontWeight: 700
        }}
      >
        {t(translations.welcome.title)}
      </Typography>
    </Box>
  );
}

export default LoginMessage;
