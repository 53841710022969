import { bookingApi } from '../../app/services/BookingApi';
import { forgottenPasswordApi } from '../../app/services/ForgottenPasswordAPI';
import { loginApi } from '../../app/services/LoginApi';
import { recoveryPasswordApi } from '../../app/services/RecoveryPasswordAPI';
import { refreshTokenApi } from '../../app/services/RefreshTokenApi';
import { settingsApi } from '../../app/services/SettingsApi';
import { resetCredentials } from '../../app/slices/Auth';
import { AppDispatch } from '../../store/store';

export const resetAppStateAndStorage = (dispatch: AppDispatch) => {
  dispatch(bookingApi.util.resetApiState());
  dispatch(loginApi.util.resetApiState());
  dispatch(settingsApi.util.resetApiState());
  dispatch(recoveryPasswordApi.util.resetApiState());
  dispatch(forgottenPasswordApi.util.resetApiState());
  dispatch(refreshTokenApi.util.resetApiState());
  dispatch(resetCredentials());
};
