import {
  Box,
  Card,
  CardContent,
  CardProps,
  Collapse,
  Divider,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Location } from 'src/types/Location';

import StepTitle from '../../../components/StepTitle';

const MainStyledBox = styled(Box)({
  padding: 2
});

const FirstLineBox = styled(Box)({ display: 'flex', flexDirection: 'row' });

const StyledTitleBox = styled(Box)({ cursor: 'pointer' });

const StyledCardContent = styled(CardContent)({ paddingLeft: 36 });

const StyledMessageActionBox = styled(Box)({
  width: '40%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end'
});

const DividerBox = styled(Box)({
  paddingX: 12,
  margin: 4
});

interface StyledCardProps extends CardProps {
  expanded: boolean;
}

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'expanded'
})<StyledCardProps>(({ expanded, theme }) => ({
  padding: 16,
  backgroundColor: expanded ? theme.palette.grey['100'] : 'white',
  borderRadius: '4px'
}));

type Props = {
  isExpanded?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  step: {
    stepName: string;
    location?: Location;
    stepBadge: string | number;
  };
  edit?: JSX.Element;
  content: JSX.Element;
  message?: JSX.Element;
  withDivider?: boolean;
  handleAction?: boolean;
};

function AccordionStepWrapper({
  step,
  edit,
  message,
  content,
  disabled,
  isLoading,
  isExpanded,
  withDivider
}: Props): JSX.Element {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const isMinWidthMobile = useMediaQuery(theme.breakpoints.up('mobile'));

  const handleExpandClick = () => {
    if (!disabled) setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(!!isExpanded);
  }, [isExpanded]);

  return (
    <MainStyledBox>
      <StyledCard elevation={0} expanded={expanded && !edit}>
        <FirstLineBox>
          <StyledTitleBox
            onClick={handleExpandClick}
            sx={{ width: message && isMinWidthMobile ? '50%' : '80%' }}
          >
            <StepTitle
              isLoading={isLoading}
              color={'primary.dark'}
              location={step.location}
              stepName={step.stepName}
              stepBadge={step.stepBadge}
              disabled={disabled}
            />
          </StyledTitleBox>
          <StyledMessageActionBox
            sx={{ width: message && isMinWidthMobile ? '50%' : '20%' }}
          >
            {!disabled && (
              <>
                <> {edit} </>
                {isMinWidthMobile && <>{message}</>}
              </>
            )}
          </StyledMessageActionBox>
        </FirstLineBox>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <StyledCardContent>{content}</StyledCardContent>
        </Collapse>
      </StyledCard>
      {withDivider && (!expanded || edit) && (
        <DividerBox>
          <Divider variant="middle" />
        </DividerBox>
      )}
    </MainStyledBox>
  );
}

export default AccordionStepWrapper;
