import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Banner from 'src/app/components/Banner';
import AcceptJob from 'src/app/pages/Booking/Features/AcceptJob';
import { BookingItem, DEPOT_STEPS } from 'src/app/types/Booking';
import { translations } from 'src/locales/translations';
import { BookingStatusType } from 'src/types/BookingType';
import { LevelType } from 'src/types/LevelType';
import { DEFAULT_BOOKING_DEPOT_COMPLETED } from 'src/utils/helpers/BookingHelper';

import StatusBannerWrapper from '../../../../Features/StatusBanner/components/StatusBannerWrapper';
import StatusStepper from '../../../../Features/StatusBanner/components/StatusStepper';
import { hasFile } from '../../../../Helpers/hasFile';

type Props = {
  version: number;
  isLoading?: boolean;
  booking: BookingItem;
};

function DepotStatusBanner({
  isLoading,
  version,
  booking
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [banner, setBanner] = useState<JSX.Element>();
  const [dataTestId, setDataTestId] = useState<string>();

  useEffect(() => {
    if (booking.status === BookingStatusType.NEW) {
      setDataTestId('accept-job-depot');
      setBanner(<AcceptJob version={version} booking={booking} />);
    } else if (DEFAULT_BOOKING_DEPOT_COMPLETED.includes(booking.status)) {
      setDataTestId('banner-depot-job-completed');
      setBanner(
        <Banner
          buttonLink={'/'}
          type={LevelType.SUCCESS}
          message={t(
            translations.pages.booking.pages.depot.features.banner
              .completedMessage
          )}
          buttonText={t(
            translations.pages.booking.pages.depot.features.banner
              .completedButton
          )}
        />
      );
    } else {
      setBanner(undefined);
      setDataTestId('stepper-depot');
    }
  }, [booking]);

  return (
    <StatusBannerWrapper
      isLoading={isLoading}
      banner={banner}
      stepper={
        !banner ? (
          <StatusStepper
            hasBookingFileUploaded={hasFile(booking)}
            status={booking.status}
            steps={DEPOT_STEPS}
            bookingType={'depot'}
            statusCompleted={DEFAULT_BOOKING_DEPOT_COMPLETED}
          />
        ) : undefined
      }
      dataTestId={dataTestId}
    />
  );
}

export default DepotStatusBanner;
