import styles from '@convelio/stylio/styles.module.scss';
import { Page, StyleSheet, View } from '@react-pdf/renderer';
import { ReactNode } from 'react';

import { BookingItem } from '../../../types/Booking';
import { FooterView } from './FooterView';
import { BookingNumberAndBudget } from './Header/BookingNumberAndBudget';
import { ReactComponent as DocumentLogo } from './Header/DocumentLogo.svg';
import { UserButton } from './Header/UserButton';

interface ConvelioLayoutPageProps {
  children: ReactNode;
  booking: BookingItem;
}

export const ConvelioLayoutPage = ({
  children,
  booking
}: ConvelioLayoutPageProps): JSX.Element => {
  return (
    <Page style={style.page}>
      <View style={style.header}>
        <View style={style.headerRow}>
          <DocumentLogo />
          <UserButton
            accountName={booking.accountName || ''}
            customerRef={booking.customerRef || ''}
          />
        </View>
        <View style={style.headerRow}>
          <BookingNumberAndBudget
            bookingNumber={booking.bookingNumber}
            budgetEur={booking.budgetEur}
            budgetUsd={booking.budgetUsd}
            budgetGbp={booking.budgetGbp}
          />
        </View>
      </View>
      {children}
      <FooterView bookingType={booking.type} />
    </Page>
  );
};

const style = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: styles.cvoSpacingG,
    fontSize: styles.cvoBodySmFontSize,
    fontFamily: 'Helvetica'
  },
  header: {
    flexDirection: 'column',
    marginBottom: styles.cvoSpacingM
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: styles.cvoSpacingXs,
    paddingHorizontal: styles.cvoSpacingS
  }
});
