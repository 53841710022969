import { Tabs, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AntTab from 'src/app/components/AntTab';
import AvatarStep from 'src/app/components/AvatarStep';
import { translations } from 'src/locales/translations';

import { a11yProps } from '../../../../utils/helpers/TabHelper';
import { TabItem } from '../types';

type Props = {
  index: number;
  items: TabItem[];
  handleChange: (index: number) => void;
};

function DashboardTab({ index, items, handleChange }: Props): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Tabs
      value={index}
      onChange={(e, newIndex) => handleChange(newIndex)}
      aria-label="basic tabs example"
      scrollButtons
      TabIndicatorProps={{
        style: {
          backgroundColor: theme.palette.grey['600']
        }
      }}
      sx={{
        paddingTop: 24
      }}
    >
      {items.map((tab, index) => (
        <AntTab
          key={index}
          label={t(translations[tab.type].title)}
          icon={
            <AvatarStep
              step={tab.value.total}
              color={theme.palette.common.white}
              bgColor={theme.palette.grey['600']}
            />
          }
          {...a11yProps('basicTab', index)}
        />
      ))}
    </Tabs>
  );
}

export default DashboardTab;
