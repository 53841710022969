import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/system';
import { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { translations } from '../../../../../locales/translations';
import { useAppDispatch } from '../../../../../store/hooks';
import EmailInput from '../../../../components/EmailInput';
import PasswordInput from '../../../../components/PasswordInput';
import NotificationSnackbar from '../../../../components/SnackBar';
import { useLoginMutation } from '../../../../services/LoginApi';
import { setCredentials } from '../../../../slices/Auth';
import { LoginRequest } from '../../../../types/Login';
import { useSettings } from '../../../Setting/useSettings';
import ForgetPasswordLink from '../../components/ForgetPasswordLink';
import LoginButton from '../../components/LoginButton';
import './LoginForm.styles.css';

function LoginForm(): JSX.Element {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t } = useTranslation();
  const { refreshAppLanguage } = useSettings();

  const schema = yup
    .object({
      email: yup.string().email().required(),
      password: yup.string().required()
    })
    .required();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    return await executeRecaptcha('loginPage');
  }, [executeRecaptcha]);

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid }
  } = useForm<LoginRequest>({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const dispatch = useAppDispatch();
  const [login, { isError }] = useLoginMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleLogin = async (request: LoginRequest) => {
    setIsLoading(true);
    const recaptcha = await handleReCaptchaVerify();

    if (!recaptcha) {
      console.error('recaptcha is not verified');
      return;
    }
    request.recaptcha = recaptcha;

    try {
      const response = await login(request).unwrap();
      dispatch(setCredentials(response));
      await refreshAppLanguage();
    } catch (error) {
      console.debug(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      {isError && (
        <NotificationSnackbar
          open={true}
          message={t(translations.login.errorNotification)}
          severity={'error'}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 16,
          width: (theme) => theme.spacing(428),
          px: {
            xs: '4%',
            sm: 2
          }
        }}
      >
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error }
          }) => (
            <EmailInput
              sx={{
                marginTop: 16
              }}
              value={value}
              onChange={onChange}
              error={!!error}
              onBlur={onBlur}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error }
          }) => (
            <PasswordInput
              sx={{
                marginTop: 16
              }}
              value={value}
              datatestid="password-input"
              onChange={onChange}
              error={!!error}
              onBlur={onBlur}
            />
          )}
        />
        <ForgetPasswordLink />
        <LoginButton
          disabled={!isDirty || !isValid || isLoading}
          isLoading={isLoading}
          type="submit"
        />
        <Box
          sx={{
            color: 'grey.500',
            fontSize: '0.8em',
            marginTop: '24px'
          }}
        >
          This site is protected by reCAPTCHA and the Google{' '}
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
          <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
          apply.
        </Box>
      </Box>
    </form>
  );
}

export default LoginForm;
