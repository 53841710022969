import { Document } from '@react-pdf/renderer';

import { BookingItem } from '../../../../types/Booking';
import { ConvelioLayoutPage } from '../../Page';
import { MerchandiseView } from '../../Page/Section/Merchandise/MerchandiseView';
import { DepotDropOffView } from './DepotDropOffView';
import { DepotPickupView } from './DepotPickupView';

interface DepotBookingPDFProps {
  booking: BookingItem;
}

export const DepotBookingDocument = ({
  booking
}: DepotBookingPDFProps): JSX.Element => {
  return (
    <Document>
      <ConvelioLayoutPage booking={booking}>
        <DepotDropOffView booking={booking} />
        <DepotPickupView booking={booking} />
      </ConvelioLayoutPage>
      <ConvelioLayoutPage booking={booking}>
        <MerchandiseView booking={booking} />
      </ConvelioLayoutPage>
    </Document>
  );
};
