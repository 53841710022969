import { Box, Divider, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'src/locales/translations';
import { BookingStatusType } from 'src/types/BookingType';
import {
  CrateChangeMethodType,
  CrateForm,
  CrateFormFile,
  PACKING_CONFIRMATION_FORM_KEYS,
  PackingCrate,
  PackingCrateOperation,
  PackingCrateStatus
} from 'src/types/Packing';

import AccordionSubTitle from '../../components/AccordionSubTitle';
import { PackingCrateForm } from '../../components/PackingCrateForm';
import PackingDateForm from '../../components/PackingDateForm';
import { useCrates } from '../../hooks/useCrates';
import { usePacking } from '../../hooks/usePacking';

const MainEditWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

type Props = {
  bookingId: string;
  status: BookingStatusType;
  isLoading?: boolean;
  isBeingEdited: boolean;
  crates: PackingCrate[] | null;
  packDate: string | null;
  packLoadVolume: number | null;
  handleSubmitPackingConfirmation: (date?: Date) => void;
  handleChangeCrate: (
    crate: PackingCrate,
    method: CrateChangeMethodType,
    operation?: PackingCrateOperation
  ) => Promise<PackingCrate>;
  handleFileDelete: (fileId: string) => void;
  handleFileUpload: (crateId: string, files: CrateFormFile[]) => void;
  handleConfirmationIsDone(isDone: boolean): void;
};

function PackingConfirmationEdit({
  bookingId,
  status,
  crates,
  packDate,
  isLoading,
  isBeingEdited,
  packLoadVolume,
  handleChangeCrate,
  handleFileUpload,
  handleFileDelete,
  handleConfirmationIsDone,
  handleSubmitPackingConfirmation
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { confirmationStepIsDone } = usePacking();
  const { isAllCratesConfirmedWithFiles } = useCrates();
  const [isDone, setIsDone] = useState<boolean>(false);
  const [isCrateFormValid, setIsCrateFormValid] = useState<boolean>(false);

  const packingTranslations =
    translations.pages.booking.pages.packing.features.packingConfirmation;

  const handleConfirmCrate = async (
    crate: PackingCrate
  ): Promise<PackingCrate> => {
    return await handleChangeCrate(
      crate,
      crate.id ? CrateChangeMethodType.PUT : CrateChangeMethodType.POST,
      PackingCrateOperation.CONFIRMATION
    );
  };

  const handleDeleteCrate = async (crate: PackingCrate): Promise<void> => {
    await handleChangeCrate(crate, CrateChangeMethodType.DELETE);
  };

  const handleDateConfirmation = ({ date }: { date: Date }): void => {
    handleSubmitPackingConfirmation(date);
  };

  const handleCrateFileUpload = (
    crateId: string,
    files: CrateFormFile[]
  ): void => {
    handleFileUpload(crateId, files);
  };

  const handleCrateFileDelete = (fileId: string): void => {
    handleFileDelete(fileId);
  };

  useEffect(() => {
    const value = confirmationStepIsDone(status, isCrateFormValid);
    setIsDone(value);
    handleConfirmationIsDone(value);
  }, [isCrateFormValid, packDate]);

  const handleCrateFormValid = (crates: CrateForm[]): void => {
    setIsCrateFormValid(isAllCratesConfirmedWithFiles(crates));
  };

  return (
    <MainEditWrapper>
      {!isDone && (
        <AccordionSubTitle
          title={`A - ${t(packingTranslations.titleCrateForm)}`}
          tooltip={t(packingTranslations.tooltipCrateForm)}
        />
      )}
      <PackingCrateForm
        bookingId={bookingId}
        status={status}
        isLoading={isLoading}
        isDone={isDone}
        isBeingEdited={isBeingEdited}
        packLoadVolume={packLoadVolume}
        handleConfirmCrate={handleConfirmCrate}
        formKeys={PACKING_CONFIRMATION_FORM_KEYS}
        handleDeleteCrate={handleDeleteCrate}
        formType={PackingCrateOperation.CONFIRMATION}
        handleCrateFormValid={handleCrateFormValid}
        handleCrateFileUpload={handleCrateFileUpload}
        handleCrateFileDelete={handleCrateFileDelete}
        subTitle={t(packingTranslations.subTitleCrateForm)}
        crates={crates?.map((crate) => ({
          ...crate,
          notEditable:
            !!crate.id && crate.status === PackingCrateStatus.CONFIRMED
        }))}
      />
      {!isDone && <Divider variant="fullWidth" sx={{ marginY: 24 }} />}
      {!isDone && (
        <AccordionSubTitle
          title={`B - ${t(packingTranslations.titleDateForm)}`}
        />
      )}
      <PackingDateForm
        dataTestId="packing-confirmation-date-form"
        isDone={isDone}
        date={packDate}
        disabled={!isCrateFormValid}
        isBeingEdited={isBeingEdited}
        label={t(packingTranslations.dateFormLabel)}
        buttonLabel={t(packingTranslations.dateFormButtonLabel)}
        handleDateChange={handleDateConfirmation}
      />
    </MainEditWrapper>
  );
}

export default PackingConfirmationEdit;
