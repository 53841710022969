import { useMemo } from 'react';

import { useBookingListFilter } from '../../../../hooks/useBookingListFilter';
import { BookingJobType } from '../../types';
import { usePackingRequests } from './usePackingRequests';

export interface PackingBookingsCounterReturnType {
  totalBookingsToUpdate: number;
  totalBookingsInComing: number;
  isLoading: boolean;
}

export const usePackingBookingsCounter =
  (): PackingBookingsCounterReturnType => {
    const { bookings, isLoading } = usePackingRequests();
    const { filterConfirmedBookings } = useBookingListFilter();

    const totalConfirmedBookings = useMemo(
      () =>
        filterConfirmedBookings(
          bookings[BookingJobType.CONFIRM_PACKING]?.data || []
        ).length,
      [filterConfirmedBookings, bookings]
    );

    const totalBookingsToUpdate = useMemo(() => {
      return (
        (bookings[BookingJobType.OVERDUE]?.total || 0) +
        totalConfirmedBookings +
        (bookings[BookingJobType.SCHEDULE_PACKING]?.total || 0) +
        (bookings[BookingJobType.CONFIRM_PICKUP]?.total || 0) +
        (bookings[BookingJobType.CONFIRM_DROP_OFF]?.total || 0)
      );
    }, [bookings, totalConfirmedBookings]);

    const totalBookingsInComing = useMemo(
      () => totalConfirmedBookings,
      [totalConfirmedBookings]
    );

    return {
      totalBookingsToUpdate,
      totalBookingsInComing,
      isLoading
    };
  };
