import { useTranslation } from 'react-i18next';

import { translations } from '../../../../../../../locales/translations';
import { BookingActionType } from '../../../../../../../types/BookingType';
import { ActivityData } from '../../ActivityData';
import { BuilderDecisionParams } from '../../BuilderDecisionParams';
import { EventMessageBuilder } from '../../EventMessageBuilder';

export const useBookingUpdatedMessageBuilder = (): EventMessageBuilder => {
  const { t } = useTranslation();

  const supports = (params: BuilderDecisionParams): boolean => {
    return params.eventResult.type === BookingActionType.BOOKING_UPDATED;
  };

  const build = (_: ActivityData): string => {
    return t(translations.pages.booking.features.activity.bookingUpdated);
  };

  return {
    supports,
    build
  };
};
