import { CostOverview } from 'src/app/pages/Booking/Features/Header/CostOverview';
import { BookingItem, LinkedBooking } from 'src/app/types/Booking';
import { BookingMacroType } from 'src/types/BookingType';

import { DepotBookingPdfDownloadLink } from '../../../../../../components/Pdf/Booking/Depot/DepotBookingPdfDownloadLink';
import { HeaderAccountName } from '../../../../Features/Header/HeaderAccountName';
import HeaderBookingNumber from '../../../../Features/Header/HeaderBookingNumber';
import HeaderFlags from '../../../../Features/Header/HeaderFlag';
import HeaderLink from '../../../../Features/Header/HeaderLink';
import { HeaderWrapper } from '../../../../Features/Header/HeaderWrapper';

type Props = {
  isLoading?: boolean;
  booking: BookingItem;
  next?: LinkedBooking;
  previous?: LinkedBooking;
};

function DepotHeader({
  booking,
  isLoading,
  next,
  previous
}: Props): JSX.Element {
  return (
    <HeaderWrapper
      type={BookingMacroType.DEPOT}
      isLoading={isLoading}
      flags={
        <HeaderFlags
          orderAccountName={booking.orderAccountName}
          customerRef={booking.customerRef}
          deliveryMethod={booking.deliveryMethod}
        />
      }
      costOverview={<CostOverview booking={booking} />}
      bookingNumber={<HeaderBookingNumber booking={booking} />}
      downloadPdf={<DepotBookingPdfDownloadLink booking={booking} />}
      link={
        next || previous ? (
          <HeaderLink previous={previous} next={next} />
        ) : undefined
      }
      accountName={<HeaderAccountName accountName={booking.accountName} />}
    />
  );
}

export default DepotHeader;
