import DashboardIcon from '@mui/icons-material/Dashboard';
import LineStyleIcon from '@mui/icons-material/LineStyle';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PagesIcon from '@mui/icons-material/Pages';
import { ListItemIcon, ListItemText, MenuList, styled } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { RefObject, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Route, routes } from 'src/types/Routes';

import { translations } from '../../../../../locales/translations';

const ListItemIconStyled = styled(ListItemIcon)({
  color: '#040D2B',
  fontSize: 'small'
});

const paperProps = {
  sx: {
    left: '0px !important',
    width: 800,
    maxWidth: '95%',
    marginLeft: '2.5%',
    overflow: 'visible',
    backgroundColor: `white`,
    color: '#040D2B',
    borderRadius: '0px'
  }
};

interface HamburgerMenuProps {
  handleClose: (event: Event | SyntheticEvent) => void;
  anchorRef: RefObject<HTMLButtonElement>;
  open: boolean;
}

export const HamburgerMenu = ({
  handleClose,
  open,
  anchorRef
}: HamburgerMenuProps): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const mainNavBarTranslations = translations.components.mainNavBar;
  const hambugerMenuitems = {
    dashboard: <DashboardIcon />,
    transport: <LocalShippingIcon />,
    packing: <PagesIcon />,
    depot: <LineStyleIcon />
  };

  const onClickMenuItem = (event: Event | SyntheticEvent, target: string) => {
    handleClose(event);
    navigate(routes[target as keyof Route]);
  };

  return (
    <Menu
      data-testid="hamburger-menu"
      id="hamburger-menu"
      anchorEl={anchorRef.current}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button'
      }}
      anchorOrigin={{
        vertical: 48,
        horizontal: 0
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      PaperProps={paperProps}
    >
      <MenuList>
        {Object.entries(hambugerMenuitems).map(([key, icon]) => (
          <MenuItem key={key} onClick={(e) => onClickMenuItem(e, key)}>
            <ListItemIconStyled>{icon}</ListItemIconStyled>
            <ListItemText>{t(mainNavBarTranslations[key])}</ListItemText>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
