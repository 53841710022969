export type Route = {
  dashboard: string;
  transport: string;
  transportRequests: string;
  transportPlanning: string;
  transportHistory: string;
  transportOverdue: string;
  transportToFinalize: string;
  transportToSchedule: string;
  transportToConfirm: string;
  transportOnHold: string;
  packing: string;
  depot: string;
  packingPlanning: string;
  packingHistory: string;
  packingBooking: string;
  depotPlanning: string;
  depotHistory: string;
};

export const routes: Route = {
  dashboard: `/dashboard/`,
  transport: `/dashboard/transport`,
  packing: `/dashboard/packing`,
  depot: `/dashboard/depot`,
  transportRequests: `/dashboard/transport/requests`,
  transportPlanning: `/dashboard/transport/planning`,
  transportHistory: `/dashboard/transport/history`,
  transportOverdue: `/dashboard/transport/overdue`,
  transportToFinalize: `/dashboard/transport/to-finalize`,
  transportToSchedule: `/dashboard/transport/to-schedule`,
  transportToConfirm: `/dashboard/transport/to-confirm`,
  transportOnHold: `/dashboard/transport/on-hold`,
  packingPlanning: `/dashboard/packing/planning`,
  packingHistory: `/dashboard/packing/history`,
  packingBooking: `/dashboard/packing/booking`,
  depotPlanning: `/dashboard/depot/planning`,
  depotHistory: `/dashboard/depot/history`
};
